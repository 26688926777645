import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import AuthContext from "../../../../context/auth/authContext";
import Modulos from "../../../general/screen/Modulos";
import { getToken } from "../../../helpers/validations";
import EcosistemaTitulo from "../../ecosistemas/EcosistemaTitulo";
import HomeInUser from "../../principal/components/HomeInUser";
import VidaCards from "../components/VidaCards";
import { getCurp } from "../../../../services/salud";
import ModalVidaCurp from "../../../hooks/components/ModalVidaCurp";
import useTitle from "../../../hooks/useTitle";
import Swal from "sweetalert2";

const VidaScreen = () => {
  useTitle({ title: "Detalle Ecosistema Vida" });
  const { globalCurpVida, globalCurpVidaModal, globalActualizarVidaCurp } =
    useContext(AuthContext);
  const { pathname } = useLocation();
  const { globalActualizarEcosistemaActivo } = useContext(AuthContext);
  const [mostrarModalCurp, setMostrarModalCurp] = useState(false);

  const getCurpVida = async () => {
    if (globalCurpVida === false) {
      Swal.fire({
        icon: "info",
        title: "Cargando...",
        allowOutsideClick: false,
        showConfirmButton: false,
      });
      let data = await getCurp(getToken());
      if (data?.codigo === 1) {
        // el usuario ya tiene actualizado el curp
        globalActualizarVidaCurp(true);
        setMostrarModalCurp(false);
      } else {
        setMostrarModalCurp(true);
      }
      Swal.close();
    }
  };

  useEffect(() => {
    setTimeout(() => {
      if (globalCurpVida) {
        if (pathname === "/ecosistema/vida") {
          globalActualizarEcosistemaActivo(13);
        }
      }
    }, 200);
    getCurpVida();
  }, [globalCurpVida]);

  useEffect(() => {
    getCurpVida();
  }, [globalCurpVidaModal]);

  return (
    <div className="homein">
      <EcosistemaTitulo />

      <div className="homein__resumen">
        <HomeInUser id={13} />
      </div>

      <div className="homein__detalle">
        <Modulos />
        <VidaCards />
      </div>

      <ModalVidaCurp show={mostrarModalCurp} close={setMostrarModalCurp} />
    </div>
  );
};

export default VidaScreen;
