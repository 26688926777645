import { useContext, useEffect, useRef, useState } from "react"
import AuthContext from "../../context/auth/authContext"

const events = ['keypress','mousemove','touchmove','click','scroll']

const useActive = (time) => {
    
    const {globalSesion, globalInactivaSesion} = useContext(AuthContext)
    const [active, setActive] = useState(true)
    const timer = useRef()

    useEffect(()=>{
        const handleEvent = () => {
            setActive(true)
            if(timer.current){
                window.clearTimeout(timer.current)
                // console.log('limpio timeOut')
            }
            
            timer.current = window.setTimeout(()=>{
                // console.log('activo timeOut')
                setActive(false)
                if(!globalSesion){
                    globalInactivaSesion()
                }
            }, time)
        }

        events.forEach((event)=>{
            // console.log('inicio efecto')
            document.addEventListener(event, handleEvent)
        })

        return ()=>{
            events.forEach((event)=>{
                // console.log('limpio efecto evento')
                document.removeEventListener(event, handleEvent)
            })
        }

    },[time])

    return active

}

export default useActive