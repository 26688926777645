import React from 'react'

const AuthRegisterImagenes = () => {
    return (
        <div className="login__container-imagenes desktop">
            <img src="/assets/img/homeout/img-activar-cuenta.webp" alt="imagen de activar cuenta" />
        </div>
    )
}

export default AuthRegisterImagenes