import React from 'react'
import useBotons from '../../hooks/useBotons'
import '../styles/activarcuenta.css'

const HomeOutActivaTuCuenta = () => {
    const {activaCuentaBtn} = useBotons()

    return (
        <div className='out__activarcuenta'>
            <h3>Activa tu cuenta</h3>
            <p>Valida tu cuenta en 1 minuto y accede a tus beneficios</p>
            <div className='eco__unirme'>
                {activaCuentaBtn('verde', true)}
            </div>
        </div>
    )
}

export default HomeOutActivaTuCuenta