import React from 'react'
import useCards from '../../../hooks/useCards'
import useOutEcosistema from '../../../hooks/useOutEcosistema'
import useTitle from '../../../hooks/useTitle'
import HomeOutActivaTuCuenta from '../../ecosistemas/HomeOutActivaTuCuenta'
import PrincipalContacto from '../../principal/components/PrincipalContacto'
import PrincipalLogos from '../../principal/components/PrincipalLogos'
import "../styles/emprendimiento.css";

const HomeOutEmprendimiento = () => {

  useTitle({ title: 'Emprendimiento | BNP Paribas Cadif' })
  const { ecosistemaPresentacionOut } = useOutEcosistema()
  const { ecosistemaCards } = useCards()

  return (
    <>
      {ecosistemaPresentacionOut(2)}

      {ecosistemaCards(2)}


      <HomeOutActivaTuCuenta />
      <div style={{background:'var(--bg_claro)'}}>
        <PrincipalLogos direccion='centro' />

        <PrincipalContacto />
      </div>
    </>
  )
}

export default HomeOutEmprendimiento