import React from 'react'


const PrincipalContactoContactos = () => {

    let linkFace = 'https://www.facebook.com/profile.php?id=100063739894626'
    let linkLinked = 'https://www.linkedin.com/showcase/cardif-m%C3%A9xico'

    const abrirLink = (link) =>{
        window.open(link, '_blank', 'noopener,noreferrer')
        
    }

    const sendSMSEmail = () =>{
        window.location = 'mailto:contacto@vivetuseguro.com'
    }

    const makeCall = () =>{
        window.location = 'tel:8008012402'
    }



    return (
        <div className="contacto__contactanos" id="contactoFooter">
            <div className="contacto__contactanos-email" style={{fontFamily:'Scotia-Headline'}}>

                <p>Contáctanos:</p>
                <p><span onClick={()=>{sendSMSEmail()}} style={{cursor:"pointer"}} >contacto@vivetuseguro.com</span></p>
            </div>

            <div className="contacto__contactanos-llamada">
                <div className="contactanos__llamada-group">
                    <div className="contactanos__llamada">
                        <p>Centro de atención a clientes:</p>
                        <p onClick={()=>{makeCall()}} style={{cursor:"pointer"}} className='llamar-telefono-mobile'>800 801 2402</p>
                    </div>
                    {/* <div className="contactanos__llamada">
                        <p>Línea CrediScotia:</p>
                        <p>(01) 615-5708</p>
                    </div> */}
                </div>
                <div className="contactanos__redes">
                    <p>Síguenos en:</p>
                    <div>
                        <img src="/assets/img/homeout/ico_facebook.svg" alt="logo red facebook" onClick={()=>{abrirLink(linkFace)}} style={{cursor:"pointer"}} />
                        <i className="fa-brands fa-linkedin" style={{color:"var(--rojo)",fontSize:"40px",marginLeft:"10px",cursor:'pointer'}} onClick={()=>{abrirLink(linkLinked)}} ></i>
                        {/* <img src="/assets/img/homeout/ico_twiter.png" alt="logo red instagram" />
                        <img src="/assets/img/homeout/ico_youtube.svg" alt="logo red youtube" /> */}
                    </div>
                </div>
            </div>

        </div>
    )
}

export default PrincipalContactoContactos