import React, { Suspense } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import PageNotFound from '../../modules/404/PageNotFound'
import FallbackCards from '../../modules/helpers/FallbackCards'
import PrincipalContactoResumen from '../../modules/homeout/principal/components/PrincipalContactoResumen'
import HomeOutSalud from '../../modules/homeout/salud/screens/HomeOutSalud'

const RouterOutSalud = () => {

    return (
        <>
            <Suspense fallback={<FallbackCards />}>
                <Routes>
                    <Route path='/' element={<HomeOutSalud />} />
                    <Route path='/404' element={<PageNotFound />} />
                    <Route path='*' element={<Navigate to="/404" />} />
                </Routes>

                {window.location.pathname !== "/salud" && < PrincipalContactoResumen />}
            </Suspense>
        </>
    )

}

export default RouterOutSalud