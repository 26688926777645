export const terminosycondiciones = {
    titulo: 'Términos y condiciones',
    descrip: [
        'Bienvenido al sitio web de Cardif México Seguros de Vida, S.A. de C.V. / Cardif México Seguros Generales, S.A. de C.V. (en adelante, indistintamente, “Cardif”, o “Nosotros”.) Los términos "Usted" y "Usuario" según se utilizan, hacen referencia a toda persona y/o entidad que acceda al presente Sitio.',
        'Por medio del presente documento, el Usuario tendrá acceso a toda aquella información relacionada con los términos y condiciones de uso del sitio web  www.vivetuseguro.com (en adelante, el “Sitio”). Al aceptar estos términos y condiciones, el Usuario declara haberlos leído y comprender que éstos regirán el uso del Sitio. En caso de no aceptarlos o comprenderlos adecuadamente, el Usuario no podrá o deberá abstenerse de utilizar el Sitio, en caso contrario, reconoce y acepta que el uso del Sitio implica la aceptación de los presentes términos y condiciones de uso.',
        'Estos términos y condiciones serán aplicables a todo Usuario del Sitio. Cardif, en cualquier momento, podrá realizar todo tipo de modificaciones al contenido, materiales e información que aparece sobre, o en relación con el Sitio, así como interrumpir, suspender o terminar su funcionamiento en cualquier momento y sin previo aviso. Por lo anterior, los Usuarios se comprometen a revisar los presentes Términos y Condiciones de forma periódica para conocer dichas actualizaciones. No obstante, todo lo anterior Cardif le informará a los Usuarios de las actualizaciones mediante los medios electrónicos descritos en el presente documento.',
        'I.	ASPECTOS GENERALES DE USO DEL SITIO',
        'Usted podrá hacer uso del Sitio desde cualquier ordenador o dispositivo móvil que cuente con acceso a Internet, y cumpla con los requerimientos técnicos señalados más adelante. Cualquier Usuario podrá acceder al Sitio, sin embargo, para poder solicitar y hacer uso de los beneficios disponibles del Sitio, el Usuario deberá tener uno o más productos de seguros respaldados por Cardif, ya sea en su calidad de contratante o como Titular, la cual será verificada de forma previa al uso de los beneficios y por los medios que determine Cardif.',
        'Solo si el producto de seguro adquirido con Cardif, así lo tiene previsto y sujeto a las reglas del mismo, el Titular podrá designar a terceros, para que se les dé acceso al Sitio, ya sea para el uso de todos o solo de un número determinado de beneficios. Dichos terceros serán considerados como Usuarios y estarán sujetos a los presentes Términos y Condiciones.',
        'Toda la información que sea ingresada al Sitio será considerada como veraz y actualizada, por lo que el Usuario será el único responsable por mantener esa información al día, de manera tal que refleje su situación actual y real. Por lo anterior, Cardif, no se hace responsable de errores, inconvenientes o daños de cualquier tipo, que surjan por errores, imprecisiones o inconsistencias en la información proporcionada por el Usuario.',
        'Por lo anterior, los Usuarios deberán facilitar información de la que sean titulares, evitando la vulneración de derechos de terceros y en particular, derechos de propiedad intelectual y/o derechos de imagen. En el supuesto de que los Usuarios incumplan dicha obligación, responderán frente a terceros de los daños y perjuicios ocasionados, manteniendo en paz y a salvo en todo momento a Cardif.',
        'Igualmente, los Usuarios se obligan a respetar las leyes aplicables y los derechos de terceros al utilizar los contenidos y servicios del Sitio, por lo que quedan obligados a utilizarlos conforme a las buenas prácticas de mercado y de orden público.',
        'Vínculos con otros sitios en la Internet',
        'II.	MECÁNICA DE FUNCIONAMIENTO DEL SITIO',
        'Para acceder al Sitio, el Usuario procederá con el registro correspondiente, por lo que deberá proporcionar toda la información que sea requerida en el mismo.',
        'En virtud de lo anterior, todo Usuario asociado a uno o varios productos de seguro de Cardif, a través del Sitio, podrá tener acceso, entre otras, a las siguientes funcionalidades:',
        'a.	Acceso y uso a los beneficios contemplados en la póliza de seguro que el Usuario tenga contratada con Cardif.',
        'b.	Medios de contacto con Cardif.',
        'c.	Recuperación y modificación de clave de acceso al Sitio.',
        'Asimismo, Cardif podrá, de tiempo en tiempo, agregar nuevas funcionalidades o actualizar y/o modificar aquellas ya existentes en el Sitio, mismas que serán oportunamente notificadas a los Usuarios a través de los medios descritos en este documento.',
        'Cada Usuario acepta que, salvo en caso de manifestar su oposición, las comunicaciones que reciba en relación con uso del Sitio le serán enviadas a la dirección de correo electrónico registrada en su perfil.',
        'Adicionalmente, al utilizar y registrarse en el Sitio, usted acepta que cualquier información, documento y otro antecedente que comparta con Cardif será almacenado y registrado por Cardif para efectos de dar cumplimiento a la normativa vigente y sus obligaciones contractuales bajo las pólizas de seguros que emite y que estarán a su disposición en caso de requerirse.',
        'III.	RESTRICCIONES',
        'La inclusión de vínculos a otros sitios no implica ninguna relación diferente al "vínculo" mismo.Todas las transacciones realizadas en dichos vínculos son responsabilidad exclusiva del usuario y de la entidad relacionada.',
        'Derecho de propiedad Industrial o Comercial',
        'El usuario reconoce que el contenido(incluidos entre otros: texto, software, música, sonido, fotografías, vídeo, gráficos u otro material) ubicado bien sea en la publicidad de los anunciantes o en la información producida comercialmente y distribuida de forma electrónica y presentada al usuario por BNP PARIBAS CARDIF por sí mismo o por un tercero autorizado, está protegido por derechos de autor, marcas, patentes u otros bienes mercantiles o formas diferentes del derecho de propiedad y no podrá ser modificado, copiado, publicado, o distribuido por el usuario.Esto puede incurrir en responsabilidad civil y responsabilidad penal según las normas vigentes.',
        'Derecho de los Invitados',
        'El invitado tendrá derecho a acceder a los cursos disponibles en esta web por invitación del Usuario titular, y siempre que éste mantenga dicha invitación vigente durante la vigencia de la relación contractual con BNP Paribas Cardif.La calidad de invitado no le otorga derecho ni beneficio alguno respecto a las coberturas de seguros contratadas con BNP Paribas Cardif.',
        'Proceso de Screening',
        'Todos los invitados inscritos, estarán sujetos a un proceso de aprobación dentro de los 7 días hábiles posteriores a su inscripción.',
    ]
}