const AuthReducer = (state, payload) => {
  switch (payload.action) {
    case "INICIAR_SESION":
      return {
        ...state,
        globalDataUser: payload.data.globalDataUser,
        globalModulos: payload.data.globalModulos,
        globalToken: payload.data.globalToken,
        globalTokenUser: payload.data.globalTokenUser,
        globalAutenticado: true,
        globalCargando: false,
        globalSesion: false,
        globalStatusEncuesta: payload.data.globalStatusEncuesta,
      };
    case "ACTUALIZAR_USUARIO":
      return {
        ...state,
        globalDataUser: {
          ...state.globalDataUser,
          globalUsuName: payload.data.globalUsuName,
          globalUsuSurname: payload.data.globalUsuSurname,
          globalUsuSurnameMat: payload.data.globalUsuSurnameMat,
          globalUsuEmail: payload.data.globalUsuEmail,
          globalUsuCellphone: payload.data.globalUsuCellphone,
        },
      };
    case "ACTUALIZAR_DOCUMENTO_USUARIO":
      return {
        ...state,
        globalDataUser: {
          ...state.globalDataUser,
          globalDocIdentidad: payload.data,
        },
      };
    case "ACTUALIZAR_ECOSISTEMA_ACTIVO":
      return {
        ...state,
        globalEcosistemaActivo: payload.data,
      };
    case "ACTIVA_VENTANA_CIERRE":
      return {
        ...state,
        globalVentanaCierre: true,
      };
    case "DESACTIVA_VENTANA_CIERRE":
      return {
        ...state,
        globalVentanaCierre: false,
      };
    case "INACTIVA_SESION":
      return {
        ...state,
        globalDataUser: {
          globalUsuId: "",
          globalUsuName: "",
          globalUsuSurnameMat: "",
          globalUsuSurname: "",
          globalUsuCellphone: "",
          globalUsuEmail: "",
          globalUsuTipoDoc: "",
          globalDocIdentidad: "",
          globalUsuRole: "",
          globalInvitado: "",
          globalPlanCardifId: "",
        },
        globalModulos: {
          modValorFactura: false,
          modCuidados: false,
          modPFinanciera: false,
          modEmpleabilidad: false,
          modEmprendimiento: false,
          modSalud: false,
          modVida: false,
          modCancer: false,
          modAccidentes: false,
          modProteccionFinanciera: false,
          modVidaPlus: false,
          modPFinancieraElemental: false,
          modCoursera: false,
        },
        globalToken: "",
        globalTokenUser: "",
        globalAutenticado: false, // por defecto debe ser FALSE
        globalCargando: true, // por defecto debe ser TRUE
        globalVentanaCierre: true,
        globalStatusEncuesta: false,
      };
    case "ACTUALIZAR_ENCUESTA":
      return {
        ...state,
        globalStatusEncuesta: payload.data,
      };
    case "CERRAR_SESION":
      return {
        ...state,
        globalDataUser: {
          globalUsuId: "",
          globalUsuName: "",
          globalUsuSurnameMat: "",
          globalUsuSurname: "",
          globalUsuCellphone: "",
          globalUsuEmail: "",
          globalUsuTipoDoc: "",
          globalDocIdentidad: "",
          globalUsuRole: "",
          globalInvitado: "",
          globalPlanCardifId: "",
        },
        globalModulos: {
          modValorFactura: false,
          modCuidados: false,
          modPFinanciera: false,
          modEmpleabilidad: false,
          modEmprendimiento: false,
          modSalud: false,
          modVida: false,
          modCancer: false,
          modAccidentes: false,
          modProteccionFinanciera: false,
          modVidaPlus: false,
          modPFinancieraElemental: false,
          modCoursera: false,
        },
        globalToken: "",
        globalTokenUser: "",
        globalAutenticado: false, // por defecto debe ser FALSE
        globalCargando: true, // por defecto debe ser TRUE
        globalVentanaCierre: false,
        globalStatusEncuesta: false,
      };
    case "ACTUALIZAR_INVITADOR":
      return {
        ...state,
        globalNroInvitados: payload.data,
      };
    case "ACTUALIZAR_BENEFICIARIOS":
      return {
        ...state,
        globalBeneficiariosSalud: payload.data,
      };
    case "ACTUALIZAR_BENEFICIARIOS_MODAL":
      return {
        ...state,
        globalBeneficiariosSaludModal: payload.data,
      };
    case "ACTUALIZAR_BENEFICIARIOS_MODAL_ENCASA":
      return {
        ...state,
        globalBeneficiariosSaludModalEnCasa: payload.data,
      };
    case "ACTUALIZAR_VIDA_CURP":
      return {
        ...state,
        globalCurpVida: payload.data,
      };
    case "ACTUALIZAR_VIDA_CURP_MODAL":
      return {
        ...state,
        globalCurpVidaModal: payload.data,
      };
    default:
      return state;
  }
};

export default AuthReducer;
