import React, { useContext, useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import AuthContext from "../../context/auth/authContext";
import useBotons from "./useBotons";
import "../hooks/ecostyles.css";

const usePresentacion = (
  botonActivo = true,
  textoBoton = "",
  idModuloEcosistema = 1
) => {
  const { pathname } = useLocation();
  const { globalDetalleEcosistemas } = useContext(AuthContext);
  const { iniciaProcesoBtn, iniciaProcesoBtnAccion, activaCuentaBtn } =
    useBotons();
  const [direccion, setDireccion] = useState([]);

  useEffect(() => {
    let dir = pathname.split("/");
    setDireccion(dir);
  }, []);

  const presentacion = () => {
    return (
      <>
        <section className="eco__presentacion">
          {/* ZONA DE RUTAS - MIGAJAS */}
          <div className="presentacion__migaja">
            <div className="migaja__texto">
              <NavLink
                to={`/${direccion[1]}/${direccion[2]}`}
                className="migaja__link"
              >
                Inicio
              </NavLink>
              {direccion
                .filter((d, i) => i >= 3)
                .map((dir, index) => {
                  return (
                    <div key={index}>
                      <span>{" >"}</span>

                      <NavLink
                        to={`/${direccion[1]}/${direccion[2]}/${direccion[3]}`}
                        className="migaja__link"
                      >
                        {dir}
                      </NavLink>
                    </div>
                  );
                })}
            </div>
          </div>
        </section>
      </>
    );
  };

  const presentacionOut = () => {
    let data = globalDetalleEcosistemas.filter(
      (g) => g.id === idModuloEcosistema
    )[0];

    return (
      <>
        <div className="miga__box_c">
          <div className="miga__texto">
            <NavLink to={`/${direccion[1]}`} className="miga__link">
              Inicio
            </NavLink>
            {direccion
              .filter((d, i) => i >= 2)
              .map((dir, index) => {
                return (
                  <div key={index}>
                    <span>{" >"}</span>

                    <span
                      to={`/${direccion[1]}/${direccion[2]}}`}
                      className="miga__link"
                    >
                      {dir}
                    </span>
                  </div>
                );
              })}
          </div>
        </div>
        <section
          className="eco__presentacion sinfondoresponsive"
          style={{
            backgroundImage: `url(${data.img})`,
            backgroundSize: "contain",
            backgroundPosition: "right center",
            backgroundRepeat: "no-repeat",
            //height: '40rem',
          }}
        >
          <div className="fondoblanco">
            <div className="eco__presentacion-textos izquierda">
              {/* ZONA DE TEXTOS Y BOTONES */}
              <div className="eco__textos">
                <h2 className="ecoout__textos-titlegrande">{data.tituloOut}</h2>
                <p className="eco__textos-descripmini">{data.descripcionOut}</p>

                {idModuloEcosistema === 51 ? (
                  <p className="eco__textos-descripmini">
                    • Recibir la calificación del comportamiento de tus créditos
                    en un solo número.
                    <br />
                    • Comparar tus resultados con el resto de la población en
                    México.
                    <br />
                    • Obtener los factores que influyen en tus resultados y cómo
                    puedes mejorar tu puntuación.
                    <br />
                    • Conocer tu estatus crediticio.
                    <br />
                  </p>
                ) : (
                  <></>
                )}

                {idModuloEcosistema === 52 ? (
                  <p className="eco__textos-descripmini">
                    <img
                      src="/assets/img/ecosistemas/proteccion-elemental/interna/uber-logo.png"
                      alt="imagen ecosistema"
                    />
                    <br></br>
                    Este servicio cubre un (1) evento al año y hasta por un
                    monto máximo de $300 pesos.
                    <br />
                  </p>
                ) : (
                  <></>
                )}

                <div className="proteccion__btns">{activaCuentaBtn()}</div>
                {idModuloEcosistema === 48 ? (
                  <p className="eco__textos-descripmini">
                    Ponemos a tu disposición una red de servicios de salud al
                    lugar donde te encuentres.
                  </p>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>

          {/* ZONA DE IMAGEN */}
          <div className="eco__presentacion-img desktop"></div>
        </section>
      </>
    );
  };

  const presentacionAccion = (siguienteLink, accion) => {
    // let data = globalDetalleEcosistemas[idModuloEcosistema - 1]
    let data = globalDetalleEcosistemas.filter(
      (g) => g.id === idModuloEcosistema
    )[0];

    return (
      <>
        <section className="eco__presentacion">
          {/* ZONA DE RUTAS - MIGAJAS */}
          <div className="presentacion__migaja">
            <div className="migaja__texto">
              <NavLink
                to={`/${direccion[1]}/${direccion[2]}`}
                className="migaja__link"
              >
                Inicio
              </NavLink>
              {direccion
                .filter((d, i) => i >= 3)
                .map((dir, index) => {
                  return (
                    <div key={index}>
                      <span>{" >"}</span>

                      <NavLink
                        to={`/${direccion[1]}/${direccion[2]}/${direccion[3]}`}
                        className="migaja__link"
                      >
                        {dir}
                      </NavLink>
                    </div>
                  );
                })}
            </div>
          </div>

          {/* ZONA DE TEXTOS Y BOTONES */}
          <div className={`eco__presentacion-textos`}>
            <div>
              <h2 className="eco__textos-title">{data.title}</h2>
              {/* <h2 className={`eco__textos-subtitle`}>{data.descrip}</h2> */}
              <p className="eco__textos-descrip">{data.descrip}</p>

              <div className="proteccion__btns">
                {iniciaProcesoBtnAccion(
                  botonActivo,
                  textoBoton,
                  data.colorPrincipal,
                  false,
                  siguienteLink,
                  accion
                )}
              </div>
            </div>
          </div>

          {/* ZONA DE IMAGEN */}
          <div className="eco__presentacion-img">
            <div>
              <img src={data.imgDescrip} alt="imagen ecosistema" />
            </div>
          </div>
        </section>
      </>
    );
  };

  const presentacionSinImagen = () => {
    return (
      <>
        <section className="eco__presentacion-mini">
          {/* ZONA DE RUTAS - MIGAJAS */}
          <div className="presentacion__migaja">
            <div className="migaja__texto">
              <NavLink
                to={`/${direccion[1]}/${direccion[2]}`}
                className="migaja__link"
              >
                Inicio
              </NavLink>
              {direccion
                .filter((d, i) => i >= 3)
                .map((dir, index) => {
                  return (
                    <div key={index}>
                      <span>{" >"}</span>
                      {index === 0 && (
                        <NavLink
                          to={`/${direccion[1]}/${direccion[2]}/${direccion[3]}`}
                          className="migaja__link"
                        >
                          {dir}
                        </NavLink>
                      )}
                      {index === 1 && (
                        <NavLink
                          to={`/${direccion[1]}/${direccion[2]}/${direccion[3]}/${direccion[4]}`}
                          className="migaja__link"
                        >
                          {dir}
                        </NavLink>
                      )}
                      {index === 2 && (
                        <NavLink
                          to={`/${direccion[1]}/${direccion[2]}/${direccion[3]}/${direccion[4]}/${direccion[5]}`}
                          className="migaja__link"
                        >
                          {dir}
                        </NavLink>
                      )}
                      {index === 3 && (
                        <NavLink
                          to={`/${direccion[1]}/${direccion[2]}/${direccion[3]}/${direccion[4]}/${direccion[5]}/${direccion[6]}`}
                          className="migaja__link"
                        >
                          {dir}
                        </NavLink>
                      )}
                    </div>
                  );
                })}
            </div>
          </div>
        </section>
      </>
    );
  };

  const presentacionTexto = () => {
    return (
      <>
        <section className="eco__presentacion-mini">
          {/* ZONA MIGRAJA */}
          <div className="presentacion__migaja">
            <div className="migaja__texto">
              <NavLink
                to={`/${direccion[1]}/${direccion[2]}`}
                className="migaja__link"
              >
                Inicio
              </NavLink>
              {direccion
                .filter((d, i) => i >= 3)
                .map((dir, index) => {
                  return (
                    <div key={index}>
                      <span>{" >"}</span>
                      <NavLink
                        to={`/${direccion[1]}/${direccion[2]}/${direccion[3]}`}
                        className="migaja__link"
                      >
                        {dir}
                      </NavLink>
                    </div>
                  );
                })}
            </div>
          </div>
        </section>
      </>
    );
  };

  const presentacionHoja = (titulo = "", etapa = "1") => {
    let data = globalDetalleEcosistemas.filter(
      (g) => g.id === idModuloEcosistema
    )[0];
    // let data = globalDetalleEcosistemas[idModuloEcosistema - 1]
    return (
      <>
        <section className="eco__presentacion">
          <div className="presentacion__migaja">
            <div className="migaja__texto">
              <NavLink
                to={`/${direccion[1]}/${direccion[2]}`}
                className="migaja__link"
              >
                Inicio
              </NavLink>
              {direccion
                .filter((d, i) => i >= 3)
                .map((dir, index) => {
                  return (
                    <div key={index}>
                      <span>{" >"}</span>
                      <NavLink
                        to={`/${direccion[1]}/${direccion[2]}/${direccion[3]}`}
                        className="migaja__link"
                      >
                        {dir}
                      </NavLink>
                    </div>
                  );
                })}
            </div>
          </div>
          <div
            className={`eco__presentacion-textos altura`}
            style={{ backgroundColor: `${data.colorPrincipal}` }}
          >
            <div>
              <h2>{titulo}</h2>
              <div className="presentacionTexto__etapa">
                <div
                  className={
                    etapa === "1" ? "etapa__proceso_hoja" : "etapa__proceso"
                  }
                  style={{
                    backgroundColor: `${
                      etapa === "1" ? "#fff" : `${data.colorPrincipal}`
                    }`,
                    color: `${
                      etapa === "1" ? `${data.colorPrincipal}` : "#fff"
                    }`,
                  }}
                >
                  {etapa === "1" ? <>1 Datos personales</> : <>1</>}
                </div>
                <div className="etapa__linea"></div>
                <div
                  className={
                    etapa === "2" ? "etapa__proceso_hoja" : "etapa__proceso"
                  }
                  style={{
                    backgroundColor: `${
                      etapa === "2" ? "#fff" : `${data.colorPrincipal}`
                    }`,
                    color: `${
                      etapa === "2" ? `${data.colorPrincipal}` : "#fff"
                    }`,
                  }}
                >
                  {etapa === "2" ? <>2 Educación</> : <>2</>}
                </div>
                <div className="etapa__linea"></div>
                <div
                  className={
                    etapa === "3" ? "etapa__proceso_hoja" : "etapa__proceso"
                  }
                  style={{
                    backgroundColor: `${
                      etapa === "3" ? "#fff" : `${data.colorPrincipal}`
                    }`,
                    color: `${
                      etapa === "3" ? `${data.colorPrincipal}` : "#fff"
                    }`,
                  }}
                >
                  {etapa === "3" ? <>3 Experiencia laboral</> : <>3</>}
                </div>
                <div className="etapa__linea"></div>
                <div
                  className={
                    etapa >= "4" ? "etapa__proceso_hoja" : "etapa__proceso"
                  }
                  style={{
                    backgroundColor: `${
                      etapa >= "4" ? "#fff" : `${data.colorPrincipal}`
                    }`,
                    color: `${
                      etapa >= "4" ? `${data.colorPrincipal}` : "#fff"
                    }`,
                  }}
                >
                  {etapa >= "4" ? <>4 Personalización</> : <>4</>}
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  };

  const presentacionIn = (ecosys, service, acc, siguienteLink) => {
    let data = globalDetalleEcosistemas.filter(
      (g) => g.id === idModuloEcosistema
    )[0];
    // console.log({direccion})
    let fondo = true;
    if (idModuloEcosistema === 12) fondo = false;
    return (
      <>
        <div className="miga__box">
          <div className="miga__texto">
            <NavLink
              to={`/${direccion[1]}/${direccion[2]}`}
              className="miga__link"
            >
              Inicio
            </NavLink>
            {direccion
              .filter((d, i) => i >= 3)
              .map((dir, index) => {
                return (
                  <div key={index}>
                    <span>{" >"}</span>

                    <NavLink
                      to={`/${direccion[1]}/${direccion[2]}/${direccion[3]}`}
                      className="miga__link"
                    >
                      {dir}
                    </NavLink>
                  </div>
                );
              })}
          </div>
        </div>
        <section
          className="eco__presentacion sinfondoresponsive"
          style={{
            backgroundImage: `url(${data.img})`,
            backgroundSize: "contain",
            backgroundPosition: "right center",
            backgroundRepeat: "no-repeat",
            height: "40rem",
          }}
        >
          {/* ZONA DE RUTAS - MIGAJAS */}

          {/* ZONA DE TEXTOS Y BOTONES */}
          <div className="fondoblanco">
            <div className="eco__presentacion-textos">
              <div className="eco__textos">
                <h2 className="eco__textos-titlegrande">{data.tituloOut}</h2>
                <p className="eco__textos-descripmini">{data.descripcionOut}</p>

                <div className="proteccion__btns">
                  {iniciaProcesoBtn(
                    ecosys,
                    service,
                    acc,
                    botonActivo,
                    textoBoton,
                    data.colorPrincipal,
                    fondo,
                    siguienteLink,
                    data.descrip,
                    "Banner Principal"
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* ZONA DE IMAGEN */}
          <div className="eco__presentacion-img desktop"></div>
        </section>
      </>
    );
  };

  return {
    presentacion,
    presentacionOut,
    presentacionTexto,
    presentacionSinImagen,
    presentacionAccion,
    presentacionHoja,
    presentacionIn,
  };
};

export default usePresentacion;
