import React, { Suspense } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import FallbackCards from '../../modules/helpers/FallbackCards'

const ValorFacturaScreen = React.lazy(() => import('../../modules/homein/valor-factura/screens/ValorFacturaScreen'))
const ValorFacturaVerificacionVehicular = React.lazy(() => import('../../modules/homein/valor-factura/components/verificacion-vehicular/ValorFacturaVerificacionVehicular'))
const ValorFacturaMultas = React.lazy(() => import('../../modules/homein/valor-factura/components/multas/ValorFacturaMultas'))
const ValorFacturaCuponesDescuento = React.lazy(() => import('../../modules/homein/valor-factura/components/cupones-descuento/ValorFacturaCuponesDescuento'))
const ValorFacturaCuponesTrimestrales = React.lazy(() => import('../../modules/homein/valor-factura/components/cupones-trimestrales/ValorFacturaCuponesTrimestrales'))
const PageNotFound = React.lazy(() => import('../../modules/404/PageNotFound'))

const RouterValorFactura = () => {
    return (
        <>
            <Suspense fallback={<FallbackCards />}>
                <Routes>
                    <Route path='/verificacion-vehicular' element={<ValorFacturaVerificacionVehicular />} />
                    <Route path='/multas' element={<ValorFacturaMultas />} />
                    <Route path='/cupones-descuento' element={<ValorFacturaCuponesDescuento />} />
                    <Route path='/cupones-trimestrales' element={<ValorFacturaCuponesTrimestrales />} />
                    <Route path='/' element={<ValorFacturaScreen />} />
                    <Route path='/404' element={<PageNotFound />} />
                    <Route path='*' element={<Navigate to="/404" />} />
                </Routes>
            </Suspense>
        </>
    )
}

export default RouterValorFactura