import React, { Suspense } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import FallbackCards from '../../modules/helpers/FallbackCards'
import HomeOutEmprendimiento from '../../modules/homeout/emprendimiento/screens/HomeOutEmprendimiento'
import PrincipalContactoResumen from '../../modules/homeout/principal/components/PrincipalContactoResumen'
import '../../modules/homeout/emprendimiento/styles/homeOutProteccion.css'
import PageNotFound from '../../modules/404/PageNotFound'

const EmprendimientoOutCoursera = React.lazy(()=> import('../../modules/homeout/emprendimiento/components/EmprendimientoOutCoursera'))
const EmprendimientoOutMarketing = React.lazy(()=> import('../../modules/homeout/emprendimiento/components/EmprendimientoOutMarketing'))
const EmprendimientoOutCoworking = React.lazy(()=> import('../../modules/homeout/emprendimiento/components/EmprendimientoOutCoworking'))
const EmprendimientoOutAsesoria = React.lazy(()=> import('../../modules/homeout/emprendimiento/components/EmprendimientoOutAsesoria'))

const RouterOutEmprendimiento = () => {
    return (
        <>
            <Suspense fallback={<FallbackCards />}>

                <Routes>
                    <Route path='/coursera' element={<EmprendimientoOutCoursera />} />
                    <Route path='/marketing' element={<EmprendimientoOutMarketing />} />
                    <Route path='/coworking' element={<EmprendimientoOutCoworking />} />
                    <Route path='/legal' element={<EmprendimientoOutAsesoria />} />
                    <Route path='/' element={<HomeOutEmprendimiento />} />
                    <Route path='/404' element={<PageNotFound />} />
                    <Route path='*' element={<Navigate to="/404" />} />
                </Routes>

                {window.location.pathname !== "/emprendimiento" && < PrincipalContactoResumen />}
            </Suspense>
        </>
    )
}

export default RouterOutEmprendimiento