import React, { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import AuthContext from "../../../../context/auth/authContext";
import Modulos from "../../../general/screen/Modulos";
import EcosistemaTitulo from "../../ecosistemas/EcosistemaTitulo";
import HomeInUser from "../../principal/components/HomeInUser";
import ProteccionFinancieraElementalCards from "../components/ProteccionFinancieraElementalCards";
import useTitle from "../../../hooks/useTitle";

const ProteccionFinancieraElementalScreen = () => {
  useTitle({ title: "Detalle Ecosistema Salud Financiera" });
  const { pathname } = useLocation();
  const { globalActualizarEcosistemaActivo } = useContext(AuthContext);

  useEffect(() => {
    if (pathname === "/ecosistema/mi-salud-financiera") {
      globalActualizarEcosistemaActivo(10);
    }
  }, []);

  return (
    <div className="homein">
      <EcosistemaTitulo />

      <div className="homein__resumen">
        <HomeInUser id={10} />
      </div>

      <div className="homein__detalle">
        <Modulos />
        <ProteccionFinancieraElementalCards />
      </div>
    </div>
  );
};

export default ProteccionFinancieraElementalScreen;
