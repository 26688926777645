import React, { useEffect } from 'react'
import { NavLink } from 'react-router-dom'

const HeaderHamburger = ({ setUser, hamburger, setHamburger }) => {

    const activarMenuBeneficios = () => {
        setHamburger(!hamburger)
    }

    useEffect(() => {
        setUser(false)
    }, [])

    return (
        <ul className='hamburger'>
            <div className='hamburger__title'>¿Que Seguro tienes?</div>
           
            <NavLink onClick={activarMenuBeneficios} to="/emprendimiento" className={({ isActive }) => isActive ? 'header__text active' : 'header__text'}>
                <li>
                    <div className='hamburger__text'>Seguro de Protección de Ingresos Emprendimiento</div>
                </li>
            </NavLink>
            <NavLink onClick={activarMenuBeneficios} to="/empleabilidad" className={({ isActive }) => isActive ? 'header__text active' : 'header__text'}>
                <li>
                    <div className='hamburger__text'>Seguro de Protección de Ingresos Empleabilidad</div>
                </li>
            </NavLink>
            
            <NavLink onClick={activarMenuBeneficios} to="/proteccion-financiera" className={({ isActive }) => isActive ? 'header__text active' : 'header__text'}>
                <li>
                    <div className='hamburger__text'>Seguro de Protección Financiera</div>
                </li>
            </NavLink>
            
            <NavLink onClick={activarMenuBeneficios} to="/vidaplus" className={({ isActive }) => isActive ? 'header__text active' : 'header__text'}>
                <li>
                    <div className='hamburger__text'>Seguro de Vida Plus</div>
                </li>
            </NavLink>

            <NavLink onClick={activarMenuBeneficios} to="/mi-salud-financiera" className={({ isActive }) => isActive ? 'header__text active' : 'header__text'}>
                <li>
                    <div className='hamburger__text'>Mi salud financiera</div>
                </li>
            </NavLink>
           
        </ul>

    )
}

export default HeaderHamburger