import React from "react";

const AuthLoginImagenes = () => {
  return (
    <div className="login__container-imagenes desktop">
      <img
        src="/assets/img/homeout/img_activar_cuenta.webp"
        alt="imagen de activar cuenta"
      />
      <div className="login__imagenes-textos">
        <div className="login__imagenes-card">
          <p>
            "Sé que mi seguro tiene beneficios pensados en mi bienestar y el de
            mi familia"
          </p>
          <div className="login__imagenes-card-footer">
            <p>Carolina G.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthLoginImagenes;
