import { useEffect, useState } from "react"
import { Modal } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import { formateaFecha } from "../../helpers/formateaFecha"

const ModalVerCita = ({show, setShow, color, ecoDetalle, objAgenda}) => {

    const handleClose = () => setShow(false)

    const [disableBotonVideollamada, setDisableBotonVideollamada] = useState(true)
    const [horas, minutos, segundos] = objAgenda.hora.split(':')
    const [dia, mes, anio] = formateaFecha(objAgenda.fechaAgenda).split('-')

    const dateAgenda = new Date(+anio, +mes - 1, dia, horas, minutos, segundos)

    const dias = ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"]
    const meses = ["enero", "febrero", "marzo", "abril", "mayo", "junio", "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"]

    const navigate = useNavigate()

    const gotoEditarCita = () => {
        navigate(ecoDetalle.linkEditAgendamiento, {
            state: {
                editarCita: true
            }
        })
    }

    const iniciarAsesoria = () => {
        handleClose()
        window.open(objAgenda.urlVideollamada, "_blank")
    }

    const validateHoraCita = () => {
        if(Math.abs(Date.now() - dateAgenda) <= 900000) {
            setDisableBotonVideollamada(false)
        }
        else {
            setDisableBotonVideollamada(true)
        }
    }

    setInterval(() => {
        validateHoraCita()
    }, 60000)

    useEffect(() => {
        validateHoraCita()
    }, [])

    return (
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Body>
                <div style={{ height: "40px" }}>
                    <button
                        id="btnCierraModal"
                        type="button"
                        className="close-modal btnTermsClosePoli ajusteXmodal newCloseModalButton"
                        onClick={handleClose}
                    >
                        <p className="iconCloseTerms">
                            <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="#111111" className="bi bi-x-circle" viewBox="0 0 16 16">
                                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                            </svg>
                        </p>
                    </button>
                </div>
                <div className="div__modal__cita">
                    <h1 style={{ color: `var(--${color})` }}>Detalles de tu asesoría</h1>
                    <div className="div__info__cita">
                        <p><b style={{ fontFamily: 'Scotia-Bold' }}>Servicio: </b>{objAgenda.servicio}</p>
                        <p><b style={{ fontFamily: 'Scotia-Bold' }}>Fecha: </b>{dias[objAgenda.fechaAgenda.getDay()] + " " + objAgenda.fechaAgenda.getDate() + " de " + meses[objAgenda.fechaAgenda.getMonth()] + " " + objAgenda.fechaAgenda.getFullYear()}</p>
                        <p><b style={{ fontFamily: 'Scotia-Bold' }}>Horario: </b>{horas < 12 ? (objAgenda.hora.substring(0, 5) + " am") : (horas >= 13 ? ((horas - 12) + ":" + minutos) : (horas + ":" + minutos) ) + " pm"}</p>
                        <p><b style={{ fontFamily: 'Scotia-Bold' }}>Medio: </b>{objAgenda.medio}</p>
                        {
                            objAgenda.medio === "Vía Llamada Telefónica" && <p><b style={{ fontFamily: 'Scotia-Bold' }}>Teléfono: </b>{objAgenda.telefono}</p>
                        }
                    </div>
                    <div className="agenda__btn__cita">
                        <button
                            onClick={gotoEditarCita}
                            style={{
                            backgroundColor: `transparent`,
                            color: `var(--${color})`,
                            borderColor: `var(--${color})`,
                            }}
                        > Editar cita
                        </button>
                    </div>
                    {
                        objAgenda.medio === "Vía Videollamada" &&
                        <div className="agenda__btn__cita">
                            <button
                                onClick={iniciarAsesoria}
                                style={{
                                backgroundColor: `var(--${color})`,
                                color: '#fff',
                                borderColor: `var(--${color})`,
                                }}
                                disabled={disableBotonVideollamada}
                            > Iniciar asesoría
                            </button>
                        </div>
                    }
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default ModalVerCita