import React, { Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import FallbackCards from "../../modules/helpers/FallbackCards";
import VidaPlusEvaluacionMedica from "../../modules/homein/vidaplus/components/evaluacion-medica/VidaPlusEvaluacionMedica";

const VidaPlusScreen = React.lazy(() =>
  import("../../modules/homein/vidaplus/screens/VidaPlusScreen")
);
const VidaPlusAsestenciaMedica = React.lazy(() =>
  import(
    "../../modules/homein/vidaplus/components/asistencia-medica/VidaPlusAsistenciaMedica"
  )
);
const VidaPlusRedSalud = React.lazy(() =>
  import("../../modules/homein/vidaplus/components/red-salud/VidaPlusRedSalud")
);
const VidaPlusCoursera = React.lazy(() =>
  import("../../modules/homein/vidaplus/components/coursera/VidaPlusCoursera")
);
const PageNotFound = React.lazy(() => import("../../modules/404/PageNotFound"));

const RouterVidaPlus = () => {
  return (
    <>
      <Suspense fallback={<FallbackCards />}>
        <Routes>
          <Route
            path="/asistencia-medica"
            element={<VidaPlusAsestenciaMedica />}
          />
          <Route path="/red-salud" element={<VidaPlusRedSalud />} />
          <Route path="/coursera" element={<VidaPlusCoursera />} />
          <Route path="/" element={<VidaPlusScreen />} />
          <Route
            path="/evaluacion-medica"
            element={<VidaPlusEvaluacionMedica />}
          />
          <Route path="/404" element={<PageNotFound />} />
          <Route path="*" element={<Navigate to="/404" />} />
        </Routes>
      </Suspense>
    </>
  );
};

export default RouterVidaPlus;
