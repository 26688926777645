import React, { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import AuthContext from "../../../../context/auth/authContext";
import Modulos from "../../../general/screen/Modulos";
import EcosistemaTitulo from "../../ecosistemas/EcosistemaTitulo";
import HomeInUser from "../../principal/components/HomeInUser";
import ProteccionFinancieraCards from "../components/ProteccionFinancieraCards";
import useTitle from "../../../hooks/useTitle";

const ProteccionFinancieraScreen = () => {
  useTitle({ title: "Detalle Ecosistema Protección Financiera" });
  const { pathname } = useLocation();
  const { globalActualizarEcosistemaActivo } = useContext(AuthContext);

  useEffect(() => {
    if (pathname === "/ecosistema/proteccion-financiera") {
      globalActualizarEcosistemaActivo(8);
    }
  }, []);

  return (
    <div className="homein">
      <EcosistemaTitulo />

      <div className="homein__resumen">
        <HomeInUser id={8} />
      </div>

      <div className="homein__detalle">
        <Modulos />
        <ProteccionFinancieraCards />
      </div>
    </div>
  );
};

export default ProteccionFinancieraScreen;
