import React from 'react'

const PortalInformacion = (props) => {

    return (
        <div className='portal'>
            <div className="portal__edicion">

                <section className="section__cabecera">
                    <p>{props.terminos.titulo}</p>
                </section>

                <section className="section__cuerpo">
                    {
                        props.terminos.descrip.map((des,i) => {
                            return <p key ={i} >{des}</p>
                        })
                    }
                </section>

                <section className="section__btn">
                    <div className='section__btnItem' onClick={() => props.setTerminos(false)}>Aceptar</div>
                </section>
            </div>


        </div>
    )
}

export default PortalInformacion