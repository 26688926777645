import React, { useState } from 'react'
import { encodeBase64, encryptDecrypt } from '../../helpers/validations'
import sha256 from "sha256";
import { changPassword } from '../../../services/auth';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2'
import ReCAPTCHA from 'react-google-recaptcha';
import { COD_RECAPTCHA_V3 } from '../../../enviroments/enviroment';
import useValidator from '../../hooks/useValidator';

const AuthPasswordFormPass = ({ errorData, mensaje, recaptchaRef, setMensaje, setSpinner, setErrorData, formCode, setCount }) => {

    const navigate = useNavigate()
    const [formPass, setFormPass] = useState({
        id: "",
        code: "",
        password: "",
        passwordconfirm: "",
        tokenCaptcha: ""
    })
    const [oculto, setOculto] = useState(true);
    const [ocultoConfirm, setOcultoConfirm] = useState(true);

    const { formValidador, error } = useValidator(formPass.password) 

    const handleChangePass = (e) => {
        setFormPass({
            ...formPass,
            [e.target.name]: e.target.value,
        })
    }

    const cambiarOculto = () => {
        setOculto(!oculto);
    };

    const cambiarOcultoConfirm = () => {
        setOcultoConfirm(!ocultoConfirm);
    };

    const handleSubmitNuevaContrasena = async (e) => {
        e.preventDefault();

        if ((formPass.password === formPass.passwordconfirm) && !error) {

            const token = await recaptchaRef.current.executeAsync();
            recaptchaRef.current.reset()
            if (!token) {
                setMensaje('Captcha inválido, actualizar web')
                return
            }

            setSpinner(true)

            let pattern = /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!,%,&,@,#,$,^,*,?,_,~,.,(,),/,-]).{7,15}/
            if (!pattern.test(formPass.passwordconfirm)) {
                setMensaje("La nueva contraseña debe tener al entre 8 y 16 caracteres, al menos un dígito, al menos una minúscula y al menos una mayúscula.")
                setErrorData(true)
                setSpinner(false)
                // console.log('err 1')
                return
            }

            if (!pattern.test(formPass.password)) {
                setMensaje("La nueva contraseña debe tener al entre 8 y 16 caracteres, al menos un dígito, al menos una minúscula y al menos una mayúscula.")
                setErrorData(true)
                setSpinner(false)
                // console.log('err 2')
                return
            }

            try {
                let objUsuario = {
                    id: formCode.id,
                    code: formCode.code,
                    password: encodeBase64(encryptDecrypt(sha256(formPass.passwordconfirm),
                        token.substring(token.length - 10, token.length)
                    )),
                    tokenCaptcha: token
                }

                // console.log({ objUsuario })
                changPassword(objUsuario).then(res => {
                    // console.log({ res })
                    setSpinner(false)
                    if (res.codigo === 0) {
                        setMensaje("")
                        setErrorData(false)
                        // console.log('Password cambiada con éxito')
                        setCount(1)
                        navigate('/iniciasesion')
                        Swal.fire({
                            icon: 'success',
                            title: 'Contraseña actualizada con éxito'
                        })
                    } else {
                        setMensaje(res.mensaje)
                        setErrorData(true)
                    }
                })
            } catch (err) {
                setMensaje(err)
                setErrorData(true)
                setSpinner(false)
            }
        } else {
            // console.log('error de contraseñas')
            setErrorData(true)
            setMensaje("Las contraseñas deben ser iguales")
        }
    }

    return (
        <div className="auth__login">
            <h3 className="auth__title">Restablecer contraseña</h3>
            <p className="auth__subtitle" > Ingresa tu nueva contraseña para activar tu sesión. </p>

            {
                errorData &&
                <div className="cajaregistra__error" >
                    <i className="fa-solid fa-triangle-exclamation"></i>
                    <div className="error__informativo">
                        <p>{mensaje}</p>
                    </div>
                </div>
            }
            <form onSubmit={handleSubmitNuevaContrasena}>

                <div className="auth__login-label" >Nueva contraseña</div>
                <div className="auth__login-group" >
                    <div className="auth__login-pass">
                        <input
                            type={`${oculto ? "password" : "text"}`}
                            className="auth__login-docompleto"
                            placeholder="Ingresa tu código"
                            name="password"
                            value={formPass.password}
                            onChange={handleChangePass}
                        />
                        {oculto ? (
                            <i
                                onClick={cambiarOculto}
                                className="fa-solid fa-eye-slash pass__icon"
                            ></i>
                            ) : (
                            <i
                                onClick={cambiarOculto}
                                className="fa-solid fa-eye pass__icon"
                            ></i>
                        )}
                    </div>
                </div>

                <div className="auth__login-label" >Repetir nueva contraseña</div>
                <div className="auth__login-group" >
                    <div className="auth__login-pass">
                        <input
                            type={`${ocultoConfirm ? "password" : "text"}`}
                            className="auth__login-docompleto"
                            placeholder="Ingresa tu código"
                            name="passwordconfirm"
                            value={formPass.passwordconfirm}
                            onChange={handleChangePass}
                        />
                        {ocultoConfirm ? (
                            <i
                                onClick={cambiarOcultoConfirm}
                                className="fa-solid fa-eye-slash pass__icon"
                            ></i>
                            ) : (
                            <i
                                onClick={cambiarOcultoConfirm}
                                className="fa-solid fa-eye pass__icon"
                            ></i>
                        )}
                    </div>
                </div>

                {formValidador()}

                <div>
                    <input
                        type="submit"
                        className="btn__login completo"
                        value="Cambiar contraseña"
                    />
                    <ReCAPTCHA
                        ref={recaptchaRef}
                        size="invisible"
                        sitekey={COD_RECAPTCHA_V3}
                    />
                </div>
            </form>
        </div>
    )
}

export default AuthPasswordFormPass