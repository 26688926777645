import React, { Suspense } from "react";
import FallbackCards from "../../../helpers/FallbackCards";
import useBotons from "../../../hooks/useBotons";
import "../styles/homeOutBanner.css";

const PrincipalBanner = () => {
  const { activaCuentaBtn } = useBotons();

  return (
    <>
      <Suspense fallback={<FallbackCards />}>
        <section
          className="homeout__banner principal"
          style={{
            backgroundImage:
              'url("/assets/img/homeout/proteccion/img_banner_callout.webp")',
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
        >
          <div
            className="homeout__banner-texto"
            style={{ backgroundColor: "transparent", backdropFilter: "none" }}
          >
            <div>
              {/* <h4 style={{ color: 'white', fontSize: '42px', letterSpacing: '1px', fontFamily: 'Scotia-Headline' }}>¿No tienes una Cuenta?</h4> {title} */}
              <p
                style={{ color: "white", maxWidth: "509px", fontSize: "20px" }}
              >
                Para acceder a tus beneficios exclusivos por contratar un seguro
                de la alianza Scotiabank + Cardif México, debes activar tu
                cuenta.
              </p>
              {/* {description} */}
              {activaCuentaBtn()}
            </div>
          </div>
        </section>
      </Suspense>
    </>
  );
};

export default PrincipalBanner;
