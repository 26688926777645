import React, { Suspense } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import FallbackCards from '../../modules/helpers/FallbackCards'

const CuidadosOrientacionPsicologica = React.lazy(() => import('../../modules/homein/cuidados/components/orientacion-psicologica/CuidadosOrientacionPsicologica'))
const CuidadosOrientacionNutricional = React.lazy(() => import('../../modules/homein/cuidados/components/orientacion-nutricional/CuidadosOrientacionNutricional'))
const CuidadosAnalisisClinicos = React.lazy(() => import('../../modules/homein/cuidados/components/analisis-clinicos/CuidadosAnalisisClinicos'))
const CuidadosHealthCoach = React.lazy(() => import('../../modules/homein/cuidados/components/health-coach/CuidadosHealthCoach'))
const CuidadosServicioDental = React.lazy(() => import('../../modules/homein/cuidados/components/servicio-dental/CuidadosServicioDental'))
const CuidadosScreen = React.lazy(() => import('../../modules/homein/cuidados/screens/CuidadosScreen'))
const PageNotFound = React.lazy(() => import('../../modules/404/PageNotFound'))

const RouterCuidados = () => {
    return (
        <>
            <Suspense fallback={<FallbackCards />}>
                <Routes>
                    <Route path='/orientacion-psicologica' element={<CuidadosOrientacionPsicologica />} />
                    <Route path='/orientacion-nutricional' element={<CuidadosOrientacionNutricional />} />
                    <Route path='/analisis-clinicos' element={<CuidadosAnalisisClinicos />} />
                    <Route path='/health-coach' element={<CuidadosHealthCoach />} />
                    <Route path='/servicio-dental' element={<CuidadosServicioDental />} />
                    <Route path='/' element={<CuidadosScreen />} />
                    <Route path='/404' element={<PageNotFound />} />
                    <Route path='*' element={<Navigate to="/404" />} />
                </Routes>
            </Suspense>
        </>
    )
}

export default RouterCuidados