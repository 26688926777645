import React, { Suspense } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import PageNotFound from '../../modules/404/PageNotFound'
import FallbackCards from '../../modules/helpers/FallbackCards'
import PrincipalContactoResumen from '../../modules/homeout/principal/components/PrincipalContactoResumen'
import VidaPlusOutEvaluacionMedica from '../../modules/homeout/vidaplus/components/VidaPlusOutEvaluacionMedica'
import HomeOutVidaPlus from '../../modules/homeout/vidaplus/screens/HomeOutVidaPlus'

const VidaPlusOutAsistenciaMedica = React.lazy(()=> import ('../../modules/homeout/vidaplus/components/VidaPlusOutAsistenciaMedica'))
const VidaPlusOutRedSalud = React.lazy(()=> import ('../../modules/homeout/vidaplus/components/VidaPlusOutRedSalud'))
const VidaPlusOutCoursera = React.lazy(()=> import ('../../modules/homeout/vidaplus/components/VidaPlusOutCoursera'))

const RouterOutVidaPlus = () => {

    return (
        <>
            <Suspense fallback={<FallbackCards />}>
                <Routes>
                    <Route path='/asistencia-medica' element={<VidaPlusOutAsistenciaMedica />} />
                    <Route path='/evaluacion-medica' element={<VidaPlusOutEvaluacionMedica />} />
                    <Route path='/red-salud' element={<VidaPlusOutRedSalud />} />
                    <Route path='/coursera' element={<VidaPlusOutCoursera />} />
                    <Route path='/' element={<HomeOutVidaPlus />} />
                    <Route path='/404' element={<PageNotFound />} />
                    <Route path='*' element={<Navigate to="/404" />} />
                </Routes>

                {window.location.pathname !== "/vidaplus" && < PrincipalContactoResumen />}
            </Suspense>
        </>
    )

}

export default RouterOutVidaPlus