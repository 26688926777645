import React, { useEffect, useReducer } from "react";
import { CLI_PUB } from "../../enviroments/enviroment";
import { detalleEcosistemas, ecosistemas } from "../../modules/helpers/data";
import {
  getCoberturas,
  getCoberturasGlobal,
  getDataUser,
  getIdUser,
  isactive,
} from "../../modules/helpers/validations";
import AuthContext from "./authContext";
import AuthReducer from "./authReducer";
import * as CryptoJS from "crypto-js";
import { useLocation, useNavigate } from "react-router-dom";

const AuthState = (props) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [state, dispatch] = useReducer(AuthReducer, {
    globalDataUser: {
      globalUsuId: "",
      globalUsuName: "",
      globalUsuSurnameMat: "",
      globalUsuSurname: "",
      globalUsuCellphone: "",
      globalUsuEmail: "",
      globalUsuTipoDoc: "",
      globalDocIdentidad: "",
      globalUsuRole: "",
      globalInvitado: "",
      globalNroInvitados: 0,
      globalPlanCardifId: "",
    },
    globalModulos: {
      modValorFactura: false,
      modCuidados: false,
      modPFinanciera: false,
      modEmpleabilidad: false,
      modEmprendimiento: false,
      modSalud: false,
      modVida: false,
      modCancer: false,
      modAccidentes: false,
      modProteccionFinanciera: false,
      modVidaPlus: false,
      modPFinancieraElemental: false,
      modCoursera: false,
    },
    globalEcosistemas: ecosistemas,
    globalDetalleEcosistemas: detalleEcosistemas,
    globalEcosistemaActivo: ecosistemas[0]?.id,
    globalToken: "",
    globalTokenUser: "",
    globalAutenticado: false, // por defecto debe ser FALSE
    globalCargando: true, // por defecto debe ser TRUE
    globalVentanaCierre: false,
    globalStatusEncuesta: true,
    globalBeneficiariosSalud: false,
    globalBeneficiariosSaludModal: 0,
    globalBeneficiariosSaludModalEnCasa: 0,
    globalCurpVida: false,
    globalCurpVidaModal: 0,
  });

  // Listado de Funciones globales:
  const globalIniciarSesion = (login) => {
    dispatch({
      action: "INICIAR_SESION",
      data: login,
    });
  };

  const globalActualizarEcosistemaActivo = (idEcosistema) => {
    dispatch({
      action: "ACTUALIZAR_ECOSISTEMA_ACTIVO",
      data: idEcosistema,
    });
  };

  const globalDesactivaVentanaCierre = () => {
    dispatch({
      action: "DESACTIVA_VENTANA_CIERRE",
    });
  };

  const globalInactivaSesion = () => {
    sessionStorage.clear();

    dispatch({
      action: "INACTIVA_SESION",
    });
    navigate("/iniciasesion");
  };

  const globalActualizarEncuesta = (estado) => {
    dispatch({
      action: "ACTUALIZAR_ENCUESTA",
      data: estado === 1,
    });
  };

  const globalCerrarSesion = () => {
    sessionStorage.clear();

    dispatch({
      action: "CERRAR_SESION",
    });
    // console.log('cierre de sesion')
    navigate("/iniciasesion");
    window.location.reload();
  };

  const validaLogueo = () => {
    if (isactive()) {
      let dataUser = getDataUser();
      let idUser = getIdUser();
      let token = sessionStorage.getItem("token");

      globalIniciarSesion({
        globalDataUser: {
          globalUsuId: idUser,
          globalUsuName: dataUser.nombres,
          globalUsuSurnameMat: dataUser.apellidoMaterno,
          globalUsuSurname: dataUser.apellidoPaterno,
          globalUsuCellphone: dataUser.numeroCelular,
          globalUsuEmail: dataUser.correoElectronico,
          globalUsuTipoDoc: dataUser.dicTipoDocumento,
          globalDocIdentidad: dataUser.identificacion,
          globalUsuRole: "",
          globalInvitado: dataUser.nroinvitado > 0,
          globalNroInvitados: dataUser.nroinvitado,
          globalPlanCardifId: dataUser.idPlanCardif,
        },
        globalModulos: getCoberturasGlobal(getCoberturas()),
        globalToken: token,
        globalTokenUser: CryptoJS.AES.encrypt(
          JSON.stringify(dataUser),
          CLI_PUB
        ),
        globalAutenticado: true,
        globalCargando: false,
      });
      if (pathname.includes("/ecosistema")) {
        navigate(pathname);
      } else {
        navigate("/ecosistema");
      }
    }
  };

  const globalActualizarNroInvitados = (nroInvitados) => {
    dispatch({
      action: "ACTUALIZAR_INVITADOS",
      data: nroInvitados,
    });
  };

  const globalActualizarDatosUsuario = (dataUsuario) => {
    dispatch({
      action: "ACTUALIZAR_USUARIO",
      data: dataUsuario,
    });
  };

  const globalActualizarDocumentoUsuario = (documento) => {
    dispatch({
      action: "ACTUALIZAR_DOCUMENTO_USUARIO",
      data: documento,
    });
  };

  const globalActualizarBeneficiariosSalud = (status) => {
    dispatch({
      action: "ACTUALIZAR_BENEFICIARIOS",
      data: status,
    });
  };

  const globalActualizarBeneficiariosSaludModal = (status) => {
    dispatch({
      action: "ACTUALIZAR_BENEFICIARIOS_MODAL",
      data: status,
    });
  };

  const globalActualizarBeneficiariosSaludModalEnCasa = (status) => {
    dispatch({
      action: "ACTUALIZAR_BENEFICIARIOS_MODAL_ENCASA",
      data: status,
    });
  };

  const globalActualizarVidaCurp = (status) => {
    dispatch({
      action: "ACTUALIZAR_VIDA_CURP",
      data: status,
    });
  };

  const globalActualizarVidaModalCurp = (status) => {
    dispatch({
      action: "ACTUALIZAR_VIDA_CURP_MODAL",
      data: status,
    });
  };

  useEffect(() => {
    validaLogueo();
    // console.log({invi: getDataUser().nroinvitado})
  }, []);

  return (
    <AuthContext.Provider
      value={{
        globalDataUser: state.globalDataUser,
        globalSponsor: state.globalSponsor,
        globalContrato: state.globalContrato,
        globalModulos: state.globalModulos,
        globalToken: state.globalToken,
        globalTokenUser: state.globalTokenUser,
        globalAutenticado: state.globalAutenticado,
        globalCargando: state.globalCargando,
        globalEcosistemas: state.globalEcosistemas,
        globalDetalleEcosistemas: state.globalDetalleEcosistemas,
        globalEcosistemaActivo: state.globalEcosistemaActivo,
        globalVentanaCierre: state.globalVentanaCierre,
        globalStatusEncuesta: state.globalStatusEncuesta,
        globalBeneficiariosSalud: state.globalBeneficiariosSalud,
        globalBeneficiariosSaludModal: state.globalBeneficiariosSaludModal,
        globalBeneficiariosSaludModalEnCasa:
          state.globalBeneficiariosSaludModalEnCasa,
        globalCurpVida: state.globalCurpVida,
        globalCurpVidaModal: state.globalCurpVidaModal,
        globalIniciarSesion,
        globalActualizarEcosistemaActivo,
        globalCerrarSesion,
        globalInactivaSesion,
        globalDesactivaVentanaCierre,
        globalActualizarNroInvitados,
        globalActualizarDatosUsuario,
        globalActualizarEncuesta,
        globalActualizarDocumentoUsuario,
        globalActualizarBeneficiariosSalud,
        globalActualizarBeneficiariosSaludModal,
        globalActualizarBeneficiariosSaludModalEnCasa,
        globalActualizarVidaCurp,
        globalActualizarVidaModalCurp,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthState;
