import React, { Suspense } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import FallbackCards from '../../modules/helpers/FallbackCards'

const CourseraScreen = React.lazy(() => import('../../modules/homein/coursera/screens/CourseraScreen'))
const PageNotFound = React.lazy(() => import('../../modules/404/PageNotFound'))

const RouterCoursera = () => {
    return (
        <>
            <Suspense fallback={FallbackCards}>
                <Routes>
                    <Route path='/' element={<CourseraScreen />} />
                    <Route path='/404' element={<PageNotFound />} />
                    <Route path='*' element={<Navigate to="/404" />} />
                </Routes>
            </Suspense>
        </>
    )
}

export default RouterCoursera