import React from 'react'
import useCards from '../../../hooks/useCards'

const VidaPlusCards = () => {
  const { homeinCards } = useCards()
  
  return (
      <>
          {homeinCards(9)}

      </>
  )
} 

export default VidaPlusCards