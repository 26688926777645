import React, { Suspense } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import FallbackCards from '../../modules/helpers/FallbackCards'
import PrincipalContactoResumen from '../../modules/homeout/principal/components/PrincipalContactoResumen'

const HomeOutVida = React.lazy(() => import('../../modules/homeout/vida/screens/HomeOutVida'))
const PageNotFound = React.lazy(() => import('../../modules/404/PageNotFound'))

const RouterOutVida = () => {
    return (
        <>
            <Suspense fallback={<FallbackCards />}>
                <Routes>
                    <Route path='/' element={<HomeOutVida />} />
                    <Route path='/404' element={<PageNotFound />} />
                    <Route path='*' element={<Navigate to="/404" />} />
                </Routes>

                {window.location.pathname !== "/vida" && < PrincipalContactoResumen />}
            </Suspense>
        </>
    )
}

export default RouterOutVida