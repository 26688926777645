import { URL_BACKEND_AUTHS } from "../enviroments/enviroment"

export const getUmanoLink = async (token) => {
    try{
        const response = await fetch(`${URL_BACKEND_AUTHS}/umano/obtenerLink`,{
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
        if(response.status>300){
            return ['', response.status]
        }
        const json = await response.json()
        return [json, response.status]
    

    }catch(err){
        console({err})
        throw err
    }
}