import "../../styles/psico.css";
import "../../styles/marketing.css";
import "../../styles/salario.css";
import "../../styles/salud.css";
import "../../../../styles/general.css";
import HomeConfiguracionPerfil from "../components/HomeConfiguracionPerfil";
import useTitle from "../../../hooks/useTitle";

const HomeConfiguracion = () => {
  useTitle({ title: "Configuración" });
  return (
    <>
      <HomeConfiguracionPerfil />
    </>
  );
};

export default HomeConfiguracion;
