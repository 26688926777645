import { useRef, useState } from "react"
import { useEffect } from "react"

const useNearScreen = (distance) => {

    const elementRef = useRef()
    const [isNear, setIsNear] = useState(false)

    useEffect(() => {

        const validarDistancia = (evento, observer) => {            
            if(evento[0].isIntersecting){
                setIsNear(true)
                observer.disconnect()
            }
        }

        const observer = new IntersectionObserver(validarDistancia, {
            rootMargin: distance
        })

        observer.observe(elementRef.current)

        return () => observer.disconnect()

    },[])

    return {elementRef, isNear}

}

export default useNearScreen