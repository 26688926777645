// let date = new Date();
//const months = ["ENE", "FEB", "MAR","ABR", "MAY", "JUN", "JUL", "AGO", "SEP", "OCT", "NOV", "DIC"];
const months = ["01", "02", "03","04", "05", "06", "07", "08", "09", "10", "11", "12"];

export const formateaFecha = (date) => {
    let formatted_date = ""
    if (parseInt(date.getDate()) <= 9) {
        formatted_date = "0" + date.getDate() + "-" + months[date.getMonth()] + "-" + date.getFullYear()
    }
    else {
        formatted_date = date.getDate() + "-" + months[date.getMonth()] + "-" + date.getFullYear()
    }
    return formatted_date;
}
