import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import AuthContext from '../../../context/auth/authContext'

const HeaderUserPerfil = ({ subMenuUser = false, setCerrando, setUser = () => { } }) => {

    const { globalCerrarSesion, globalDataUser } = useContext(AuthContext)

    const navigate = useNavigate()

    const gotoPerfil = () => {
        setUser(false)
        navigate('/ecosistema/configuracion')
    }
    const gotoInvitados = () => {
        setUser(false)
        navigate('/ecosistema/invitados')
    }

    const cerrarSesion = () => {
        setCerrando(true)
        setUser(false)
        setCerrando(false)
        globalCerrarSesion()
    }

    const gotoAgendamientos = () => {
        setUser(false)
        navigate('/ecosistema/mis-agendamientos')
    }

    return (
        <div className={`header__user-detalle  ${subMenuUser ? 'mostrar' : ''}`}>
            <div className="user__detalle-section">
                <i className="fa-regular fa-circle-user"></i>
                <p>{globalDataUser.globalUsuName} {globalDataUser.globalUsuSurname}</p>
                <div onClick={gotoPerfil} >Configuración de perfil</div>
            </div>
            {
                globalDataUser.globalInvitado > 0 &&
                <>
                    <div className="user__detalle-invitado" onClick={gotoInvitados}>
                        <img src="/assets/img/header/ico-add-user.png" alt="ico user" />
                        <span >Agregar invitado</span>
                    </div>
                </>

            }
            <div className="user__detalle-agendamientos" onClick={gotoAgendamientos}>
                <span>Mis agendamientos</span>
            </div>
            <div className="user__detalle-cerrar" onClick={cerrarSesion}>
                <img src="/assets/img/header/ico_register.png" alt="ico user" />
                <span>Cerrar Sesión</span>
            </div>

        </div>

    )
}

export default HeaderUserPerfil