import useTitle from "../../hooks/useTitle";
import AuthPasswordForm from "../components/AuthPasswordForm";
import PrincipalContactoResumen from "../../homeout/principal/components/PrincipalContactoResumen";
import AuthLoginImagenes from "../components/AuthLoginImagenes";
import "../styles/authActivar.css";

const AuthPassword = () => {
  useTitle({ title: "Recuperación de contraseña" });

  return (
    <>
      <main className="login__container">
        <div className="login__container-datos">
          <AuthPasswordForm />
        </div>

        <AuthLoginImagenes />
      </main>

      <PrincipalContactoResumen />
    </>
  );
};

export default AuthPassword;
