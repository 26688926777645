import { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import AuthContext from "../../../../context/auth/authContext";
import Modulos from "../../../general/screen/Modulos";
import EcosistemaTitulo from "../../ecosistemas/EcosistemaTitulo";
import HomeInUser from "../../principal/components/HomeInUser";
import EmprendimientoCards from "../components/EmprendimientoCards";
import useTitle from "../../../hooks/useTitle";

const EmprendimientoScreen = () => {
  useTitle({ title: "Detalle Ecosistema Emprendimiento" });
  const { pathname } = useLocation();
  const { globalActualizarEcosistemaActivo } = useContext(AuthContext);

  useEffect(() => {
    if (pathname === "/ecosistema/emprendimiento") {
      globalActualizarEcosistemaActivo(2);
    }
  }, []);

  return (
    <div className="homein">
      <EcosistemaTitulo />

      <div className="homein__resumen">
        <HomeInUser id={2} />
      </div>

      <div className="homein__detalle">
        <Modulos />
        <EmprendimientoCards />
      </div>
    </div>
  );
};

export default EmprendimientoScreen;
