import React from "react";
import "../styles/homeOutCompania.css";

const PrincipalCompania = () => {
  return (
    <div>
      <h3 className="out__compania-title">¡Estás en buenas manos!</h3>
      <div className="out__compania-cards">
        <div className="out__compania-card">
          <div className="compania__header">
            <img
              src="/assets/img/homeout/img_user_M.webp"
              alt="avatar compania"
            />
            <div>
              <p className="compania__header-title">Gabriel K.</p>
            </div>
          </div>
          <div className="compania__body">
            <p>
              "Gracias a mi seguro Tarjeta Protegida pude diagnosticar mi salud
              financiera, lo que me permitió tomar mejores decisiones que me
              permitieron ahorrar"
            </p>
          </div>
        </div>
        <div className="out__compania-card">
          <div className="compania__header">
            <img
              src="/assets/img/homeout/img_user_F.webp"
              alt="avatar compania"
            />
            <div>
              <p className="compania__header-title">Jennifer R..</p>
            </div>
          </div>
          <div className="compania__body">
            <p>
              "El beneficio gratuito de cursos con certificación online de
              Coursera, me permitió aprender un idioma nuevo y repotenciar mi
              perfil como profesional"
            </p>
          </div>
        </div>
        <div className="out__compania-card">
          <div className="compania__header">
            <img
              src="/assets/img/homeout/img_user_F.webp"
              alt="avatar compania"
            />
            <div>
              <p className="compania__header-title">Claudia C.</p>
            </div>
          </div>
          <div className="compania__body">
            <p>
              "Las asesorías gratuitas en marketing digital que me da mi seguro,
              me ayudan potenciar mi negocio en redes sociales".
            </p>
          </div>
        </div>

        {/* <div className="out__compania-card">
                    <div className="compania__header">
                        <img src="/assets/img/homeout/img_user_F.webp" alt="avatar compania" />
                        <div>
                            <p className='compania__header-title'>Anngie F.</p>
                        </div>
                    </div>
                    <div className="compania__body">
                        <p>"Life es el beneficio que ofrece mi seguro que más uso. Al terminar de trabajar, llego a casa a relajarme con mis clases de YOGA. ¡También me encanta poder disfrutar de clases Fitness presenciales en gimnasios!"</p>
                    </div>
                </div> */}
      </div>
    </div>
  );
};

export default PrincipalCompania;
