import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../../context/auth/authContext";
import AliceCarousel from "react-alice-carousel";
import 'react-alice-carousel/lib/alice-carousel.css';
import "../styles/modulos.css";
import { ButtonNextModulo, ButtonNextModuloDisabled, ButtonPreviousModuloDisabled, ButtonPreviousModulo } from "../../hooks/components/ButtonsModulos";
import { addLogs } from "../../../services/logs_proteccion";

const Modulos = () => {

  const { globalEcosistemas, globalEcosistemaActivo, globalActualizarEcosistemaActivo, globalModulos, globalActualizarBeneficiariosSaludModalEnCasa } = useContext(AuthContext)
  const [modulos, setModulos] = useState([])
  const [carouselModulos, setCarouselModulos] = useState([])
  const [showHideArrows, setShowHideArrows] = useState(false)
  const refPrevButtonCarousel = useRef();
  const refNextButtonCarousel = useRef();
  let navigate = useNavigate()

  const responsive = {
    320:{
        items: 1,
        itemsFit: 'contain'
    },
    576: { 
        items: 2,
        itemsFit: 'contain'
    },
    768: {
        items: 3,
        itemsFit: 'contain'
    },
    1024:{
        items: 3,
        itemsFit: 'contain'
    },
    1200:{
        items: 4,
        itemsFit: 'contain'
    },
    1400:{
        items: 5,
        itemsFit: 'contain'
    },
    1600:{
        items: 6,
        itemsFit: 'contain'
    },
    1800:{
        items: 7,
        itemsFit: 'contain'
    }
    
  };

  const seleccionarEcosistema = (eco) => {
    globalActualizarEcosistemaActivo(eco.id)
    globalActualizarBeneficiariosSaludModalEnCasa(0)
    addLogs('', 'Ingresa al ecosistema ' + eco.name, '')
    if (eco.id === 2) {
      navigate("/ecosistema/emprendimiento");
    } else if (eco.id === 3) {
      navigate("/ecosistema/empleabilidad");
    } else if (eco.id === 8) {
      navigate("/ecosistema/proteccion-financiera");
    } else if (eco.id === 9) {
      navigate("/ecosistema/vidaplus");
    } else if (eco.id === 10) {
      navigate('/ecosistema/mi-salud-financiera') 
    } else if (eco.id === 11) {
      navigate('/ecosistema/coursera')
    } else if (eco.id === 12) {
      navigate('/ecosistema/salud')
    } else if (eco.id === 13) {
      navigate('/ecosistema/vida')
    } else if (eco.id === 14) {
      navigate('/ecosistema/cuidados')
    } else if (eco.id === 15) {
      navigate('/ecosistema/valor-factura')
    }
  };

  const filtraEcosistemasActivos = useCallback(() => {
    let data = [];
    Object.entries(globalModulos).forEach(([nombre, value]) => {
      if (value) {
        data.push(
          ...globalEcosistemas.filter(
            (e) => e.modulo === nombre && e.active === true
          )
        );
      }
    });
    setModulos(data);

    let carouselEcosistemas = [];
    data.forEach(eco => {
      carouselEcosistemas.push(
        <div
          key={eco.id}
          onClick={() => seleccionarEcosistema(eco)}
          className={`modulos__eco ${globalEcosistemaActivo === eco.id ? `activo ${eco.color}` : '' }`}
        >
          {/*<img src={eco.ico} alt="imagen de ecosistema" />*/}
          <p>{eco.name}</p>              
        </div>
      )
    })

    setCarouselModulos(carouselEcosistemas)
  }, [globalModulos, globalEcosistemaActivo]);

  const mostrarOcultarBotonesCarousel = () => {
    if (refPrevButtonCarousel.current?.disabled && refNextButtonCarousel.current?.disabled) {
      refNextButtonCarousel.current.style.display = 'none';
      refPrevButtonCarousel.current.style.display = 'none';
    } else if (refPrevButtonCarousel.current?.disabled !== refNextButtonCarousel.current?.disabled) {
      refNextButtonCarousel.current.style.display = 'block';
      refPrevButtonCarousel.current.style.display = 'block';
    }
  }

  useEffect(() => {
    filtraEcosistemasActivos();
  }, [filtraEcosistemasActivos]);

  useEffect(() => {
    mostrarOcultarBotonesCarousel()
  }, [showHideArrows])

  return (
    <>

    {/* DESKTOP */}
    <div className='modulos container container__modulos d-none d-md-flex'>

      {
        modulos?.length > 0 && (
          <AliceCarousel       
            items={carouselModulos}
            responsive={responsive}
            disableDotsControls
            onInitialized={mostrarOcultarBotonesCarousel}
            onResized={() => setShowHideArrows(!showHideArrows)}
            renderPrevButton={({ isDisabled }) => {
              return <button className="div__prev__button" ref={refPrevButtonCarousel} disabled={isDisabled}>{ isDisabled ? <ButtonPreviousModuloDisabled /> : <ButtonPreviousModulo /> }</button>
            }}
            renderNextButton={({ isDisabled }) => {
              return <button className="div__next__button" ref={refNextButtonCarousel} disabled={isDisabled}>{ isDisabled ? <ButtonNextModuloDisabled /> : <ButtonNextModulo /> }</button>
            }}
          />
        )
      }      
    </div>

    {/* MOBILE */}
    <div className="modulos d-flex d-md-none">
      {
        modulos.map(eco => { 
          return (
            <div
              key={eco.id}
              onClick={() => seleccionarEcosistema(eco)}
              className={`modulos__eco ${globalEcosistemaActivo === eco.id ? `activo ${eco.color}` : '' }`}
            >
              {/*<img src={eco.ico} alt="imagen de ecosistema" />*/}
              <p>{eco.name}</p>              
            </div>
          )
        })
      }
    </div>
    </>
  );
};
export default Modulos;
