import PrincipalContactoResumen from "../../homeout/principal/components/PrincipalContactoResumen";
import useTitle from "../../hooks/useTitle";
import AuthLoginForm from "../components/AuthLoginForm";
import AuthLoginImagenes from "../components/AuthLoginImagenes";
import "../styles/authActivar.css";

const AuthLogin = () => {
  useTitle({ title: "Iniciar Sesión" });

  return (
    <>
      <main className="login__container">
        <div className="login__container-datos">
          <AuthLoginForm />
        </div>

        <AuthLoginImagenes />
      </main>

      <PrincipalContactoResumen />
    </>
  );
};

export default AuthLogin;
