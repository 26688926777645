import React from 'react'
import useBotons from '../../../hooks/useBotons'
import useTitle from '../../../hooks/useTitle'

const HomeOutNosotros = () => {

  useTitle({ title: '¿Quiénes somos?' })
  const { activaCuentaBtn } = useBotons()

  return (
    <div className='nosotros'>
      <h2>¿Quiénes somos?</h2>
      <p>Somos Cardif Mexico, una Compañía de Seguros y Reaseguros a nivel mundial que <strong>en alianza con Scotiabank respaldamos tus Seguros.</strong></p>
      <p>Buscamos mejorar tu experiencia y la de todos nuestros clientes a través de productos y servicios de alto valor, por eso hemos creado diversos beneficios a los que podrás acceder gratuitamente según el tipo de Seguro que cuentes con nosotros.</p>

      <div className="somos__btn">
        {activaCuentaBtn('rojo', true)}
      </div>

      <h2>Nuestra Misión</h2>
      <p><strong>"Satisfacer las necesidades de nuestros socios y clientes finales, brindando soluciones innovadoras, simples y flexibles".</strong> La mejor forma de vivir nuestra misión es; principalmente, satisfaciendo las necesidades de nuestros clientes internos y brindándoles soluciones practicas que generen valor.</p>

      <h2>Nuestra Visión</h2>
      <p><strong>"Ser la compañía de seguros referente en banca, retail y financieras ofreciendo productos orientados a personas y sus bienes".</strong> La mejor forma de vivir nuestra visión es; principalmente, siendo referentes de buenas conductas y prácticas en nuestro día a día, desarrollando una actitud de apoyo y proactividad.</p>

      <h2>Nuestros Valores</h2>
      <ul>
        <li>
          <p>
            <strong>Compromiso:</strong> Demostrar un alto espíritu de equipo: comprometernos con nuestros clientes internos y externos y desarrollar siempre nuestras competencias profesionales
          </p>
        </li>
        <li>
          <p>
            <strong>Proactividad:</strong> Tener capacidad de reacción; adaptarse y anticiparse a situaciones nuevas o imprevistas.
          </p>
        </li>
        <li>
          <p>
            <strong>Creatividad:</strong> Hacer propuestas y participar de los cambios; asumir iniciativas, compartir ideas y experiencias.
          </p>
        </li>
        <li>
          <p>
            <strong>Ambición:</strong> Esfuerzo espontáneo por mejorar nuestro desempeño y participar activamente por el cumplimiento de los objetivos comunes.
          </p>
        </li>
      </ul>

    </div>
  )
}

export default HomeOutNosotros