import { useState } from "react";
import { useEffect } from "react";
import Countdown from "react-countdown";

export default function Timer({ mill, end, status, handleSetTimer }) {

    let dateTime = Date.now() + mill;

    const [timeCodeIndex, setTimeCodeIndex] = useState(false);

    const renderer = ({ hours, minutes, seconds, completed }) => {
        let milisegunSegundos = seconds * 1000;
        let milisegunMinutos = minutes * 60000;
        let tiempoFinal = milisegunSegundos + milisegunMinutos;
        handleSetTimer(tiempoFinal);
        if (completed) {
            return (
                <span className='timer-span tx-primary tx-bold'>
                    <span className='timer'>
                        { (timeCodeIndex) && end(false) }
                    </span>
                </span>
              );
        } else {
          return (
            <span style={{display: status ? 'none' : 'block'}} className='timer-span tx-primary tx-bold'>
                { tiempoFinal === 60000
                    ? 60
                    :  seconds } Seg.
            </span>
          );
        }
    };

    useEffect(()=>{
        setTimeCodeIndex(!timeCodeIndex);
    },[status]);

    return (
        <Countdown date={dateTime} renderer={renderer} key={timeCodeIndex} />
    )
}