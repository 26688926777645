import Axios from "axios";
import * as CryptoJS from "crypto-js";
import { CLI_PUB, URL_BACKEND_AUTHS } from "../enviroments/enviroment";

export function getToken() {
  return CryptoJS.AES.decrypt(sessionStorage.getItem('token'),CLI_PUB).toString(CryptoJS.enc.Utf8);
}

export function isActive() {
  return window.sessionStorage.getItem("token") ? true : false;
}
export function getUser() {
  if (sessionStorage.getItem('user') === null) {
    return null
  }
  return JSON.parse(CryptoJS.AES.decrypt(sessionStorage.getItem('user'),CLI_PUB).toString(CryptoJS.enc.Utf8))
}
export function parseJwt(token) {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/inicio");
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );
  return JSON.parse(jsonPayload);
}

export function closeSession() {
  localStorage.clear();
  sessionStorage.clear();
  window.location.reload();
}

export function parseIntConvert(number) {
  let num = parseInt(number.replace(/[,]/gi, ""));
  return num;
}
export function coverageId() {
  let aux;
  if (isActive()) {
    // getUser().coverage.forEach(element=>{
    //     aux.push(element.idModulo);
    // });
    // console.log(getUser().coverage)
    aux = getUser().coverage;
  }
  return aux;
}

// export function encryptDecrypt(password, keyPass) {
//     var key = keyPass.split("");
//     var output = [];

//     for (var i = 0; i < password.length; i++) {
//         var charCode = password.charCodeAt(i) ^ key[i % key.length].charCodeAt(0);
//         output.push(String.fromCharCode(charCode));
//     }
//     return output.join("");
// }

export function encryptPass(password) {}

export function getCurrentDate(separator = "") {
  let newDate = new Date();
  let date = newDate.getDate();
  let month = newDate.getMonth() + 1;
  let year = newDate.getFullYear();

  return `${year}${separator}${
    month < 10 ? `0${month}` : `${month}`
  }${separator}${date}`;
}

export function addLogs(pagina, control, extra) {
  var logUser = {
    idUsuario: 0,
    ciudad_localizacion: "",
    fechaServidor: "",
    horaServidor: "",
    fechaLocal: "",
    horaLocal: "",
    direccion_ip: "",
    pagina: pagina,
    control: control,
    extra: extra,
  };
  if (getUser() === null) {
    logUser.idUsuario = 0;
  } else {
    logUser.idUsuario = getUser().idUsuario;
  }
  let date = new Date();
  logUser.fechaLocal = `${date.getDate()}/${
    date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
  }/${date.getFullYear()}`;
  logUser.horaLocal = `${
    date.getHours() < 10 ? "0" + date.getHours() : date.getHours()
  }:${date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()}`;
  Axios.get(`https://geomok.grupomok.com.co/json`, {
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((succ) => {
      logUser.direccion_ip = succ.data.ip;
      logUser.ciudad_localizacion = succ.data.city;
      Axios.post(`${URL_BACKEND_AUTHS}/logUser/inserta`, JSON.stringify(logUser), {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + getToken(),
        },
      })
        .then((success) => {
          if (pagina === "logout") {
            getOutSession();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    })
    .catch((error) => {
      console.log("EERROR ??", error);
    });
}

const getOutSession = () => {
  closeSession();
  setTimeout(() => {
    console.log(isActive());
  }, 2000);
};
