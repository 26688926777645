import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import SpinnerCuadrado from "../../helpers/SpinnerCuadrado";
import HeaderUser from "./HeaderUser";

const HeaderMenu = ({ globalAutenticado }) => {
  const [cerrando, setCerrando] = useState(false);
  const { pathname } = useLocation();

  const llamarGTM = (ecosys, service, acc) => {
    window.dataLayer.push({
      event: `SBK_${ecosys}_${service}_BTN_${acc}`,
      action: `BTN_${acc}`,
    });
  };

  return (
    <div className="home__opciones">
      {(!globalAutenticado) ? (
        <>
          {cerrando && <SpinnerCuadrado />}

          {/*<NavLink
                            to="/nosotros"
                            className={({ isActive }) => isActive ? 'header__text activo desktop' : 'header__text desktop'}
                        >
                            <div className='header__nosotros'>
                                <p>Nosotros</p>
                            </div>
                        </NavLink>*/}

          {/* CONOCE TUS BENEFICIOS */}
          {/*<HeaderSubMenu />*/}

          {/* ZONA DE INICIO DE SESION Y ALTAS */}
          <NavLink
            to="/iniciasesion"
            className="desktop"
            // onClick={llamarGTM("GR", "INICIASESION", "INICIARSESION")}
          >
            <div
              className="header__botones"
              style={{
                fontWeight: "bolder",
                letterSpacing: "0.04em",
                textAlign: "center",
              }}
            >
              Iniciar sesión
            </div>
          </NavLink>

          <NavLink
            to="/registro"
            className="desktop"
            // onClick={llamarGTM("GR", "ACTIVATUCUENTA", "REGISTRAR")}
          >
            <div
              className="header__botonesconfondo"
              style={{
                fontWeight: "bolder",
                letterSpacing: "0.04em",
                textAlign: "center",
              }}
            >
              Activa tu cuenta
            </div>
          </NavLink>

          {pathname !== "/iniciasesion" ? (
            <NavLink
              to="/iniciasesion"
              className="mobile"
              // onClick={llamarGTM("GR", "INICIASESION", "INICIARSESION")}
            >
              <div className="header__botones">Iniciar Sesión</div>
            </NavLink>
          ) : null}
        </>
      ) : (
        <>
          <HeaderUser cerrando={cerrando} setCerrando={setCerrando} />
        </>
      )}
    </div>
  );
};

export default HeaderMenu;
