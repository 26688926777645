import { URL_BACKEND_AUTHS } from "../enviroments/enviroment"


export const refreshToken = async (token,objUsuario) => {
    try{
        
        const response = await fetch(`${URL_BACKEND_AUTHS}/Token/RefreshToken`,{
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(objUsuario),
        })
        const json = await response.json()
        return json
    
    }catch(err){
        throw err
    }
}