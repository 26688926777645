import React from "react";
import { useNavigate } from "react-router-dom";

const OutEcosistemasCards = ({
  ecoDetalle,
  index,
  color,
  nombreEcosistema,
}) => {
  const navigate = useNavigate();

  return (
    <div className="eco__card">
      <div>
        <div className="eco__card-img">
          <img
            className="eco__card-icoprincipal"
            src={ecoDetalle.imgmini}
            alt="imagen eco"
          />
          <div className="eco__card-ico_container ">
            <img
              className="eco__card-ico"
              src={ecoDetalle.ico}
              alt="imagen eco icono"
            />
          </div>
        </div>
        <div className="eco__card-texto">
          <div className="eco__card-superior">
            <h4
              style={{ textAlign: "center", marginBottom: "1em" }}
              className={`eco__card-title  ${color}`}
            >
              {ecoDetalle.title}
            </h4>
            {/*<h4 className={`eco__card-title`}>{index + 1}. {ecoDetalle.title}</h4>*/}
            {(ecoDetalle.id >= 38 && ecoDetalle.id <= 45) ||
            ecoDetalle.id === 59 ? (
              <></>
            ) : (
              <p
                className="eco__card-subtitulo"
                style={{
                  textAlign: "center",
                  fontFamily: "Scotia",
                  fontWeight: "bold",
                }}
              >
                {ecoDetalle.subtitle}
              </p>
            )}

            <p
              style={{ textAlign: "left" }}
              className="eco__card-descripcion"
              dangerouslySetInnerHTML={{ __html: ecoDetalle.desc }}
            ></p>
          </div>
        </div>
      </div>
      <div className="eco__card-btn">
        {ecoDetalle.id === 59 ||
        (ecoDetalle.id >= 60 && ecoDetalle.id <= 83) ||
        nombreEcosistema.includes("Cuidados") ? (
          <>
            <br />
            <br />
          </>
        ) : (
          <button
            className={`vermas__btn`}
            onClick={() => {
              navigate(ecoDetalle.linkOut);
            }}
          >
            Ver más
          </button>
        )}
      </div>
    </div>
  );
};

export default OutEcosistemasCards;
