import React from "react";
import usePresentationNew from "../../../../hooks/usePresentationNew";
import VidaPlusEvaluacionMedicaDetalle from "./VidaPlusEvaluacionMedicaDetalle";
import useTitle from "../../../../hooks/useTitle";

const VidaPlusEvaluacionMedica = () => {
  useTitle({ title: "Servicio Evaluación Médica en Vida Plus" });
  const { presentacionIn } = usePresentationNew(true, "¡Comienza ya!", 46);

  return (
    <>
      {presentacionIn(
        "VP",
        "EVALUACIONMEDICA",
        "COMIENZAYA",
        "evaluacion-medica"
      )}

      <VidaPlusEvaluacionMedicaDetalle />
    </>
  );
};

export default VidaPlusEvaluacionMedica;
