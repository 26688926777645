import React, { useState, useContext } from 'react'
import { encodeBase64, encryptDecrypt, getCoberturas, getCoberturasGlobal, getIdUser } from '../../helpers/validations'
import sha256 from "sha256";
import { getSession, postLogin, postUpdate } from '../../../services/auth';
import ReCAPTCHA from 'react-google-recaptcha';
import { CLI_PUB, COD_RECAPTCHA_V3 } from '../../../enviroments/enviroment';
import useValidator from '../../hooks/useValidator';
import * as CryptoJS from "crypto-js";
import { addLogs } from '../../../services/logs_proteccion';
import { refreshTokenSession } from '../../helpers/sessions';
import AuthContext from '../../../context/auth/authContext';
import Portal from '../../helpers/Portal';
import { PoliticasDeTratamiento } from '../../helpers/PoliticasDeTratamiento';
import { terminosycondiciones } from '../../helpers/terminoscondiciones';

const AuthRegisterFormPass = ({ errorData, mensaje, recaptchaRef, setMensaje, setSpinner, setErrorData, formCode, setCount, form }) => {

    const { globalIniciarSesion } = useContext(AuthContext);
    const [mensajeErrorCheckbox, setMensajeErrorCheckbox] = useState('')
    const [ocultoPass, setOcultoPass] = useState(true);
    const [ocultoConfirmPass, setOcultoConfirmPass] = useState(true);

    const [terminos, setTerminos] = useState(false)
    const [politicas, setPoliticas] = useState(false)

    const [formPass, setFormPass] = useState({
        id: "",
        code: "",
        password: "",
        passwordconfirm: "",
        tokenCaptcha: ""
    })

    const { formValidador } = useValidator(formPass.password) 

    const handleChangePass = (e) => {
        document.getElementById("passwordconfirm").style.borderColor = "var(--borde)"
        document.getElementById("passwordconfirm").style.color = "var(--bs-offcanvas-color)"
        document.getElementById("password").style.borderColor = "var(--borde)"
        document.getElementById("password").style.color = "var(--bs-offcanvas-color)"
        setMensaje("")
        setFormPass({
            ...formPass,
            [e.target.name]: e.target.value,
        })
    }

    const cambiarOcultoPass = () => {
        setOcultoPass(!ocultoPass);
    };

    const cambiarOcultoConfirmPass = () => {
        setOcultoConfirmPass(!ocultoConfirmPass);
    };

    const handleSubmitActivaCuenta = async (e) => {
        e.preventDefault();

        setMensajeErrorCheckbox('')

        let camposVacios = false;
        if (document.getElementById("check_terminos").checked === false || document.getElementById("check_privacidad").checked === false) {
            setMensajeErrorCheckbox("Para continuar, acepta los avisos legales")
            camposVacios = true
        }

        if (formPass.password === '') {
            setMensaje("Este dato es necesario")
            document.getElementById("password").style.borderColor = 'var(--rojo)'
            document.getElementById("password").style.color = 'var(--rojo)'
            camposVacios = true
        }

        if (formPass.passwordconfirm === '') {
            setMensaje("Este dato es necesario")
            document.getElementById("passwordconfirm").style.borderColor = 'var(--rojo)'
            document.getElementById("passwordconfirm").style.color = 'var(--rojo)'
            camposVacios = true
        }

        if (formPass.password === formPass.passwordconfirm) {

            if(camposVacios) return

            const token = await recaptchaRef.current.executeAsync();
            recaptchaRef.current.reset()
            let tokenUpdate = await recaptchaRef.current.executeAsync();
            recaptchaRef.current.reset()
            let tokenLogin = await recaptchaRef.current.executeAsync();
            recaptchaRef.current.reset()

            if (!token) {
                setMensaje('Captcha inválido, actualizar web')
                return
            }

            setSpinner(true)

            let pattern = /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!,%,&,@,#,$,^,*,?,_,~,.,(,),/,-]).{7,15}/
            if (!pattern.test(formPass.passwordconfirm)) {
                setMensaje("La nueva contraseña debe tener al entre 8 y 16 caracteres, al menos un dígito, al menos una minúscula y al menos una mayúscula.")
                setErrorData(true)
                setSpinner(false)
                document.getElementById("passwordconfirm").style.borderColor = 'var(--rojo)'
                document.getElementById("passwordconfirm").style.color = 'var(--rojo)'
                // console.log('err 1')
                return
            }

            if (!pattern.test(formPass.password)) {
                setMensaje("La nueva contraseña debe tener al entre 8 y 16 caracteres, al menos un dígito, al menos una minúscula y al menos una mayúscula.")
                setErrorData(true)
                setSpinner(false)
                document.getElementById("password").style.borderColor = 'var(--rojo)'
                document.getElementById("password").style.color = 'var(--rojo)'
                // console.log('err 2')
                return
            }

            try {

                let saltPassword = btoa(
                encryptDecrypt(
                    sha256(formPass.password),
                    tokenUpdate.substring(
                    tokenUpdate.length - 10,
                    tokenUpdate.length
                    )
                )
                );
    
                let datosUpdate = {
                    fono: "0",//rpta.user.fono ? rpta.user.fono : "",
                    idusuario: formCode.id,//rpta.user.idusuario,
                    email: form.identificacion,//rpta.user.email ? rpta.user.email : "",
                    clave: saltPassword,
                    tokenCaptcha: tokenUpdate,
                };
                // ****************
                postUpdate(datosUpdate).then(async (res) => {
                    if (res.Codigo === 1 || res.Codigo === 2) {
                        setMensaje(res.Mensaje);
                        setSpinner(false);
                        document.getElementById("passwordconfirm").style.borderColor = 'var(--rojo)'
                        document.getElementById("passwordconfirm").style.color = 'var(--rojo)'
                        document.getElementById("password").style.borderColor = 'var(--rojo)'
                        document.getElementById("password").style.color = 'var(--rojo)'
                    } else if (res.Codigo === 0) {
                        addLogs("Activación cuenta Usuario", "Se envía SMS al usuario sobre activación de cuenta exitosa", "")
                        setMensaje("");
        
                        // PASO 3:....
                        // navigate('/registro/3')
                        // ***************************
    
                        let objUsuario = {
                            usuario: encodeBase64(form.identificacion),
                            password: encodeBase64(
                                encryptDecrypt(
                                    sha256(formPass.password),
                                    tokenLogin.substring(
                                        tokenLogin.length - 10,
                                        tokenLogin.length
                                    )
                                )
                            ),
                            sitioPassword: "9B9CB6631B27AA1DF47DB351B497004810015B09",
                            sitioUsuario: "CARDIF",
                            deviceInfo: "",
                            tokenCaptcha: tokenLogin,
                        };
    
                        postLogin(objUsuario).then((rpta) => {
                            if (rpta.codigo === 0) {
                                sessionStorage.setItem(
                                "token",
                                CryptoJS.AES.encrypt(rpta.data.token, CLI_PUB)
                                );
            
                                // VALIDAR CAMPOS QUE BRINDA
                                //setMensaje('Alta conforme, iniciar sesión')
    
                                getSession(rpta.data.token).then((data) => {
                                    // console.log('paso getSession')
                                    let res = { data }.data;
                                    sessionStorage.setItem(
                                        "user",
                                        CryptoJS.AES.encrypt(JSON.stringify(res), CLI_PUB)
                                    );
                                    addLogs(
                                        "Iniciar Sesión",
                                        "Inicio de sesión exitoso",
                                        JSON.stringify(objUsuario)
                                    );
                
                                    let idUser = getIdUser();
                                    globalIniciarSesion({
                                        globalDataUser: {
                                            globalUsuId: idUser,
                                            globalUsuName: res.nombres,
                                            globalUsuSurnameMat: res.apellidoMaterno,
                                            globalUsuSurname: res.apellidoPaterno,
                                            globalUsuCellphone: res.numeroCelular,
                                            globalUsuEmail: res.correoElectronico,
                                            globalUsuTipoDoc: res.dicTipoDocumento,
                                            globalDocIdentidad: res.identificacion,
                                            globalUsuRole: "",
                                            globalInvitado: res.invitado,
                                            globalPlanCardifId: res.idPlanCardif,
                                        },
                                        globalModulos: getCoberturasGlobal(getCoberturas()),
                                        globalToken: CryptoJS.AES.encrypt(
                                        rpta.data.token,
                                        CLI_PUB
                                        ),
                                        globalTokenUser: CryptoJS.AES.encrypt(
                                        JSON.stringify(res),
                                        CLI_PUB
                                        ),
                                        globalAutenticado: true,
                                        globalCargando: false,
                                    });
    
                                    // navigate('/ecosistema')
                                    //validaruta()
                                    setSpinner(false);
                                    setCount(4)
                                    refreshTokenSession();

                                    const state = window.history.state
                                    const urlOrigin = window.location.origin
                                    const newUrl = urlOrigin + "/registroexitoso"
                                    window.history.pushState(state, "", newUrl)
                                });
                            } else {
                                // console.log('paso postLogin pero NO es 0')
                                setMensaje(rpta);
                                setSpinner(false);
                            }
                        });
    
                    // FINA PASO 3 ***************************
                    }
                });
                // FIN PASE 2 ****************
            } catch (err) {
                document.getElementById("passwordconfirm").style.borderColor = 'var(--rojo)'
                document.getElementById("passwordconfirm").style.color = 'var(--rojo)'
                document.getElementById("password").style.borderColor = 'var(--rojo)'
                document.getElementById("password").style.color = 'var(--rojo)'
                setMensaje(err)
                setErrorData(true)
                setSpinner(false)
            }
        } else {
            // console.log('error de contraseñas')
            setErrorData(true)
            setMensaje("La contraseña no coincide. Por favor, revísala")
            document.getElementById("passwordconfirm").style.borderColor = 'var(--rojo)'
            document.getElementById("passwordconfirm").style.color = 'var(--rojo)'
            document.getElementById("password").style.borderColor = 'var(--rojo)'
            document.getElementById("password").style.color = 'var(--rojo)'
        }
    }

    return (
        <>
            {
                terminos && <Portal setTerminos={setTerminos} terminos={terminosycondiciones} />
            }
            {
                politicas && <Portal setTerminos={setPoliticas} terminos={PoliticasDeTratamiento} />
            }
            <div className="auth__login">
                <h3 className="auth__title">Crear una contraseña</h3>
                <p className="auth__subtitle" > Solo falta crear una contraseña segura </p>
                <form onSubmit={handleSubmitActivaCuenta}>

                    <div className="auth__login-label" >Contraseña</div>
                    <div className="auth__login-group" >
                        <div className="auth__login-pass">
                            <input
                                type={`${ocultoPass ? "password" : "text"}`}
                                className="auth__login-docompleto"
                                placeholder="Ingresa tu contraseña"
                                name="password"
                                id="password"
                                value={formPass.password}
                                onChange={handleChangePass}
                            />
                            {ocultoPass ? (
                            <i
                                onClick={cambiarOcultoPass}
                                className="fa-solid fa-eye-slash pass__icon"
                            ></i>
                            ) : (
                            <i
                                onClick={cambiarOcultoPass}
                                className="fa-solid fa-eye pass__icon"
                            ></i>
                            )}
                        </div>
                    </div>
                    {
                        mensaje !== '' &&
                        <div className='texto__error'>
                            <p>{mensaje}</p>
                        </div>
                    }

                    <div className="auth__login-label" >Confirmar contraseña</div>
                        <div className="auth__login-group" >
                            <div className="auth__login-pass">
                            <input
                                type={`${ocultoConfirmPass ? "password" : "text"}`}
                                className="auth__login-docompleto"
                                placeholder="Confirma tu contraseña"
                                name="passwordconfirm"
                                id="passwordconfirm"
                                value={formPass.passwordconfirm}
                                onChange={handleChangePass}
                            />
                            {ocultoConfirmPass ? (
                            <i
                                onClick={cambiarOcultoConfirmPass}
                                className="fa-solid fa-eye-slash pass__icon"
                            ></i>
                            ) : (
                            <i
                                onClick={cambiarOcultoConfirmPass}
                                className="fa-solid fa-eye pass__icon"
                            ></i>
                            )}
                        </div>
                    </div>
                    {
                        mensaje !== '' &&
                        <div className='texto__error'>
                            <p>{mensaje}</p>
                        </div>
                    }

                    {formValidador()}

                    <div className="div__checkboxes">
                        <input type='checkbox' id='check_terminos' /><label className="label__checkbox"><p>He leído y estoy de acuerdo con los <span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => setTerminos(true)}>términos y condiciones</span></p></label>
                        <br/>
                        <input type='checkbox' id='check_privacidad' /><label className="label__checkbox"><p>He leído y estoy de acuerdo con el <span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => setPoliticas(true)}>aviso de privacidad</span></p></label>
                    </div>
                    {
                        mensajeErrorCheckbox !== '' &&
                        <div className='texto__error' style={{ marginTop: '1rem', marginLeft: '2.5rem'}}>
                            <p>{mensajeErrorCheckbox}</p>
                        </div>
                    }

                    <div>
                        <input
                            type="submit"
                            className="btn__login completo"
                            value="Finalizar"
                        />
                        <ReCAPTCHA
                            ref={recaptchaRef}
                            size="invisible"
                            sitekey={COD_RECAPTCHA_V3}
                        />
                    </div>
                </form>
            </div>
        </>
    )
}

export default AuthRegisterFormPass