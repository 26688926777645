import React, { Suspense } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import FallbackCards from '../../modules/helpers/FallbackCards'

const VidaBienestar = React.lazy(() => import('../../modules/homein/vida/components/bienestar/VidaBienestar'))
const VidaScreen = React.lazy(() => import('../../modules/homein/vida/screens/VidaScreen'))
const PageNotFound = React.lazy(() => import('../../modules/404/PageNotFound'))

const RouterVida = () => {
    return (
        <>
            <Suspense fallback={FallbackCards}>
                <Routes>
                    <Route path='/bienestar-integral' element={<VidaBienestar />} />
                    <Route path='/' element={<VidaScreen />} /> 
                    <Route path='/404' element={<PageNotFound />} />
                    <Route path='*' element={<Navigate to="/404" />} />
                </Routes>
            </Suspense>
        </>
    )
}

export default RouterVida