import React, { Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import FallbackCards from "../../modules/helpers/FallbackCards";

const ProteccionFinancieraScreen = React.lazy(() =>
  import(
    "../../modules/homein/proteccion-financiera/screens/ProteccionFinancieraScreen"
  )
);
const ProteccionFinancieraCoursera = React.lazy(() =>
  import(
    "../../modules/homein/proteccion-financiera/components/ProteccionFinancieraCoursera"
  )
);
const ProteccionFinancieraOnline = React.lazy(() =>
  import(
    "./../../modules/homein/proteccion-financiera/components/proteccion-online/ProteccionFinancieraOnline"
  )
);
const ProteccionFinancieraAlertasBuro = React.lazy(() =>
  import(
    "../../modules/homein/proteccion-financiera/components/alertas-buro/ProteccionFinancieraAlertasBuro"
  )
);
const ProteccionFinancieraCvDesign = React.lazy(() =>
  import(
    "../../modules/homein/proteccion-financiera/components/curriculum/ProteccionFinancieraCvDesign"
  )
);
const ProteccionFinancieraMarcaPersonalAsesoria = React.lazy(() =>
  import(
    "../../modules/homein/proteccion-financiera/components/marca-personal/ProteccionFinancieraMarcaPersonalAsesoria"
  )
);
const ProteccionFinancieraMarcaPersonalPsicotecnica = React.lazy(() =>
  import(
    "../../modules/homein/proteccion-financiera/components/marca-personal/ProteccionFinancieraMarcaPersonalPsicotecnica"
  )
);
const RouterProteccionFinancieraLegal = React.lazy(() =>
  import("./RouterProteccionFinancieraLegal")
);
const ProteccionFinancieraCvAsesoria = React.lazy(() =>
  import(
    "../../modules/homein/proteccion-financiera/components/curriculum/ProteccionFinancieraCvAsesoria"
  )
);
const RouterProteccionFinancieraSalario = React.lazy(() =>
  import("./RouterProteccionFinancieraSalario")
);
const RouterProteccionFinancieraJob = React.lazy(() =>
  import("./RouterProteccionFinancieraJob")
);
const PageNotFound = React.lazy(() => import("../../modules/404/PageNotFound"));

const RouterProteccionFinanciera = () => {
  return (
    <>
      <Suspense fallback={<FallbackCards />}>
        <Routes>
          <Route path="/coursera" element={<ProteccionFinancieraCoursera />} />
          <Route
            path="/proteccion-online"
            element={<ProteccionFinancieraOnline />}
          />
          <Route
            path="/legal/*"
            element={<RouterProteccionFinancieraLegal />}
          />
          <Route
            path="/curriculum/*"
            element={<ProteccionFinancieraCvDesign />}
          />
          <Route
            path="/marca-personal/*"
            element={<ProteccionFinancieraCvAsesoria />}
          />
          <Route
            path="/alertas-buro"
            element={<ProteccionFinancieraAlertasBuro />}
          />
          <Route
            path="/entrevista"
            element={<ProteccionFinancieraMarcaPersonalAsesoria />}
          />
          <Route
            path="/aptitudes-habilidades"
            element={<ProteccionFinancieraMarcaPersonalPsicotecnica />}
          />
          <Route
            path="/reporte-salarial/*"
            element={<RouterProteccionFinancieraSalario />}
          />
          <Route
            path="/ofertas-laborales/*"
            element={<RouterProteccionFinancieraJob />}
          />
          <Route path="/" element={<ProteccionFinancieraScreen />} />
          <Route path="/404" element={<PageNotFound />} />
          <Route path="*" element={<Navigate to="/404" />} />
        </Routes>
      </Suspense>
    </>
  );
};

export default RouterProteccionFinanciera;
