import { URL_BACKEND_AUTHS } from "../enviroments/enviroment"

export const getCoursera = async (idUsuario,token,eco,datacoursera) => {
    try{
        const response = await fetch(`${URL_BACKEND_AUTHS}/coursera/obtenerLink`,{
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(datacoursera),
        })
        if(response.status>300){
            return ['', response.status]
        }
        const json = await response.json()
        return [json, response.status]
    

    }catch(err){
        console({err})
        throw err
    }
}

export const saveCourseraData = async (token,obj) => {
    
    try {
        const response = await fetch(`${URL_BACKEND_AUTHS}/coursera/GuardarDataCoursera`, {
            mode: 'cors',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(obj),
        })
        const json = await response.json()
        return json

    } catch (err) {
        throw err
    }
}

export const CantidadIntentosCoursera = async (token) => {
    
    try {
        const response = await fetch(`${URL_BACKEND_AUTHS}/coursera/CantidadIntentosCoursera`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
        })
        const json = await response.json()
        return json

    } catch (err) {
        throw err
    }
}

