import React from "react";
import useCards from "../../../hooks/useCards";
import useOutEcosistema from "../../../hooks/useOutEcosistema";
import useTitle from "../../../hooks/useTitle";
import HomeOutActivaTuCuenta from "../../ecosistemas/HomeOutActivaTuCuenta";
import PrincipalContacto from "../../principal/components/PrincipalContacto";
import PrincipalLogos from "../../principal/components/PrincipalLogos";

const HomeOutSalud = () => {
  useTitle({ title: "Salud | BNP Paribas Cadif" });
  const { ecosistemaPresentacionOut } = useOutEcosistema();
  const { ecosistemaCards } = useCards();

  return (
    <>
      {ecosistemaPresentacionOut(12)}

      {ecosistemaCards(12)}

      <HomeOutActivaTuCuenta />
      <div style={{ background: "var(--bg_claro)" }}>
        <PrincipalLogos direccion="centro" />

        <PrincipalContacto />
      </div>
    </>
  );
};

export default HomeOutSalud;
