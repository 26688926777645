import { Modal } from "react-bootstrap";
import Swal from "sweetalert2";
import "../encuestaCoursera.css";
import { useContext, useState } from "react";
import AuthContext from "../../../context/auth/authContext";
import { postCurp } from "../../../services/salud";
import { getToken } from "../../helpers/validations";
import { addLogs } from "../../../services/logs_proteccion";

const ModalVidaCurp = (props) => {
  const { globalActualizarVidaCurp } = useContext(AuthContext);
  const [infoCuestionario, setInfoCuestionario] = useState(
    defaulBeneficiario()
  );

  function defaulBeneficiario() {
    return {
      curp: "",
    };
  }

  const handleClose = () => {
    props.close(false);
  };

  const handleSaveCurp = async (e) => {
    e.preventDefault();
    let data = await postCurp(getToken(), infoCuestionario.curp);
    if (data?.codigo === 1) {
      handleClose();
      globalActualizarVidaCurp(true);
      addLogs('Vida', 'usuario agrega CURP exitósamente', '')
      Swal.fire("Éxito", data?.mensaje, "success");
    } else {
      Swal.fire("Error", data?.mensaje, "error");
    }
  };

  const onChangeForm = (e, i) => {
    let value = e.target.value;
    const key = e.target.name;
    let tmpForm = { ...infoCuestionario };
    tmpForm[key] = value;
    setInfoCuestionario(tmpForm);
  };

  return (
    <>
      <Modal
        show={props.show}
        onHide={handleClose}
        size="md"
        backdrop="static"
        centered
      >
        <Modal.Body>
          <div className="encuesta__coursera mt-5">
            <h1 className="mb-5">Ingresa tu CURP</h1>
            <p>
              Así facilitas el acceso a los servicios médicos y de bienestar
            </p>

            <form
              className="formBeneficiario mt-4 mb-4"
              onSubmit={handleSaveCurp}
            >
              <div className="col-12 d-flex justify-content-center mb-4">
                <div className="col-6">
                  <label style={{ display: "flex" }}>
                    <b>CURP</b>
                  </label>
                  <input
                    className="form-control-salud-beneficiario"
                    type="text"
                    name="curp"
                    value={infoCuestionario.curp}
                    placeholder="Ej: MOMOCD 000000MDFNSN00"
                    style={{ borderRadius: "0px 8px 8px 0px", flexGrow: 1 }}
                    onChange={onChangeForm}
                    required
                    minLength="18"
                  />
                  <p
                    style={{ display: "flex", padding: "5px 0 0 0" }}
                    className="text-right"
                  >
                    ¿No conoces tu CURP? &nbsp;{" "}
                    <a
                      style={{ color: "#138468", fontWeight: "bold" }}
                      href="https://www.gob.mx/curp/"
                      target="_blank"
                    >
                      Ingresa aquí
                    </a>
                  </p>
                </div>
              </div>

              <div className="card__btns__encuesta">
                <button
                  className={`activacuenta__btn verde-vida`}
                  style={{ borderRadius: "7px", fontSize: "12px" }}
                  type="submit"
                >
                  CONTINUAR
                </button>
                <button
                  className={`unirme__btn verde-vida`}
                  style={{ borderRadius: "7px", fontSize: "12px" }}
                  onClick={handleClose}
                >
                  VOLVER
                </button>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ModalVidaCurp;
