import React from "react";
import useCards from "../../../hooks/useCards";

const EmpleabilidadCards = () => {
  const { homeinCards } = useCards();

  return <>{homeinCards(3)}</>;
};

export default EmpleabilidadCards;
