import { URL_BACKEND_AUTHS } from "../enviroments/enviroment"

export const TraerBeneficiarios = async (token) => {
    try {
        const response = await fetch(`${URL_BACKEND_AUTHS}/Profile/TraerBeneficiarios`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })

        const json = await response.json()
        return json

    } catch (err) {
        throw err
    }
}

export const postRegisterBeneficios = async (token, data) => {
    try {
        const response = await fetch(`${URL_BACKEND_AUTHS}/Profile/agregarInvitados`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(data),
        })

        const json = await response.json()
        return json

    } catch (err) {
        throw err
    }
}

export const getCurp = async (token) => {
    try {
        const response = await fetch(`${URL_BACKEND_AUTHS}/Profile/TraerCURP`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })

        const json = await response.json()
        return json

    } catch (err) {
        throw err
    }
}

export const postCurp = async (token, curp) => {
    try {
        const response = await fetch(`${URL_BACKEND_AUTHS}/Profile/GuardarCURP/${curp}`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })

        const json = await response.json()
        return json

    } catch (err) {
        throw err
    }
}

export const getMedismart = async (token, idContingencia, ecosistema) => {
    try {
        const response = await fetch(`${URL_BACKEND_AUTHS}/Profile/MediSmartLink?idBeneficiario=${idContingencia}&ecosistema=${ecosistema}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })

        const json = await response.json()
        return json

    } catch (err) {
        throw err
    }
}

export const getHolos = async (token) => {
    try {
        const response = await fetch(`${URL_BACKEND_AUTHS}/Profile/GetHolos`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })

        const json = await response.json()
        return json

    } catch (err) {
        throw err
    }
}