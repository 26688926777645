import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import AuthContext from '../../../context/auth/authContext'
import SpinnerCuadrado from '../../helpers/SpinnerCuadrado'
import HeaderUserPerfil from './HeaderUserPerfil'

const HeaderUserMobile = ({ setHamburger, setUser }) => {

    const { globalAutenticado } = useContext(AuthContext)
    const [cerrando, setCerrando] = useState(false)
    const navigate = useNavigate()

    const iniciaSesion = () => {
        setUser(false)
        navigate('/iniciasesion')
    }

    const registrate = () => {
        setUser(false)
        navigate('/registro')
    }

    useEffect(() => {
        setHamburger(false)
    }, [])


    return (
        <>
            {
                cerrando && <SpinnerCuadrado />
            }
            {
                globalAutenticado
                    ? <HeaderUserPerfil subMenuUser="true" setCerrando={setCerrando} setUser={setUser} />
                    : <div className=''>
                        <div className="usermobile__group" onClick={iniciaSesion}>
                            <i className="fa-regular fa-circle-user"></i>
                            <span >Inicia sesión</span>
                        </div>
                        <div className="usermobile__group" onClick={registrate}>
                            <i className="fa-solid fa-arrow-right-to-bracket"></i>
                            <span>¡Activa tu cuenta!</span>
                        </div>
                    </div>
            }
        </>

    )
}

export default HeaderUserMobile