import React, { Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import PageNotFound from "../../modules/404/PageNotFound";
import FallbackCards from "../../modules/helpers/FallbackCards";
import PrincipalContactoResumen from "../../modules/homeout/principal/components/PrincipalContactoResumen";
import HomeOutProteccionFinanciera from "../../modules/homeout/proteccion-financiera/screens/HomeOutProteccionFinanciera";

const ProteccionFinancieraOutCoursera = React.lazy(() =>
  import(
    "../../modules/homeout/proteccion-financiera/components/ProteccionFinancieraOutCoursera"
  )
);
const ProteccionFinancieraOutCurriculum = React.lazy(() =>
  import(
    "../../modules/homeout/proteccion-financiera/components/ProteccionFinancieraOutCurriculum"
  )
);
const ProteccionFinancieraOutProteccionOnline = React.lazy(() =>
  import(
    "../../modules/homeout/proteccion-financiera/components/ProteccionFinancieraOutProteccionOnline"
  )
);
const ProteccionFinancieraOutLegal = React.lazy(() =>
  import(
    "../../modules/homeout/proteccion-financiera/components/ProteccionFinancieraOutLegal"
  )
);
const ProteccionFinancieraOutAlertasBuro = React.lazy(() =>
  import(
    "../../modules/homeout/proteccion-financiera/components/ProteccionFinancieraOutAlertasBuro"
  )
);
const ProteccionFinancieraOutMarcaPersonal = React.lazy(() =>
  import(
    "../../modules/homeout/proteccion-financiera/components/ProteccionFinancieraOutMarcaPersonal"
  )
);
const ProteccionFinancieraOutEntrevista = React.lazy(() =>
  import(
    "../../modules/homeout/proteccion-financiera/components/ProteccionFinancieraOutEntrevista"
  )
);
const ProteccionFinancieraOutHabilidades = React.lazy(() =>
  import(
    "../../modules/homeout/proteccion-financiera/components/ProteccionFinancieraOutHabilidades"
  )
);

const RouterOutProteccionFinanciera = () => {
  return (
    <>
      <Suspense fallback={<FallbackCards />}>
        <Routes>
          <Route
            path="/coursera"
            element={<ProteccionFinancieraOutCoursera />}
          />
          <Route
            path="/curriculum"
            element={<ProteccionFinancieraOutCurriculum />}
          />
          <Route
            path="/proteccion-online"
            element={<ProteccionFinancieraOutProteccionOnline />}
          />
          <Route path="/legal" element={<ProteccionFinancieraOutLegal />} />
          <Route
            path="/alertas-buro"
            element={<ProteccionFinancieraOutAlertasBuro />}
          />
          <Route
            path="/marca-personal"
            element={<ProteccionFinancieraOutMarcaPersonal />}
          />
          <Route
            path="/entrevista"
            element={<ProteccionFinancieraOutEntrevista />}
          />
          <Route
            path="/habilidades"
            element={<ProteccionFinancieraOutHabilidades />}
          />
          <Route path="/" element={<HomeOutProteccionFinanciera />} />
          <Route path="/404" element={<PageNotFound />} />
          <Route path="*" element={<Navigate to="/404" />} />
        </Routes>

        {window.location.pathname !== "/proteccion-financiera" && (
          <PrincipalContactoResumen />
        )}
      </Suspense>
    </>
  );
};

export default RouterOutProteccionFinanciera;
