import { refreshToken } from "../../services/token";
import { getToken2, getToken, isactive } from "./validations";
import { password, user as us, CLI_PUB } from "../../enviroments/enviroment";
import * as CryptoJS from "crypto-js";

export function refreshTokenSession(globalInactivaSesion) {
    try{
        let token = getToken2()
        let user = JSON.parse(token['Usuario']);
        
        let form ={ 
            usuario: user['identificacion'], 
            password: user['clave'], 
            sitioUsuario: `${us}`, 
            sitioPassword: `${password}`
        }

        setInterval(() => {
            if (isactive()) {
                refreshToken(getToken(),form).then(res=>{
                    sessionStorage.setItem('token', CryptoJS.AES.encrypt(res.token, CLI_PUB));
                })
            }
        }, 60000);

        refreshToken(getToken(),form).then(res=>{
            sessionStorage.setItem('token', CryptoJS.AES.encrypt(res.token, CLI_PUB));
        }).catch((err) => {
            globalInactivaSesion()
        })

    }catch(err){

    }
}
