import moment from "moment"
import { useNavigate } from "react-router"
import { downAgendaOutlookV2, getAgendaCalendar, getAgendaYahoo } from "../../../helpers/agenda-helpers"
import { useEffect, useState } from "react"
import { formateaFecha } from "../../../helpers/formateaFecha"

const HomeAgendamientosDetalle = ({ agenda }) => {

    const [disableBotonVideollamada, setDisableBotonVideollamada] = useState(true)
    const [horas, minutos, segundos] = agenda.hora.split(':')
    const [dia, mes, anio] = formateaFecha(agenda.fechaAgenda).split('-')

    const dateAgenda = new Date(+anio, +mes - 1, dia, horas, minutos, segundos)

    const dias = ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"]
    const meses = ["enero", "febrero", "marzo", "abril", "mayo", "junio", "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"]

    let navigate = useNavigate()

    const gotoEditAgenda = () => {
        navigate(agenda.urlEdit, {
            state: {
                editarCita: true
            }
        })
    }

    const iniciarVideollamada = () => {
        window.open(agenda.urlVideollamada, "_blank")
    }

    const validateHoraCita = () => {
        if(Math.abs(Date.now() - dateAgenda) <= 900000) {
            setDisableBotonVideollamada(false)
        }
        else {
            setDisableBotonVideollamada(true)
        }
    }

    setInterval(() => {
        validateHoraCita()
    }, 60000)

    useEffect(() => {
        validateHoraCita()
    }, [])

    const onAgendaCalendar = ({horaAgenda, fechaAgenda, urlCliente, calendario}) => {
        let hora = horaAgenda.split(':');
        let horaEnd = parseInt(hora[0]) + 1 + hora[1] + hora[2];
        let fechaCalendar = moment(fechaAgenda).format("YYYYMMDD") + "T" + horaAgenda.replaceAll(":", "");
        let fechaCalendarEnd = moment(fechaAgenda).format("YYYYMMDD") + `T${horaEnd === '80000' || horaEnd === '90000' ? '0' : ''}` + horaEnd;

        getCalendar({calendario, dateStart: fechaCalendar, dateEnd: fechaCalendarEnd, urlCliente});
    }

    const getCalendar = ({calendario, dateStart, dateEnd, urlCliente}) => {
        let asunto = 'Vivetuseguro+-+Agendamiento+con+Especialista';
        let location = 'Mexico';
        if(calendario === 1){
            let description = `Haga+click+en+el+enlace+para+unirse+a+la+cita:+<a+href="${urlCliente}"+target="_blank">Ingresar</a>`;
            getAgendaCalendar({ asunto, dateStart, dateEnd, description, location })
        }else if(calendario === 2){
            let description = `Haga clic en el enlace para unirse a la cita: ${urlCliente}`;
            let asuntoAlarma = 'Vivetuseguro - Agendamiento con Especialista';
            downAgendaOutlookV2({ asunto, dateStart, dateEnd, description, location, asuntoAlarma });
        }else{
            let description = `Haga+click+en+el+enlace+para+unirse+a+la+cita:+${urlCliente}`;
            let duration = '60';
            getAgendaYahoo({ asunto, dateStart, dateEnd, description, location, duration });
        }
    }

    return (
        <div className="div__agendamientos">
            <h2>{agenda.servicio}</h2>
            <p>Esta es la programación que tienes agendada</p>

            <div className="div__info__cita">
                <p><b style={{ fontFamily: 'Scotia-Bold' }}>Servicio: </b>{agenda.servicio}</p>
                <p><b style={{ fontFamily: 'Scotia-Bold' }}>Fecha: </b>{dias[agenda.fechaAgenda.getDay()] + " " + agenda.fechaAgenda.getDate() + " de " + meses[agenda.fechaAgenda.getMonth()] + " " + agenda.fechaAgenda.getFullYear()}</p>
                <p><b style={{ fontFamily: 'Scotia-Bold' }}>Horario: </b>{horas < 12 ? (agenda.hora.substring(0, 5) + " am") : (horas >= 13 ? ((horas - 12) + ":" + minutos) : (horas + ":" + minutos) ) + " pm"}</p>
                <p><b style={{ fontFamily: 'Scotia-Bold' }}>Medio: </b>{agenda.medio}</p>
                {
                    agenda.medio === "Vía Llamada Telefónica" && <p><b style={{ fontFamily: 'Scotia-Bold' }}>Teléfono: </b>{agenda.telefono}</p>
                }
            </div>
            <div className="div__agendamientos__botones">
                <button style={{ backgroundColor: 'transparent', borderColor: 'var(--rojo)', color: 'var(--rojo)', width: '12rem' }} onClick={gotoEditAgenda}>Editar cita</button>
                {
                    agenda.medio === "Vía Videollamada" && <button style={{ color: '#fff', backgroundColor: 'var(--rojo)', borderColor: 'var(--rojo)', width: '12rem' }} onClick={iniciarVideollamada} disabled={disableBotonVideollamada}>Iniciar</button>
                }
            </div>
            <div className="div__copiar__calendario">
                <p>Copiar en mi calendario</p>
                <div className="icons__calendar">
                    <img src="/assets/img/iconos/icon_google_calendar.svg" onClick={() => onAgendaCalendar({horaAgenda: agenda.hora, fechaAgenda: agenda.fechaAgenda, urlCliente: agenda.urlVideollamada, calendario: 1})} alt="google calendar" />
                    <img src="/assets/img/iconos/icon_outlook_calendar.svg" onClick={() => onAgendaCalendar({horaAgenda: agenda.hora, fechaAgenda: agenda.fechaAgenda, urlCliente: agenda.urlVideollamada, calendario: 2})} alt="outlook calendar" />
                    <img src="/assets/img/iconos/icon_yahoo_calendar.svg" onClick={() => onAgendaCalendar({horaAgenda: agenda.hora, fechaAgenda: agenda.fechaAgenda, urlCliente: agenda.urlVideollamada, calendario: 3})} alt="yahoo calendar" />
                </div>
            </div>
        </div>
    )
}

export default HomeAgendamientosDetalle