import { URL_BACKEND_AUTHS } from "../enviroments/enviroment"


export const postLogin = async (objUsuario) => {

    try {
        const response = await fetch(`${URL_BACKEND_AUTHS}/Login/IniciarSession`, {

            mode: 'cors',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                // 'Authorization': `Bearer  + ${JWT}`
            },
            body: JSON.stringify(objUsuario),
        })
        const json = await response.json()
        return json

    } catch (err) {
        throw err
    }
}

export const getSession = async (token) => {
    try {
        const response = await fetch(`${URL_BACKEND_AUTHS}/Login/obtenerSession`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
        const json = await response.json()
        return json


    } catch (err) {
        throw err
    }
}


export const postRegister = async (objUsuario) => {
    try {
        const response = await fetch(`${URL_BACKEND_AUTHS}/Login/RegistrarUser`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                // 'Authorization': `Bearer  + ${JWT}`
            },
            body: JSON.stringify(objUsuario),
        })

        const json = await response.json()
        return json

    } catch (err) {
        throw err
    }
}

export const postUpdate = async (objUsuario) => {

    try {
        const response = await fetch(`${URL_BACKEND_AUTHS}/login/updateuser`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                // 'Authorization': `Bearer  + ${JWT}`
            },
            body: JSON.stringify(objUsuario),
        })
        const json = await response.json()
        return json


    } catch (err) {
        throw err
    }

}

export const logout = async (id) => {
    try {

        const response = await fetch(`${URL_BACKEND_AUTHS}/Login/Logout/${id}`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                // 'Authorization': `Bearer  + ${JWT}`
            },
        })
        const json = await response
        return json

    } catch (err) {
        throw err
    }
}

export const recovery = async (objUsuario) => {

    try {
        const response = await fetch(`${URL_BACKEND_AUTHS}/Login/RecuperaContrasena`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                // 'Authorization': `Bearer  + ${JWT}`
            },
            body: JSON.stringify(objUsuario),
        })
        const json = await response.json()
        return json


    } catch (err) {
        throw err
    }
}

export const getCodigo = async (objUsuario) => {
    try {
        const response = await fetch(`${URL_BACKEND_AUTHS}/Login/ObtenerCodigo`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(objUsuario)
        })
        const json = await response.json()
        return json
    } catch (err) {
        throw err
    }
}

export const sendCode = async (objUsuario) => {

    try {
        const response = await fetch(`${URL_BACKEND_AUTHS}/Login/EnvioCodigo`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                // 'Authorization': `Bearer  + ${JWT}`
            },
            body: JSON.stringify(objUsuario),
        })
        const json = await response.json()
        return json


    } catch (err) {
        throw err
    }
}

export const changPassword = async (objUsuario) => {

    try {
        const response = await fetch(`${URL_BACKEND_AUTHS}/Login/CambiarContrasena`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                // 'Authorization': `Bearer  + ${JWT}`
            },
            body: JSON.stringify(objUsuario),
        })
        const json = await response.json()
        return json


    } catch (err) {
        throw err
    }
}


export const changeUserProfile = async (objUsuario, token) => {

    try {
        const response = await fetch(`${URL_BACKEND_AUTHS}/Profile/ActualizarDatospersonales`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(objUsuario),
        })
        const json = await response.json()
        return json


    } catch (err) {
        throw err
    }
}

export const changePassProfile = async (objUsuario, token) => {
    console.log(objUsuario)
    // console.log(JSON.stringify(objUsuario))
    try {
        const response = await fetch(`${URL_BACKEND_AUTHS}/Profile/ActualizarPassword`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(objUsuario),
        })
        const json = await response.json()
        return json
    } catch (err) {
        throw err
    }
}

export const addInvitado = async (objUsuario) => {

    try {
        const response = await fetch(`${URL_BACKEND_AUTHS}/Login/RegistrarInvitado`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                // 'Authorization': `Bearer  + ${JWT}`
            },
            body: JSON.stringify(objUsuario),
        })
        const json = await response.json()
        return json


    } catch (err) {
        throw err
    }
}

export const listadoInvitados = async (dni) => {
    try {
        const response = await fetch(`${URL_BACKEND_AUTHS}/Login/listaInvitados2?identificacion=${dni}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                //'Authorization': 'Bearer ' + token
            }
        })
        const json = await response.json()
        return json


    } catch (err) {
        throw err
    }
}

export const borraInvitado = async (id) => {
    try {
        const response = await fetch(`${URL_BACKEND_AUTHS}/Login/borrarInvitado?id=${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                //'Authorization': 'Bearer ' + token
            }
        })
        const json = await response.json()
        return json


    } catch (err) {
        throw err
    }
}

export const updateInvitado = async (objUsuario) => {

    try {
        const response = await fetch(`${URL_BACKEND_AUTHS}/Login/updateInvitado`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                // 'Authorization': `Bearer  + ${JWT}`
            },
            body: JSON.stringify(objUsuario),
        })
        const json = await response.json()
        return json


    } catch (err) {
        throw err
    }
}

export const getInfoContingenciaUsuario = async (token) => {
    try {
        const response = await fetch(`${URL_BACKEND_AUTHS}/Login/updateInvitado`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
        const json = await response.json()
        return json
    } catch (err) {
        throw err
    }
}