import React, { Suspense } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import FallbackCards from '../../modules/helpers/FallbackCards'
import PrincipalContactoResumen from '../../modules/homeout/principal/components/PrincipalContactoResumen'

const HomeOutCoursera = React.lazy(() => import('../../modules/homeout/coursera/screens/HomeOutCoursera'))
const PageNotFound = React.lazy(() => import('../../modules/404/PageNotFound'))

const RouterOutCoursera = () => {
    return (
        <>
            <Suspense fallback={<FallbackCards />}>
                <Routes>
                    <Route path='/' element={<HomeOutCoursera />} />
                    <Route path='/404' element={<PageNotFound />} />
                    <Route path='*' element={<Navigate to="/404" />} />
                </Routes>

                {window.location.pathname !== "/coursera" && < PrincipalContactoResumen />}
            </Suspense>
        </>
    )
}

export default RouterOutCoursera