import React, { useContext } from 'react'
import { getCoberturas } from '../../../helpers/validations';
import EmpleabilidadScreen from '../../empleabilidad/screens/EmpleabilidadScreen';
import EmprendimientoScreen from '../../emprendimiento/screens/EmprendimientoScreen';
import ProteccionFinancieraScreen from '../../proteccion-financiera/screens/ProteccionFinancieraScreen';
import ProteccionFinancieraElementalScreen from '../../proteccion-financiera-elemental/screens/ProteccionFinancieraElementalScreen';
import VidaPlusScreen from '../../vidaplus/screens/VidaPlusScreen';
import CourseraScreen from '../../coursera/screens/CourseraScreen';
import VidaScreen from '../../vida/screens/VidaScreen';
import SaludScreen from '../../salud/screens/SaludScreen';
import AuthContext from '../../../../context/auth/authContext';
import CuidadosScreen from '../../cuidados/screens/CuidadosScreen';
import ValorFacturaScreen from '../../valor-factura/screens/ValorFacturaScreen';

const HomeInScreen = () => {

    const {globalActualizarEcosistemaActivo} = useContext(AuthContext)

    let component = <></>

    switch (getCoberturas()[0]) {
        case 'Empleabilidad':
            globalActualizarEcosistemaActivo(3)
            component = <EmpleabilidadScreen />
            break;
        case 'Emprendimiento':
            globalActualizarEcosistemaActivo(2)
            component = <EmprendimientoScreen />
            break;
        case 'Protección Financiera':
            globalActualizarEcosistemaActivo(8)
            component = <ProteccionFinancieraScreen />
            break;
        case 'VidaPlus':
            globalActualizarEcosistemaActivo(9)
            component = <VidaPlusScreen />
            break;
        case 'Proteccion Financiera Elemental':
            globalActualizarEcosistemaActivo(10)
            component = <ProteccionFinancieraElementalScreen />
            break;
        case 'Eco Coursera':
            globalActualizarEcosistemaActivo(11)
            component = <CourseraScreen />
            break;
        case 'Vida':
            globalActualizarEcosistemaActivo(13)
            component = <VidaScreen />
            break;
        case 'Salud':
            globalActualizarEcosistemaActivo(12)
            component = <SaludScreen />
            break;
        case 'Cuidados':
            component = <CuidadosScreen />
            globalActualizarEcosistemaActivo(14)
            break;
        case 'Valor Factura':
            component = <ValorFacturaScreen />
            globalActualizarEcosistemaActivo(15)
            break;
        default:
            break;
    }

    return (
        <>
            {/* <Modulos /> */}
            {/* Módulos por defecto a mostrar (debe ser dinámico) */}
            {component}
        </>
    )
}

export default HomeInScreen