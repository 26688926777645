import React, { useState } from 'react'
import AuthRegisterFormIdentificacion from './AuthRegisterFormIdentificacion';
import SpinnerCuadrado from '../../helpers/SpinnerCuadrado';
import { useNavigate } from 'react-router-dom';
import PrincipalPregunta from '../../homeout/principal/components/PrincipalPregunta';
import "../styles/authValidador.css";

const AuthRegisterForm = () => {

  const navigate = useNavigate()

  const [spinner, setSpinner] = useState(false)
  const [mensaje, setMensaje] = useState("");

  const gotoInicio = () => {
    navigate('/iniciasesion')
  }

  const gotoOlvidePass = () => {
    navigate('/password')
  }

  const limpiarRegistro = () => {
    setMensaje('')
  }

  return (
    <>
      {
        spinner && <SpinnerCuadrado />
      }

      <div className="auth__login" >

        {
          mensaje === "Ya existe ese usuario con esa documentación, por favor inicie sesión" &&
          <div className="cajaregistra__error" >
            <i className="fa-solid fa-triangle-exclamation"></i>
            <div className="error__informativo">
              <p>{mensaje}
                <span onClick={gotoInicio}> Inicia sesión aquí.</span>
                Si no recuerda la contraseña
                <span onClick={gotoOlvidePass}> restablézcala aquí.</span>
              </p>
            </div>
          </div>
        }

        {
          mensaje === "Tu seguro aún no está activo. Podrás activar tu cuenta próximamente" ?
            <>
              <div className="cajaregistra__error" >
                <i className="fa-solid fa-triangle-exclamation"></i>
                <div className="error__informativo">
                  <p>{mensaje}</p>
                </div>
              </div>

              <h2 className='error__title'>Te recomendamos revisar:</h2>
              <div className="preguntas__listado">
                <PrincipalPregunta
                  titulo='¿Aún no tienes un seguro con nostros?'
                  texto='Si quieres disfrutar los servicios de Scotiabank en alianza con BNP Paribas Cardif acércate a cualquiera de nuestras sucursales Scotiabank a nivel nacional.'
                />
                <PrincipalPregunta
                  titulo='¿Acabas de adquirir un seguro?'
                  texto='No te preocupes. La información de tu cuenta está en proceso de creación. En breve, tendrás acceso a tu cuenta.'
                />
              </div>
              <div className='error__volver' onClick={limpiarRegistro}>
                {'<'} Volver
              </div>
            </>
            :
            <>
              {
                mensaje !== '' && mensaje !== "Ya existe ese usuario con esa documentación, por favor inicie sesión" &&
                <div className="cajaregistra__error" >
                  <i className="fa-solid fa-triangle-exclamation"></i>
                  <div className="error__informativo">
                    <p>{mensaje}</p>
                  </div>
                </div>
              }

              {/* ************************************ */}
              {/* FORMULARIO DE REGISTRO */}

              <AuthRegisterFormIdentificacion
                setSpinner={setSpinner}
                setMensaje={setMensaje}
              />
            </>
        }

      </div>
    </>
  )
}

export default AuthRegisterForm