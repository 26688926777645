import '../../../styles/mejorahv.css'

const VidaPlusEvaluacionMedicaDetalle = () => {
    return (
        <>
            <div className='box_hv_servicios' >
            <div>
            Desarrollamos con UMANO MEDICAL una solución para brindar la mejor atención médica a <br></br>distancia. Con atención personalizada las 24 horas del día, los 365 días del año. Sin importar dónde <br></br>estés o cual sea tu padecimiento, nosotros estamos aquí para apoyarte.<br></br> 
                </div>
                <div>
                Nuestro sistema se compone de <b>9 elementos fundamentales:</b>
                </div>
                <div className="eco__cards-in">
                    <div className='ecosistemas__cards-in'>
                        <div className='eco__card__beneficios' >
                            <div className='eco__card-img_beneficios' >
                                <img src='/assets/img/ecosistemas/modulos/preevaluacion-medica/calendario.svg' style={{ width: "auto" }} alt="imagen eco" />
                                <h3 className='eco_titulo_h3'>365 DÍAS / 24 HRS</h3>
                            </div>
                            <div className='eco__card_descrip' >
                            Dispuestos a ayudar en todo momento.
                            </div>
                        </div>
                        <div className='eco__card__beneficios' >
                            <div className='eco__card-img_beneficios' >
                                <img src='/assets/img/ecosistemas/modulos/preevaluacion-medica/lenguaje.svg' style={{ width: "auto" }} alt="imagen eco" />
                                <h3 className='eco_titulo_h3'>LENGUAJE NATURAL</h3>
                            </div>
                            <div className='eco__card_descrip' >
                            Interacción rápida con el reconocimiento de las oraciones.
                            </div>
                        </div>
                        <div className='eco__card__beneficios' >
                            <div className='eco__card-img_beneficios' >
                                <img src='/assets/img/ecosistemas/modulos/preevaluacion-medica/inteligencia.svg' style={{ width: "auto" }} alt="imagen eco" />
                                <h3 className='eco_titulo_h3'>INTELIGENCIA ARTIFICIAL</h3>
                            </div>
                            <div className='eco__card_descrip' >
                            La I.A. elige las mejores preguntas durante la evaluación.
                            </div>
                        </div>
                        <div className='eco__card__beneficios' >
                            <div className='eco__card-img_beneficios' >
                                <img src='/assets/img/ecosistemas/modulos/preevaluacion-medica/equipo.svg' style={{ width: "auto" }} alt="imagen eco" />
                                <h3 className='eco_titulo_h3'>EQUIPO DE PROFESIONALES</h3>
                            </div>
                            <div className='eco__card_descrip' >
                            Contenidos creados y revisados por los mejores médicos.
                            </div>
                        </div>
                        <div className='eco__card__beneficios' >
                            <div className='eco__card-img_beneficios' >
                                <img src='/assets/img/ecosistemas/modulos/preevaluacion-medica/seguro.svg' style={{ width: "auto" }} alt="imagen eco" />
                                <h3 className='eco_titulo_h3'>100% / SEGURO</h3>
                            </div>
                            <div className='eco__card_descrip' >
                            Datos confidenciales con tecnología de encriptado.
                            </div>
                        </div>
                        <div className='eco__card__beneficios' >
                            <div className='eco__card-img_beneficios' >
                                <img src='/assets/img/ecosistemas/modulos/preevaluacion-medica/respuesta.svg' style={{ width: "auto" }} alt="imagen eco" />
                                <h3 className='eco_titulo_h3'>RESPUESTA MENOS DE 5 MIN</h3>
                            </div>
                            <div className='eco__card_descrip' >
                            Atención inmediata en el momento que lo necesite.
                            </div>
                        </div>
                        <div className='eco__card__beneficios' >
                            <div className='eco__card-img_beneficios' >
                                <img src='/assets/img/ecosistemas/modulos/preevaluacion-medica/especialidades.svg' style={{ width: "auto" }} alt="imagen eco" />
                                <h3 className='eco_titulo_h3'>VARIEDAD DE ESPECIALIDADES</h3>
                            </div>
                            <div className='eco__card_descrip' >
                            Amplio directorio de médicos y especialistas de la salud.
                            </div>
                        </div>
                        <div className='eco__card__beneficios' >
                            <div className='eco__card-img_beneficios' >
                                <img src='/assets/img/ecosistemas/modulos/preevaluacion-medica/bases.svg' style={{ width: "auto" }} alt="imagen eco" />
                                <h3 className='eco_titulo_h3'>BASES DE DATOS</h3>
                            </div>
                            <div className='eco__card_descrip' >
                            Optimización constante por el aprendizaje profundo.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default VidaPlusEvaluacionMedicaDetalle