import '../styles/homeOutOfertas.css'
import LazyPrincipalOfertasCards from './LazyPrincipalOfertasCards'

const PrincipalOfertas = () => {
    return (
        <div className='homeout__ofertas'>
            <h2>¿Qué te Ofrecemos?</h2>
            <p>Al contratar cualquiera de nuestros seguros, podrás disfrutar de:</p>
            <LazyPrincipalOfertasCards />
        </div>
    )
}

export default PrincipalOfertas