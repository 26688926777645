import { URL_BACKEND_AUTHS } from "../enviroments/enviroment"

export const addSchedule = async (token, obj) => {

    try {
        const response = await fetch(`${URL_BACKEND_AUTHS}/agendamientos/AgregarAgenda`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(obj),
        })

        if (response.status === 401) {
            return ['', response.status]
        }
        const json = await response.json()
        return [json, response.status]

    } catch (err) {
        throw err
    }
}

export const addScheduleSalud = async (token, obj) => {
    /*Area*/
    try {
        const response = await fetch(`${URL_BACKEND_AUTHS}/agendamientos/AgregarAgendaSalud`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(obj),
        }
        )
        if (response.status === 401) {
            return ['', response.status]
        }
        const json = await response.json()
        return [json, response.status]


    } catch (err) {
        throw err
    }
}


export const addScheduleCoworking = async (token, obj) => {

    try {
        const response = await fetch(`${URL_BACKEND_AUTHS}/coworking/AgregarCoworking`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(obj),
        })

        if (response.status === 401) {
            return ['', response.status]
        }
        const json = await response.json()
        return [json, response.status]

    } catch (err) {
        throw err
    }
}

export const updateScheduleCoworking = async (token, obj) => {
    try {
        const response = await fetch(`${URL_BACKEND_AUTHS}/coworking/UpdateCoworking`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(obj),
        })

        if (response.status === 401) {
            return ['', response.status]
        }
        const json = await response.json()
        return [json, response.status]


    } catch (err) {
        throw err
    }
}


export const updateSchedule = async (token, obj) => {
    try {
        const response = await fetch(`${URL_BACKEND_AUTHS}/agendamientos/UpdateAgenda`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(obj),
        })

        if (response.status === 401) {
            return ['', response.status]
        }
        const json = await response.json()
        return [json, response.status]


    } catch (err) {
        throw err
    }
}


export const deleteSchedule = async (token, id) => {
    /*Area*/
    try {
        const response = await fetch(`${URL_BACKEND_AUTHS}/agendamientos/DeleteAgenda/${id}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            // body: JSON.stringify(obj),
        })

        if (response.status === 401) {
            return ['', response.status]
        }
        const json = await response.json()
        return [json, response.status]

    } catch (err) {
        throw err
    }
}

export const deleteScheduleCoworking = async (token, id) => {
    /*Area*/
    try {
        const response = await fetch(`${URL_BACKEND_AUTHS}/coworking/DeleteCoworking/${id}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            // body: JSON.stringify(obj),
        })

        if (response.status === 401) {
            return ['', response.status]
        }
        const json = await response.json()
        return [json, response.status]

    } catch (err) {
        throw err
    }
}


export const getSchedules = async (token, id, idTipoAgenda) => {
    /*Area*/
    try {
        const response = await fetch(`${URL_BACKEND_AUTHS}/agendamientos/agendas/${id}?idTipoAgenda=${idTipoAgenda}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
        const json = await response.json()
        return json


    } catch (err) {
        throw err
    }
}

export const getSchedulesCow = async (token, id) => {
    /*Area*/
    try {
        const response = await fetch(`${URL_BACKEND_AUTHS}/coworking/ListaCoworking/${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
        const json = await response.json()
        return json


    } catch (err) {
        throw err
    }
}

export const sendSMSEmail = async (token, id, metod) => {
    /*Area*/
    try {
        const response = await fetch(`${URL_BACKEND_AUTHS}/agendamientos/MensajeAgendar/${id}?metodo=${metod}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
        const json = await response.json()
        return json
    } catch (err) {
        throw err
    }
}



export const sendSMSEmailCoworking = async (token, id, metod) => {
    /*Area*/
    try {
        const response = await fetch(`${URL_BACKEND_AUTHS}/coworking/MensajeAgendar/${id}?metodo=${metod}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
        const json = await response.json()
        return json
    } catch (err) {
        throw err
    }
}

export const getAgendaByEcosistema = async (token, nombreEcosistema) => {
    try {
        const response = await fetch(`${URL_BACKEND_AUTHS}/agendamientos/agendasEcosistemas/${nombreEcosistema}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
        const json = await response.json()
        return json
    } catch (err) {
        throw err
    }
}

export const getHorasAgendamiento = async (token) => {
    try {
        const response = await fetch(`${URL_BACKEND_AUTHS}/agendamientos/obtenerHorasAgendamiento`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
        const json = await response.json()
        return json
    } catch (err) {
        throw err
    }
}