import React from 'react'
import PrincipalPregunta from './PrincipalPregunta'
import '../styles/homeOutPreguntas.css'
import { HashLink } from 'react-router-hash-link'

const PrincipalPreguntas = () => {

    return ( 
        <div className='out__preguntas'>
            <div className="preguntas__titulo">
                <h3>Preguntas Frecuentes</h3>
                <p>¿Tienes dudas sobre nuestros seguros y beneficios? Encuentra aquí las respuestas.</p>
                <div><HashLink smooth="true" to='#contactoFooter' style={{ color: 'var(--rojo)' }}>¿No solucionamos tu duda?</HashLink></div>
            </div>
            <div className="preguntas__listado">
                <PrincipalPregunta 
                    titulo='¿Cómo puedo usar los beneficios gratuitos de mi seguro?'
                    texto='1. Verifica que cuentes con una póliza viente.'
                    texto2='2. Verifica que tu cuenta está activa.'
                    texto3='Si tienes problemas de acceso llama al: 800 801 2402'
                />
                <PrincipalPregunta
                    titulo='¿Acceder a los beneficios tiene un costo adicional al seguro contratado?'
                    texto='No, los beneficios no generan costos adicionales al seguro contratado'
                />
                <PrincipalPregunta
                    titulo='¿Si no cuento con un seguro, cómo puedo adquirirlo?'
                    texto='Acércate a cualquiera de las sucursales Scotiabank en el país y pregunta por el seguro que más se adapte a tus necesidades.                    '
                />
            </div>
        </div>
    )
}

export default PrincipalPreguntas