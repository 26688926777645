import React, { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import AuthContext from "../../../../context/auth/authContext";
import Modulos from "../../../general/screen/Modulos";
import EcosistemaTitulo from "../../ecosistemas/EcosistemaTitulo";
import HomeInUser from "../../principal/components/HomeInUser";
import useTitle from "../../../hooks/useTitle";
import CuidadosCards from "../components/CuidadosCards";

const CuidadosScreen = () => {
  useTitle({ title: "Detalle Ecosistema Cuidados" });
  const { pathname } = useLocation();
  const { globalActualizarEcosistemaActivo } = useContext(AuthContext);

  useEffect(() => {
    if (pathname === "/ecosistema/cuidados") {
      globalActualizarEcosistemaActivo(14);
    }
  }, []);

  return (
    <div className="homein">
      <EcosistemaTitulo />

      <div className="homein__resumen">
        <HomeInUser id={14} />
      </div>

      <div className="homein__detalle">
        <Modulos />
        <CuidadosCards />
      </div>
    </div>
  );
};

export default CuidadosScreen;
