import { useContext, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Swal from "sweetalert2";
import "../encuestaCoursera.css";
import ButtonCloseVida from "./ButtonCloseVida";
import FormBaseBeneficiario from "./FormBaseBeneficiario";
import {
  getCurp,
  postCurp,
  postRegisterBeneficios,
} from "../../../services/salud";
import { getToken } from "../../helpers/validations";
import SpinnerCuadrado from "../../helpers/SpinnerCuadrado";
import AuthContext from "../../../context/auth/authContext";
import { getSession } from "../../../services/auth";
import { addLogs } from "../../../services/logs_proteccion";

const ModalEncuentaSaludBeneficiario = (props) => {
  const {
    globalDataUser,
    globalActualizarBeneficiariosSalud,
    globalActualizarBeneficiariosSaludModal,
  } = useContext(AuthContext);
  const [curpPrincipal, setCurpPrincipal] = useState("");
  const [infoCuestionario, setInfoCuestionario] = useState([
    defaulBeneficiario(),
    defaulBeneficiario(),
    defaulBeneficiario(),
    defaulBeneficiario(),
    defaulBeneficiario(),
    defaulBeneficiario(),
  ]);
  const [cantBeneficiarios, setCantBeneficiarios] = useState(1); // pendiente para agregar más beneficiarios
  const [statusCurp, setStatusCurp] = useState(false);
  const [rfcTitular, setRfcTitular] = useState("");
  const [correoTitular, setCorreoTitular] = useState("");
  const [celularTitular, setCelularTitular] = useState("");
  const [spinner, setSpinner] = useState(false);
  const [esPlanIndividual, setEsPlanIndividual] = useState(false);

  const idPlanesIndividuales = ["2960", "2961", "2962", "2963", "2964", "2970", "2971", "2972", "2973", "2974"]

  const handleClose = () => {
    props.close(false);
  };

  function defaulBeneficiario() {
    return {
      CURP: "",
      primerNombreUsuario: "",
      segundoNombreUsuario: "",
      apellidoPaternoUsuario: "",
      apellidoMaternoUsuario: "",
      parentesco: "",
      numeroCelular: "",
      genero: "",
      correoElectronico: "",
      fechaNacimiento: "",
      RFC: "",
    };
  }

  const textValidationForm = {
    CURP: "Ingresa el CURP",
    nombreUsuario: "Ingrese un nombre",
    parentesco: "Ingrese un parentesco",
    numeroCelular: "Ingresa el número de celular",
    genero: "Selecciona un género",
    correoElectronico: "Ingresa el Correo electrónico",
    fechaNacimiento: "Ingresa la fecha de nacimiento",
    RFC: "Ingresa el RFC",
  };

  const getCurpModal = async () => {
    if (statusCurp === false) {
      let data = await getCurp(getToken());
      if (data?.codigo === 1) {
        setStatusCurp(true);
        setCurpPrincipal(data?.data);
      }
    }
  };

  const handleSetCurpPrincipal = (e) => {
    const value = e.target.value;
    setCurpPrincipal(value);
  };

  const saveBeneficiario = async (e) => {
    e.preventDefault();
    let statusInsert = await verificaInsercion();
    if (!esPlanIndividual) {
      if (statusInsert) {
        if (!statusCurp && !curpPrincipal) {
          Swal.fire(
            "Advertencia",
            "El Curp principal no puede estar vacio",
            "warning"
          );
          return;
        } else {
          setSpinner(true);
          let data = await postCurp(getToken(), curpPrincipal);
          if (data?.codigo === 1) {
            setStatusCurp(true);
          }
        }
        let arrayBeneficiario = [];
        for (let aB = 0; aB < cantBeneficiarios; aB++) {
          if (validarBeneficiariosPorEdad(infoCuestionario[aB])) {
            arrayBeneficiario.push(infoCuestionario[aB]);
          } else {
            setSpinner(false);
            return;
          }
        }
        let data = await postRegisterBeneficios(getToken(), arrayBeneficiario);
        if (data?.codigo === 1) {
          handleClose();
          globalActualizarBeneficiariosSalud(true); // Se setea para no validar al momento de ingresar
          globalActualizarBeneficiariosSaludModal(0); // Se setea para no mostrar modal
          addLogs(
            "Salud",
            'Clic en "Confirmar" para agregar nuevo(s) beneficiario(s)',
            "Cantidad beneficiarios: " + cantBeneficiarios
          );
          Swal.fire("Éxito", data?.mensaje, "success");
        } else {
          Swal.fire("Error", data?.mensaje, "error");
        }
      } else if (!statusInsert) {
        Swal.fire(
          "Advertencia",
          "Uno o más registros tienen el mismo RFC, CURP o un RFC o CURP es igual al del titular. Por favor verifique la información.",
          "warning"
        );
      }
    } else {
      handleClose()
      globalActualizarBeneficiariosSaludModal(0)
      globalActualizarBeneficiariosSalud(true);
      Swal.fire(
        "Actualización exitosa",
        "Su información ha sido actualizada.",
        "success"
      );
    }
    setSpinner(false);
  };

  const verificaInsercion = async () => {
    return new Promise((resolve, reject) => {
      let rfcs = [];
      let curps = [];
      for (let i = 0; i < cantBeneficiarios; i++) {
        rfcs.push(infoCuestionario[i].RFC);
        curps.push(infoCuestionario[i].CURP);
      }
      let statusVerificacion = true;
      rfcs = deleteDuplicados(rfcs);
      curps = deleteDuplicados(curps);
      if (
        rfcs.length !== cantBeneficiarios ||
        curps.length !== cantBeneficiarios ||
        rfcs.includes(rfcTitular) ||
        curps.includes(curpPrincipal)
      ) {
        statusVerificacion = false;
      }
      resolve(statusVerificacion);
    });
  };

  const deleteDuplicados = (arr) => {
    for (var i = arr.length - 1; i >= 0; i--) {
      if (arr.indexOf(arr[i]) !== i) arr.splice(i, 1);
    }
    return arr;
  };

  const obtenerEdad = function (fechaNacimiento) {
    var month_diff = Date.now() - fechaNacimiento.getTime();
    var age_dt = new Date(month_diff);
    var year = age_dt.getUTCFullYear();
    return Math.abs(year - 1970);
  };

  const validarBeneficiariosPorEdad = function (beneficiario) {
    var edadBeneficiario = obtenerEdad(new Date(beneficiario.fechaNacimiento));

    // Si el beneficiario es menor de edad, se comprueba que el rfc sea distinto al del titular (y de los demás beneficiarios en caso de haber 2 o más beneficiarios).
    if (edadBeneficiario < 18) {
      if (cantBeneficiarios === 1) {
        if (beneficiario.RFC === rfcTitular) {
          Swal.fire(
            "Error",
            "El RFC de uno de los beneficiarios no debe ser igual al del titular.",
            "error"
          );
          return false;
        }
      } else {
        for (let i = 0; i < cantBeneficiarios; i++) {
          if (beneficiario !== infoCuestionario[i]) {
            if (
              beneficiario.RFC === rfcTitular ||
              beneficiario.RFC === infoCuestionario[i].RFC
            ) {
              Swal.fire(
                "Error",
                "El RFC de uno de los beneficiarios no debe ser igual al del titular o de alguno de los demás beneficiarios.",
                "error"
              );
              return false;
            }
          }
        }
      }
    }
    // En caso contrario, se comprueba que el rfc, correo y telefono sean distintos al del titular (y de los demás beneficiarios en caso de haber 2 o más beneficiarios).
    else {
      if (cantBeneficiarios === 1) {
        if (
          beneficiario.RFC === rfcTitular ||
          beneficiario.numeroCelular === celularTitular ||
          beneficiario.correoElectronico === correoTitular
        ) {
          Swal.fire(
            "Error",
            "El RFC, número celular o correo de uno de los beneficiarios no debe ser igual al del titular.",
            "error"
          );
          return false;
        }
      } else {
        for (let i = 0; i < cantBeneficiarios; i++) {
          if (beneficiario !== infoCuestionario[i]) {
            if (
              beneficiario.RFC === rfcTitular ||
              beneficiario.RFC === infoCuestionario[i].RFC ||
              beneficiario.numeroCelular === celularTitular ||
              beneficiario.numeroCelular ===
                infoCuestionario[i].numeroCelular ||
              beneficiario.correoElectronico === correoTitular ||
              beneficiario.correoElectronico ===
                infoCuestionario[i].correoElectronico
            ) {
              Swal.fire(
                "Error",
                "El RFC, número celular o correo de uno de los beneficiarios no debe ser igual al del titular o de alguno de los demás beneficiarios.",
                "error"
              );
              return false;
            }
          }
        }
      }
    }
    return true;
  };

  const validateForm = function (e) {
    const formData = document.querySelector("#formBeneficiarios");
    for (let i = 0; i < formData.length; i++) {
      let cmp = formData[i];
      if (cmp.required) {
        switch (cmp.type) {
          case "text":
          case "number":
          case "email":
          case "date":
          case "select-one":
            if (cmp.value === "") {
              cmpRequired(cmp); // Se agrega color al border
              cmpTextRequired(cmp); // Se agrega texto validador
            }
            break;
          case "radio":
            // Se valida distinto pero no aplica para el formulario
            cmpTextRequired(cmp);
            break;
          default: // no se valida dado que pueden ser botones
            break;
        }
      }
    }
  };

  const cmpRequired = (cmp, type = "add") => {
    if (type === "add") {
      cmp.classList.add("cmp-required");
    } else {
      cmp.classList.remove("cmp-required");
    }
  };

  const cmpTextRequired = (cmp, type = "add") => {
    let name = cmp.name;
    if (name.indexOf("genero") > -1) {
      name = "genero";
    }
    let textValida = textValidationForm[name];
    let parent = cmp.parentNode;
    let statusValida = true;
    let element = false;
    for (let i = 0; i < parent.childNodes.length; i++) {
      if (parent.childNodes[i].classList) {
        let valida = true;
        if (cmp.type === "radio") {
          valida = !parent.querySelector(`input[name="${cmp.name}"]:checked`);
          statusValida = valida;
        }
        if (parent.childNodes[i].classList.contains("cmp-required-text")) {
          statusValida = false;
          element = parent.childNodes[i];
        }
      }
    }

    if (textValida) {
      // para que dicha validación funcione, es necesario que el input esté dentro de un div padre
      if (type === "add") {
        statusValida &&
          parent.insertAdjacentHTML(
            "beforeend",
            `<p class='col-12 cmp-required-text'>${textValida}</p>`
          );
      } else {
        element && parent.removeChild(element);
      }
    }
  };

  const onChangeForm = (e, i) => {
    const value = e.target.value;
    const key = e.target.name;
    let tmpForm = { ...infoCuestionario };
    if (value !== "") {
      cmpRequired(e.target, "remove");
      cmpTextRequired(e.target, "remove");
    } else {
      if (e.target.name !== "segundoNombreUsuario") {
        cmpRequired(e.target);
        cmpTextRequired(e.target);
      }
    }
    if (key.indexOf("genero") > -1) {
      tmpForm[i]["genero"] = value;
    }
    tmpForm[i][key] = value;
    setInfoCuestionario(tmpForm);
  };

  const handleSetCantBeneficiarios = () => {
    if (cantBeneficiarios < 6) {
      setCantBeneficiarios(cantBeneficiarios + 1);
    }
  };
  const handleSetCantBeneficiariosQuitar = () => {
    if (cantBeneficiarios > 1) {
      setCantBeneficiarios(cantBeneficiarios - 1);
    }
  };

  const preCargarData = async () => {
    let data = await getSession(getToken());
    if (data) {
      setRfcTitular(data.identificacion);
      setCelularTitular(data.numeroCelular);
      setCorreoTitular(data.correoElectronico);
    }
  };

  useEffect(() => {
    preCargarData();
    getCurpModal();
    if (idPlanesIndividuales.includes(globalDataUser.globalPlanCardifId)) {
      setEsPlanIndividual(true)
    }
    else {
      setEsPlanIndividual(false)
    }
  }, []);

  return (
    <>
      <Modal
        show={props.show}
        onHide={handleClose}
        size="lg"
        backdrop="static"
        centered
      >
        <Modal.Body>
          {spinner && <SpinnerCuadrado />}
          <ButtonCloseVida close={handleClose} />
          <div className="titulo__salud">
            <h3>Completa estos datos</h3>
            <p>
              Esta información es necesaria para usar los servicios médicos y de
              bienestar
            </p>
          </div>
          <form
            onSubmit={saveBeneficiario}
            className="formBeneficiario"
            id="formBeneficiarios"
          >
            <div className="preguntas__modal">
              <div className={esPlanIndividual && 'preguntas__modal__individual'}>
                <h3 className="titulo_infopersonal">1. Información Personal</h3>
                <div className="cuerpo_curp">
                  <label>CURP</label>
                  <input
                    className="form-control-salud-beneficiario"
                    type="text"
                    name="curpPrincipal"
                    value={curpPrincipal}
                    placeholder="Ej: MOMOCD 000000MDFNSN00"
                    style={{ borderRadius: "0px 8px 8px 0px", flexGrow: 1, maxWidth: esPlanIndividual && '100%' }}
                    onChange={(e) => {
                      handleSetCurpPrincipal(e);
                    }}
                    disabled={statusCurp}
                    required
                    minLength="18"
                  />
                  <p className={esPlanIndividual ? "text-right text-conoce-curp-individual" : "text-right"}>
                    ¿No conoces tu CURP?{" "}
                    <a href="https://www.gob.mx/curp/" target="_blank">
                      Ingresa aquí
                    </a>
                  </p>
                </div>
              </div>
              
              { !esPlanIndividual &&
                <div className="cuerpo_beneficios">
                  <h3 className="titulo_seccion">
                    2. Información de usuarios adicionales
                  </h3>

                  <p className="subtitulo_seccion">
                    Así los usuarios adicionales que designes también podrán gozar
                    de estos beneficios. Puedes añadir hasta 6 usuarios
                    adicionales.
                  </p>

                  <FormBaseBeneficiario
                    i={0}
                    onChangeForm={onChangeForm}
                    infoCuestionario={infoCuestionario}
                    cerrar={() => {}}
                  />

                  {cantBeneficiarios > 1 && (
                    <>
                      <hr />
                      <FormBaseBeneficiario
                        i={1}
                        onChangeForm={onChangeForm}
                        infoCuestionario={infoCuestionario}
                        cerrar={handleSetCantBeneficiariosQuitar}
                      />
                    </>
                  )}

                  {cantBeneficiarios > 2 && (
                    <>
                      <hr />
                      <FormBaseBeneficiario
                        i={2}
                        onChangeForm={onChangeForm}
                        infoCuestionario={infoCuestionario}
                        cerrar={handleSetCantBeneficiariosQuitar}
                      />
                    </>
                  )}

                  {cantBeneficiarios > 3 && (
                    <>
                      <hr />
                      <FormBaseBeneficiario
                        i={3}
                        onChangeForm={onChangeForm}
                        infoCuestionario={infoCuestionario}
                        cerrar={handleSetCantBeneficiariosQuitar}
                      />
                    </>
                  )}

                  {cantBeneficiarios > 4 && (
                    <>
                      <hr />
                      <FormBaseBeneficiario
                        i={4}
                        onChangeForm={onChangeForm}
                        infoCuestionario={infoCuestionario}
                        cerrar={handleSetCantBeneficiariosQuitar}
                      />
                    </>
                  )}

                  {cantBeneficiarios > 5 && (
                    <>
                      <hr />
                      <FormBaseBeneficiario
                        i={5}
                        onChangeForm={onChangeForm}
                        infoCuestionario={infoCuestionario}
                        cerrar={handleSetCantBeneficiariosQuitar}
                      />
                    </>
                  )}
                </div>
              }

              <br />
              { !esPlanIndividual &&
                <div className="card__btns__encuesta">
                  <button
                    type="button"
                    className={`btnSaludMas`}
                    style={{
                      borderRadius: "7px",
                      fontSize: "12px",
                      marginTop: "15px",
                    }}
                    onClick={handleSetCantBeneficiarios}
                  >
                    + AGREGAR MÁS USUARIOS
                  </button>
                </div>
              }

              <div className="card__btns__encuesta">
                <button
                  className={`activacuenta__btn azul-salud`}
                  style={{ borderRadius: "7px" }}
                  type="submit"
                  onClick={validateForm}
                >
                  CONFIRMAR
                </button>
                <button
                  className={`unirme__btn azul-salud`}
                  style={{ borderRadius: "7px" }}
                  onClick={handleClose}
                >
                  CERRAR
                </button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ModalEncuentaSaludBeneficiario;
