import "../styles/homeOutLogos.css";

const PrincipalLogos = ({ direccion }) => {
  return (
    <div className={`homeout__logos ${direccion}`}>
      {/* <img src="/assets/img/homeout/img_sponsor.svg" alt="logo cliente" /> */}
      <img
        src="/assets/img/homeout/logo_vivetuseguro.webp"
        alt="logo sponsor"
        style={{ height: "5rem" }}
      />
    </div>
  );
};

export default PrincipalLogos;
