import React, { Suspense } from 'react'
import FallbackCards from '../../../helpers/FallbackCards'
import useNearScreen from '../../../hooks/useNearScreen'

const PrincipalBanner = React.lazy(()=> import('./PrincipalBanner'))

const LazyPrincipalBanner = () => {

    const { elementRef, isNear } = useNearScreen('20px')

    return <div ref={elementRef}>
        <Suspense fallback={<FallbackCards />}>
            {
                isNear ? <PrincipalBanner /> : <FallbackCards />
            }
        </Suspense>
    </div>
}

export default LazyPrincipalBanner