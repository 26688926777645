import React, { useContext } from 'react'
import AuthContext from '../../../../context/auth/authContext'
import HomeInUserEcosistemas from './HomeInUserEcosistemas'

const HomeInUser = ({ id = 1, statusBeneficiarios = true, setMostrarModalEncuesta }) => {

  const { globalDataUser, globalEcosistemas, globalDetalleEcosistemas } = useContext(AuthContext)

  
  const sendSMSEmail = () =>{
  window.location = 'mailto:contacto@vivetuseguro.com'
  }
  const makeCall = () =>{
    window.location = 'tel:8008012402'
  }

  return (
    <div className='userin__container'>
      <h4 className='desktop' style={{ fontFamily: 'Scotia-Headline' }}>Hola</h4>
      <h4 className='desktop' style={{ fontFamily: 'Scotia-Headline' }}>{globalDataUser.globalUsuName}</h4>
      <h4 className='mobile' style={{ fontFamily: 'Scotia-Headline' }}>Hola<br/>{globalDataUser.globalUsuName}</h4>
      <p className='userin__container-texto desktop' style={{ marginBottom: '0' }}>Empieza a disfrutar de tus beneficios.</p>
      <p className='userin__container-texto mobile'>Empieza a disfrutar de tus beneficios.</p>
      <p className='userin__container-subtexto desktop' style={{ marginBottom: '2rem', fontSize: '14px' }}>Accesos directos</p>
      <div className="userin__beneficios desktop">

        <div className="">
          {
            globalEcosistemas.find(e => e.id === id).ecos?.map((eco, index) => {
              return (
                <HomeInUserEcosistemas
                  key={eco}
                  color={globalEcosistemas.find(e => e.id === id).color}
                  ecoDetalle={globalDetalleEcosistemas.find(det => det.id === eco)}
                  index={index}
                />
              )
            })
          }
        </div>
        {!statusBeneficiarios && (
          <div className='mb-3'>
            <button className='col-12 btnActualizaDatos' onClick={()=>{setMostrarModalEncuesta(true)}}>
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="15" viewBox="0 2 22 19">
                <path id="Trazado_15474" data-name="Trazado 15474" d="M10,14h2v2H10Zm0-6h2v4H10Zm1-8L0,19H22Z" fill="#fff"/>
              </svg> &nbsp;
              Actualiza tus datos  
            </button>
          </div>
        )}
      </div>
      <div className="desktop" style={{ width: "100%", height: "1px", backgroundColor: "black" }} ></div>
      <br></br>
      <p className='userin__container-texto desktop' style={{ fontWeight: "bold", marginBottom: '20px' }} >¡Estamos para ayudarte! </p>
      <p className='userin__container-subtexto desktop' style={{ fontSize: '14px' }}>Línea de atención</p>
      <p className='userin__container-subtexto desktop llamar-telefono-mobile' style={{ fontSize: '14px', cursor:"pointer" }} onClick={()=>{makeCall()}}>800 801 2402</p>
      <br></br>
      <p className='userin__container-subtexto desktop' style={{ fontSize: '14px' }}>Correo de Ayuda  </p>
      <p className='userin__container-subtexto desktop' style={{ fontSize: '14px', cursor:"pointer" }} onClick={()=>{sendSMSEmail()}}>contacto@vivetuseguro.com </p>

    </div>
  )
}

export default HomeInUser