import React, { useState, useRef } from 'react'
import AuthPasswordFormDocumento from './AuthPasswordFormDocumento';
import AuthPasswordFormCodigo from './AuthPasswordFormCodigo';
import AuthPasswordFormPass from './AuthPasswordFormPass';
import SpinnerCuadrado from '../../helpers/SpinnerCuadrado';

const AuthPasswordForm = () => {

    const [errorData, setErrorData] = useState(false)
    const [mensaje, setMensaje] = useState("");
    const [spinner, setSpinner] = useState(false)
    const [formCode, setFormCode] = useState({
        id: "",
        code: "",
        tokenCaptcha: ""
    })

    const [count, setCount] = useState(1)
    const textVolver = '< Volver'

    const [form, setForm] = useState({
        identificacion: "",
        tokenCaptcha: ""
    })

    // RECAPTCHA
    const recaptchaRef = useRef();

    return (
        <>
            {
                spinner && <SpinnerCuadrado />
            }

            {
                count === 1 &&
                <AuthPasswordFormDocumento
                    errorData={errorData}
                    mensaje={mensaje}
                    recaptchaRef={recaptchaRef}
                    setMensaje={setMensaje}
                    setSpinner={setSpinner}
                    setErrorData={setErrorData}
                    setFormCode={setFormCode}
                    formCode={formCode}
                    setCount={setCount}
                    textVolver={textVolver}
                    form={form}
                    setForm={setForm}
                />
            }
            {
                count === 2 &&
                <AuthPasswordFormCodigo
                    errorData={errorData}
                    mensaje={mensaje}
                    formCode={formCode}
                    recaptchaRef={recaptchaRef}
                    setMensaje={setMensaje}
                    setSpinner={setSpinner}
                    form={form}
                    setErrorData={setErrorData}
                    setCount={setCount}
                    setFormCode={setFormCode}
                />
            }
            {
                count === 3 &&
                <AuthPasswordFormPass
                    errorData={errorData}
                    mensaje={mensaje}
                    recaptchaRef={recaptchaRef}
                    setMensaje={setMensaje}
                    setSpinner={setSpinner}
                    setErrorData={setErrorData}
                    formCode={formCode}
                    setCount={setCount}
                />
            }
        </>
    )
}

export default AuthPasswordForm