import React, { Suspense } from 'react'
import FallbackCards from '../../../helpers/FallbackCards'
import '../styles/homeOutContacto.css'

const PrincipalContactoContactos = React.lazy(()=> import('./PrincipalContactoContactos'))
const PrincipalContactoConocenos = React.lazy(()=> import('./PrincipalContactoConocenos'))
const PrincipalContactoLink = React.lazy(()=> import('./PrincipalContactoLink'))

const PrincipalContacto = () => {
    return (
        <Suspense fallback={<FallbackCards />}>
            <section className='out__contacto'>
                <PrincipalContactoContactos />

                <PrincipalContactoConocenos />
                <PrincipalContactoLink modalidad='desktop' />
            </section>
        </Suspense>

    )
}

export default PrincipalContacto