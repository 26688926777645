export const ecosistemas = [
  {
    id: 1,
    position: 1,
    name: "Tarjeta Protegida",
    eco: "Protección Financiera",
    desc: "Con tu Seguro de Tarjeta Protegida accede a beneficios que te permitirán conocer tu salud financiera y capacitarte gratuitamente.",
    titulocards: "Beneficios para hacer tu Vida más Fácil",
    img: "/assets/img/ecosistemas/proteccion/img_proteccion_in.webp",
    ico: "/assets/img/ecosistemas/proteccion/ico_proteccion_in.png",
    active: false,
    activeCarousel: false,
    color: "verde",
    // ecos: [1, 2, 3, 4, 5, 6], Previo a update 1 agosto 2022
    ecos: [1, 2, 5, 6],
    modulo: "modPFinanciera",
    rutaexterna: "/proteccion",
    imgMob: "/assets/img/ecosistemas/proteccion/img_proteccion_in_mobile.webp",
    idProgramaCoursera: 0,
    tituloCarrusel: "",
  },
  {
    id: 2,
    position: 2,
    name: "Emprendimiento",
    eco: "Emprendimiento",
    desc: "¡Disfruta los beneficios exclusivos que tienes al contratar tu seguro! Coursera,  Personal Branding y Marketing Digital, Asesoría en Derecho Tributario y Propiedad Intelectual, además de acceso a un espacio para Coworking.",
    titulocards: "Beneficios para hacer tu Vida más Fácil",
    img: "/assets/img/ecosistemas/emprendimiento/img_emprendimiento_in.webp",
    ico: "/assets/img/ecosistemas/emprendimiento/ico_emprendimiento_in.png",
    active: true,
    activeCarousel: true,
    color: "verde",
    ecos: [7, 8, 9, 10],
    modulo: "modEmprendimiento",
    rutaexterna: "/emprendimiento",
    imgMob:
      "/assets/img/ecosistemas/emprendimiento/img_emprendimiento_in_mobile.webp",
    idProgramaCoursera: 3,
    tituloCarrusel: "Autoempleado",
  },
  {
    id: 3,
    position: 3,
    name: "Empleabilidad",
    eco: "Empleabilidad",
    desc: "Por tu Seguro de Cuota Protegida Dependiente obtienes gratis 6 beneficios",
    titulocards: "Beneficios para hacer tu Vida más Fácil",
    img: "/assets/img/ecosistemas/empleabilidad/img_empleabilidad_in.webp",
    ico: "/assets/img/ecosistemas/empleabilidad/ico_empleabilidad_in.png",
    active: true,
    activeCarousel: true,
    color: "morado",
    ecos: [12, 13, 58, 57, 17, 14, 15, 16],
    modulo: "modEmpleabilidad",
    rutaexterna: "/empleabilidad",
    imgMob:
      "/assets/img/ecosistemas/empleabilidad/img_empleabilidad_in_mobile.webp",
    idProgramaCoursera: 2,
    tituloCarrusel: "Empleado",
  },
  {
    id: 4,
    position: 4,
    name: "Salud",
    eco: "Scotia Salud",
    desc: "Con tu Seguro Scotia Salud accede a beneficios que te permitirán resolver dudas y llevar un mejor seguimiento de tu salud.",
    titulocards: "Beneficios para cuidar de tu salud",
    img: "/assets/img/ecosistemas/salud/img_salud_in.webp",
    ico: "/assets/img/ecosistemas/salud/ico_salud_in.png",
    active: false,
    activeCarousel: false,
    color: "azul",
    ecos: [20, 21, 22, 23, 24, 25, 26, 29, 30],
    // ecos: [20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30],
    modulo: "modSalud",
    rutaexterna: "/salud",
    imgMob: "/assets/img/ecosistemas/salud/img_salud_in_mobile.webp",
    idProgramaCoursera: 0,
    tituloCarrusel: "",
  },
  {
    id: 5,
    position: 5,
    name: "Vida",
    eco: "Vida",
    desc: "Por contar con tu Seguro ScotiaVida, obtienes gratis este beneficio.",
    titulocards: "",
    img: "/assets/img/ecosistemas/vida/img_vida_in.webp",
    ico: "/assets/img/ecosistemas/vida/ico_vida_in.webp",
    active: false,
    activeCarousel: false,
    color: "celeste",
    ecos: [31, 32],
    modulo: "modVida",
    rutaexterna: "/vida",
    imgMob: "/assets/img/ecosistemas/vida/img_vida_in_mobile.webp",
    idProgramaCoursera: 0,
    tituloCarrusel: "",
  },
  {
    id: 6,
    position: 6,
    name: "Cáncer Plus",
    eco: "Cancer Plus",
    desc: "Por contar con tu Seguro Cáncer Plus, obtienes gratis estos 3 beneficios.",
    titulocards: "",
    img: "/assets/img/ecosistemas/cancer/img_cancer_in.webp",
    ico: "/assets/img/ecosistemas/cancer/ico_cancer_in.png",
    active: false,
    activeCarousel: false,
    color: "naranja-claro",
    ecos: [33, 34, 35, 36],
    modulo: "modCancer",
    rutaexterna: "/cancer",
    imgMob: "/assets/img/ecosistemas/cancer/img_cancer_in_mobile.webp",
    idProgramaCoursera: 0,
    tituloCarrusel: "",
  },
  {
    id: 7,
    position: 7,
    name: "Accidentes personales",
    eco: "Accidentes personales",
    desc: "Por contar con tu seguro Accidentes personales, obtienes gratis este beneficio.",
    titulocards: "",
    img: "/assets/img/ecosistemas/accidentes/img_accidentes_in.webp",
    ico: "/assets/img/ecosistemas/accidentes/ico_accidentes_in.png",
    active: false,
    activeCarousel: false,
    color: "morado-claro",
    ecos: [37],
    modulo: "modAccidentes",
    rutaexterna: "/accidentes",
    imgMob: "/assets/img/ecosistemas/accidentes/img_accidentes_in_mobile.webp",
    idProgramaCoursera: 0,
    tituloCarrusel: "",
  },
  {
    id: 8,
    position: 8,
    name: "Protección Financiera",
    eco: "Seguro de Protección financiera",
    desc: "Con tu Seguro Protección Financiera podrás obtener estos beneficios.",
    titulocards: "Beneficios para hacer tu Vida más Fácil",
    img: "/assets/img/ecosistemas/proteccion/img_proteccion_in.webp",
    ico: "/assets/img/ecosistemas/proteccion/ico_proteccion_in.png",
    active: true,
    activeCarousel: true,
    color: "morado",
    ecos: [38, 39, 40, 41, 42, 43, 44, 45, 55, 56],
    modulo: "modProteccionFinanciera",
    rutaexterna: "/proteccion-financiera",
    imgMob: "/assets/img/ecosistemas/proteccion/img_proteccion_in_mobile.webp",
    idProgramaCoursera: 1,
    tituloCarrusel: "Seguro Protección Financiera",
  },
  {
    id: 9,
    position: 9,
    name: "Vital Plus",
    eco: "Vital Plus",
    desc: "Con tu seguro podrás obtener estos beneficios.",
    titulocards: "Beneficios para hacer tu Vida más Fácil",
    img: "/assets/img/ecosistemas/vidaplus/img_vidaplus_in.webp",
    ico: "/assets/img/ecosistemas/vidaplus/ico_vidaplus_in.svg",
    active: true,
    activeCarousel: true,
    color: "verde-gris",
    ecos: [47, 48, 49],
    modulo: "modVidaPlus",
    rutaexterna: "/vidaplus",
    imgMob:
      "/assets/img/ecosistemas/empleabilidad/img_empleabilidad_in_mobile.webp",
    idProgramaCoursera: 1,
    tituloCarrusel: "Vital Plus",
  },
  {
    id: 10,
    position: 10,
    name: "Mi Salud Financiera",
    eco: "Mi salud financiera",
    desc: "¡Disfruta los beneficios exclusivos que tienes al contratar tu seguro!, Diagnóstica tu salud financiera, Mi score de crédito, servicio de taxi, asistencia por robo o extravío de documentos y asesoría legal, seguridad informática.",
    titulocards: "Beneficios para hacer tu Vida más Fácil",
    img: "/assets/img/ecosistemas/proteccion-elemental/img_proteccion_elemental_in.webp",
    ico: "/assets/img/ecosistemas/vidaplus/ico_vidaplus_in.svg",
    active: true,
    activeCarousel: true,
    color: "naranjo-rosado",
    ecos: [50, 51, 52, 53, 54],
    modulo: "modPFinancieraElemental",
    rutaexterna: "/mi-salud-financiera",
    imgMob:
      "/assets/img/ecosistemas/proteccion-elemental/img_proteccion_elemental_in_mobile.webp",
    idProgramaCoursera: 1,
    tituloCarrusel: "Mi salud financiera",
  },
  {
    id: 11,
    position: 11,
    name: "Eco Coursera",
    eco: "Eco Coursera",
    desc: "¡Disfruta los beneficios exclusivos que tienes al contratar tu seguro!,Más de 1,500 cursos 100% digitales sin costo que te ayudan a mejorar tu perfil profesional.",
    titulocards: "",
    img: "/assets/img/ecosistemas/emprendimiento/img_emprendimiento_in.webp",
    ico: "/assets/img/ecosistemas/vidaplus/ico_vidaplus_in.svg",
    active: true,
    activeCarousel: true,
    color: "verde",
    ecos: [59],
    modulo: "modCoursera",
    rutaexterna: "/coursera",
    imgMob:
      "/assets/img/ecosistemas/proteccion-elemental/img_proteccion_elemental_in_mobile.webp",
    idProgramaCoursera: 4,
    tituloCarrusel: "Eco Coursera",
  },
  {
    id: 12,
    position: 12,
    name: "Salud",
    eco: "Salud",
    desc: 'Servicios de salud digital que se acomodan a tus necesidades; facilitándote el acceso a especialidades médicas, "check-up" anual y apoyo en medicamentos.',
    titulocards: "",
    img: "/assets/img/ecosistemas/salud/img-salud.webp",
    ico: "",
    active: true,
    activeCarousel: true,
    color: "celeste",
    ecos: [60, 62, 61, 63],
    modulo: "modSalud",
    rutaexterna: "/salud",
    imgMob:
      "/assets/img/ecosistemas/proteccion-elemental/img_proteccion_elemental_in_mobile.webp",
    idProgramaCoursera: 0,
    tituloCarrusel: "Salud",
  },
  {
    id: 13,
    position: 13,
    name: "Vida",
    eco: "Vida",
    desc: "¡Disfruta de los beneficios exclusivos que tienes al contratar tu seguro!",
    titulocards: "",
    img: "/assets/img/ecosistemas/vida/img_vida_in.webp",
    ico: "/assets/img/ecosistemas/vidaplus/ico_vidaplus_in.svg",
    active: true,
    activeCarousel: true,
    color: "verde",
    ecos: [64, 65, 66],
    modulo: "modVida",
    rutaexterna: "/vida",
    imgMob:
      "/assets/img/ecosistemas/proteccion-elemental/img_proteccion_elemental_in_mobile.webp",
    idProgramaCoursera: 4,
    tituloCarrusel: "Vida",
  },
  {
    id: 14,
    position: 14,
    name: "Cuidados",
    eco: "Cuidados",
    desc: "¡Disfruta de los beneficios exclusivos que tienes al contratar tu seguro!",
    titulocards: "",
    img: "/assets/img/ecosistemas/cuidados/img_cuidados_in.webp",
    ico: "/assets/img/ecosistemas/vidaplus/ico_vidaplus_in.svg",
    active: true,
    activeCarousel: true,
    color: "celeste",
    ecos: [67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79],
    modulo: "modCuidados",
    rutaexterna: "/cuidados",
    imgMob:
      "/assets/img/ecosistemas/proteccion-elemental/img_proteccion_elemental_in_mobile.webp",
    idProgramaCoursera: 0,
    tituloCarrusel: "Cuidados",
  },
  {
    id: 15,
    position: 15,
    name: "Valor Factura",
    eco: "Valor Factura",
    desc: "¡Disfruta de los beneficios exclusivos que tienes al contratar tu seguro!",
    titulocards: "",
    img: "/assets/img/ecosistemas/valor-factura/img_valor-factura_in.webp",
    ico: "",
    active: true,
    activeCarousel: true,
    color: "naranja2",
    ecos: [80, 81, 82, 83],
    modulo: "modValorFactura",
    rutaexterna: "/valor-factura",
    imgMob:
      "/assets/img/ecosistemas/proteccion-elemental/img_proteccion_elemental_in_mobile.webp",
    idProgramaCoursera: 4,
    tituloCarrusel: "Valor Factura",
  },
];

export const detalleEcosistemas = [
  {
    id: 1,
    group: 1,
    shorttitle: "Cursos online con certificación",
    title: "Cursos online con certificación",
    subtitle: "Desarrolla tus habilidades",
    desc: "Accede a cursos de Coursera de forma gratuita y obtén un certificado al finalizar cada uno.",
    img: "/assets/img/ecosistemas/modulos/coursera/img_coursera_principal.png",
    imgmini:
      "/assets/img/ecosistemas/modulos/coursera/img_coursera_principal.png",
    ico: "/assets/img/ecosistemas/modulos/coursera/courseraProteccion.png",
    linkOut: "/proteccion/coursera",
    linkIn: "/ecosistema/proteccion/coursera",
    descrip:
      "Explora cursos de las universidades y compañías líderes en el mundo.",
    imgDescrip: "/assets/img/ecosistemas/proteccion/coursera/score-woman.png",
    colorPrincipal: "#009DD6",
    colorSecundario: "#91DDF8",
    flatImgConFondo: true,
    tituloOut: "Cursos Online con Certificación",
    descripcionOut:
      "Con tu Seguro de Tarjeta Protegida accede a beneficios que te permitirán conocer tu salud financiera y capacitarte gratuitamente.",
    imagenOut:
      "/assets/img/ecosistemas/modulos/coursera/img_coursera_principal.png",
    colorOut: "#00A693",
    textoIn:
      "Con tu Seguro accede a beneficios que te permitirán conocer tu salud financiera y capacitarte gratuitamente.",
    paginaBtn: "",
    esAgendamiento: false,
  },
  {
    id: 2,
    group: 1,
    shorttitle: "Score Financiero",
    title: "Tu Score Financiero",
    subtitle: "Alcanza tus metas. Conoce tu score financiero",
    desc: "Recibe reportes completos sobre tu puntaje dentro del Sistema Financiero.",
    img: "/assets/img/ecosistemas/modulos/score/img_score_principal.png",
    imgmini:
      "/assets/img/ecosistemas/modulos/score/img_score_principal_mini.png",
    ico: "/assets/img/ecosistemas/modulos/score/scoreProteccion.png",
    linkOut: "/proteccion/score-credito",
    linkIn: "/ecosistema/proteccion/score-credito",
    descrip: "Conoce tu score financiero y administra tus finanzas",
    imgDescrip: "/assets/img/ecosistemas/proteccion/score/score-woman.png",
    colorPrincipal: "var(--rojo)",
    colorSecundario: "#84D8C6",
    flatImgConFondo: true,
    tituloOut: "Tu Score Financiero",
    descripcionOut:
      "Conoce tu puntaje financiero y aprende cómo mejorar tu perfil crediticio, gracias a tu Seguro Tarjeta protegida podrás saber con exactitud, cuál es tu actual puntaje en materia crediticia y como mejorar tu perfil para solicitudes crediticias.",
    imagenOut: "/assets/img/ecosistemas/modulos/score/img_score_principal.png",
    colorOut: "#00A693",
    textoIn:
      "Conoce tu puntaje financiero y aprende cómo mejorar tu perfil crediticio, gracias a tu Seguro Tarjeta protegida podrás saber con exactitud, cuál es tu actual puntaje en materia crediticia y como mejorar tu perfil para solicitudes crediticias.",
    paginaBtn: "",
    esAgendamiento: false,
  },
  {
    id: 3,
    group: 1,
    shorttitle: "Alertas Financieras",
    title: "Obtén Alertas Financieras",
    subtitle: "Protégete de los fraudes con alertas financieras.",
    desc: "Recibe notificaciones en tiempo real sobre tus productos financieros.",
    img: "/assets/img/ecosistemas/modulos/alertasfinancieras/img_alertasfinancieras_principal.png",
    imgmini:
      "/assets/img/ecosistemas/modulos/alertasfinancieras/img_alertasfinancieras_principal_mini.png",
    ico: "/assets/img/ecosistemas/modulos/alertasfinancieras/alertasProteccion.png",
    linkOut: "/proteccion/alertas-notificaciones",
    linkIn: "/ecosistema/proteccion/alertas-notificaciones",
    descrip: "Todas las alertas financieras en un solo lugar",
    imgDescrip: "/assets/img/ecosistemas/proteccion/alertas/alerts-man.png",
    colorPrincipal: "var(--rojo)",
    colorSecundario: "#FEBA89",
    flatImgConFondo: true,
    tituloOut: "Recibe Alertas Financieras",
    descripcionOut:
      "Recibe notificaciones por todos los productos financieros que se creen a tu nombre y evita ser víctima de fraudes.",
    imagenOut:
      "/assets/img/ecosistemas/modulos/alertasfinancieras/img_alertasfinancieras_principal.png",
    colorOut: "#00A693",
    textoIn:
      "Recibe notificaciones por todos los productos financieros que se creen a tu nombre y evita ser víctima de fraudes.",
    paginaBtn: "",
    esAgendamiento: false,
  },
  {
    id: 4,
    group: 2,
    shorttitle: "Seguridad informática",
    title: "Seguridad informática ",
    subtitle: "Siéntete seguro. Tu información en la red estará protegida",
    desc: "Porque sabemos que tu información es valiosa te brindamos herramientas y asesorías con expertos para protegerla en la red.",
    img: "/assets/img/ecosistemas/modulos/seguridadinformatica/img_seguridadinformatica_principal.png",
    imgmini:
      "/assets/img/ecosistemas/modulos/seguridadinformatica/img_seguridadinformatica_principal_mini.png",
    ico: "/assets/img/ecosistemas/modulos/seguridadinformatica/seguridadProteccion.png",
    linkOut: "/proteccion/seguridad-informatica",
    linkIn: "/ecosistema/proteccion/seguridad-informatica",
    descrip: "¡Navega tranquilo! Conoce cómo proteger tu información en la red",
    imgDescrip:
      "/assets/img/ecosistemas/proteccion/seguridad/img-security-man.png",
    colorPrincipal: "var(--rojo)",
    colorSecundario: "#4D4D4D",
    flatImgConFondo: true,
    tituloOut: "Seguridad Informática",
    descripcionOut:
      "Te enseñamos a reforzar tu seguridad informática y navegar sin problemas por toda la red.",
    imagenOut:
      "/assets/img/ecosistemas/modulos/seguridadinformatica/img_seguridadinformatica_principal.png",
    colorOut: "#00A693",
    textoIn:
      "Te enseñamos a reforzar tu seguridad informática y navegar sin problemas por toda la red.",
    paginaBtn: "",
    esAgendamiento: false,
  },
  {
    id: 5,
    group: 2,
    shorttitle: "Salud Financiera",
    title: "Prueba tu Salud Financiera",
    subtitle: "Conoce la salud de tu estado financiero",
    desc: "Diagnostica el estado financiero de tu situación actual para gestionar tus finanzas y vivir mejor.",
    img: "/assets/img/ecosistemas/modulos/saludfinanciera/img_saludfinanciera_principal.png",
    imgmini:
      "/assets/img/ecosistemas/modulos/saludfinanciera/img_saludfinanciera_principal_mini.png",
    ico: "/assets/img/ecosistemas/modulos/saludfinanciera/saludProteccion.png",
    linkOut: "/proteccion/salud-financiera",
    linkIn: "/ecosistema/proteccion/salud-financiera",
    descrip: "Diagnostica de manera simple tu salud financiera",
    imgDescrip: "/assets/img/ecosistemas/proteccion/salud/img-salud.png",
    colorPrincipal: "var(--rojo)",
    colorSecundario: "#AFA8F6",
    flatImgConFondo: true,
    tituloOut: "Prueba tu Salud Financiera",
    descripcionOut:
      "Averigua tu estado de salud financiera haciendo este test y conoce varias técnicas de ahorro.",
    imagenOut:
      "/assets/img/ecosistemas/modulos/saludfinanciera/img_saludfinanciera_principal.png",
    colorOut: "#00A693",
    textoIn:
      "Averigua tu estado de salud financiera haciendo este test y conoce varias técnicas de ahorro.",
    paginaBtn: "",
    esAgendamiento: false,
  },
  {
    id: 6,
    group: 2,
    shorttitle: "Life",
    title: "Life: Fitness Pass",
    subtitle: "App Life",
    desc: "Prueba diferentes experiencias virtuales con los mejores centros y coaches de Latinoamérica.",
    img: "/assets/img/ecosistemas/modulos/life/img_life_principal.png",
    imgmini: "/assets/img/ecosistemas/modulos/life/img_life_principal_mini.png",
    ico: "/assets/img/ecosistemas/modulos/life/lifeProteccion.png",
    linkOut: "/proteccion/life",
    linkIn: "/ecosistema/proteccion/life",
    descrip: "Life: Fitness Pass",
    imgDescrip: "/assets/img/ecosistemas/salud/life/img-life.png",
    colorPrincipal: "var(--rojo)",
    colorSecundario: "#00a693",
    flatImgConFondo: false,
    tituloOut: "Life: Fitness Pass",
    descripcionOut:
      "Accede a miles de clases fitness a través de tu celular o pc. Encontrarás distintos deportes, programas de nutrición, entre otros.",
    imagenOut: "/assets/img/ecosistemas/modulos/life/img_life_principal.png",
    colorOut: "#00A693",
    textoIn:
      "Accede a miles de clases fitness a través de tu celular o pc. Encontrarás distintos deportes, programas de nutrición, entre otros",
    paginaBtn: "",
    esAgendamiento: false,
  },

  // EMPRENDIMIENTO

  {
    id: 7,
    group: 1,
    shorttitle: "Coursera",
    title: "Coursera",
    subtitle: "Desarrolla tus habilidades",
    desc: "A través de cursos 100% digitales impartidos por las mejores universidades del mundo, podrás potenciar tu perfil profesional y hacerlo más atractivo con certificados de valor curricular, sin costo adicional.",
    img: "/assets/img/ecosistemas/modulos/coursera/img_coursera_principal.png",
    imgmini:
      "/assets/img/ecosistemas/modulos/coursera/img_coursera_principal3.png",
    ico: "/assets/img/ecosistemas/modulos/coursera/ico_coursera_emprendimiento.svg",
    linkOut: "/emprendimiento/coursera",
    linkIn: "/ecosistema/emprendimiento/coursera",
    descrip: "Cursos online con certificación",
    imgDescrip: "/assets/img/ecosistemas/proteccion/img-coursera.png",
    colorPrincipal: "var(--rojo)",
    colorSecundario: "#FB6330",
    flatImgConFondo: false,
    tituloOut: "Cursos Online con Certificación",
    descripcionOut:
      "Con tu Seguro de Tarjeta Protegida accede a beneficios que te permitirán conocer tu salud financiera y capacitarte gratuitamente.",
    imagenOut: "/assets/img/ecosistemas/proteccion/img-coursera.png",
    colorOut: "#fb6330",
    textoIn:
      "Con tu Seguro accede a beneficios que te permitirán conocer tu salud financiera y capacitarte gratuitamente.",
    paginaBtn: "Curso online - exploración panel",
    detail_eco: "EM",
    detail_service: "COURSERA",
    detail_action: "INGRESAR",
    esAgendamiento: false,
  },

  {
    id: 8,
    group: 1,
    shorttitle: "Coworking",
    title: "Coworking",
    subtitle: "Un espacio ideal para hacer negocios.",
    desc: "Reserva el espacio perfecto para tus sesiones de trabajo durante todo un día, sin costo adicional.",
    img: "/assets/img/ecosistemas/modulos/coworking/img_coworking_principal.png",
    imgmini:
      "/assets/img/ecosistemas/modulos/coworking/img_coworking_principal_mini2.png",
    ico: "/assets/img/ecosistemas/modulos/coworking/ico_coworking.svg",
    linkOut: "/emprendimiento/coworking",
    linkIn: "/ecosistema/emprendimiento/coworking",
    linkEditAgendamiento: "/ecosistema/emprendimiento/coworking/elige-espacio",
    descrip: "Uso de espacios Coworking",
    imgDescrip:
      "/assets/img/ecosistemas/emprendimiento/coworking/img-coworking.png",
    colorPrincipal: "var(--rojo)",
    colorSecundario: "#FB6330",
    flatImgConFondo: false,
    tituloOut: "Coworking ",
    descripcionOut:
      "Reserva el espacio perfecto para tus sesiones de trabajo durante todo un día, sin costo adicional.",
    imagenOut:
      "/assets/img/ecosistemas/modulos/coworking/img_coworking_principal.png",
    colorOut: "#fb6330",
    textoIn:
      "Reserva el espacio perfecto para tus sesiones de trabajo durante todo un día, sin costo adicional.",
    paginaBtn: "Ingreso_Portal_Coworking",
    detail_eco: "EM",
    detail_service: "COWORKING",
    detail_action: "INGRESAR",
    esAgendamiento: true,
    agendaTipo: 2036,
  },
  {
    id: 9,
    group: 1,
    shorttitle: "Asesoría Legal",
    title: "Asesoría legal",
    subtitle: "Porque alguien debe leer la letra pequeña.",
    desc: "Asesoría legal digital en licencias y propiedad intelectual, asesoría en derecho laboral, registro de marca, patentes  y más.",
    img: "/assets/img/ecosistemas/modulos/legal/img_legal_principal.png",
    imgmini:
      "/assets/img/ecosistemas/modulos/legal/img_legal_principal_mini2.png",
    ico: "/assets/img/ecosistemas/modulos/legal/ico_legal.svg",
    linkOut: "/emprendimiento/legal",
    linkIn: "/ecosistema/emprendimiento/legal",
    linkEditAgendamiento: "/ecosistema/emprendimiento/legal/programar-asesoria",
    descrip: "Asesoría legal",
    imgDescrip:
      "/assets/img/ecosistemas/emprendimiento/asesoria/img-asesoria-digital.png",
    colorPrincipal: "var(--rojo)",
    colorSecundario: "#FB6330",
    flatImgConFondo: false,
    tituloOut: "Asesoría Legal",
    descripcionOut:
      "Asesoría legal digital en licencias y propiedad intelectual, asesoría en derecho laboral, registro de marca, patentes  y más. ",
    imagenOut: "/assets/img/ecosistemas/modulos/legal/img_legal_principal.png",
    colorOut: "#fb6330",
    textoIn:
      "Asesoría legal digital en licencias y propiedad intelectual, asesoría en derecho laboral, registro de marca, patentes  y más. ",
    paginaBtn: "Ingreso_Portal_AsesoriaLegal",
    detail_eco: "EM",
    detail_service: "ASESORIALEGAL",
    detail_action: "INGRESAR",
    esAgendamiento: true,
    agendaTipo: 2042,
  },
  {
    id: 10,
    group: 2,
    shorttitle: "Marketing digital",
    title: "Personal branding y marketing digital",
    subtitle: "Lleva tu marca al siguiente nivel.",
    desc: "Recibe consultoría exclusiva especializada en temas de marketing, creación y posicionamiento de marca para potenciar el alcance de tu empresa.",
    img: "/assets/img/ecosistemas/modulos/marketing/img_marketing_principal.png",
    imgmini:
      "/assets/img/ecosistemas/modulos/marketing/img_marketing_principal_mini2.png",
    ico: "/assets/img/ecosistemas/modulos/marketing/ico_marketing2.svg",
    linkOut: "/emprendimiento/marketing",
    linkIn: "/ecosistema/emprendimiento/marketing",
    linkEditAgendamiento:
      "/ecosistema/emprendimiento/marketing/programar-asesoria",
    descrip: "Impulsa tus ideas con Marketing Digital.",
    imgDescrip:
      "/assets/img/ecosistemas/emprendimiento/coursera/img-marketing-digital.png",
    colorPrincipal: "var(--rojo)",
    colorSecundario: "#FB6330",
    flatImgConFondo: false,
    tituloOut: "Marketing Digital",
    descripcionOut:
      "Recibe consultoría exclusiva especializada en temas de marketing, creación y posicionamiento de marca para potenciar el alcance de tu empresa.",
    imagenOut:
      "/assets/img/ecosistemas/modulos/marketing/img_marketing_principal.png",
    colorOut: "#fb6330",
    textoIn:
      "Recibe consultoría exclusiva especializada en temas de marketing, creación y posicionamiento de marca para potenciar el alcance de tu empresa.",
    paginaBtn: "Ingreso Portal MarketingDigital y Branding",
    detail_eco: "EM",
    detail_service: "MARCAPERSONAL",
    detail_action: "INGRESAR",
    esAgendamiento: true,
    agendaTipo: 2043,
  },
  {
    id: 11,
    group: 2,
    shorttitle: "Life: Fitness Pass",
    title: "Life: Fitness Pass",
    subtitle: "App Life",
    desc: "Prueba diferentes experiencias virtuales con los mejores centros y coaches de Latinoamérica.",
    img: "/assets/img/ecosistemas/modulos/life/img_life_principal.png",
    imgmini: "/assets/img/ecosistemas/modulos/life/img_life_principal_mini.png",
    ico: "/assets/img/ecosistemas/modulos/life/fitnessEmprendimiento.png",
    linkOut: "/emprendimiento/life",
    linkIn: "/ecosistema/emprendimiento/life",
    descrip: "Life: Fitness Pass",
    imgDescrip: "/assets/img/ecosistemas/salud/life/img-life.png",
    colorPrincipal: "var(--rojo)",
    colorSecundario: "#fb6330",
    flatImgConFondo: false,
    tituloOut: "Life: Fitness Pass",
    descripcionOut:
      "Accede a miles de clases fitness a través de tu celular o pc. Encontrarás distintos deportes, programas de nutrición, entre otros.",
    imagenOut: "/assets/img/ecosistemas/modulos/life/img_life_principal.png",
    colorOut: "#fb6330",
    textoIn:
      "Accede a miles de clases fitness a través de tu celular o pc. Encontrarás distintos deportes, programas de nutrición, entre otros",
    paginaBtn: "",
    esAgendamiento: false,
  },

  // EMPLEABILIDAD

  {
    id: 12,
    group: 1,
    shorttitle: "Cursos online",
    title: "Coursera",
    subtitle: "Desarrolla tus habilidades",
    desc: "A través de cursos 100% digitales impartidos por las mejores universidades del mundo, podrás potenciar tu perfil profesional y hacerlo más atractivo con certificados de valor curricular, sin costo adicional.",
    img: "/assets/img/ecosistemas/modulos/coursera/img_coursera_principal.png",
    imgmini:
      "/assets/img/ecosistemas/modulos/coursera/img_coursera_principal3.png",
    ico: "/assets/img/ecosistemas/modulos/coursera/Ico_coursera.webp",
    linkOut: "/empleabilidad/coursera",
    linkIn: "/ecosistema/empleabilidad/coursera",
    descrip: "Cursos online con certificación",
    imgDescrip:
      "/assets/img/ecosistemas/empleabilidad/coursera/img-card-coursera.png",
    colorPrincipal: "var(--rojo)",
    colorSecundario: "#7849B8",
    flatImgConFondo: false,
    tituloOut: "Cursos online para potenciar tus conocimientos",
    descripcionOut: "Aprende de la plataforma #1 del mundo.",
    imagenOut: "/assets/img/ecosistemas/proteccion/img-coursera.png",
    colorOut: "#7849B8",
    textoIn:
      "Con tu Seguro accede a beneficios que te permitirán conocer tu salud financiera y capacitarte gratuitamente.",
    paginaBtn: "Curso online - exploración panel",
    detail_eco: "EL",
    detail_service: "COURSERA",
    detail_action: "INGRESAR",
    esAgendamiento: false,
  },
  {
    id: 13,
    group: 1,
    shorttitle: "Diseña tu CV",
    title: "Mejora tu CV",
    subtitle: "Tu hoja de vida es tu carta de presentación",
    desc: "Tu currículum es tu mejor carta de presentación, sintetiza tu historia académica, laboral y profesional para incrementar tus posibilidades de ser seleccionado para una vacante.",
    img: "/assets/img/ecosistemas/modulos/curriculum/img_curriculum_principal.png",
    imgmini:
      "/assets/img/ecosistemas/modulos/curriculum/img_curriculum_principal_mini2.png",
    ico: "/assets/img/ecosistemas/modulos/curriculum/Ico_Mejora_CV.webp",
    linkOut: "/empleabilidad/curriculum",
    linkIn: "/ecosistema/empleabilidad/curriculum",
    descrip: "Mejora tu Hoja de vida",
    imgDescrip: "/assets/img/ecosistemas/empleabilidad/cv/img-mejora-tu-cv.png",
    colorPrincipal: "var(--rojo)",
    colorSecundario: "#7849B8",
    flatImgConFondo: false,
    tituloOut: "Mejora tu hoja de vida",
    descripcionOut:
      "Una buena Hoja de vida puede ayudarte a tu próximo objetivo laboral.",
    imagenOut:
      "/assets/img/ecosistemas/modulos/curriculum/img_curriculum_principal.png",
    colorOut: "#7849B8",
    textoIn:
      "Si estás sin trabajo o buscas una mejor oportunidad este servicio es para ti. Con unos clicks busca nuevas oportunidades laborales de forma sencilla.",
    paginaBtn: "Ingreso_Portal_Curriculum",
    detail_eco: "EL",
    detail_service: "MEJORATUCV",
    detail_action: "INGRESAR",
    esAgendamiento: false,
  },
  {
    id: 14,
    group: 1,
    shorttitle: "Revisa tu CV",
    title: "Optimiza tu marca personal",
    subtitle: "Obtén la posición laboral que deseas",
    desc: "Con un CV perfecto impresionarás a cualquiera. Reserva una cita con uno de nuestros asesores para que con su ayuda, tu carta de presentación sea excepcional.",
    img: "/assets/img/ecosistemas/modulos/entrevista/img_entrevista_principal.png",
    imgmini:
      "/assets/img/ecosistemas/modulos/entrevista/img_entrevista_principal_mini2.png",
    ico: "/assets/img/ecosistemas/modulos/entrevista/Ico_marca_personal.webp",
    linkOut: "/empleabilidad/marca-personal",
    linkIn: "/ecosistema/empleabilidad/marca-personal",
    linkEditAgendamiento: "/ecosistema/empleabilidad/marca-personal",
    descrip: "Prepárate para tu entrevista",
    imgDescrip:
      "/assets/img/ecosistemas/empleabilidad/entrevista/img-preparate-entrevista.png",
    colorPrincipal: "#7849B8",
    colorSecundario: "#7849B8",
    flatImgConFondo: false,
    tituloOut: "Prepárate para tu entrevista",
    descripcionOut:
      "Una buena preparación puede darte ese trabajo que siempre deseaste.",
    imagenOut:
      "/assets/img/ecosistemas/modulos/entrevista/img_entrevista_principal.png",
    colorOut: "#7849B8",
    textoIn:
      "Una buena preparación puede darte ese trabajo que siempre deseaste.",
    paginaBtn: "Ingreso_Portal_Curriculum",
    detail_eco: "EL",
    detail_service: "MARCAPERSONAL",
    detail_action: "INGRESAR",
    esAgendamiento: true,
    agendaTipo: 2023,
  },
  {
    id: 15,
    group: 2,
    shorttitle: "Bolsa de trabajo",
    title: "Ofertas disponibles en México",
    subtitle: "Expande tus horizontes laborales",
    desc: "Más de 40,000 ofertas laborales vigentes con las mejores empresas de México diferenciadas por industria. Busca la opción que más se ajusta a tu perfil.",
    img: "/assets/img/ecosistemas/modulos/ofertas/img_ofertas_MX_1.jpg",
    imgmini:
      "/assets/img/ecosistemas/modulos/ofertas/img_ofertas_principal_mini2.png",
    ico: "/assets/img/ecosistemas/modulos/ofertas/Ico_ofertas_MX.png",
    linkOut: "/empleabilidad/job",
    linkIn: "/ecosistema/empleabilidad/job",
    descrip: "Conoce ofertas laborales",
    imgDescrip: "/assets/img/ecosistemas/empleabilidad/ofertas/img-ofertas.png",
    colorPrincipal: "var(--rojo)",
    colorSecundario: "#7849B8",
    flatImgConFondo: false,
    tituloOut: "Conoce ofertas laborales",
    descripcionOut: "Expande tus horizontes laborales.",
    imagenOut:
      "/assets/img/ecosistemas/modulos/ofertas/img_ofertas_principal.png",
    colorOut: "#7849B8",
    textoIn:
      "Si estás sin trabajo o buscas una mejor oportunidad este servicio es para ti. Con unos clicks busca nuevas oportunidades laborales de forma sencilla.",
    paginaBtn: "Ingreso_Portal_Bolsa Trabajo",
    detail_eco: "EL",
    detail_service: "OFERTASLABORALES",
    detail_action: "INGRESAR",
    esAgendamiento: false,
  },
  {
    id: 16,
    group: 2,
    shorttitle: "Compara tu salario",
    title: "Conoce nuestro Reporte Salarial",
    subtitle: "Compara tu salario de acuerdo al mercado",
    desc: "Compara tu salario actual y la oferta de remuneración en el mercado laboral, a través de una estudio basado en datos de industria, cargo, carrera, experiencia, entre otros.",
    img: "/assets/img/ecosistemas/modulos/salario/img_reporte_1.jpg",
    imgmini:
      "/assets/img/ecosistemas/modulos/salario/img_salario_principal_mini2.png",
    ico: "/assets/img/ecosistemas/modulos/salario/Ico_reporte_salarial.png",
    linkOut: "/empleabilidad/salario",
    linkIn: "/ecosistema/empleabilidad/salario",
    descrip: "Compara tu salario",
    imgDescrip: "/assets/img/ecosistemas/empleabilidad/salario/img-salario.png",
    colorPrincipal: "var(--rojo)",
    colorSecundario: "#7849B8",
    flatImgConFondo: false,
    tituloOut: "Compara tu salario",
    descripcionOut: "Conoce cómo te encuentras con relación al mercado.",
    imagenOut:
      "/assets/img/ecosistemas/modulos/salario/img_salario_principal.png",
    colorOut: "#7849B8",
    textoIn:
      "Conoce tu puntaje financiero y aprende cómo mejorar tu perfil crediticio, gracias a tu Seguro Protección de Ingresos podrás saber con exactitud, cuál es tu actual puntaje en materia crediticia y como mejorar tu perfil para solicitudes futuras.",
    paginaBtn: "Ingreso_Portal_ComparaSalario",
    detail_eco: "EL",
    detail_service: "REPORTESALARIAL",
    detail_action: "INGRESAR",
    esAgendamiento: false,
  },
  {
    id: 17,
    group: 2,
    shorttitle: "Asesoría legal laboral",
    title: "Consultoría en Derecho Laboral",
    subtitle: "Estamos para apoyarte. Obtén orientación legal laboral",
    desc: "Queremos acompañarte cuando más nos necesites, recibe asesoría y apoyo permanente de parte de nuestros expertos en Derecho Laboral.",
    img: "/assets/img/ecosistemas/modulos/orientacionlaboral/img_orientacionlaboral_principal.png",
    imgmini:
      "/assets/img/ecosistemas/modulos/orientacionlaboral/img_orientacionlaboral_principal_mini2.png",
    ico: "/assets/img/ecosistemas/modulos/orientacionlaboral/Ico_consultoría.png",
    linkOut: "/empleabilidad/legal",
    linkIn: "/ecosistema/empleabilidad/legal",
    linkEditAgendamiento: "/ecosistema/empleabilidad/legal/programar-asesoria",
    descrip: "Orientación legal laboral",
    imgDescrip:
      "/assets/img/ecosistemas/empleabilidad/legal/img-asesoria-digital.png",
    colorPrincipal: "var(--rojo)",
    colorSecundario: "#7849B8",
    flatImgConFondo: false,
    tituloOut: "Orientación legal laboral",
    descripcionOut:
      "Programa una sesión y asesórate por expertos sobre temas legales en el ámbito laboral.",
    imagenOut:
      "/assets/img/ecosistemas/modulos/orientacionlaboral/img_orientacionlaboral_principal.png",
    colorOut: "#7849B8",
    textoIn:
      "Queremos acompañarte cuando más nos necesites, recibe asesoría y apoyo permanente de parte de nuestros expertos en Derecho Laboral.",
    paginaBtn: "Ingreso_Portal_AsesoriaLegal",
    detail_eco: "EL",
    detail_service: "DERECHOLABORAL",
    detail_action: "INGRESAR",
    esAgendamiento: true,
    agendaTipo: 2035,
  },
  {
    id: 18,
    group: 2,
    shorttitle: "Podcast",
    title: "Podcast",
    subtitle: "Aprende de los líderes dentro del sector empresarial",
    desc: "Escucha Podcast de los grandes líderes de hoy e inspírate en tu crecimiento personal y profesional en base a su experiencia.",
    img: "/assets/img/ecosistemas/modulos/podcast/img_podcast_principal.png",
    imgmini:
      "/assets/img/ecosistemas/modulos/podcast/img_podcast_principal_mini.png",
    ico: "/assets/img/ecosistemas/modulos/podcast/ico_podcast.svg",
    linkOut: "/empleabilidad/podcast",
    linkIn: "/ecosistema/empleabilidad/podcast",
    descrip: "Podcast",
    imgDescrip: "/assets/img/ecosistemas/empleabilidad/podcast/img-podcast.png",
    colorPrincipal: "var(--rojo)",
    colorSecundario: "#7849B8",
    flatImgConFondo: false,
    tituloOut: "Podcast",
    descripcionOut:
      "Aprende de la experiencia y los consejos de líderes y expertos.",
    imagenOut:
      "/assets/img/ecosistemas/modulos/podcast/img_podcast_principal.png",
    colorOut: "#7849B8",
    textoIn: "Aprende de la experiencia y los consejos de líderes y expertos.",
    paginaBtn: "",
    esAgendamiento: false,
  },
  {
    id: 19,
    group: 2,
    shorttitle: "Life",
    title: "Life: Fitness Pass",
    subtitle: "App Life",
    desc: "Prueba diferentes experiencias virtuales con los mejores centros y coaches de Latinoamérica.",
    img: "/assets/img/ecosistemas/modulos/life/img_life_principal.png",
    imgmini: "/assets/img/ecosistemas/modulos/life/img_life_principal_mini.png",
    ico: "/assets/img/ecosistemas/modulos/life/lifeEmple.png",
    linkOut: "/empleabilidad/life",
    linkIn: "/ecosistema/empleabilidad/life",
    descrip: "LIFE",
    imgDescrip: "/assets/img/ecosistemas/salud/life/img-life.png",
    colorPrincipal: "var(--rojo)",
    colorSecundario: "#7849b8",
    flatImgConFondo: false,
    tituloOut: "Life: Fitness Pass",
    descripcionOut:
      "Accede a miles de clases fitness a través de tu celular o pc. Encontrarás distintos deportes, programas de nutrición, entre otros.",
    imagenOut: "/assets/img/ecosistemas/modulos/life/img_life_principal.png",
    colorOut: "#7849B8",
    textoIn:
      "Accede a miles de clases fitness a través de tu celular o pc. Encontrarás distintos deportes, programas de nutrición, entre otros",
    paginaBtn: "",
    esAgendamiento: false,
  },

  // SALUD

  {
    id: 20,
    group: 1,
    shorttitle: "Asesoría nutricional",
    title: "Asesoría nutricional",
    subtitle: "Agenda una cita nutricional",
    desc: "Tene asesorias virtuales con especialistas, estando en la comodidad de tu hogar.",
    img: "/assets/img/ecosistemas/modulos/asesorianutricional/img_asesorianutricional_principal.png",
    imgmini:
      "/assets/img/ecosistemas/modulos/asesorianutricional/img_asesorianutricional_principal_mini.png",
    ico: "/assets/img/ecosistemas/modulos/asesorianutricional/ico_asesorianutricional.png",
    linkOut: "/salud/asesoria-nutricional",
    linkIn: "/ecosistema/salud/asesoria-nutricional",
    descrip: "Asesoría nutricional",
    imgDescrip:
      "/assets/img/ecosistemas/salud/asesoria/img-asesoria-nutricional.jpeg",
    colorPrincipal: "#0070B8",
    colorSecundario: "#0070B8",
    flatImgConFondo: false,
    tituloOut: "Asesoría nutricional",
    descripcionOut:
      "A través de nuestro servicio virtual, nos aseguramos que puedas tener asesorias virtuales con especialistas, estando en la comodidad de tu hogar.",
    imagenOut:
      "/assets/img/ecosistemas/modulos/asesorianutricional/img_asesorianutricional_principal.png",
    colorOut: "var(--azul)",
    textoIn:
      "A través de nuestro servicio virtual, nos aseguramos que puedas tener asesorias virtuales con especialistas, estando en la comodidad de tu hogar.",
    paginaBtn: "",
    esAgendamiento: false,
  },
  {
    id: 21,
    group: 1,
    shorttitle: "Telemedicina",
    title: "Telemedicina",
    subtitle: "Agenda una cita médica",
    desc: "Conversa con un médico por videollamada y resuelve rápidamente tus consultas acerca de cualquier molestia o enfermedad.",
    img: "/assets/img/ecosistemas/modulos/telemedicina/img_telemedicina_principal.png",
    imgmini:
      "/assets/img/ecosistemas/modulos/telemedicina/img_telemedicina_principal_mini.png",
    ico: "/assets/img/ecosistemas/modulos/telemedicina/ico-telemedicina.png",
    linkOut: "/salud/telemedicina",
    linkIn: "/ecosistema/salud/telemedicina",
    descrip: "Telemedicina",
    imgDescrip:
      "/assets/img/ecosistemas/salud/telemedicina/img-telemedicina.png",
    colorPrincipal: "#0070B8",
    colorSecundario: "#0070B8",
    flatImgConFondo: false,
    tituloOut: "Telemedicina",
    descripcionOut:
      "Podrás consultarle a un médico acerca de una enfermedad o molestia que tengas, siempre que no sea una situación de emergencia. En este se incluye el envío de una receta digital personalizada con la firma y sello del médico.",
    imagenOut:
      "/assets/img/ecosistemas/modulos/telemedicina/img_telemedicina_principal.png",
    colorOut: "var(--azul)",
    textoIn:
      "Podrás consultarle a un médico acerca de una enfermedad o molestia que tengas, siempre que no sea una situación de emergencia. En este se incluye el envío de una receta digital personalizada con la firma y sello del médico.",
    paginaBtn: "",
    esAgendamiento: false,
  },
  {
    id: 22,
    group: 1,
    shorttitle: "Médico a Domicilio",
    title: "Médico a Domicilio",
    subtitle: "Más cerca de ti",
    desc: "Recibe atención médica en la comodidad de tu hogar, ampliando así la buena práctica con un trato cálido a nuestros socios.",
    img: "/assets/img/ecosistemas/modulos/medico/img_medico_principal.png",
    imgmini:
      "/assets/img/ecosistemas/modulos/medico/img_medico_principal_mini.png",
    ico: "/assets/img/ecosistemas/modulos/medico/ico_medico.png",
    linkOut: "/salud/medico-domicilio",
    linkIn: "/ecosistema/salud/medico-domicilio",
    descrip: "Médico a Domicilio",
    imgDescrip: "/assets/img/ecosistemas/salud/medico/img-medico-domicilio.png",
    colorPrincipal: "#0070B8",
    colorSecundario: "#0070B8",
    flatImgConFondo: false,
    tituloOut: "Médico a Domicilio",
    descripcionOut: "Personal capacitado en tu hogar.",
    imagenOut:
      "/assets/img/ecosistemas/modulos/medico/img_medico_principal.png",
    colorOut: "var(--azul)",
    textoIn: "Personal capacitado en tu hogar.",
    paginaBtn: "",
    esAgendamiento: false,
  },
  {
    id: 23,
    group: 2,
    shorttitle: "Life",
    title: "Life: Fitness Pass",
    subtitle: "App Life",
    desc: "Prueba diferentes experiencias virtuales con los mejores centros y coaches de Latinoamérica.",
    img: "/assets/img/ecosistemas/modulos/life/img_life_principal.png",
    imgmini: "/assets/img/ecosistemas/modulos/life/img_life_principal_mini.png",
    ico: "/assets/img/ecosistemas/modulos/life/fitnessSalud.png",
    linkOut: "/salud/life",
    linkIn: "/ecosistema/salud/life",
    descrip: "LIFE",
    imgDescrip: "/assets/img/ecosistemas/salud/life/img-life.png",
    colorPrincipal: "#0070B8",
    colorSecundario: "#0070B8",
    flatImgConFondo: false,
    tituloOut: "Life: Fitness Pass",
    descripcionOut:
      "Accede a miles de clases fitness a través de tu celular o pc. Encontrarás distintos deportes, programas de nutrición, entre otros.",
    imagenOut: "/assets/img/ecosistemas/modulos/life/img_life_principal.png",
    colorOut: "var(--azul)",
    textoIn:
      "Accede a miles de clases fitness a través de tu celular o pc. Encontrarás distintos deportes, programas de nutrición, entre otros",
    paginaBtn: "",
    esAgendamiento: false,
  },
  {
    id: 24,
    group: 2,
    shorttitle: "Orientación médica telefónica",
    title: "Orientación médica telefónica",
    subtitle: "Enlace telefónico con un médico",
    desc: "Frente a cualquier síntoma de baja complejidad conversa con un médico y resuelve rápidamente tus consultas y contactate con especialistas.",
    img: "/assets/img/ecosistemas/modulos/orientacionmedica/img_orientacionmedica_principal.png",
    imgmini:
      "/assets/img/ecosistemas/modulos/orientacionmedica/img_orientacionmedica_principal_mini.png",
    ico: "/assets/img/ecosistemas/modulos/orientacionmedica/ico_orientacionmedica.png",
    linkOut: "/salud/orientacion-medica",
    linkIn: "/ecosistema/salud/orientacion-medica",
    descrip: "Orientación médica telefónica",
    imgDescrip:
      "/assets/img/ecosistemas/salud/orientacion/img-orientacion-medica.jpg",
    colorPrincipal: "#0070B8",
    colorSecundario: "#0070B8",
    flatImgConFondo: false,
    tituloOut: "Orientación Médica Telefónica",
    descripcionOut:
      "Frente a cualquier síntoma de baja complejidad podrás consultarle a un médico acerca de consultas , podrás obtener recomendaciones de una terapéutica farmacológica y/o derivación con un médico especialista.",
    imagenOut:
      "/assets/img/ecosistemas/modulos/orientacionmedica/img_orientacionmedica_principal.png",
    colorOut: "var(--azul)",
    textoIn:
      "Frente a cualquier síntoma de baja complejidad podrás consultarle a un médico acerca de consultas , podrás obtener recomendaciones de una terapéutica farmacológica y/o derivación con un médico especialista.",
    paginaBtn: "",
    esAgendamiento: false,
  },

  {
    id: 25,
    group: 2,
    shorttitle: "Ambulancia",
    title: "Ambulancia",
    subtitle: "Ambulancia a tu disposición",
    desc: "Frente a una enfermedad grave o un accidente trasladate en una ambulancia terrestre a un hospital y/o clínica.",
    img: "/assets/img/ecosistemas/modulos/ambulancia/img_ambulancia_principal.png",
    imgmini:
      "/assets/img/ecosistemas/modulos/ambulancia/img_ambulancia_principal_mini.png",
    ico: "/assets/img/ecosistemas/modulos/ambulancia/ico_ambulancia.png",
    linkOut: "/salud/ambulancia",
    linkIn: "/ecosistema/salud/ambulancia",
    descrip: "Ambulancia",
    imgDescrip: "/assets/img/ecosistemas/salud/ambulancia/img-ambulancia.jpg",
    colorPrincipal: "#0070B8",
    colorSecundario: "#0070B8",
    flatImgConFondo: false,
    tituloOut: "Ambulancia",
    descripcionOut:
      "Frente a una enfermedad grave o un accidente trasladate en una ambulancia terrestre a un hospital y/o clínica.",
    imagenOut:
      "/assets/img/ecosistemas/modulos/ambulancia/img_ambulancia_principal.png",
    colorOut: "var(--azul)",
    textoIn:
      "Frente a una enfermedad grave o un accidente trasladate en una ambulancia terrestre a un hospital y/o clínica.",
    paginaBtn: "",
    esAgendamiento: false,
  },
  {
    id: 26,
    group: 2,
    shorttitle: "Enfermera a Domicilio",
    title: "Enfermera a Domicilio",
    subtitle: "Personal capacitado en tu hogar",
    desc: "Para garantizar el mejor de los cuidados agenda una enfermera para atenderte desde tu hogar.",
    img: "/assets/img/ecosistemas/modulos/enfermera/img_enfermera_principal.png",
    imgmini:
      "/assets/img/ecosistemas/modulos/enfermera/img_enfermera_principal_mini.png",
    ico: "/assets/img/ecosistemas/modulos/enfermera/ico_enfermera.png",
    linkOut: "/salud/enfermera-domicilio",
    linkIn: "/ecosistema/salud/enfermera-domicilio",
    descrip: "Enfermera a Domicilio",
    imgDescrip: "/assets/img/ecosistemas/salud/enfermera/img-enfermera.jpg",
    colorPrincipal: "#0070B8",
    colorSecundario: "#0070B8",
    flatImgConFondo: false,
    tituloOut: "Enfermera a Domicilio",
    descripcionOut: "Personal capacitado en tu hogar.",
    imagenOut:
      "/assets/img/ecosistemas/modulos/enfermera/img_enfermera_principal.png",
    colorOut: "var(--azul)",
    textoIn: "Personal capacitado en tu hogar.",
    paginaBtn: "",
    esAgendamiento: false,
  },

  // ERROR
  {
    id: 29,
    group: 2,
    shorttitle: "Análisis Clínicos Básicos",
    title: "Análisis Clínicos Básicos",
    subtitle: "Realiza exámenes de laboratorio",
    desc: "Coordina y realiza la toma de muestra de exámenes de laboratorio, obtén tus resultados con la red de centros especializados.",
    img: "/assets/img/ecosistemas/modulos/analisisclinicos/img_analisisclinicos_principal.png",
    imgmini:
      "/assets/img/ecosistemas/modulos/analisisclinicos/img_analisisclinicos_principal_mini.png",
    ico: "/assets/img/ecosistemas/modulos/analisisclinicos/ico_analisisclinicos.png",
    linkOut: "/salud/analisis-clinico",
    linkIn: "/ecosistema/salud/analisis-clinico",
    descrip: "Análisis Clínicos básicos",
    imgDescrip:
      "/assets/img/ecosistemas/salud/analisis/img-analisis-clinico.jpeg",
    colorPrincipal: "#0070B8",
    colorSecundario: "#0070B8",
    flatImgConFondo: false,
    tituloOut: "Análisis Clínicos Básicos",
    descripcionOut: "Realiza exámenes de laboratorio.",
    imagenOut:
      "/assets/img/ecosistemas/modulos/analisisclinicos/img_analisisclinicos_principal.png",
    colorOut: "var(--azul)",
    textoIn: "Personal capacitado en tu hogar.",
    paginaBtn: "",
    esAgendamiento: false,
  },
  // {
  //     id: 27,
  //     group: 2,
  //     shorttitle: 'Servicios Funerarios',
  //     title: 'Servicios Funerarios',
  //     subtitle: 'Siempre a tu lado',
  //     desc: 'Te acompañamos en momentos difíciles. Ten el soporte necesario y la asistencia que necesitas.',
  //     img: '/assets/img/ecosistemas/salud/img-servicios-funerarios.jpg',
  //     ico: '/assets/img/ecosistemas/salud/ico-Servicios-Funerarios.png',
  //     linkOut: '/salud/servicio-funerario',
  //     linkIn: '/ecosistema/salud/servicio-funerario',
  //     descrip: 'Servicio funerario',
  //     imgDescrip: '/assets/img/ecosistemas/salud/img-servicios-funerarios.jpg',
  //     colorPrincipal: '#0070B8',
  //     colorSecundario: '#0070B8',
  //     flatImgConFondo: false,
  // tituloOut: 'LIFE',
  // descripcionOut: 'Regístrate y accede a miles de clases fitness online.',
  // imagenOut: '/assets/img/ecosistemas/proteccion/img-life.png',
  // colorOut: 'var(--azul)'
  // },
  // {
  //     id: 28,
  //     group: 2,
  //     shorttitle: 'Delivery de medicamentos',
  //     title: 'Delivery de medicamentos',
  //     subtitle: 'Delivery de manera cómoda y sencilla',
  //     desc: 'Compra y recibe la entrega total de la receta de tus medicamentos, de la mano con la red de clínicas y farmacias.',
  //     img: '/assets/img/ecosistemas/salud/img-delivery-medicamentos.png',
  //     ico: '/assets/img/ecosistemas/salud/ico-Delivery-Medicamentos.png',
  //     linkOut: '/salud/delivery',
  //     linkIn: '/ecosistema/salud/delivery',
  //     descrip: 'Delivery de medicamentos',
  //     imgDescrip: '/assets/img/ecosistemas/salud/img-delivery-medicamentos.png',
  //     colorPrincipal: '#0070B8',
  //     colorSecundario: '#0070B8',
  //     flatImgConFondo: false,
  // tituloOut: 'LIFE',
  // descripcionOut: 'Regístrate y accede a miles de clases fitness online.',
  // imagenOut: '/assets/img/ecosistemas/proteccion/img-life.png',
  // colorOut: 'var(--azul)'
  // },

  {
    id: 30,
    group: 2,
    shorttitle: "Urgencia Dental",
    title: "Urgencia Dental",
    subtitle: "Atención personalizada para ti",
    desc: "Resuelve síntomas de dolor agudo, asociados a piezas dentarias y mucosas y recibe la atención hasta tu estabilización.",
    img: "/assets/img/ecosistemas/modulos/dental/img_dental_principal.png",
    imgmini:
      "/assets/img/ecosistemas/modulos/dental/img_dental_principal_mini.png",
    ico: "/assets/img/ecosistemas/modulos/dental/ico_dental.png",
    linkOut: "/salud/urgencia-dental",
    linkIn: "/ecosistema/salud/urgencia-dental",
    descrip: "Urgencia Dental",
    imgDescrip: "/assets/img/ecosistemas/salud/dental/img-urgencia-dental.jpg",
    colorPrincipal: "#0070B8",
    colorSecundario: "#0070B8",
    flatImgConFondo: false,
    tituloOut: "Urgencia dental",
    descripcionOut: "Atención personalizada para ti.",
    imagenOut:
      "/assets/img/ecosistemas/modulos/dental/img_dental_principal.png",
    colorOut: "var(--azul)",
    textoIn: "Asistencia cuando la necesitas",
    paginaBtn: "",
    esAgendamiento: false,
  },

  // VIDA

  {
    id: 31,
    group: 1,
    shorttitle: "Life:Fitness Pass",
    title: "Life: Fitness Pass",
    subtitle: "App Life",
    desc: "Prueba diferentes experiencias virtuales con los mejores centros y coaches de Latinoamérica.",
    img: "/assets/img/ecosistemas/modulos/life/img_life_principal.png",
    imgmini: "/assets/img/ecosistemas/modulos/life/img_life_principal_mini.png",
    ico: "/assets/img/ecosistemas/modulos/life/fitnessVida.png",
    linkOut: "/vida/life",
    linkIn: "/ecosistema/vida/life",
    descrip: "LIFE",
    imgDescrip: "/assets/img/ecosistemas/salud/life/img-life.png",
    colorPrincipal: "#009DD6",
    colorSecundario: "#009DD6",
    flatImgConFondo: false,
    tituloOut: "Life: Fitness Pass",
    descripcionOut:
      "Accede a miles de clases fitness a través de tu celular o pc. Encontrarás distintos deportes, programas de nutrición, entre otros.",
    imagenOut: "/assets/img/ecosistemas/modulos/life/img_life_principal.png",
    colorOut: "var(--celeste)",
    textoIn:
      "Accede a miles de clases fitness a través de tu celular o pc. Encontrarás distintos deportes, programas de nutrición, entre otros",
    paginaBtn: "",
    esAgendamiento: false,
  },

  {
    id: 32,
    group: 1,
    shorttitle: "Cursos online con certificación",
    title: "Cursos online con certificación",
    subtitle: "Desarrolla tus habilidades",
    desc: "Disfruta de una variedad de cursos en línea. Estudia el tema de tu interés en el momento que tú quieras de la mano con Coursera.",
    img: "/assets/img/ecosistemas/modulos/coursera/img_coursera_principal.png",
    imgmini:
      "/assets/img/ecosistemas/modulos/coursera/img_coursera_principal.png",
    ico: "/assets/img/ecosistemas/modulos/coursera/courseraVida.png",
    linkOut: "/vida/coursera",
    linkIn: "/ecosistema/vida/coursera",
    descrip: "Cursos online con certificación",
    imgDescrip:
      "/assets/img/ecosistemas/empleabilidad/coursera/img-card-coursera.png",
    colorPrincipal: "#009DD6",
    colorSecundario: "#009DD6",
    flatImgConFondo: false,
    tituloOut: "Cursos Online con Certificación",
    descripcionOut:
      "Con tu Seguro de Tarjeta Protegida accede a beneficios que te permitirán conocer tu salud financiera y capacitarte gratuitamente.",
    imagenOut:
      "/assets/img/ecosistemas/modulos/coursera/img_coursera_principal.png",
    colorOut: "var(--celeste)",
    textoIn:
      "Con tu Seguro accede a beneficios que te permitirán conocer tu salud financiera y capacitarte gratuitamente.",
    paginaBtn: "",
    esAgendamiento: false,
  },

  // CANCER

  {
    id: 33,
    group: 1,
    shorttitle: "Asistencia Psicológica",
    title: "Asistencia Psicológica",
    subtitle: "Asistencia cuando la necesitas",
    desc: "Obtén una asistencia profesional con un especialista para mantener tu salud mental.",
    img: "/assets/img/ecosistemas/modulos/asistenciapsicologica/img_asistenciapsicologica_principal.png",
    imgmini:
      "/assets/img/ecosistemas/modulos/asistenciapsicologica/img_asistenciapsicologica_principal_mini.png",
    ico: "/assets/img/ecosistemas/modulos/asistenciapsicologica/ico_asistenciapsicologica.png",
    linkOut: "/cancer/asistencia-psicologica",
    linkIn: "/ecosistema/cancer/asistencia-psicologica",
    descrip: "Asistencia psicológica",
    imgDescrip:
      "/assets/img/ecosistemas/cancer/psico/img-asistencia-psicologica.png",
    colorPrincipal: "#FF9E80",
    colorSecundario: "#FF9E80",
    flatImgConFondo: false,
    tituloOut: "Asistencia psicológica",
    descripcionOut: "Asistencia cuando la necesitas",
    imagenOut:
      "/assets/img/ecosistemas/cancer/psico/img-asistencia-psicologica.png",
    colorOut: "var(--naranja-claro)",
    textoIn: "Asistencia cuando la necesitas",
    paginaBtn: "",
    esAgendamiento: false,
  },
  {
    id: 34,
    group: 1,
    shorttitle: "Asesoría nutricional",
    title: "Asesoría nutricional",
    subtitle: "Agenda una cita  nutricional",
    desc: "Obtén una asesoría virtual con un especialista en temas de nutrición general.",
    img: "/assets/img/ecosistemas/modulos/asesorianutricional/img_asesorianutricional_principal.png",
    imgmini:
      "/assets/img/ecosistemas/modulos/asesorianutricional/img_asesorianutricional_principal_mini.png",
    ico: "/assets/img/ecosistemas/modulos/asesorianutricional/ico_asesorianutricional_naranja.png",
    linkOut: "/cancer/asesoria-nutricional",
    linkIn: "/ecosistema/cancer/asesoria-nutricional",
    descrip: "Asesoría nutricional",
    imgDescrip:
      "/assets/img/ecosistemas/cancer/asesoria/img-asesoria-nutricional.jpeg",
    colorPrincipal: "#FF9E80",
    colorSecundario: "#FF9E80",
    flatImgConFondo: false,
    tituloOut: "Asesoría nutricional",
    descripcionOut: "Asesoría virtual con un especialista.",
    imagenOut:
      "/assets/img/ecosistemas/modulos/asesorianutricional/img_asesorianutricional_principal.png",
    colorOut: "var(--naranja-claro)",
    textoIn:
      "A través de nuestro servicio virtual, nos aseguramos que puedas tener asesorias virtuales con especialistas, estando en la comodidad de tu hogar.",
    paginaBtn: "",
    esAgendamiento: false,
  },
  {
    id: 35,
    group: 1,
    shorttitle: "Chequeo Preventivo",
    title: "Chequeo Preventivo Anual",
    subtitle: "Una chequeo para proteger tu futuro",
    desc: "Accede a un chequeo especializado para proteger tu futuro.",
    img: "/assets/img/ecosistemas/modulos/chequeopreventivo/img_chequeopreventivo_principal.png",
    imgmini:
      "/assets/img/ecosistemas/modulos/chequeopreventivo/img_chequeopreventivo_principal_mini.png",
    ico: "/assets/img/ecosistemas/modulos/chequeopreventivo/ico_chequeopreventivo.png",
    linkOut: "/cancer/chequeo-preventivo",
    linkIn: "/ecosistema/cancer/chequeo-preventivo",
    descrip: "Chequeo Preventivo Anual",
    imgDescrip:
      "/assets/img/ecosistemas/cancer/chequeo/img-chequeo-preventivo.png",
    colorPrincipal: "#FF9E80",
    colorSecundario: "#FF9E80",
    flatImgConFondo: false,
    tituloOut: "Chequeo Preventivo Anual",
    descripcionOut: "Asistencia cuando la necesitas",
    imagenOut:
      "/assets/img/ecosistemas/cancer/chequeo/img-chequeo-preventivo.png",
    colorOut: "var(--naranja-claro)",
    textoIn:
      "Podrás acceder a un chequeo preventivo anual con la finalidad de identificar factores de riesgo que permite prevenir enfermedades futuras",
    paginaBtn: "",
    esAgendamiento: false,
  },
  {
    id: 36,
    group: 2,
    shorttitle: "Life",
    title: "Life: Fitness Pass",
    subtitle: "App Life",
    desc: "Prueba diferentes experiencias virtuales con los mejores centros y coaches de Latinoamérica.",
    img: "/assets/img/ecosistemas/modulos/life/img_life_principal.png",
    imgmini: "/assets/img/ecosistemas/modulos/life/img_life_principal_mini.png",
    ico: "/assets/img/ecosistemas/modulos/life/fitnessCancer.png",
    linkOut: "/cancer/life",
    linkIn: "/ecosistema/cancer/life",
    descrip: "LIFE",
    imgDescrip: "/assets/img/ecosistemas/salud/life/img-life.png",
    colorPrincipal: "#FF9E80",
    colorSecundario: "#FF9E80",
    flatImgConFondo: false,
    tituloOut: "Life: Fitness Pass",
    descripcionOut:
      "Accede a miles de clases fitness a través de tu celular o pc. Encontrarás distintos deportes, programas de nutrición, entre otros.",
    imagenOut: "/assets/img/ecosistemas/modulos/life/img_life_principal.png",
    colorOut: "var(--naranja-claro)",
    textoIn:
      "Accede a miles de clases fitness a través de tu celular o pc. Encontrarás distintos deportes, programas de nutrición, entre otros",
    paginaBtn: "",
    esAgendamiento: false,
  },

  // ACCIDENTES

  {
    id: 37,
    group: 1,
    shorttitle: "Telemedicina",
    title: "Telemedicina",
    subtitle: "Agenda una cita médica",
    desc: "Conversa con un médico por videollamada y resuelve rápidamente tus consultas acerca de cualquier molestia o enfermedad.",
    img: "/assets/img/ecosistemas/modulos/telemedicina/img_telemedicina_principal.png",
    imgmini:
      "/assets/img/ecosistemas/modulos/telemedicina/img_telemedicina_principal_mini.png",
    ico: "/assets/img/ecosistemas/modulos/telemedicina/ico-telemedicina_ac.png",
    linkOut: "/accidentes/telemedicina",
    linkIn: "/ecosistema/accidentes/telemedicina",
    descrip: "Telemedicina",
    imgDescrip:
      "/assets/img/ecosistemas/accidentes/telemedicina/img-telemedicina.png",
    colorPrincipal: "#7986CB",
    colorSecundario: "#7986CB",
    flatImgConFondo: false,
    tituloOut: "Telemedicina",
    descripcionOut:
      "Podrás consultarle a un médico acerca de una enfermedad o molestia que tengas, siempre que no sea una situación de emergencia. En este se incluye el envío de una receta digital personalizada con la firma y sello del médico.",
    imagenOut:
      "/assets/img/ecosistemas/modulos/telemedicina/img_telemedicina_principal.png",
    colorOut: "var(--morado-claro)",
    textoIn:
      "Podrás consultarle a un médico acerca de una enfermedad o molestia que tengas, siempre que no sea una situación de emergencia. En este se incluye el envío de una receta digital personalizada con la firma y sello del médico.",
    paginaBtn: "",
    esAgendamiento: false,
  },

  //PROTECCION FINANCIERA
  {
    id: 38,
    group: 1,
    shorttitle: "Coursera",
    title: "Coursera",
    subtitle: "Desarrolla tus habilidades",
    desc: "A través de cursos 100% digitales impartidos por las mejores universidades del mundo, podrás potenciar tu perfil profesional y hacerlo más atractivo con certificados de valor curricular, sin costo adicional.",
    img: "/assets/img/ecosistemas/modulos/coursera/img_coursera_principal.png",
    imgmini:
      "/assets/img/ecosistemas/modulos/coursera/img_coursera_principal3.png",
    ico: "/assets/img/ecosistemas/modulos/coursera/Ico_coursera.webp",
    linkOut: "/proteccion-financiera/coursera",
    linkIn: "/ecosistema/proteccion-financiera/coursera",
    descrip: "Cursos online con certificación",
    imgDescrip:
      "/assets/img/ecosistemas/proteccion-financiera/coursera/img-card-coursera.png",
    colorPrincipal: "var(--rojo)",
    colorSecundario: "#7849B8",
    flatImgConFondo: false,
    tituloOut: "Cursos online para potenciar tus conocimientos",
    descripcionOut: "Aprende de la plataforma #1 del mundo.",
    imagenOut: "/assets/img/ecosistemas/proteccion/img-coursera.png",
    colorOut: "#7849B8",
    textoIn:
      "Con tu Seguro accede a beneficios que te permitirán conocer tu salud financiera y capacitarte gratuitamente.",
    paginaBtn: "Curso online - exploración panel",
    detail_eco: "PF",
    detail_service: "COURSERA",
    detail_action: "INGRESAR",
    esAgendamiento: false,
  },

  {
    id: 39,
    group: 1,
    shorttitle: "Protección Online",
    title: "Protección Online",
    subtitle: "Desarrolla tus habilidades",
    desc: "Protege tu vida digital y la de tus seres queridos de los riesgos que existen en internet a través de ciberalarmas enviadas por correo electrónico.",
    img: "/assets/img/ecosistemas/modulos/protecciononline/proteccion_online.png",
    imgmini:
      "/assets/img/ecosistemas/modulos/protecciononline/proteccion_online.png",
    ico: "/assets/img/ecosistemas/modulos/protecciononline/Ico_proteccion.png",
    linkOut: "/proteccion-financiera/proteccion-online",
    linkIn: "/ecosistema/proteccion-financiera/proteccion-online",
    descrip: "Cursos online con certificación",
    imgDescrip:
      "/assets/img/ecosistemas/empleabilidad/coursera/img-card-coursera.png",
    colorPrincipal: "var(--rojo)",
    colorSecundario: "#7849B8",
    flatImgConFondo: false,
    tituloOut: "Cursos online para potenciar tus conocimientos",
    descripcionOut:
      "Protege tu vida digital y la de tus seres queridos de los riesgos que existen en internet a través de ciberalarmas enviadas por correo electrónico.",
    imagenOut: "/assets/img/ecosistemas/proteccion/img-coursera.png",
    colorOut: "#7849B8",
    textoIn:
      "Protege tu vida digital y la de tus seres queridos de los riesgos que existen en internet a través de ciberalarmas enviadas por correo electrónico.",
    paginaBtn: "Ingreso_Portal_Fractalia",
    detail_eco: "PF",
    detail_service: "PROTECCIONONLINE",
    detail_action: "INGRESAR",
    esAgendamiento: false,
  },
  {
    id: 40,
    group: 2,
    shorttitle: "Consultoría en Derecho Laboral",
    title: "Consultoría en Derecho Laboral",
    subtitle: "Estamos para apoyarte. Obtén orientación legal laboral",
    desc: "Queremos acompañarte cuando más nos necesites, recibe asesoría y apoyo permanente de parte de nuestros expertos en Derecho Laboral.",
    img: "/assets/img/ecosistemas/modulos/orientacionlaboral/img_orientacionlaboral_principal.png",
    imgmini:
      "/assets/img/ecosistemas/modulos/orientacionlaboral/img_orientacionlaboral_principal_mini2.png",
    ico: "/assets/img/ecosistemas/modulos/orientacionlaboral/Ico_consultoría.png",
    linkOut: "/proteccion-financiera/legal",
    linkIn: "/ecosistema/proteccion-financiera/legal",
    linkEditAgendamiento:
      "/ecosistema/proteccion-financiera/legal/programar-asesoria",
    descrip: "Orientación legal laboral",
    imgDescrip:
      "/assets/img/ecosistemas/empleabilidad/legal/img-asesoria-digital.png",
    colorPrincipal: "var(--rojo)",
    colorSecundario: "#7849B8",
    flatImgConFondo: false,
    tituloOut: "Orientación legal laboral",
    descripcionOut:
      "Programa una sesión y asesórate por expertos sobre temas legales en el ámbito laboral.",
    imagenOut:
      "/assets/img/ecosistemas/modulos/orientacionlaboral/img_orientacionlaboral_principal.png",
    colorOut: "#7849B8",
    textoIn:
      "Queremos acompañarte cuando más nos necesites, recibe asesoría y apoyo permanente de parte de nuestros expertos en Derecho Laboral.",
    paginaBtn: "Ingreso_Portal_AsesoriaLegal",
    detail_eco: "PF",
    detail_service: "DERECHOLABORAL",
    detail_action: "INGRESAR",
    esAgendamiento: true,
    agendaTipo: 2050,
  },
  {
    id: 41,
    group: 2,
    shorttitle: "Mejora tu CV",
    title: "Mejora tu CV",
    subtitle: "Tu hoja de vida es tu carta de presentación",
    desc: "Tu currículum es tu mejor carta de presentación, sintetiza tu historia académica, laboral y profesional para incrementar tus posibilidades de ser seleccionado para una vacante.",
    img: "/assets/img/ecosistemas/modulos/curriculum/img_curriculum_principal.png",
    imgmini:
      "/assets/img/ecosistemas/modulos/curriculum/img_curriculum_principal_mini2.png",
    ico: "/assets/img/ecosistemas/modulos/curriculum/Ico_Mejora_CV.webp",
    linkOut: "/proteccion-financiera/curriculum",
    linkIn: "/ecosistema/proteccion-financiera/curriculum",
    descrip: "Mejora tu Hoja de vida",
    imgDescrip: "/assets/img/ecosistemas/empleabilidad/cv/img-mejora-tu-cv.png",
    colorPrincipal: "var(--rojo)",
    colorSecundario: "#7849B8",
    flatImgConFondo: false,
    tituloOut: "Mejora tu hoja de vida",
    descripcionOut:
      "Una buena Hoja de vida puede ayudarte a tu próximo objetivo laboral.",
    imagenOut:
      "/assets/img/ecosistemas/modulos/curriculum/img_curriculum_principal.png",
    colorOut: "#7849B8",
    textoIn:
      "Si estás sin trabajo o buscas una mejor oportunidad este servicio es para ti. Con unos clicks busca nuevas oportunidades laborales de forma sencilla.",
    paginaBtn: "Ingreso_Portal_Curriculum",
    detail_eco: "PF",
    detail_service: "MEJORATUCV",
    detail_action: "INGRESAR",
    esAgendamiento: false,
  },
  {
    id: 42,
    group: 2,
    shorttitle: "Optimiza tu marca Personal",
    title: "Optimiza tu marca Personal",
    subtitle: "Obtén la posición laboral que deseas",
    desc: "Con un CV perfecto impresionarás a cualquiera. Reserva una cita con uno de nuestros asesores para que con su ayuda, tu carta de presentación sea excepcional.",
    img: "/assets/img/ecosistemas/modulos/entrevista/img_entrevista_principal.png",
    imgmini:
      "/assets/img/ecosistemas/modulos/entrevista/img_entrevista_principal_mini2.png",
    ico: "/assets/img/ecosistemas/modulos/entrevista/Ico_marca_personal.webp",
    linkOut: "/proteccion-financiera/marca-personal",
    linkIn: "/ecosistema/proteccion-financiera/marca-personal",
    linkEditAgendamiento: "/ecosistema/proteccion-financiera/marca-personal",
    descrip: "Prepárate para tu entrevista",
    imgDescrip:
      "/assets/img/ecosistemas/empleabilidad/entrevista/img-preparate-entrevista.png",
    colorPrincipal: "#7849B8",
    colorSecundario: "#7849B8",
    flatImgConFondo: false,
    tituloOut: "Prepárate para tu entrevista",
    descripcionOut:
      "Una buena preparación puede darte ese trabajo que siempre deseaste.",
    imagenOut:
      "/assets/img/ecosistemas/modulos/entrevista/img_entrevista_principal.png",
    colorOut: "#7849B8",
    textoIn:
      "Una buena preparación puede darte ese trabajo que siempre deseaste.",
    paginaBtn: "Ingreso_Portal_Curriculum",
    detail_eco: "PF",
    detail_service: "MARCAPERSONAL",
    detail_action: "INGRESAR",
    esAgendamiento: true,
    agendaTipo: 2051,
  },
  {
    id: 43,
    group: 1,
    shorttitle: "Alertas Buró",
    title: "Alertas Buró",
    subtitle: "Desarrolla tus habilidades",
    desc: "Con la herramienta de Alertas Buró recibe notificaciones mediante correo electrónico que te avisan sobre movimientos en información crediticia.",
    img: "/assets/img/ecosistemas/modulos/alertasburo/alertas_buro.png",
    imgmini: "/assets/img/ecosistemas/modulos/alertasburo/alertas_buro.png",
    ico: "/assets/img/ecosistemas/modulos/alertasburo/Ico_alertas_buro.png",
    linkOut: "/proteccion-financiera/alertas-buro",
    linkIn: "/ecosistema/proteccion-financiera/alertas-buro",
    descrip: "Cursos online con certificación",
    imgDescrip:
      "/assets/img/ecosistemas/empleabilidad/coursera/img-card-coursera.png",
    colorPrincipal: "var(--rojo)",
    colorSecundario: "#7849B8",
    flatImgConFondo: false,
    tituloOut: "Cursos online para potenciar tus conocimientos",
    descripcionOut:
      "Con la herramienta de Alertas Buró recibe notificaciones mediante correo electrónico que te avisan sobre movimientos en información crediticia.",
    imagenOut: "/assets/img/ecosistemas/proteccion/img-coursera.png",
    colorOut: "#7849B8",
    textoIn:
      "Con la herramienta de Alertas Buró recibe notificaciones mediante correo electrónico que te avisan sobre movimientos en información crediticia.",
    paginaBtn: "Ingreso_Portal_AlertasBuro",
    detail_eco: "PF",
    detail_service: "ALERTASBURO",
    detail_action: "INGRESAR",
    esAgendamiento: false,
  },
  {
    id: 44,
    group: 2,
    shorttitle: "Mide tus aptitudes y habilidades",
    title: "Mide tus aptitudes y habilidades",
    subtitle: "Desarrolla tus habilidades",
    desc: "Entrena con nuestro simulador de pruebas psicométricas para optimizar tus fortalezas y mejorar las áreas que requieren más atención.",
    img: "/assets/img/ecosistemas/empleabilidad/entrevista/cardEntrevista.webp",
    imgmini:
      "/assets/img/ecosistemas/empleabilidad/entrevista/Card_mide_aptitudes.webp",
    ico: "/assets/img/ecosistemas/modulos/entrevista/Ico_habilidades.png",
    linkOut: "/proteccion-financiera/aptitudes-habilidades",
    linkIn: "/ecosistema/proteccion-financiera/aptitudes-habilidades",
    descrip: "Cursos online con certificación",
    imgDescrip:
      "/assets/img/ecosistemas/empleabilidad/coursera/img-card-coursera.png",
    colorPrincipal: "var(--rojo)",
    colorSecundario: "#7849B8",
    flatImgConFondo: false,
    tituloOut: "Mide tus aptitudes y habilidades",
    descripcionOut:
      "Entrena con nuestro simulador de pruebas psicométricas para optimizar tus fortalezas y mejorar las áreas que requieren más atención.",
    imagenOut: "/assets/img/ecosistemas/proteccion/img-coursera.png",
    colorOut: "#7849B8",
    textoIn:
      "Con tu Seguro accede a beneficios que te permitirán conocer tu salud financiera y capacitarte gratuitamente.",
    paginaBtn: "",
    detail_eco: "PF",
    detail_service: "APTITUDES",
    detail_action: "INGRESAR",
    esAgendamiento: false,
  },
  {
    id: 45,
    group: 2,
    shorttitle: "Prepárate para una Entrevista",
    title: "Prepárate para una Entrevista",
    subtitle: "Desarrolla tus habilidades",
    desc: "No le tengas miedo a los procesos de selección, un experto en reclutamiento te ayudará con una simulación de entrevista para trabajar tu confianza.",
    img: "/assets/img/ecosistemas/empleabilidad/entrevista/cardDise.webp",
    imgmini:
      "/assets/img/ecosistemas/empleabilidad/entrevista/Card_prepara_entrevista.webp",
    ico: "/assets/img/ecosistemas/modulos/entrevista/Ico_prepara_entrevistas.png",
    linkOut: "/proteccion-financiera/entrevista",
    linkIn: "/ecosistema/proteccion-financiera/entrevista",
    linkEditAgendamiento: "/ecosistema/proteccion-financiera/entrevista",
    descrip: "Cursos online con certificación",
    imgDescrip:
      "/assets/img/ecosistemas/empleabilidad/coursera/img-card-coursera.png",
    colorPrincipal: "var(--rojo)",
    colorSecundario: "#7849B8",
    flatImgConFondo: false,
    tituloOut: "Prepárate para una Entrevista",
    descripcionOut:
      "No le tengas miedo a los procesos de selección, un experto en reclutamiento te ayudará con una simulación de entrevista para trabajar tu confianza.",
    imagenOut: "/assets/img/ecosistemas/proteccion/img-coursera.png",
    colorOut: "#7849B8",
    textoIn:
      "Con tu Seguro accede a beneficios que te permitirán conocer tu salud financiera y capacitarte gratuitamente.",
    paginaBtn: "Ingreso_Portal Simulación Entrevista",
    detail_eco: "PF",
    detail_service: "PREPARAENTREVISTA",
    detail_action: "INGRESAR",
    esAgendamiento: true,
    agendaTipo: 2052,
  },

  //VidaPlus

  {
    id: 46,
    group: 1,
    shorttitle: "Pre-evaluación médica utilizando inteligencia artificial",
    title: "Pre-evaluación médica utilizando inteligencia artificial",
    subtitle: "",
    desc: "El usuario y su familia podrá ingresar a realizar su Pre-evaluación médica y en cuestión de minutos evaluará sus síntomas por medio de inteligencia artificial.",
    img: "/assets/img/ecosistemas/modulos/preevaluacion-medica/img-preevaluacion.png",
    imgmini:
      "/assets/img/ecosistemas/modulos/preevaluacion-medica/img-preevaluacion.png",
    ico: "/assets/img/ecosistemas/vidaplus/ico_preevaluacion_medica.svg",
    linkOut: "/vidaplus/evaluacion-medica",
    linkIn: "/ecosistema/vidaplus/evaluacion-medica",
    descrip: "Cursos online con certificación",
    imgDescrip:
      "/assets/img/ecosistemas/proteccion-financiera/coursera/img-card-coursera.png",
    colorPrincipal: "var(--rojo)",
    colorSecundario: "#7849B8",
    flatImgConFondo: false,
    tituloOut: "Pre-evaluación médica utilizando inteligencia artificial",
    descripcionOut:
      "El usuario y su familia podrá ingresar a realizar su Pre-evaluación médica y en cuestión de minutos evaluará sus síntomas por medio de inteligencia artificial.",
    imagenOut: "/assets/img/ecosistemas/proteccion/img-coursera.png",
    colorOut: "#7849B8",
    textoIn:
      "El usuario y su familia podrá ingresar a realizar su Pre-evaluación médica y en cuestión de minutos evaluará sus síntomas por medio de inteligencia artificial.",
    paginaBtn: "Ingreso_Portal_UMANO",
    detail_eco: "VP",
    detail_service: "EVALUACIONMEDICA",
    detail_action: "INGRESAR",
    esAgendamiento: false,
  },

  {
    id: 47,
    group: 1,
    shorttitle: "Asistencia médica telefónica",
    title: "Asistencia médica telefónica",
    subtitle: "",
    desc: "Dispuestos a ayudar en cualquier momento. 24 horas los 365 días del año.",
    img: "/assets/img/ecosistemas/modulos/asistenciamedica/img_asistencia_medica_ini.png",
    imgmini:
      "/assets/img/ecosistemas/modulos/asistenciamedica/img-asistencia-medica.png",
    ico: "/assets/img/ecosistemas/vidaplus/ico_asistencia_medica.svg",
    linkOut: "/vidaplus/asistencia-medica",
    linkIn: "/ecosistema/vidaplus/asistencia-medica",
    descrip: "Cursos online con certificación",
    imgDescrip:
      "/assets/img/ecosistemas/empleabilidad/coursera/img-card-coursera.png",
    colorPrincipal: "var(--rojo)",
    colorSecundario: "#7849B8",
    flatImgConFondo: false,
    tituloOut: "Asistencia médica telefónica",
    descripcionOut:
      "Dispuestos a ayudar en cualquier momento. 24 horas los 365 días del año. Asistiéndote desde la palma de tu mano.",
    imagenOut: "/assets/img/ecosistemas/proteccion/img-coursera.png",
    colorOut: "#7849B8",
    textoIn:
      "Dispuestos a ayudar en cualquier momento. 24 horas los 365 días del año. Asistiéndote desde la palma de tu mano.",
    paginaBtn: "Ingreso_Portal_UMANO",
    detail_eco: "VP",
    detail_service: "ASISTENCIAMEDICA",
    detail_action: "INGRESAR",
    esAgendamiento: false,
  },
  {
    id: 48,
    group: 1,
    shorttitle: "Red de servicios de salud",
    title: "Red de servicios de salud",
    subtitle: "",
    desc: "Direccionamiento y uso de red de servicios de salud. El prestador de servicios de Grupo MOK presta este servicio como información o referencia.",
    img: "/assets/img/ecosistemas/modulos/redserviciossalud/img-red-servicios-in.png",
    imgmini:
      "/assets/img/ecosistemas/modulos/redserviciossalud/img-red-servicios.png",
    ico: "/assets/img/ecosistemas/vidaplus/ico-red-servicios-salud.svg",
    linkOut: "/vidaplus/red-salud",
    linkIn: "/ecosistema/vidaplus/red-salud",
    descrip: "Orientación legal laboral",
    imgDescrip:
      "/assets/img/ecosistemas/empleabilidad/legal/img-asesoria-digital.png",
    colorPrincipal: "var(--rojo)",
    colorSecundario: "#7849B8",
    flatImgConFondo: false,
    tituloOut: "Direccionamiento y uso de red de servicios de salud.",
    descripcionOut:
      "En caso de que el Usuario lo requiera, el prestador de servicios de Grupo MOK, le proporcionará información sobre médicos especialistas en las distintas especialidades de la medicina o sobre centros de salud, clínicas, hospitales y laboratorios médicos cercanos al Domicilio del Afiliado o al lugar donde este se encuentre. Asistiéndote desde la palma de tu mano.",
    imagenOut:
      "/assets/img/ecosistemas/modulos/orientacionlaboral/img_orientacionlaboral_principal.png",
    colorOut: "#7849B8",
    textoIn:
      "En caso de que el Usuario lo requiera, el prestador de servicios de Grupo MOK, le proporcionará información sobre médicos especialistas en las distintas especialidades de la medicina o sobre centros de salud, clínicas, hospitales y laboratorios médicos cercanos al Domicilio del Afiliado o al lugar donde este se encuentre.",
    paginaBtn: "Ingreso_Portal_UMANO",
    detail_eco: "VP",
    detail_service: "REDSALUD",
    detail_action: "INGRESAR",
    esAgendamiento: false,
  },
  {
    id: 49,
    group: 1,
    shorttitle: "Coursera",
    title: "Coursera",
    subtitle: "",
    desc: "A través de cursos 100% digitales impartidos por las mejores universidades del mundo, podrás potenciar tu perfil profesional y hacerlo más atractivo con certificados de valor curricular, sin costo adicional.",
    img: "/assets/img/ecosistemas/modulos/coursera/img_coursera_principal.png",
    imgmini:
      "/assets/img/ecosistemas/modulos/coursera/img_coursera_principal3.png",
    ico: "/assets/img/ecosistemas/modulos/coursera/ico_coursera_3.svg",
    linkOut: "/vidaplus/coursera",
    linkIn: "/ecosistema/vidaplus/coursera",
    descrip: "Mejora tu Hoja de vida",
    imgDescrip: "/assets/img/ecosistemas/empleabilidad/cv/img-mejora-tu-cv.png",
    colorPrincipal: "var(--rojo)",
    colorSecundario: "#7849B8",
    flatImgConFondo: false,
    tituloOut:
      "Da el siguiente paso hacia tus metas personales y profesionales con Coursera.",
    descripcionOut:
      "Coursera es una plataforma de educación virtual, que reúne contenidos y clases de muchas Universidades a través del mundo, con el fin de brindar una oferta de educación masiva, con cursos en distintos idiomas. Tu curso hacia el éxito. Adquiere habilidades con los cursos, certificados y títulos en línea que ofrecen las universidades y las empresas más importantes del mundo.",
    imagenOut:
      "/assets/img/ecosistemas/modulos/curriculum/img_curriculum_principal.png",
    colorOut: "#7849B8",
    textoIn:
      "Con tu Seguro accede a beneficios que te permitirán conocer tu salud financiera y capacitarte gratuitamente.",
    paginaBtn: "Curso online - exploración panel",
    detail_eco: "VP",
    detail_service: "COURSERA",
    detail_action: "INGRESAR",
    esAgendamiento: false,
  },

  //Proteccion Financiera Elemental
  {
    id: 50,
    group: 1,
    shorttitle: "Diagnostica tu salud financiera",
    title: "Diagnostica tu salud financiera",
    subtitle: "",
    desc: "Realiza tu diagnóstico personal y recibe algunas recomendaciones para mantener una buena salud financiera.",
    img: "/assets/img/ecosistemas/proteccion-elemental/interna/Interna_salud_financiera_1.png",
    imgmini:
      "/assets/img/ecosistemas/proteccion-elemental/cards/Card_Diagnostico.png",
    ico: "/assets/img/ecosistemas/proteccion-elemental/Ico_diagnostico.svg",
    linkOut: "/mi-salud-financiera/salud",
    linkIn: "/ecosistema/mi-salud-financiera/salud",
    descrip: "Mejora tu Hoja de vida",
    imgDescrip: "/assets/img/ecosistemas/empleabilidad/cv/img-mejora-tu-cv.png",
    colorPrincipal: "var(--rojo)",
    colorSecundario: "#7849B8",
    flatImgConFondo: false,
    tituloOut: "Diagnostica tu salud financiera",
    descripcionOut:
      "Realiza tu diagnóstico personal y recibe algunas recomendaciones para mantener una buena salud financiera personal. Mediante una serie de cuestionarios podrás conocer tu conocimiento en conceptos como: finanzas personales, tu nivel de gasto, ahorro y presupuesto mensual, toma de decisiones para tu mes a mes y al futuro, entre otros tópicos.",
    imagenOut:
      "/assets/img/ecosistemas/modulos/curriculum/img_curriculum_principal.png",
    colorOut: "#7849B8",
    textoIn:
      "Realiza tu diagnóstico personal y recibe algunas recomendaciones para mantener una buena salud financiera personal.\n\nMediante una serie de cuestionarios podrás conocer tu conocimiento en conceptos como: finanzas personales, tu nivel de gasto, ahorro y presupuesto mensual, toma de decisiones para tu mes a mes y al futuro, entre otros tópicos.\n\nPuedes realizar este cuestionario más de una vez, y saber cómo has mejorado tus finanzas personales.",
    paginaBtn: "Ingreso Diagnostica tu salud financiera",
    detail_eco: "SF",
    detail_service: "SALUDFINANCIERA",
    detail_action: "INGRESAR",
    esAgendamiento: false,
  },
  {
    id: 51,
    group: 1,
    shorttitle: "Mi Score de crédito",
    title: "Mi Score de crédito",
    subtitle: "",
    desc: "Visualiza tu puntaje actual conforme a las interacciones que tengas en el sector financiero.",
    img: "/assets/img/ecosistemas/proteccion-elemental/interna/Interna_score_1.png",
    imgmini:
      "/assets/img/ecosistemas/proteccion-elemental/cards/Card_Score.png",
    ico: "/assets/img/ecosistemas/proteccion-elemental/Ico_Score.svg",
    linkOut: "/mi-salud-financiera/score",
    linkIn: "/ecosistema/mi-salud-financiera/score",
    descrip: "Mejora tu Hoja de vida",
    imgDescrip: "/assets/img/ecosistemas/empleabilidad/cv/img-mejora-tu-cv.png",
    colorPrincipal: "var(--rojo)",
    colorSecundario: "#7849B8",
    flatImgConFondo: false,
    tituloOut: "Mi Score de crédito",
    descripcionOut:
      "Consulta tu puntaje actual y recibe recomendaciones para mejorarlo. Al obtener tu score de crédito obtendrás los siguientes beneficios: ",
    imagenOut:
      "/assets/img/ecosistemas/modulos/curriculum/img_curriculum_principal.png",
    colorOut: "#7849B8",
    textoIn:
      "Consulta tu puntaje actual y recibe recomendaciones para mejorarlo. Es importante que consideres que tienes acceso a 4 reportes al año de Mi Score para conocer tu puntuación de comportamiento crediticio.\nAdicional, recibirás la siguiente información:",
    paginaBtn: "Ingreso Mi Score de Crédito",
    detail_eco: "SF",
    detail_service: "SCORECREDITO",
    detail_action: "INGRESAR",
    esAgendamiento: false,
  },
  {
    id: 52,
    group: 2,
    shorttitle: "Servicio de taxi",
    title: "Servicio de taxi",
    subtitle: "",
    desc: "A consecuencia de robo o accidente, trasládate a través de un servicio de taxi seguro.",
    img: "/assets/img/ecosistemas/proteccion-elemental/interna/Interna_taxi.png",
    imgmini: "/assets/img/ecosistemas/proteccion-elemental/cards/Card_taxi.png",
    ico: "/assets/img/ecosistemas/proteccion-elemental/Ico_taxi.svg",
    linkOut: "/mi-salud-financiera/taxi",
    linkIn: "/ecosistema/mi-salud-financiera/taxi",
    descrip: "Mejora tu Hoja de vida",
    imgDescrip: "/assets/img/ecosistemas/empleabilidad/cv/img-mejora-tu-cv.png",
    colorPrincipal: "var(--rojo)",
    colorSecundario: "#7849B8",
    flatImgConFondo: false,
    tituloOut: "Servicio de taxi",
    descripcionOut:
      "A consecuencia de un evento de robo o accidente, y en caso de ser necesario, solicita un servicio de taxi seguro",
    imagenOut:
      "/assets/img/ecosistemas/modulos/curriculum/img_curriculum_principal.png",
    colorOut: "#7849B8",
    textoIn:
      "A consecuencia de un evento de robo o accidente, y en caso de ser necesario, solicita un servicio de taxi seguro.",
    paginaBtn: "Ingreso Servicio de taxi",
    detail_eco: "SF",
    detail_service: "TAXI",
    detail_action: "INGRESAR",
    esAgendamiento: false,
  },
  {
    id: 53,
    group: 1,
    shorttitle: "Asistencia por robo o extravío de documentos y asesoría legal",
    title: "Asistencia por robo o extravío de documentos y asesoría legal",
    subtitle: "",
    desc: "Recibe información sobre qué hacer en caso de robo o extravío de documentación oficial. Además, recibe asesoría de abogados certificados en diversas materias de derecho familiar, civil, mercantil, fiscal, penal, etc.",
    img: "/assets/img/ecosistemas/proteccion-elemental/interna/Interna_asesoria_1.png",
    imgmini:
      "/assets/img/ecosistemas/proteccion-elemental/cards/Card_asesoria.png",
    ico: "/assets/img/ecosistemas/proteccion-elemental/Ico_asesoria.svg",
    linkOut: "/mi-salud-financiera/legal",
    linkIn: "/ecosistema/mi-salud-financiera/legal",
    linkEditAgendamiento:
      "/ecosistema/mi-salud-financiera/legal/programar-asesoria",
    descrip: "Mejora tu Hoja de vida",
    imgDescrip: "/assets/img/ecosistemas/empleabilidad/cv/img-mejora-tu-cv.png",
    colorPrincipal: "var(--rojo)",
    colorSecundario: "#7849B8",
    flatImgConFondo: false,
    tituloOut: "Asistencia por robo o extravío de documento y asesoría legal",
    descripcionOut:
      "Se brindará Asesoría legal de un profesional en derecho, de forma ilimitada siempre y cuando se trate de actos, hechos, servicios o productos que se encuentren debidamente comprendidos en las legislaciones y normativas aplicables a las distintas ramas y materias del derecho.",
    imagenOut:
      "/assets/img/ecosistemas/modulos/curriculum/img_curriculum_principal.png",
    colorOut: "#7849B8",
    textoIn:
      "Se brindará Asesoría legal de un profesional en derecho, de forma ilimitada siempre y cuando se trate de actos, hechos, servicios o productos que se encuentren debidamente comprendidos en las legislaciones y normativas aplicables a las distintas ramas y materias del derecho.",
    paginaBtn:
      "Ingreso Asistencia por robo o extravío de documentos y asesoría legal",
    detail_eco: "SF",
    detail_service: "ASISTENCIADOCUMENTOS",
    detail_action: "INGRESAR",
    esAgendamiento: true,
    agendaTipo: 2053,
  },
  {
    id: 54,
    group: 2,
    shorttitle: "Seguridad informática",
    title: "Seguridad informática",
    subtitle: "",
    desc: "Recibe asistencia y asesoría telefónica y/o virtual para resolver dudas derivadas de sistemas.",
    img: "/assets/img/ecosistemas/proteccion-elemental/interna/Interna_Seg_info_1.png",
    imgmini:
      "/assets/img/ecosistemas/proteccion-elemental/cards/Card_Seguridad_Info.png",
    ico: "/assets/img/ecosistemas/proteccion-elemental/Ico_seg_info.svg",
    linkOut: "/mi-salud-financiera/seguridad",
    linkIn: "/ecosistema/mi-salud-financiera/seguridad",
    linkEditAgendamiento:
      "/ecosistema/mi-salud-financiera/seguridad/programar-asesoria",
    descrip: "Mejora tu Hoja de vida",
    imgDescrip: "/assets/img/ecosistemas/empleabilidad/cv/img-mejora-tu-cv.png",
    colorPrincipal: "var(--rojo)",
    colorSecundario: "#7849B8",
    flatImgConFondo: false,
    tituloOut: "Seguridad Informática",
    descripcionOut:
      "Recibe asistencia y asesoría telefónica y/o virtual de resolver dudas derivadas de sistemas computacionales, aplicaciones, hardware, entre otros.",
    imagenOut:
      "/assets/img/ecosistemas/modulos/curriculum/img_curriculum_principal.png",
    colorOut: "#7849B8",
    textoIn:
      "Recibe asistencia y asesoría telefónica y/o virtual de resolver dudas derivadas de sistemas computacionales, aplicaciones, hardware, entre otros.",
    paginaBtn: "Ingreso Seguridad Informática",
    detail_eco: "SF",
    detail_service: "SEGURIDADINFORMATICA",
    detail_action: "INGRESAR",
    esAgendamiento: true,
    agendaTipo: 2054,
  },

  //Servicios extras Proteccion Financiera
  {
    id: 55,
    group: 2,
    shorttitle: "Bolsa de trabajo",
    title: "Ofertas disponibles en México",
    subtitle: "Expande tus horizontes laborales",
    desc: "Más de 40,000 ofertas laborales vigentes con las mejores empresas de México diferenciadas por industria. Busca la opción que más se ajusta a tu perfil.",
    img: "/assets/img/ecosistemas/modulos/ofertas/img_ofertas_MX_1.jpg",
    imgmini:
      "/assets/img/ecosistemas/modulos/ofertas/img_ofertas_principal_mini2.png",
    ico: "/assets/img/ecosistemas/modulos/ofertas/Ico_ofertas_MX.png",
    linkOut: "/proteccion-financiera/ofertas-laborales",
    linkIn: "/ecosistema/proteccion-financiera/ofertas-laborales",
    descrip: "Conoce ofertas laborales",
    imgDescrip: "/assets/img/ecosistemas/empleabilidad/ofertas/img-ofertas.png",
    colorPrincipal: "var(--rojo)",
    colorSecundario: "#7849B8",
    flatImgConFondo: false,
    tituloOut: "Conoce ofertas laborales",
    descripcionOut: "Expande tus horizontes laborales.",
    imagenOut:
      "/assets/img/ecosistemas/modulos/ofertas/img_ofertas_principal.png",
    colorOut: "#7849B8",
    textoIn:
      "Si estás sin trabajo o buscas una mejor oportunidad este servicio es para ti. Con unos clicks busca nuevas oportunidades laborales de forma sencilla.",
    paginaBtn: "Ingreso_Portal_Bolsa Trabajo",
    detail_eco: "PF",
    detail_service: "OFERTASLABORALES",
    detail_action: "INGRESAR",
    esAgendamiento: false,
  },
  {
    id: 56,
    group: 2,
    shorttitle: "Compara tu salario",
    title: "Conoce nuestro Reporte Salarial",
    subtitle: "Compara tu salario de acuerdo al mercado",
    desc: "Compara tu salario actual y la oferta de remuneración en el mercado laboral, a través de una estudio basado en datos de industria, cargo, carrera, experiencia, entre otros.",
    img: "/assets/img/ecosistemas/modulos/salario/img_reporte_1.jpg",
    imgmini:
      "/assets/img/ecosistemas/modulos/salario/img_salario_principal_mini2.png",
    ico: "/assets/img/ecosistemas/modulos/salario/Ico_reporte_salarial.png",
    linkOut: "/proteccion-financiera/reporte-salarial",
    linkIn: "/ecosistema/proteccion-financiera/reporte-salarial",
    descrip: "Compara tu salario",
    imgDescrip: "/assets/img/ecosistemas/empleabilidad/salario/img-salario.png",
    colorPrincipal: "var(--rojo)",
    colorSecundario: "#7849B8",
    flatImgConFondo: false,
    tituloOut: "Compara tu salario",
    descripcionOut: "Conoce cómo te encuentras con relación al mercado.",
    imagenOut:
      "/assets/img/ecosistemas/modulos/salario/img_salario_principal.png",
    colorOut: "#7849B8",
    textoIn:
      "Conoce tu puntaje financiero y aprende cómo mejorar tu perfil crediticio, gracias a tu Seguro Protección de Ingresos podrás saber con exactitud, cuál es tu actual puntaje en materia crediticia y como mejorar tu perfil para solicitudes futuras.",
    paginaBtn: "Ingreso_Portal_ComparaSalario",
    detail_eco: "PF",
    detail_service: "REPORTESALARIAL",
    detail_action: "INGRESAR",
    esAgendamiento: false,
  },
  {
    id: 57,
    group: 2,
    shorttitle: "Mide tus aptitudes y habilidades",
    title: "Mide tus aptitudes y habilidades",
    subtitle: "Desarrolla tus habilidades",
    desc: "Entrena con nuestro simulador de pruebas psicométricas para optimizar tus fortalezas y mejorar las áreas que requieren más atención.",
    img: "/assets/img/ecosistemas/empleabilidad/entrevista/cardEntrevista.webp",
    imgmini:
      "/assets/img/ecosistemas/empleabilidad/entrevista/Card_mide_aptitudes.webp",
    ico: "/assets/img/ecosistemas/modulos/entrevista/Ico_habilidades.png",
    linkOut: "/empleabilidad/habilidades",
    linkIn: "/ecosistema/empleabilidad/habilidades",
    descrip: "Cursos online con certificación",
    imgDescrip:
      "/assets/img/ecosistemas/empleabilidad/coursera/img-card-coursera.png",
    colorPrincipal: "var(--rojo)",
    colorSecundario: "#7849B8",
    flatImgConFondo: false,
    tituloOut: "Mide tus aptitudes y habilidades",
    descripcionOut:
      "Entrena con nuestro simulador de pruebas psicométricas para optimizar tus fortalezas y mejorar las áreas que requieren más atención.",
    imagenOut: "/assets/img/ecosistemas/proteccion/img-coursera.png",
    colorOut: "#7849B8",
    textoIn:
      "Con tu Seguro accede a beneficios que te permitirán conocer tu salud financiera y capacitarte gratuitamente.",
    paginaBtn: "",
    detail_eco: "EL",
    detail_service: "APTITUDES",
    detail_action: "INGRESAR",
    esAgendamiento: false,
  },
  {
    id: 58,
    group: 2,
    shorttitle: "Prepárate para una Entrevista",
    title: "Prepárate para una Entrevista",
    subtitle: "Desarrolla tus habilidades",
    desc: "No le tengas miedo a los procesos de selección, un experto en reclutamiento te ayudará con una simulación de entrevista para trabajar tu confianza.",
    img: "/assets/img/ecosistemas/empleabilidad/entrevista/cardDise.webp",
    imgmini:
      "/assets/img/ecosistemas/empleabilidad/entrevista/Card_prepara_entrevista.webp",
    ico: "/assets/img/ecosistemas/modulos/entrevista/Ico_prepara_entrevistas.png",
    linkOut: "/empleabilidad/entrevista",
    linkIn: "/ecosistema/empleabilidad/entrevista",
    linkEditAgendamiento: "/ecosistema/empleabilidad/entrevista",
    descrip: "Cursos online con certificación",
    imgDescrip:
      "/assets/img/ecosistemas/empleabilidad/coursera/img-card-coursera.png",
    colorPrincipal: "var(--rojo)",
    colorSecundario: "#7849B8",
    flatImgConFondo: false,
    tituloOut: "Prepárate para una Entrevista",
    descripcionOut:
      "No le tengas miedo a los procesos de selección, un experto en reclutamiento te ayudará con una simulación de entrevista para trabajar tu confianza.",
    imagenOut: "/assets/img/ecosistemas/proteccion/img-coursera.png",
    colorOut: "#7849B8",
    textoIn:
      "Con tu Seguro accede a beneficios que te permitirán conocer tu salud financiera y capacitarte gratuitamente.",
    paginaBtn: "Ingreso_Portal Simulación Entrevista",
    detail_eco: "EL",
    detail_service: "PREPARAENTREVISTA",
    detail_action: "INGRESAR",
    esAgendamiento: true,
    agendaTipo: 2024,
  },

  //Ecosistema Coursera
  {
    id: 59,
    group: 1,
    shorttitle: "Eco coursera",
    title: "Eco Coursera",
    subtitle: "",
    desc: "A través de cursos 100% digitales impartidos por las mejores universidades del mundo, podrás potenciar tu perfil profesional y hacerlo más atractivo con certificados de valor curricular, sin costo adicional.",
    img: "/assets/img/ecosistemas/empleabilidad/entrevista/cardDise.webp",
    imgmini:
      "/assets/img/ecosistemas/modulos/coursera/img_coursera_principal3.png",
    ico: "/assets/img/ecosistemas/modulos/coursera/ico_coursera2.webp",
    linkOut: "/empleabilidad/coursera",
    linkIn: "/ecosistema/coursera",
    descrip: "Cursos online con certificación",
    imgDescrip:
      "/assets/img/ecosistemas/empleabilidad/coursera/img-card-coursera.png",
    colorPrincipal: "var(--rojo)",
    colorSecundario: "#7849B8",
    flatImgConFondo: false,
    tituloOut: "Prepárate para una Entrevista",
    descripcionOut:
      "No le tengas miedo a los procesos de selección, un experto en reclutamiento te ayudará con una simulación de entrevista para trabajar tu confianza.",
    imagenOut: "/assets/img/ecosistemas/proteccion/img-coursera.png",
    colorOut: "#7849B8",
    textoIn:
      "Con tu Seguro accede a beneficios que te permitirán conocer tu salud financiera y capacitarte gratuitamente.",
    paginaBtn: "Ingreso_Coursera",
    detail_eco: "EC",
    detail_service: "COURSERA",
    detail_action: "VERCURSOS",
    esAgendamiento: false,
  },

  //Salud
  {
    id: 60,
    group: 1,
    shorttitle: "Check-up anual",
    title: "Check-up anual",
    subtitle: "",
    desc: "Programa tu Check-up y conoce tu estado de salud.",
    img: "/assets/img/ecosistemas/salud/img-checkup-anual.png",
    imgmini: "/assets/img/ecosistemas/salud/img-checkup-anual.png",
    ico: "/assets/img/ecosistemas/salud/ico-checkup-anual.svg",
    linkOut: "/salud/checkup-anual",
    linkIn: "/ecosistema/salud/checkup-anual",
    linkEditAgendamiento: "/ecosistema/salud/checkup-anual",
    descrip: "Programa tu Check-up y conoce tu estado de salud.",
    imgDescrip: "/assets/img/ecosistemas/salud/img-checkup-anual.png",
    colorPrincipal: "var(--rojo)",
    colorSecundario: "#7849B8",
    flatImgConFondo: false,
    tituloOut: "Check-up anual",
    descripcionOut: "Programa tu Check-up y conoce tu estado de salud.",
    imagenOut: "/assets/img/ecosistemas/salud/img-checkup-anual.png",
    colorOut: "#7849B8",
    textoIn: "Programa tu Check-up y conoce tu estado de salud.",
    paginaBtn: "",
    detail_eco: "SA",
    detail_service: "CHECKUP",
    detail_action: "INGRESAR",
    esAgendamiento: true,
    agendaTipo: 2055,
  },

  {
    id: 61,
    group: 1,
    shorttitle: "Tu médico en casa",
    title: "Tu médico en casa",
    subtitle: "",
    desc: "En caso de alguna emergencia, solicita un médico en casa.  <br/>  <br/> Disponible 24/7",
    img: "/assets/img/ecosistemas/salud/img-tu-medico-en-casa.png",
    imgmini: "/assets/img/ecosistemas/salud/img-tu-medico-en-casa.png",
    ico: "/assets/img/ecosistemas/salud/ico-tu-medico-en-casa.svg",
    linkOut: "/salud/tu-medico-en-casa",
    linkIn: "/ecosistema/salud/tu-medico-en-casa",
    descrip: "En caso de alguna emergencia médica, solicita un médico en casa.",
    imgDescrip: "/assets/img/ecosistemas/salud/img-tu-medico-en-casa.png",
    colorPrincipal: "var(--rojo)",
    colorSecundario: "#7849B8",
    flatImgConFondo: false,
    tituloOut: "Tu médico en casa",
    descripcionOut:
      "En caso de alguna emergencia médica, solicita un médico en casa. <br/> Disponible 24/7",
    imagenOut: "/assets/img/ecosistemas/salud/img-tu-medico-en-casa.png",
    colorOut: "#7849B8",
    textoIn: "En caso de alguna emergencia médica, solicita un médico en casa.",
    paginaBtn: "",
    nro: "+525594178790",
    detail_eco: "SA",
    detail_service: "MEDICOENCASA",
    detail_action: "LLAMAR",
    esAgendamiento: false,
  },
  {
    id: 62,
    group: 1,
    shorttitle: "Medicina online",
    title: "Medicina online",
    subtitle: "",
    desc: "Accede a nuestros servicios para el cuidado de tu salud sin salir de tu casa.",
    img: "/assets/img/ecosistemas/salud/img-medicina-online.png",
    imgmini: "/assets/img/ecosistemas/salud/img-medicina-online.png",
    ico: "/assets/img/ecosistemas/salud/ico-medicina-online.svg",
    linkOut: "/salud/medicina-online",
    linkIn: "/ecosistema/salud/medicina-online",
    descrip:
      "Accede a nuestros servicios para el cuidado de tu salud sin salir de tu casa.",
    imgDescrip: "/assets/img/ecosistemas/salud/img-medicina-online.png",
    colorPrincipal: "var(--rojo)",
    colorSecundario: "#7849B8",
    flatImgConFondo: false,
    tituloOut: "Medicina online",
    descripcionOut:
      "Accede a nuestros servicios para el cuidado de tu salud sin salir de tu casa.",
    imagenOut: "/assets/img/ecosistemas/salud/img-medicina-online.png",
    colorOut: "#7849B8",
    textoIn:
      "Accede a nuestros servicios para el cuidado de tu salud sin salir de tu casa.",
    paginaBtn: "",
    detail_eco: "SA",
    detail_service: "MEDICINAONLINE",
    detail_action: "INGRESAR",
    esAgendamiento: false,
  },
  {
    id: 63,
    group: 1,
    shorttitle: "Reembolso por medicamentos",
    title: "Reembolso por medicamentos",
    subtitle: "",
    desc: "Solicita tu reembolso por hasta $500 pesos al año en medicamentos genéricos. <br/> Es importante que cuentes con tu receta médica con una antigüedad no mayor a 10 días hábiles.    ",
    img: "/assets/img/ecosistemas/salud/img-farmacia-digital.png",
    imgmini: "/assets/img/ecosistemas/salud/img-farmacia-digital.png",
    ico: "/assets/img/ecosistemas/salud/ico-farmacia-digital.svg",
    linkOut: "/salud/farmacia-digital",
    linkIn: "/ecosistema/salud/farmacia-digital",
    descrip:
      "Solicita tu reembolso por hasta $500 pesos al año en medicamentos genéricos.  Es importante que cuentes con tu receta médica con una antigüedad no mayor a 10 días hábiles.",
    imgDescrip: "/assets/img/ecosistemas/salud/img-farmacia-digital.png",
    colorPrincipal: "var(--rojo)",
    colorSecundario: "#7849B8",
    flatImgConFondo: false,
    tituloOut: "Reembolso  <br/> por medicamentos",
    descripcionOut:
      "Solicita tu reembolso por hasta $500 pesos al año en medicamentos genéricos.  <br/> Es importante que cuentes con tu receta médica con una antigüedad no mayor a 10 días hábiles.      ",
    imagenOut: "/assets/img/ecosistemas/salud/img-farmacia-digital.png",
    colorOut: "#7849B8",
    textoIn:
      "Solicita tu reembolso por hasta $500 pesos al año en medicamentos genéricos.  Es importante que cuentes con tu receta médica con una antigüedad no mayor a 10 días hábiles.",
    paginaBtn: "",
    nro: "+525594178790",
    detail_eco: "SA",
    detail_service: "FARMACIADIGITAL",
    detail_action: "LLAMAR",
    esAgendamiento: false,
  },

  //Ecosistema Vida
  {
    id: 64,
    group: 1,
    shorttitle: "Coursera",
    title: "Coursera",
    subtitle: "",
    desc: "Desarrolla nuevas habilidades profesionales a través de cursos 100% digitales impartidas por las mejores universidades del mundo.",
    img: "/assets/img/ecosistemas/empleabilidad/entrevista/cardDise.webp",
    imgmini:
      "/assets/img/ecosistemas/modulos/coursera/img_coursera_principal3.png",
    ico: "/assets/img/ecosistemas/modulos/coursera/ico_coursera2.webp",
    linkOut: "/empleabilidad/entrevista",
    linkIn: "/ecosistema/empleabilidad/entrevista",
    descrip: "Cursos online con certificación",
    imgDescrip:
      "/assets/img/ecosistemas/empleabilidad/coursera/img-card-coursera.png",
    colorPrincipal: "var(--rojo)",
    colorSecundario: "#7849B8",
    flatImgConFondo: false,
    tituloOut: "Prepárate para una Entrevista",
    descripcionOut:
      "Desarrolla nuevas habilidades profesionales a través de cursos 100% digitales impartidas por las mejores universidades del mundo.",
    imagenOut: "/assets/img/ecosistemas/proteccion/img-coursera.png",
    colorOut: "#7849B8",
    textoIn:
      "Con tu Seguro accede a beneficios que te permitirán conocer tu salud financiera y capacitarte gratuitamente.",
    paginaBtn: "Ingreso_Coursera",
    detail_eco: "VI",
    detail_service: "COURSERA",
    detail_action: "INGRESAR",
    esAgendamiento: false,
  },
  {
    id: 65,
    group: 1,
    shorttitle: "Bienestar Integral",
    title: "Bienestar Integral",
    subtitle: "",
    desc: "Accede a los mejores gimnasios, centros de baile, yoga, nutriólogos, psicólogos, personal trainer y más. ¡Todo lo que necesitas para mantenerte saludable!",
    img: "/assets/img/ecosistemas/vida/bienestar/BienestarIn.png",
    imgmini: "/assets/img/ecosistemas/vida/atencionBienestar.webp",
    ico: "/assets/img/ecosistemas/modulos/vida/bienestar.webp",
    linkOut: "/vida/bienestar-integral",
    linkIn: "/ecosistema/vida/bienestar-integral",
    descrip: "Cursos online con certificación",
    imgDescrip:
      "/assets/img/ecosistemas/empleabilidad/coursera/img-card-coursera.png",
    colorPrincipal: "var(--verde)",
    colorSecundario: "#7849B8",
    flatImgConFondo: false,
    tituloOut: "Prepárate para una Entrevista",
    descripcionOut:
      "Accede gratis a miles de actividades fitness y de bienestar, presencial y online para lograr tu bienestar integral.",
    imagenOut: "/assets/img/ecosistemas/proteccion/img-coursera.png",
    colorOut: "#7849B8",
    textoIn:
      "<b>Conoce Holos</b> \n\nTu Seguro de Vida incluye Holos, <b>la plataforma online de bienestar integral</b> más completa y flexible con la que podrás tener acceso a una membresía a base de créditos con los que podrás acceder a <b>sesiones con especialistas, centros fitness</b> o de bienestar, <b>recursos digitales</b> y más actividades.",
    paginaBtn: "",
    detail_eco: "VI",
    detail_service: "BIENESTARINTEGRAL",
    detail_action: "INGRESAR",
    esAgendamiento: false,
  },
  {
    id: 66,
    group: 1,
    shorttitle: "Atención médica inmediata",
    title: "Atención médica inmediata",
    subtitle: "",
    desc: "Recibe atención médica virtual, ante cualquier molestia o enfermedad.",
    img: "/assets/img/ecosistemas/empleabilidad/entrevista/cardDise.webp",
    imgmini: "/assets/img/ecosistemas/vida/atencionMedica.webp",
    ico: "/assets/img/ecosistemas/modulos/vida/medica.webp",
    linkOut: "/vida/atencion-medica",
    linkIn: "/ecosistema/vida/atencion-medica",
    descrip: "Cursos online con certificación",
    imgDescrip:
      "/assets/img/ecosistemas/empleabilidad/coursera/img-card-coursera.png",
    colorPrincipal: "var(--rojo)",
    colorSecundario: "#7849B8",
    flatImgConFondo: false,
    tituloOut: "Prepárate para una Entrevista",
    descripcionOut:
      "Recibe atención médica virtual, ante cualquier molestia o enfermedad.",
    imagenOut: "/assets/img/ecosistemas/proteccion/img-coursera.png",
    colorOut: "#7849B8",
    textoIn:
      "Con tu Seguro accede a beneficios que te permitirán conocer tu salud financiera y capacitarte gratuitamente.",
    paginaBtn: "",
    detail_eco: "VI",
    detail_service: "ASISTENCIAINMEDIATA",
    detail_action: "INGRESAR",
    esAgendamiento: false,
  },

  //Ecosistema CUIDADOS (PLAN 1, 3, 5, 7, 9, 11)
  {
    id: 67,
    group: 1,
    shorttitle: "Orientación Médica",
    title: "Orientación Médica",
    subtitle: "",
    desc: "Recibe orientación médica telefónica de parte de uno de nuestros médicos generales. Cuentas con este servicio 24/7.",
    img: "/assets/img/ecosistemas/empleabilidad/entrevista/cardDise.webp",
    imgmini:
      "/assets/img/ecosistemas/cuidados/cards/img-orientacion-medica.webp",
    ico: "/assets/img/ecosistemas/cuidados/ico-orientacion-medica.svg",
    linkOut: "/vida/atencion-medica",
    linkIn: "/ecosistema/cuidados/orientacion-medica",
    descrip: "Cursos online con certificación",
    imgDescrip:
      "/assets/img/ecosistemas/empleabilidad/coursera/img-card-coursera.png",
    colorPrincipal: "var(--rojo)",
    colorSecundario: "#7849B8",
    flatImgConFondo: false,
    tituloOut: "Prepárate para una Entrevista",
    descripcionOut:
      "Recibe orientación médica telefónica de parte de uno de nuestros médicos generales. Cuentas con este servicio 24/7.",
    imagenOut: "/assets/img/ecosistemas/proteccion/img-coursera.png",
    colorOut: "#7849B8",
    textoIn:
      "Con tu Seguro accede a beneficios que te permitirán conocer tu salud financiera y capacitarte gratuitamente.",
    paginaBtn: "",
    detail_eco: "CU",
    detail_service: "ORIENTACIONMEDICA",
    detail_action: "INGRESAR",
    esAgendamiento: false,
  },
  {
    id: 68,
    group: 1,
    shorttitle: "Orientación Psicológica",
    title: "Orientación Psicológica",
    subtitle: "",
    desc: "Un profesional de salud te brindará orientación psicológica para ayudarte a superar momentos de crisis y recibir apoyo.",
    img: "/assets/img/ecosistemas/empleabilidad/entrevista/cardDise.webp",
    imgmini:
      "/assets/img/ecosistemas/cuidados/cards/img-orientacion-psicologica.webp",
    ico: "/assets/img/ecosistemas/cuidados/ico-orientacion-psicologica.svg",
    linkOut: "/vida/atencion-medica",
    linkIn: "/ecosistema/cuidados/orientacion-psicologica",
    linkEditAgendamiento: "/ecosistema/cuidados/orientacion-psicologica",
    descrip: "Cursos online con certificación",
    imgDescrip:
      "/assets/img/ecosistemas/empleabilidad/coursera/img-card-coursera.png",
    colorPrincipal: "var(--rojo)",
    colorSecundario: "#7849B8",
    flatImgConFondo: false,
    tituloOut: "Prepárate para una Entrevista",
    descripcionOut:
      "Un profesional de salud te brindará orientación psicológica para ayudarte a superar momentos de crisis y recibir apoyo.",
    imagenOut: "/assets/img/ecosistemas/proteccion/img-coursera.png",
    colorOut: "#7849B8",
    textoIn:
      "Con tu Seguro accede a beneficios que te permitirán conocer tu salud financiera y capacitarte gratuitamente.",
    paginaBtn: "",
    detail_eco: "CU",
    detail_service: "ORIENTACIONPSICOLOGICA",
    detail_action: "INGRESAR",
    esAgendamiento: true,
    agendaTipo: 2060,
  },
  {
    id: 69,
    group: 1,
    shorttitle: "Orientación Nutricional",
    title: "Orientación Nutricional",
    subtitle: "",
    desc: "Un profesional de la salud brindará orientación para mejorar hábitos nutricionales. Agenda tu cita ahora.",
    img: "/assets/img/ecosistemas/empleabilidad/entrevista/cardDise.webp",
    imgmini:
      "/assets/img/ecosistemas/cuidados/cards/img-orientacion-nutricional.webp",
    ico: "/assets/img/ecosistemas/cuidados/ico-orientacion-nutricional.svg",
    linkOut: "/vida/atencion-medica",
    linkIn: "/ecosistema/cuidados/orientacion-nutricional",
    linkEditAgendamiento: "/ecosistema/cuidados/orientacion-nutricional",
    descrip: "Cursos online con certificación",
    imgDescrip:
      "/assets/img/ecosistemas/empleabilidad/coursera/img-card-coursera.png",
    colorPrincipal: "var(--rojo)",
    colorSecundario: "#7849B8",
    flatImgConFondo: false,
    tituloOut: "Prepárate para una Entrevista",
    descripcionOut:
      "Un profesional de la salud brindará orientación para mejorar hábitos nutricionales. Agenda tu cita ahora.",
    imagenOut: "/assets/img/ecosistemas/proteccion/img-coursera.png",
    colorOut: "#7849B8",
    textoIn:
      "Con tu Seguro accede a beneficios que te permitirán conocer tu salud financiera y capacitarte gratuitamente.",
    paginaBtn: "",
    detail_eco: "CU",
    detail_service: "ORIENTACIONNUTRICIONAL",
    detail_action: "INGRESAR",
    esAgendamiento: true,
    agendaTipo: 2061,
  },
  {
    id: 70,
    group: 2,
    shorttitle: "Análisis clínicos",
    title: "Análisis clínicos",
    subtitle: "",
    desc: "Programa tu Check-up y conoce tu estado de salud.",
    img: "/assets/img/ecosistemas/empleabilidad/entrevista/cardDise.webp",
    imgmini:
      "/assets/img/ecosistemas/cuidados/cards/img-analisis-clinicos.webp",
    ico: "/assets/img/ecosistemas/cuidados/ico-analisis-clinicos.svg",
    linkOut: "/vida/atencion-medica",
    linkIn: "/ecosistema/cuidados/analisis-clinicos",
    linkEditAgendamiento: "/ecosistema/cuidados/analisis-clinicos",
    descrip: "Cursos online con certificación",
    imgDescrip:
      "/assets/img/ecosistemas/empleabilidad/coursera/img-card-coursera.png",
    colorPrincipal: "var(--rojo)",
    colorSecundario: "#7849B8",
    flatImgConFondo: false,
    tituloOut: "Prepárate para una Entrevista",
    descripcionOut: "Programa tu Check-up y conoce tu estado de salud.",
    imagenOut: "/assets/img/ecosistemas/proteccion/img-coursera.png",
    colorOut: "#7849B8",
    textoIn:
      "Con tu Seguro accede a beneficios que te permitirán conocer tu salud financiera y capacitarte gratuitamente.",
    paginaBtn: "",
    detail_eco: "CU",
    detail_service: "ANALISISCLINICOS",
    detail_action: "INGRESAR",
    esAgendamiento: true,
    agendaTipo: 2062,
  },
  {
    id: 71,
    group: 2,
    shorttitle: "Ambulancia terrestre",
    title: "Ambulancia terrestre",
    subtitle: "",
    desc: "Solicita el envío de ambulancia terrestre y durante la llamada te proporcionaremos información del servicio.",
    img: "/assets/img/ecosistemas/empleabilidad/entrevista/cardDise.webp",
    imgmini:
      "/assets/img/ecosistemas/cuidados/cards/img-ambulancia-terrestre.webp",
    ico: "/assets/img/ecosistemas/cuidados/ico-ambulancia-terrestre.svg",
    linkOut: "/vida/atencion-medica",
    linkIn: "/ecosistema/cuidados/orientacion-medica",
    descrip: "Cursos online con certificación",
    imgDescrip:
      "/assets/img/ecosistemas/empleabilidad/coursera/img-card-coursera.png",
    colorPrincipal: "var(--rojo)",
    colorSecundario: "#7849B8",
    flatImgConFondo: false,
    tituloOut: "Prepárate para una Entrevista",
    descripcionOut:
      "Solicita el envío de ambulancia terrestre y durante la llamada te proporcionaremos información del servicio.",
    imagenOut: "/assets/img/ecosistemas/proteccion/img-coursera.png",
    colorOut: "#7849B8",
    textoIn:
      "Con tu Seguro accede a beneficios que te permitirán conocer tu salud financiera y capacitarte gratuitamente.",
    paginaBtn: "",
    detail_eco: "CU",
    detail_service: "AMBULANCIATERRESTRE",
    detail_action: "INGRESAR",
    esAgendamiento: false,
  },
  {
    id: 72,
    group: 2,
    shorttitle: "Health coach",
    title: "Health coach",
    subtitle: "",
    desc: "Contacta con un Health coach  que te brindará asesoría y acompañamiento para que contactes con especialistas según tus necesidades y presupuestos.",
    img: "/assets/img/ecosistemas/empleabilidad/entrevista/cardDise.webp",
    imgmini: "/assets/img/ecosistemas/cuidados/cards/img-health-coach.webp",
    ico: "/assets/img/ecosistemas/cuidados/ico-health-coach.svg",
    linkOut: "/vida/atencion-medica",
    linkIn: "/ecosistema/cuidados/health-coach",
    linkEditAgendamiento: "/ecosistema/cuidados/health-coach",
    descrip: "Cursos online con certificación",
    imgDescrip:
      "/assets/img/ecosistemas/empleabilidad/coursera/img-card-coursera.png",
    colorPrincipal: "var(--rojo)",
    colorSecundario: "#7849B8",
    flatImgConFondo: false,
    tituloOut: "Prepárate para una Entrevista",
    descripcionOut:
      "Contacta con un Health coach  que te brindará asesoría y acompañamiento para que contactes con especialistas según tus necesidades y presupuestos.",
    imagenOut: "/assets/img/ecosistemas/proteccion/img-coursera.png",
    colorOut: "#7849B8",
    textoIn:
      "Con tu Seguro accede a beneficios que te permitirán conocer tu salud financiera y capacitarte gratuitamente.",
    paginaBtn: "",
    detail_eco: "CU",
    detail_service: "HEALTHCOACH",
    detail_action: "INGRESAR",
    esAgendamiento: true,
    agendaTipo: 2063,
  },
  {
    id: 73,
    group: 2,
    shorttitle: "Referencias de servicios médicos",
    title: "Referencias de servicios médicos",
    subtitle: "",
    desc: "Solicita a través del Concierge Médico, referencias con base en su red médica y hospitalaria.",
    img: "/assets/img/ecosistemas/empleabilidad/entrevista/cardDise.webp",
    imgmini:
      "/assets/img/ecosistemas/cuidados/cards/img-servicios-medicos.webp",
    ico: "/assets/img/ecosistemas/cuidados/ico-servicios-medicos.svg",
    linkOut: "/vida/atencion-medica",
    linkIn: "/ecosistema/cuidados/orientacion-medica",
    descrip: "Cursos online con certificación",
    imgDescrip:
      "/assets/img/ecosistemas/empleabilidad/coursera/img-card-coursera.png",
    colorPrincipal: "var(--rojo)",
    colorSecundario: "#7849B8",
    flatImgConFondo: false,
    tituloOut: "Prepárate para una Entrevista",
    descripcionOut:
      "Solicita a través del Concierge Médico, referencias con base en su red médica y hospitalaria.",
    imagenOut: "/assets/img/ecosistemas/proteccion/img-coursera.png",
    colorOut: "#7849B8",
    textoIn:
      "Con tu Seguro accede a beneficios que te permitirán conocer tu salud financiera y capacitarte gratuitamente.",
    paginaBtn: "",
    detail_eco: "CU",
    detail_service: "SERVICIOSMEDICOS",
    detail_action: "INGRESAR",
    esAgendamiento: false,
  },
  {
    id: 74,
    group: 2,
    shorttitle: "Servicio dental",
    title: "Servicio dental",
    subtitle: "",
    desc: "Solicita una consulta básica, limpieza dental por cada aniversario de tu seguro. Obtén costos preferenciales en todos los tratamientos odontológicos que requieras.",
    img: "/assets/img/ecosistemas/empleabilidad/entrevista/cardDise.webp",
    imgmini: "/assets/img/ecosistemas/cuidados/cards/img-servicio-dental.webp",
    ico: "/assets/img/ecosistemas/cuidados/ico-servicio-dental.svg",
    linkOut: "/vida/atencion-medica",
    linkIn: "/ecosistema/cuidados/servicio-dental",
    linkEditAgendamiento: "/ecosistema/cuidados/servicio-dental",
    descrip: "Cursos online con certificación",
    imgDescrip:
      "/assets/img/ecosistemas/empleabilidad/coursera/img-card-coursera.png",
    colorPrincipal: "var(--rojo)",
    colorSecundario: "#7849B8",
    flatImgConFondo: false,
    tituloOut: "Prepárate para una Entrevista",
    descripcionOut:
      "Solicita una consulta básica, limpieza dental por cada aniversario de tu seguro. Obtén costos preferenciales en todos los tratamientos odontológicos que requieras.",
    imagenOut: "/assets/img/ecosistemas/proteccion/img-coursera.png",
    colorOut: "#7849B8",
    textoIn:
      "Con tu Seguro accede a beneficios que te permitirán conocer tu salud financiera y capacitarte gratuitamente.",
    paginaBtn: "",
    detail_eco: "CU",
    detail_service: "SERVICIODENTAL",
    detail_action: "INGRESAR",
    esAgendamiento: true,
    agendaTipo: 2064,
  },
  {
    id: 75,
    group: 2,
    shorttitle: "Asistencia funeraria",
    title: "Asistencia funeraria",
    subtitle: "",
    desc: "En caso de que llegues a faltar, tu familia podrá solicitar la coordinación de un servicio funerario, cubriendo hasta $25,000.",
    img: "/assets/img/ecosistemas/empleabilidad/entrevista/cardDise.webp",
    imgmini:
      "/assets/img/ecosistemas/cuidados/cards/img-asistencia-funeraria.webp",
    ico: "/assets/img/ecosistemas/cuidados/ico-asistencia-funeraria.svg",
    linkOut: "/vida/atencion-medica",
    linkIn: "/ecosistema/cuidados/orientacion-medica",
    descrip: "Cursos online con certificación",
    imgDescrip:
      "/assets/img/ecosistemas/empleabilidad/coursera/img-card-coursera.png",
    colorPrincipal: "var(--rojo)",
    colorSecundario: "#7849B8",
    flatImgConFondo: false,
    tituloOut: "Prepárate para una Entrevista",
    descripcionOut:
      "En caso de que llegues a faltar, tu familia podrá solicitar la coordinación de un servicio funerario, cubriendo hasta $25,000.",
    imagenOut: "/assets/img/ecosistemas/proteccion/img-coursera.png",
    colorOut: "#7849B8",
    textoIn:
      "Con tu Seguro accede a beneficios que te permitirán conocer tu salud financiera y capacitarte gratuitamente.",
    paginaBtn: "",
    detail_eco: "CU",
    detail_service: "ASISTENCIAFUNERARIA",
    detail_action: "INGRESAR",
    esAgendamiento: false,
  },

  //Ecosistema CUIDADOS (PLAN 2, 4)
  {
    id: 76,
    group: 2,
    shorttitle: "Médico general a domicilio",
    title: "Médico general a domicilio",
    subtitle: "",
    desc: "Solicita la visita de un médico general para que te valore en tu domicilio.",
    img: "/assets/img/ecosistemas/empleabilidad/entrevista/cardDise.webp",
    imgmini: "/assets/img/ecosistemas/cuidados/cards/img-medico-domicilio.webp",
    ico: "/assets/img/ecosistemas/cuidados/ico-medico-domicilio.svg",
    linkOut: "/vida/atencion-medica",
    linkIn: "/ecosistema/cuidados2/medico-domicilio",
    descrip: "Cursos online con certificación",
    imgDescrip:
      "/assets/img/ecosistemas/empleabilidad/coursera/img-card-coursera.png",
    colorPrincipal: "var(--rojo)",
    colorSecundario: "#7849B8",
    flatImgConFondo: false,
    tituloOut: "Prepárate para una Entrevista",
    descripcionOut:
      "Solicita la visita de un médico general para que te valore en tu domicilio.",
    imagenOut: "/assets/img/ecosistemas/proteccion/img-coursera.png",
    colorOut: "#7849B8",
    textoIn:
      "Con tu Seguro accede a beneficios que te permitirán conocer tu salud financiera y capacitarte gratuitamente.",
    paginaBtn: "",
    detail_eco: "CU",
    detail_service: "MEDICODOMICILIO",
    detail_action: "INGRESAR",
    esAgendamiento: false,
  },
  {
    id: 77,
    group: 2,
    shorttitle: "Terapia de rehabilitación",
    title: "Terapia de rehabilitación",
    subtitle: "",
    desc: "Solicita el servicio de terapia de rehabilitación sin costo mediante el cual, podrás elegir a un fisioterapeuta de tu preferencia dentro nuestra lista de equipo médico.",
    img: "/assets/img/ecosistemas/empleabilidad/entrevista/cardDise.webp",
    imgmini: "/assets/img/ecosistemas/cuidados/cards/img-rehabilitacion.webp",
    ico: "/assets/img/ecosistemas/cuidados/ico-rehabilitacion.svg",
    linkOut: "/vida/atencion-medica",
    linkIn: "/ecosistema/cuidados2/rehabilitacion",
    descrip: "Cursos online con certificación",
    imgDescrip:
      "/assets/img/ecosistemas/empleabilidad/coursera/img-card-coursera.png",
    colorPrincipal: "var(--rojo)",
    colorSecundario: "#7849B8",
    flatImgConFondo: false,
    tituloOut: "Prepárate para una Entrevista",
    descripcionOut:
      "Solicita el servicio de terapia de rehabilitación sin costo mediante el cual, podrás elegir a un fisioterapeuta de tu preferencia dentro nuestra lista de equipo médico.",
    imagenOut: "/assets/img/ecosistemas/proteccion/img-coursera.png",
    colorOut: "#7849B8",
    textoIn:
      "Con tu Seguro accede a beneficios que te permitirán conocer tu salud financiera y capacitarte gratuitamente.",
    paginaBtn: "",
    detail_eco: "CU",
    detail_service: "TERAPIAREHABILITACION",
    detail_action: "INGRESAR",
    esAgendamiento: false,
  },

  //Ecosistema CUIDADOS (PLAN 6, 8, 10, 12)
  {
    id: 78,
    group: 2,
    shorttitle: "Reembolso por medicamentos",
    title: "Reembolso por medicamentos",
    subtitle: "",
    desc: "Te cubriremos medicamentos genéricos con receta médica con un valor de hasta $500 pesos.",
    img: "/assets/img/ecosistemas/empleabilidad/entrevista/cardDise.webp",
    imgmini: "/assets/img/ecosistemas/cuidados/cards/img-reembolso.webp",
    ico: "/assets/img/ecosistemas/cuidados/ico-reembolso.svg",
    linkOut: "/vida/atencion-medica",
    linkIn: "/ecosistema/cuidados3/reembolso",
    descrip: "Cursos online con certificación",
    imgDescrip:
      "/assets/img/ecosistemas/empleabilidad/coursera/img-card-coursera.png",
    colorPrincipal: "var(--rojo)",
    colorSecundario: "#7849B8",
    flatImgConFondo: false,
    tituloOut: "Prepárate para una Entrevista",
    descripcionOut:
      "Te cubriremos medicamentos genéricos con receta médica con un valor de hasta $500 pesos.",
    imagenOut: "/assets/img/ecosistemas/proteccion/img-coursera.png",
    colorOut: "#7849B8",
    textoIn:
      "Con tu Seguro accede a beneficios que te permitirán conocer tu salud financiera y capacitarte gratuitamente.",
    paginaBtn: "",
    detail_eco: "CU",
    detail_service: "REEMBOLSOMEDICAMENTOS",
    detail_action: "INGRESAR",
    esAgendamiento: false,
  },
  {
    id: 79,
    group: 2,
    shorttitle: "Médico general a domicilio",
    title: "Médico general a domicilio",
    subtitle: "",
    desc: "Recibe atención médica en tu domicilio por parte de un médico general hasta por $600.00 MXN. Cubre un evento al año.",
    img: "/assets/img/ecosistemas/empleabilidad/entrevista/cardDise.webp",
    imgmini: "/assets/img/ecosistemas/cuidados/cards/img-medico-domicilio.webp",
    ico: "/assets/img/ecosistemas/cuidados/ico-medico-domicilio.svg",
    linkOut: "/vida/atencion-medica",
    linkIn: "/ecosistema/cuidados3/medico-domicilio",
    descrip: "Cursos online con certificación",
    imgDescrip:
      "/assets/img/ecosistemas/empleabilidad/coursera/img-card-coursera.png",
    colorPrincipal: "var(--rojo)",
    colorSecundario: "#7849B8",
    flatImgConFondo: false,
    tituloOut: "Prepárate para una Entrevista",
    descripcionOut:
      "Recibe atención médica en tu domicilio por parte de un médico general hasta por $600.00 MXN. Cubre un evento al año.",
    imagenOut: "/assets/img/ecosistemas/proteccion/img-coursera.png",
    colorOut: "#7849B8",
    textoIn:
      "Con tu Seguro accede a beneficios que te permitirán conocer tu salud financiera y capacitarte gratuitamente.",
    paginaBtn: "",
    detail_eco: "CU",
    detail_service: "MEDICODOMICILIO",
    detail_action: "INGRESAR",
    esAgendamiento: false,
  },

  //VALOR FACTURA
  {
    id: 80,
    group: 1,
    shorttitle: "Verificación vehicular",
    title: "Verificación vehicular",
    subtitle: "",
    desc: "La verificación se debe hacer 2 veces al año en algunos estados de la República, ¿ya sabes cuándo te toca?  Consulta aquí lo que necesitas saber.",
    img: "/assets/img/ecosistemas/valor-factura/entrevista/cardDise.png",
    imgmini:
      "/assets/img/ecosistemas/valor-factura/img-verificacion-vehicular.webp",
    ico: "/assets/img/ecosistemas/valor-factura/ico-verificacion-vehicular.svg",
    linkOut: "/valor-factura/verificacion-vehicular",
    linkIn: "/ecosistema/valor-factura/verificacion-vehicular",
    descrip: "Cursos online con certificación",
    imgDescrip:
      "/assets/img/ecosistemas/empleabilidad/coursera/img-card-coursera.png",
    colorPrincipal: "var(--naranja2)",
    colorSecundario: "#7849B8",
    flatImgConFondo: false,
    tituloOut: "Prepárate para una Entrevista",
    descripcionOut:
      "La verificación se debe hacer 2 veces al año en algunos estados de la República, ¿ya sabes cuándo te toca?  Consulta aquí lo que necesitas saber.",
    imagenOut: "/assets/img/ecosistemas/proteccion/img-coursera.png",
    colorOut: "#7849B8",
    textoIn:
      "Con tu Seguro accede a beneficios que te permitirán conocer tu salud financiera y capacitarte gratuitamente.",
    paginaBtn: "",
    detail_eco: "VF",
    detail_service: "VERIFICACIONVEHICULAR",
    detail_action: "INGRESAR",
    esAgendamiento: false,
  },
  {
    id: 81,
    group: 1,
    shorttitle: "Consulta de multas",
    title: "Consulta de multas",
    subtitle: "",
    desc: "Conoce si tienes multas vigentes para evitar penalizaciones y aprovecha los descuentos que se ofrecen por pago adelantado.",
    img: "/assets/img/ecosistemas/empleabilidad/entrevista/cardDise.webp",
    imgmini: "/assets/img/ecosistemas/valor-factura/img-multas.webp",
    ico: "/assets/img/ecosistemas/valor-factura/ico-multas.svg",
    linkOut: "/valor-factura/multas",
    linkIn: "/ecosistema/valor-factura/multas",
    descrip: "Cursos online con certificación",
    imgDescrip:
      "/assets/img/ecosistemas/empleabilidad/coursera/img-card-coursera.png",
    colorPrincipal: "var(--naranja2)",
    colorSecundario: "#7849B8",
    flatImgConFondo: false,
    tituloOut: "Prepárate para una Entrevista",
    descripcionOut:
      "Conoce si tienes multas vigentes para evitar penalizaciones y aprovecha los descuentos que se ofrecen por pago adelantado.",
    imagenOut: "/assets/img/ecosistemas/proteccion/img-coursera.png",
    colorOut: "#7849B8",
    textoIn:
      "Con tu Seguro accede a beneficios que te permitirán conocer tu salud financiera y capacitarte gratuitamente.",
    paginaBtn: "",
    detail_eco: "VF",
    detail_service: "CONSULTADEMULTAS",
    detail_action: "INGRESAR",
    esAgendamiento: false,
  },
  {
    id: 82,
    group: 1,
    shorttitle: "Cupones de descuento",
    title: "Cupones de descuento",
    subtitle: "",
    desc: "Haz uso de los beneficios que tenemos para que saques el máximo provecho a tus aplicaciones.",
    img: "/assets/img/ecosistemas/empleabilidad/entrevista/cardDise.webp",
    imgmini: "/assets/img/ecosistemas/valor-factura/img-cupones-descuento.webp",
    ico: "/assets/img/ecosistemas/valor-factura/ico-cupones.svg",
    linkOut: "/valor-factura/cupones-descuento",
    linkIn: "/ecosistema/valor-factura/cupones-descuento",
    descrip: "Cursos online con certificación",
    imgDescrip:
      "/assets/img/ecosistemas/empleabilidad/coursera/img-card-coursera.png",
    colorPrincipal: "var(--naranja2)",
    colorSecundario: "#7849B8",
    flatImgConFondo: false,
    tituloOut: "Prepárate para una Entrevista",
    descripcionOut:
      "Haz uso de los beneficios que tenemos para que saques el máximo provecho a tus aplicaciones.",
    imagenOut: "/assets/img/ecosistemas/proteccion/img-coursera.png",
    colorOut: "#7849B8",
    textoIn:
      "Con tu Seguro accede a beneficios que te permitirán conocer tu salud financiera y capacitarte gratuitamente.",
    paginaBtn: "",
    detail_eco: "VF",
    detail_service: "CUPONESDEDESCUENTO",
    detail_action: "INGRESAR",
    esAgendamiento: false,
  },
  {
    id: 83,
    group: 1,
    shorttitle: "Cupones trimestrales",
    title: "Cupones trimestrales",
    subtitle: "",
    desc: "Haz uso de los beneficios trimestrales que tenemos disponibles para ti.",
    img: "/assets/img/ecosistemas/empleabilidad/entrevista/cardDise.webp",
    imgmini:
      "/assets/img/ecosistemas/valor-factura/img-cupones-trimestrales.webp",
    ico: "/assets/img/ecosistemas/valor-factura/ico-cupones.svg",
    linkOut: "/valor-factura/cupones-trimestrales",
    linkIn: "/ecosistema/valor-factura/cupones-trimestrales",
    descrip: "Cursos online con certificación",
    imgDescrip:
      "/assets/img/ecosistemas/empleabilidad/coursera/img-card-coursera.png",
    colorPrincipal: "var(--naranja2)",
    colorSecundario: "#7849B8",
    flatImgConFondo: false,
    tituloOut: "Prepárate para una Entrevista",
    descripcionOut:
      "Haz uso de los beneficios trimestrales que tenemos para que saques el máximo provecho a tus aplicaciones.",
    imagenOut: "/assets/img/ecosistemas/proteccion/img-coursera.png",
    colorOut: "#7849B8",
    textoIn:
      "Con tu Seguro accede a beneficios que te permitirán conocer tu salud financiera y capacitarte gratuitamente.",
    paginaBtn: "",
    detail_eco: "VF",
    detail_service: "CUPONESTRIMESTRALES",
    detail_action: "INGRESAR",
    esAgendamiento: false,
  },
];

export const programasCoursera = [
  {
    id: 1,
    urlCoursera:
      "https://www.coursera.org/programs/premium-ckwnu?attemptSSOLogin=true&authMode=login",
    ssoattribute: "scotia-002",
  },
  {
    id: 2,
    urlCoursera:
      "https://www.coursera.org/programs/seguro-de-proteccion-de-ingresos-empleabilidad-0ubce?attemptSSOLogin=true&authMode=login",
    ssoattribute: "scotia-004",
  },
  {
    id: 3,
    urlCoursera:
      "https://www.coursera.org/programs/seguro-de-proteccion-de-ingresos-independientes-raexg?attemptSSOLogin=true&authMode=login",
    ssoattribute: "scotia-003",
  },
  {
    id: 4,
    urlCoursera:
      "https://www.coursera.org/programs/vida-vive-tu-seguro-kb64h?authProvider=cardifcontigovida&attemptSSOLogin=true&authMode=login",
    ssoattribute: "scotia-006",
  },
];

export const indicadoresInformeDiagnosticoFinanciero = [
  {
    id: 1,
    name: "Conocimiento",
    ico: "/assets/img/ecosistemas/proteccion-elemental/Ico_conocimiento.png",
  },
  {
    id: 2,
    name: "Ahorro",
    ico: "/assets/img/ecosistemas/proteccion-elemental/Ico_ahorro.png",
  },
  {
    id: 3,
    name: "Presupuesto",
    ico: "/assets/img/ecosistemas/proteccion-elemental/Ico_presupuesto.png",
  },
  {
    id: 4,
    name: "Endeudamiento",
    ico: "/assets/img/ecosistemas/proteccion-elemental/Ico_endeudamiento.png",
  },
  {
    id: 5,
    name: "Vivir dentro de las capacidades",
    ico: "/assets/img/ecosistemas/proteccion-elemental/Ico_capacidades.png",
  },
  {
    id: 6,
    name: "Jubilación",
    ico: "/assets/img/ecosistemas/proteccion-elemental/Ico_jubilación.png",
  },
  {
    id: 7,
    name: "Respaldo ante la muerte o incapacidad",
    ico: "/assets/img/ecosistemas/proteccion-elemental/Ico_respaldo_muerte.png",
  },
  {
    id: 8,
    name: "Cultura financiera",
    ico: "/assets/img/ecosistemas/proteccion-elemental/Ico_cultura_financiera.png",
  },
  {
    id: 9,
    name: "Actitud hacia las compras",
    ico: "/assets/img/ecosistemas/proteccion-elemental/Ico_actitud_compras.png",
  },
  {
    id: 10,
    name: "Dar",
    ico: "/assets/img/ecosistemas/proteccion-elemental/Ico_dar.png",
  },
];

export const listadoHorasInicial = [
  { id: 1, hora: "07:00 am", value: 700, activo: false, hora2: "07:00" },
  { id: 2, hora: "07:30 am", value: 730, activo: false, hora2: "07:30" },
  { id: 3, hora: "08:00 am", value: 800, activo: false, hora2: "08:00" },
  { id: 4, hora: "08:30 am", value: 830, activo: false, hora2: "08:30" },
  { id: 5, hora: "09:00 am", value: 900, activo: false, hora2: "09:00" },
  { id: 6, hora: "09:30 am", value: 930, activo: false, hora2: "09:30" },
  { id: 7, hora: "10:00 am", value: 1000, activo: false, hora2: "10:00" },
  { id: 8, hora: "10:30 am", value: 1030, activo: false, hora2: "10:30" },
  { id: 9, hora: "11:00 am", value: 1100, activo: false, hora2: "11:00" },
  { id: 10, hora: "11:30 am", value: 1130, activo: false, hora2: "11:30" },
  { id: 11, hora: "12:00 pm", value: 1200, activo: false, hora2: "12:00" },
  { id: 12, hora: "12:30 pm", value: 1230, activo: false, hora2: "12:30" },
  { id: 13, hora: "1:00 pm", value: 1300, activo: false, hora2: "13:00" },
  { id: 14, hora: "1:30 pm", value: 1330, activo: false, hora2: "13:30" },
  { id: 15, hora: "2:00 pm", value: 1400, activo: false, hora2: "14:00" },
  { id: 16, hora: "2:30 pm", value: 1430, activo: false, hora2: "14:30" },
  { id: 17, hora: "3:00 pm", value: 1500, activo: false, hora2: "15:00" },
  { id: 18, hora: "3:30 pm", value: 1530, activo: false, hora2: "15:30" },
  { id: 19, hora: "4:00 pm", value: 1600, activo: false, hora2: "16:00" },
  { id: 20, hora: "4:30 pm", value: 1630, activo: false, hora2: "16:30" },
  { id: 21, hora: "5:00 pm", value: 1700, activo: false, hora2: "17:00" },
  { id: 22, hora: "5:30 pm", value: 1730, activo: false, hora2: "17:30" },
  { id: 23, hora: "6:00 pm", value: 1800, activo: false, hora2: "18:00" },
  { id: 24, hora: "6:30 pm", value: 1830, activo: false, hora2: "18:30" },
  { id: 25, hora: "7:00 pm", value: 1900, activo: false, hora2: "19:00" },
  { id: 26, hora: "7:30 pm", value: 1930, activo: false, hora2: "19:30" },
  { id: 27, hora: "8:00 pm", value: 2000, activo: false, hora2: "20:00" },
];

export const localesFisicosActivos = [
  { value: 29647, label: "Ciudad de México" },
  { value: 29648, label: "Estado de México" },
  { value: 29649, label: "Guadalajara" },
  { value: 29650, label: "Querétaro" },
  { value: 29651, label: "Michoacán" },
  { value: 29652, label: "Puebla" },
  { value: 29653, label: "Guanajuato" },
  { value: 29654, label: "Nayarit" },
  { value: 29655, label: "Morelos" },
  { value: 29656, label: "Aguascalientes" },
  { value: 29657, label: "Hidalgo" },
  { value: 29658, label: "Tlaxcala" },
  { value: 29659, label: "Colima" },
  { value: 29660, label: "Baja California" },
  { value: 29661, label: "Chihuahua" },
  { value: 29662, label: "Nuevo León" },
  { value: 29663, label: "Coahuila" },
  { value: 29664, label: "Sonora" },
  { value: 29665, label: "Tamaulipas" },
  { value: 29666, label: "Sinaloa" },
  { value: 29667, label: "San Luis Potosí" },
  { value: 29668, label: "Oaxaca" },
  { value: 29669, label: "Quintana Roo" },
  { value: 29670, label: "Veracruz" },
  { value: 29671, label: "Chiapas" },
  { value: 29672, label: "Campeche" },
  { value: 29673, label: "Tabasco" },
  { value: 29674, label: "Yucatán" },
];

export const dataTelemedicina = [
  "Medicina General",
  "Cardiología",
  "Ginecología",
  "Internista",
  "Geriatría",
  "Dermatólogo",
  "Endocrinólogo",
  "Pediatra",
  "Psiquiatra",
];

export const dataNutricional = [
  "Alergias",
  "Crecimiento y Desarrollo",
  "Microbiota intestinal",
  "Nutrición, Salud y Bienestar",
  "Obesidad",
  "Educación nutricional",
  "Pérdida de grasa corporal",
  "Ganancia de masa muscular",
];

export const dataAnalisisClinico = [
  "Hemograma",
  "Triglicéridos",
  "Ginecología",
  "Colesterol",
  "Orina",
  "Glucosa",
];

export const dataMedicoDomicilio = ["Medicina general", "Pediatría"];

export const dataPsicologica = [
  "Depresión",
  "Trastorno bipolar",
  "Ansiedad",
  "Trastorno obsesivo-compulsivo",
  "Trastorno por estrés postraumático",
  "Trastorno por estrés agudo",
  "Disfunciones sexuales",
  "Dependencia emocional",
  "Insomnio y trastornos del sueño",
  "Trastornos de personalidad",
];

export const dataChequeoPreventivo = [
  "Consulta ginecológica",
  "Papanicolau",
  "Mamografía",
  "Consulta urológica",
  "Tacto rectal",
];

export const dataPiasesInvitado = [
  { value: "afgana", texto: "afgana" },
  { value: "alemana", texto: "alemana" },
  { value: "árabe", texto: "árabe" },
  { value: "argentina", texto: "argentina" },
  { value: "australiana", texto: "australiana" },
  { value: "belga", texto: "belga" },
  { value: "boliviana", texto: "boliviana" },
  { value: "brasileña", texto: "brasileña" },
  { value: "camboyana", texto: "camboyana" },
  { value: "canadiense", texto: "canadiense" },
  { value: "chilena", texto: "chilena" },
  { value: "china", texto: "china" },
  { value: "colombiana", texto: "colombiana" },
  { value: "coreana", texto: "coreana" },
  { value: "costarricense", texto: "costarricense" },
  { value: "cubana", texto: "cubana" },
  { value: "danesa", texto: "danesa" },
  { value: "ecuatoriana", texto: "ecuatoriana" },
  { value: "egipcia", texto: "egipcia" },
  { value: "salvadoreña", texto: "salvadoreña" },
  { value: "escocesa", texto: "escocesa" },
  { value: "española", texto: "española" },
  { value: "estadounidense", texto: "estadounidense" },
  { value: "estonia", texto: "estonia" },
  { value: "etiope", texto: "etiope" },
  { value: "filipina", texto: "filipina" },
  { value: "finlandesa", texto: "finlandesa" },
  { value: "francesa", texto: "francesa" },
  { value: "galesa", texto: "galesa" },
  { value: "griega", texto: "griega" },
  { value: "guatemalteca", texto: "guatemalteca" },
  { value: "haitiana", texto: "haitiana" },
  { value: "holandesa", texto: "holandesa" },
  { value: "hondureña", texto: "hondureña" },
  { value: "indonesa", texto: "indonesa" },
  { value: "inglesa", texto: "inglesa" },
  { value: "iraquí", texto: "iraquí" },
  { value: "iraní", texto: "iraní" },
  { value: "irlandesa", texto: "irlandesa" },
  { value: "israelí", texto: "israelí" },
  { value: "italiana", texto: "italiana" },
  { value: "japonesa", texto: "japonesa" },
  { value: "jordana", texto: "jordana" },
  { value: "laosiana", texto: "laosiana" },
  { value: "letona", texto: "letona" },
  { value: "letonesa", texto: "letonesa" },
  { value: "malaya", texto: "malaya" },
  { value: "marroquí", texto: "marroquí" },
  { value: "mexicana", texto: "mexicana" },
  { value: "nicaragüense", texto: "nicaragüense" },
  { value: "noruega", texto: "noruega" },
  { value: "neozelandesa", texto: "neozelandesa" },
  { value: "panameña", texto: "panameña" },
  { value: "paraguaya", texto: "paraguaya" },
  { value: "peruana", texto: "peruana" },
  { value: "polaca", texto: "polaca" },
  { value: "portuguesa", texto: "portuguesa" },
  { value: "puertorriqueño", texto: "puertorriqueño" },
  { value: "dominicana", texto: "dominicana" },
  { value: "rumana", texto: "rumana" },
  { value: "rusa", texto: "rusa" },
  { value: "sueca", texto: "sueca" },
  { value: "suiza", texto: "suiza" },
  { value: "tailandesa", texto: "tailandesa" },
  { value: "taiwanesa", texto: "taiwanesa" },
  { value: "turca", texto: "turca" },
  { value: "ucraniana", texto: "ucraniana" },
  { value: "uruguaya", texto: "uruguaya" },
  { value: "venezolana", texto: "venezolana" },
  { value: "vietnamita", texto: "vietnamita" },
];

export const dataInvitados = [
  {
    id: 1,
    validapais: false,
    agregarinvitado: false,
    invitado: "",
    mostrarbox: false,
    idinvitado: 0,
    invitadonumeroIdentificacion: "",
    invitadonumeroCelular: "",
    invitadocorreoElectronico: "",
    invitadonombreUsuario: "",
    invitadoapellidoPaterno: "",
    invitadoapellidoMaterno: "",
    invitadoestado: 0,
    invitadoestadoInvitado: 0,
    nacionalidad: "",
    menoredad: false,
    esmenoredad: "0",
    doc: {
      tipoDocumento: "",
      idTipoDocumento: "",
    },
    pais: {
      nacionalidad: "",
    },
    docn: "",
    terminos: false,
  },
  {
    id: 2,
    validapais: false,
    agregarinvitado: false,
    invitado: "",
    idinvitado: 0,
    invitadonumeroIdentificacion: "",
    invitadonumeroCelular: "",
    invitadocorreoElectronico: "",
    invitadonombreUsuario: "",
    invitadoapellidoPaterno: "",
    invitadoapellidoMaterno: "",
    invitadoestado: 0,
    invitadoestadoInvitado: 0,
    nacionalidad: "",
    menoredad: false,
    esmenoredad: "0",
    doc: {
      tipoDocumento: "",
      idTipoDocumento: "",
    },
    pais: {
      nacionalidad: "",
    },
    docn: "",
  },
  {
    id: 3,
    validapais: false,
    agregarinvitado: false,
    invitado: "",
    idinvitado: 0,
    invitadonumeroIdentificacion: "",
    invitadonumeroCelular: "",
    invitadocorreoElectronico: "",
    invitadonombreUsuario: "",
    invitadoapellidoPaterno: "",
    invitadoapellidoMaterno: "",
    invitadoestado: 0,
    invitadoestadoInvitado: 0,
    nacionalidad: "",
    menoredad: false,
    esmenoredad: "0",
    doc: {
      tipoDocumento: "",
      idTipoDocumento: "",
    },
    pais: {
      nacionalidad: "",
    },
    docn: "",
  },
  {
    id: 4,
    validapais: false,
    agregarinvitado: false,
    invitado: "",
    idinvitado: 0,
    invitadonumeroIdentificacion: "",
    invitadonumeroCelular: "",
    invitadocorreoElectronico: "",
    invitadonombreUsuario: "",
    invitadoapellidoPaterno: "",
    invitadoapellidoMaterno: "",
    invitadoestado: 0,
    invitadoestadoInvitado: 0,
    nacionalidad: "",
    menoredad: false,
    esmenoredad: "0",
    doc: {
      tipoDocumento: "",
      idTipoDocumento: "",
    },
    pais: {
      nacionalidad: "",
    },
    docn: "",
  },
  {
    id: 5,
    validapais: false,
    agregarinvitado: false,
    invitado: "",
    idinvitado: 0,
    invitadonumeroIdentificacion: "",
    invitadonumeroCelular: "",
    invitadocorreoElectronico: "",
    invitadonombreUsuario: "",
    invitadoapellidoPaterno: "",
    invitadoapellidoMaterno: "",
    invitadoestado: 0,
    invitadoestadoInvitado: 0,
    nacionalidad: "",
    menoredad: false,
    esmenoredad: "0",
    doc: {
      tipoDocumento: "",
      idTipoDocumento: "",
    },
    pais: {
      nacionalidad: "",
    },
    docn: "",
  },
  {
    id: 6,
    validapais: false,
    agregarinvitado: false,
    invitado: "",
    idinvitado: 0,
    invitadonumeroIdentificacion: "",
    invitadonumeroCelular: "",
    invitadocorreoElectronico: "",
    invitadonombreUsuario: "",
    invitadoapellidoPaterno: "",
    invitadoapellidoMaterno: "",
    invitadoestado: 0,
    invitadoestadoInvitado: 0,
    nacionalidad: "",
    menoredad: false,
    esmenoredad: "0",
    doc: {
      tipoDocumento: "",
      idTipoDocumento: "",
    },
    pais: {
      nacionalidad: "",
    },
    docn: "",
  },
];

export const dataFormDni = [{ id: 1 }];
