import React, { Suspense } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import HomeOutEmpleabilidad from '../../modules/homeout/empleabilidad/screens/HomeOutEmpleabilidad'
import PrincipalContactoResumen from '../../modules/homeout/principal/components/PrincipalContactoResumen'
import FallbackCards from '../../modules/helpers/FallbackCards'
import PageNotFound from '../../modules/404/PageNotFound'

const EmpleabilidadOutCoursera = React.lazy(()=> import ('../../modules/homeout/empleabilidad/components/EmpleabilidadOutCoursera'))
const EmpleabilidadOutEntrevista = React.lazy(()=> import ('../../modules/homeout/empleabilidad/components/EmpleabilidadOutEntrevista'))
const EmpleabilidadOutJob = React.lazy(()=> import ('../../modules/homeout/empleabilidad/components/EmpleabilidadOutJob'))
const EmpleabilidadOutLegal = React.lazy(()=> import ('../../modules/homeout/empleabilidad/components/EmpleabilidadOutLegal'))
const EmpleabilidadOutOfertas = React.lazy(()=> import ('../../modules/homeout/empleabilidad/components/EmpleabilidadOutOfertas'))
const EmpleabilidadOutSalario = React.lazy(()=> import ('../../modules/homeout/empleabilidad/components/EmpleabilidadOutSalario'))
const EmpleabilidadOutCurriculum = React.lazy(()=> import ('../../modules/homeout/empleabilidad/components/EmpleabilidadOutCurriculum'))

const RouterOutEmpleabilidad = () => {
    return (
        <>
            <Suspense fallback={<FallbackCards />}>

                <Routes>
                    <Route path='/coursera' element={<EmpleabilidadOutCoursera />} />
                    <Route path='/entrevista' element={<EmpleabilidadOutEntrevista />} />
                    <Route path='/job' element={<EmpleabilidadOutJob />} />
                    <Route path='/legal' element={<EmpleabilidadOutLegal />} />
                    <Route path='/ofertas' element={<EmpleabilidadOutOfertas />} />
                    <Route path='/salario' element={<EmpleabilidadOutSalario />} />
                    <Route path='/curriculum' element={<EmpleabilidadOutCurriculum />} />
                    <Route path='/' element={<HomeOutEmpleabilidad />} />
                    <Route path='/404' element={<PageNotFound />} />
                    <Route path='*' element={<Navigate to="/404" />} />
                </Routes>

                {window.location.pathname !== "/empleabilidad" && < PrincipalContactoResumen />}
            </Suspense>
        </>
    )
}

export default RouterOutEmpleabilidad