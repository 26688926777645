import React from 'react'
import useCards from '../../../hooks/useCards'

const EmprendimientoCards = () => {
    const { homeinCards } = useCards()
    
    return (
        <>
            {homeinCards(2)}

        </>
    )
}

export default EmprendimientoCards