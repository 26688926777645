import React, { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import AuthContext from "../../../../context/auth/authContext";
import Modulos from "../../../general/screen/Modulos";
import EcosistemaTitulo from "../../ecosistemas/EcosistemaTitulo";
import HomeInUser from "../../principal/components/HomeInUser";
import VidaPlusCards from "../components/VidaPlusCards";
import useTitle from "../../../hooks/useTitle";

const VidaPlusScreen = () => {
  useTitle({ title: "Detalle Ecosistema Vida Plus" });
  const { pathname } = useLocation();
  const { globalActualizarEcosistemaActivo } = useContext(AuthContext);

  useEffect(() => {
    if (pathname === "/ecosistema/vidaplus") {
      globalActualizarEcosistemaActivo(9);
    }
  }, []);

  return (
    <div className="homein">
      <EcosistemaTitulo />

      <div className="homein__resumen">
        <HomeInUser id={9} />
      </div>

      <div className="homein__detalle">
        <Modulos />
        <VidaPlusCards />
      </div>
    </div>
  );
};

export default VidaPlusScreen;
