import { useContext } from "react";
import AuthContext from "../../context/auth/authContext";
import InEcosistemasCards from "../homein/ecosistemas/InEcosistemasCards";
import OutEcosistemasCards from "../homeout/ecosistemas/OutEcosistemasCards";
import "./ecosistemas.css";
import { useState } from "react";

const useCards = () => {
  const { globalEcosistemas, globalDetalleEcosistemas } =
    useContext(AuthContext);

  const [tieneCitaAgendada, setTieneCitaAgendada] = useState(false)

  const ecosistemaCards = (id = 1) => {
    return (
      <section className="eco__cards">
        <h2>{globalEcosistemas.find((e) => e.id === id).titulocards}</h2>

        <div id="cards__out" className="ecosistemas__cards">
          {globalEcosistemas
            .find((e) => e.id === id)
            .ecos.map((eco, index) => {
              return (
                <>
                  { (globalEcosistemas.find((e) => e.id === id).name.includes('Cuidados') && index > 2) ?
                    <></> :
                    <OutEcosistemasCards
                      key={eco}
                      color={globalEcosistemas.find((e) => e.id === id).color}
                      ecoDetalle={globalDetalleEcosistemas.find(
                        (det) => det.id === eco
                      )}
                      index={index}
                      nombreEcosistema={globalEcosistemas.find((e) => e.id === id).name}
                    />
                  }
                </>
              );
            })}
        </div>
      </section>
    );
  };

  const homeinCards = (id = 1) => {
    let ecos = globalEcosistemas.find((e) => e.id === id);

    return (
      <div className="eco__sombreado">
        <h1
          style={{
            fontFamily: "Scotia-Headline",
            color: `var(--${ecos?.color})`,
          }}
        >
          {ecos?.name}
        </h1>
        { tieneCitaAgendada &&
          <div className="div__notificacion__citas__agendadas">
            <p><img src="/assets/img/ecosistemas/ico-cita-agendada.svg" alt="icono_alerta_agendamiento" style={{ paddingRight: '1rem' }} />Tienes citas agendadas</p>
          </div>
        }
        <section className="eco__cards-in">
          <div className="ecosistemas__cards-in">
            {globalEcosistemas
              .find((e) => e.id === id)
              .ecos.map((eco, index) => {
                return (
                  <InEcosistemasCards
                    key={eco}
                    ecoPadre={globalEcosistemas.find((e) => e.id === id)}
                    ecoDetalle={globalDetalleEcosistemas.find(
                      (det) => det.id === eco
                    )}
                    index={index}
                    setTieneCitaAgendada={setTieneCitaAgendada}
                  />
                );
              })}
          </div>
        </section>
      </div>
    );
  };

  return { ecosistemaCards, homeinCards };
};

export default useCards;
