import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import useAuth from '../hooks/useAuth'

const RutaPrivada = ({children}) => {

    const {globalAutenticado} = useAuth()
    const {pathname} = useLocation()

  return globalAutenticado 
  ? children
  : 
  <>
    {
      pathname.includes('ecosistema') ? <Navigate to='/404' /> : <Navigate to='/iniciasesion' />
    }
  </>
    
  
}

export default RutaPrivada