import React from 'react'
import useCards from '../../../hooks/useCards'
import useOutEcosistema from '../../../hooks/useOutEcosistema'
import useTitle from '../../../hooks/useTitle'
import HomeOutActivaTuCuenta from '../../ecosistemas/HomeOutActivaTuCuenta'
import PrincipalContacto from '../../principal/components/PrincipalContacto'
import PrincipalLogos from '../../principal/components/PrincipalLogos'
import "../styles/vidaplus.css";

const HomeOutVidaPlus = () => {

  useTitle({ title: 'Vital Plus | BNP Paribas Cadif' })
  const { ecosistemaPresentacionOut } = useOutEcosistema()
  const { ecosistemaCards } = useCards()

  return (
    <>
      {ecosistemaPresentacionOut(9)}

      {ecosistemaCards(9)}


      <HomeOutActivaTuCuenta />
      <div style={{background:'var(--bg_claro)'}}>
        <PrincipalLogos direccion='centro' />

        <PrincipalContacto />
      </div>
    </>
  )
}

export default HomeOutVidaPlus