import React, { Suspense } from 'react'
import FallbackCards from '../../../helpers/FallbackCards'
import useNearScreen from '../../../hooks/useNearScreen'

const PrincipalOfertasCards = React.lazy(()=> import('./PrincipalOfertasCards'))

const LazyPrincipalOfertasCards = () => {

    const { elementRef, isNear } = useNearScreen('20px')

    return (
        <div ref={elementRef}>
            <Suspense fallback={<FallbackCards />}>
                {
                    isNear ? <PrincipalOfertasCards /> : <FallbackCards /> 
                }
            </Suspense>
        </div>
    )
}

export default LazyPrincipalOfertasCards