import { useNavigate } from "react-router-dom"
import { getCoberturas, isactive } from "../../helpers/validations"
import "../styles/authRegisterSuccess.css"

const AuthRegisterSuccess = () => {

    const navigate = useNavigate()

    const valudaruta = () => {
        if (isactive()) {

            let cobertura = getCoberturas()

            if (cobertura.includes("Emprendimiento")) {
                navigate("/ecosistema/emprendimiento", {
                    state: {
                        loggedIn: true
                    }
                })
            } else if (cobertura.includes("Empleabilidad")){
                navigate("/ecosistema/empleabilidad", {
                    state: {
                        loggedIn: true
                    }
                })
            } else if (cobertura.includes("Protección Financiera")){
                navigate("/ecosistema/proteccion-financiera", {
                    state: {
                        loggedIn: true
                    }
                })
            } else if (cobertura.includes("Vital Plus")){
                navigate("/ecosistema/vidaplus", {
                    state: {
                        loggedIn: true
                    }
                })
            } else if (cobertura.includes("Mi Salud Financiera")) {
                navigate("/ecosistema/mi-salud-financiera", {
                    state: {
                        loggedIn: true
                    }
                })
            } else if (cobertura.includes("Eco Coursera")) {
                navigate("/ecosistema/coursera", {
                    state: {
                        loggedIn: true
                    }
                })
            } else if (cobertura.includes("Vida")) {
                navigate("/ecosistema/vida", {
                    state: {
                        loggedIn: true
                    }
                })
            } else if (cobertura.includes("Salud")) {
                navigate("/ecosistema/salud", {
                    state: {
                        loggedIn: true
                    }
                })
            } else if (cobertura.includes("Cuidados")) {
                navigate("/ecosistema/cuidados", {
                    state: {
                        loggedIn: true
                    }
                })
            }
        }
    }

    return (
        <div className="auth__login" style={{width: '525px'}}>

            <h3 className="auth__title">¡Disfruta tus beneficios!</h3>
            <p className="auth__subtitle" style={{ margin: '4rem 0rem' }} >Puedes usarlos cuantas veces quieras mientras tu seguro esté vigente</p>

            <div>
                <input
                    style={{ width: '70%' }}
                    type="button"
                    className="btn__login completo"
                    value="Ver beneficios"
                    onClick={valudaruta}
                />
            </div>
        </div>
    )
}

export default AuthRegisterSuccess