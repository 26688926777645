import React, { Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import FallbackCards from "../../modules/helpers/FallbackCards";

const ProteccionFinancieraElementalScreen = React.lazy(() =>
  import(
    "../../modules/homein/proteccion-financiera-elemental/screens/ProteccionFinancieraElementalScreen"
  )
);
const ProteccionFinancieraElementalTaxi = React.lazy(() =>
  import(
    "../../modules/homein/proteccion-financiera-elemental/components/taxi/ProteccionFinancieraElementalTaxi"
  )
);
const RouterProteccionFinancieraElementalSalud = React.lazy(() =>
  import("./RouterProteccionFinancieraElementalSalud")
);
const RouterProteccionFinancieraElementalScore = React.lazy(() =>
  import("./RouterProteccionFinancieraElementalScore")
);
const RouterProteccionFinancieraElementalLegal = React.lazy(() =>
  import("./RouterProteccionFinancieraElementalLegal")
);
const RouterProteccionFinancieraElementalSeguridad = React.lazy(() =>
  import("./RouterProteccionFinancieraElementalSeguridad")
);
const PageNotFound = React.lazy(() => import("../../modules/404/PageNotFound"));

const RouterProteccionFinancieraElemental = () => {
  return (
    <>
      <Suspense fallback={<FallbackCards />}>
        <Routes>
          <Route
            path="/salud/*"
            element={<RouterProteccionFinancieraElementalSalud />}
          />
          <Route
            path="/score/*"
            element={<RouterProteccionFinancieraElementalScore />}
          />
          <Route path="/taxi" element={<ProteccionFinancieraElementalTaxi />} />
          <Route
            path="/legal/*"
            element={<RouterProteccionFinancieraElementalLegal />}
          />
          <Route
            path="/seguridad/*"
            element={<RouterProteccionFinancieraElementalSeguridad />}
          />
          <Route path="/" element={<ProteccionFinancieraElementalScreen />} />
          <Route path="/404" element={<PageNotFound />} />
          <Route path="*" element={<Navigate to="/404" />} />
        </Routes>
      </Suspense>
    </>
  );
};

export default RouterProteccionFinancieraElemental;
