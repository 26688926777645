import React, { useContext } from "react";
import AuthContext from "../../context/auth/authContext";

const PortalCierreSesionDetalle = () => {
  const { globalDesactivaVentanaCierre } = useContext(AuthContext);

  const aceptarCierre = () => {
    globalDesactivaVentanaCierre();
    window.location.reload();
  };
    
    return (
        <div className={`loadingGlobal`}>
            <div className="cierresesion__container">
                <img src="/assets/img/ico-iconClock.svg" alt="icono cierre" />
                <h2>Tu sesión ha expirado</h2>
                <p>Hemos cerrado tu sesión por inactividad.</p>
                <div className='cierre__btn' onClick={aceptarCierre} >
                    {/* <SpinnerCircular />  */}
                    <span>Aceptar</span>
                </div>

            </div>
        </div>
  );
};

export default PortalCierreSesionDetalle;
