import { useContext, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import RouterHomeIn from "./RouterHomeIn";
import RouterHomeOut from "./RouterHomeOut";
import Header from "../modules/general/screen/Header";
import RutaPrivanda from "../modules/helpers/RutaPrivada";
import AuthContext from "../context/auth/authContext";
import PortalCierreSesion from "../modules/helpers/PortalCierreSesion";
import "../modules/homeout/principal/styles/homeOut.css";
import { refreshTokenSession } from "../modules/helpers/sessions";

const RouterMain = () => {
  const { globalVentanaCierre, globalInactivaSesion } = useContext(AuthContext);

  useEffect(() => {
    refreshTokenSession(globalInactivaSesion);
  }, []);

  return (
    <>
      <Header />

      {globalVentanaCierre && <PortalCierreSesion />}

      <Routes>
        <Route
          path="/ecosistema/*"
          element={
            <RutaPrivanda>
              <RouterHomeIn />
            </RutaPrivanda>
          }
        />
        <Route path="/*" element={<RouterHomeOut />} />
      </Routes>
    </>
  );
};

export default RouterMain;
