import React, {  useState } from 'react'
import { useNavigate } from 'react-router-dom'
import SpinnerCuadrado from '../../helpers/SpinnerCuadrado';
import AuthLoginFormIdentification from './AuthLoginFormIdentification';

const AuthLoginForm = () => {

    const [spinner, setSpinner] = useState(false)
    const [mensaje, setMensaje] = useState('')

    let navigate = useNavigate();

    const gotoRegister = () => {
        navigate('/registro')
    }

    return (
        <>
            {
                spinner && <SpinnerCuadrado />
            }
            <div className="auth__login" >
                <h3 className="auth__title">Inicia sesión</h3>
                <p className="auth__subtitle" >Ingresa tus datos para disfrutar tus beneficios</p>

                {/* FORMULARIO */}
                <AuthLoginFormIdentification 
                    mensaje={mensaje}
                    setMensaje={setMensaje} 
                    setSpinner={setSpinner}
                />

                <div className='auth__rutas'>
                    <p className="auth__logueo-text custom_checkbox">¿Aún no tienes una cuenta?</p>
                    <p className="auth__logueo-btn" onClick={gotoRegister}>Registrate aquí</p>
                </div>

            </div>
        </>
    )
}

export default AuthLoginForm