export const PoliticasDeTratamiento = {
    titulo: 'Aviso de privacidad ',
    descrip: [
        'Cardif México Seguros de Vida, S.A. de C.V. en términos de lo establecido por la Ley Federal de Protección de Datos Personales en Posesión de los Particulares (en lo sucesivo, la “Ley”) y del Reglamento de la Ley Federal de Protección de Datos Personales en Posesión de los Particulares (en lo sucesivo, el “Reglamento”) y demás disposiciones legales aplicables (en lo sucesivo y conjuntamente la “Regulación de Datos Personales”), se identifica como responsable del tratamiento de los datos personales recabados a los titulares de los mismos, (en lo sucesivo y conjuntamente con sus sociedades controladoras, subsidiarias, filiales, sociedades bajo el control común o sociedades pertenecientes al mismo grupo denominadas “Cardif”), y pone a su disposición el presente Aviso de Privacidad, en términos de la Regulación de Datos Personales). ',

        'DOMICILIO: Para efectos del presente Aviso de Privacidad, Cardif señala como domicilio el ubicado en Avenida Ejército Nacional, No. 453, Piso 10, Colonia Granada, Alcaldía Miguel Hidalgo, Código Postal 11520, Ciudad de México. ',

        'DATOS PERSONALES QUE SE RECABAN: Le damos a conocer las categorías de los datos personales que podríamos recabar, almacenar y/o utilizar de forma alguna: (1) datos de identificación, tales como nombre completo, domicilio particular, fecha de nacimiento, nacionalidad, país de nacimiento, ocupación o profesión, registro federal de contribuyentes, teléfono(s) y correo electrónico; (2) datos de contacto; (3) en caso de tratarse de extranjeros: datos migratorios, tales como país y domicilio de origen. ',

        'FINALIDADES NECESARIAS: Las finalidades de obtener sus datos personales son: (1) llevar a cabo las operaciones inherentes a nuestro negocio con usted principalmente lo relacionado con el otorgamiento de beneficios adicionales relacionados con los productos de Cardif, tales como servicios de asistencia y descuentos; (2) cumplir con las obligaciones derivadas de la relación jurídica existente o que llegare a existir entre usted y Cardif; (3) el cumplimiento de las obligaciones legales que le derivan a Cardif, así como de las políticas y procedimientos internos; (4) la operación, gestión y administración de los productos y servicios que usted tiene contratados o que llegare a contratar con Cardif; (5) la identificación; y (6) la atención de cualquier consulta, duda, aclaración, cancelación o queja. ',

        'FINALIDADES SECUNDARIAS: Adicional a las finalidades antes indicadas, se hace de su conocimiento que los datos recabados podrán tener finalidades que no dan origen a la relación jurídica, mismas que pueden ser: (1) ofrecerle, en su caso, otros productos y/o servicios o promociones relacionadas con nuestros productos de seguro; (2) enviarle toda clase de avisos, notificaciones, promociones, publicidad e información adicional, a través de correo electrónico, mensajes de texto SMS y demás medios electrónicos, ópticos o de cualquier otra tecnología que por avances tecnológicos faciliten la comunicación con usted; (3) mercadotecnia, publicidad y prospección comercial de Cardif o de las sociedades que forman parte del grupo empresarial de Cardif en México y alrededor del mundo, sus sociedades controladoras, subsidiarias, filiales o sociedades bajo el control común; (4) para realizar análisis estadístico, de generación de modelos de información y/o perfiles de comportamiento actual y predictivo y para medir la calidad de los servicios de Cardif; y (5) participar en encuestas, sorteos y promociones. ',

        'TRANSFERENCIA DE DATOS PERSONALES: Podremos transferir sus datos personales a terceros nacionales o extranjeros, en la medida en que la transferencia; (1) esté prevista en una ley o tratado en los que México sea parte; (2) sea necesaria para dar cumplimiento a las finalidades previamente mencionadas o a las políticas y procedimientos de Cardif; (3) sea con base en el artículo 37 de la Ley. Asimismo, le informamos que dichos terceros, asumen las mismas obligaciones y responsabilidades asumidas por Cardif, en términos de este Aviso de Privacidad. ',

        'LIMITACIÓN DEL USO Y DIVULGACIÓN DE LOS DATOS PERSONALES: Le informamos que sus datos personales, serán salvaguardados en todo momento bajo estándares de seguridad, garantizando la más estricta confidencialidad y privacidad de los mismos, de conformidad con las políticas y procedimientos que Cardif tenga implementados al respecto, apegándonos en todo momento a lo establecido por la Regulación de Datos Personales. Le hacemos extensivo que Cardif no venderá, alquilará o enajenará de forma alguna sus datos personales. ',

        'CONSENTIMIENTO: En el momento en el que se ponga a su disposición el presente Aviso de Privacidad y dentro de los 5 días hábiles siguientes, al no manifestarse en contrario, usted acepta que Cardif obtenga, use, divulgue y/o almacene sus datos personales, por cualquier medio. Asimismo, acepta que el uso abarque cualquier acción de acceso, manejo, aprovechamiento, transferencia o disposición de sus datos personales, de conformidad con las finalidades secundarias antes mencionadas. Lo anterior, ya sea que sus datos personales hubieren sido proporcionados o llegaren a ser proporcionados directamente por usted, socios comerciales, prestadores de servicios o cualquier otro tercero y hayan sido éstos obtenidos por cualquier medio, ya sea electrónico, óptico, sonoro, audiovisual, o a través de cualquier otra tecnología o medio con el que Cardif llegue a contar. En caso de que usted nos llegare a proporcionar datos personales de terceros, usted se obliga en este acto a informar a dichos terceros a cerca de: (1) los datos proporcionados; (2) los fines para los cuales proporcionó los mencionados datos; y (3) los términos y condiciones de este Aviso de Privacidad, así como los lugares en los que se encuentra disponible. En caso de querer manifestarse en contrario, dentro de los 5 días hábiles siguientes a la recepción del presente Aviso de Privacidad, usted podrá mandar su negativa al correo electrónico datospersonales@cardif.com.mx o marcando al 55 5001 4419 en la Ciudad de México y Área Metropolitana o al 800 801 0137 desde el interior de la República con un horario de atención de lunes a viernes de 08:30 a 21:00 horas. ',

        'EJERCICIO DE LOS DERECHOS DE ACCESO, RECTIFICACIÓN, CANCELACIÓN Y OPOSICIÓN: Usted o su representante legal debidamente acreditado, podrá ejercer cualquiera de los citados derechos, presentando una solicitud a Cardif, misma que deberá apegarse en todo momento a los requisitos señalados por el Artículo 29 de la Ley, por lo cual debe contener y acompañar lo siguiente: ',

        '1.El nombre del titular y domicilio u otro medio para comunicarle la respuesta a su solicitud. ',

        '2.Los documentos que acrediten la identidad o, o en su caso, la representación legal de titular. ',

        '3.La descripción clara y precisa de los datos personales respecto de los que se busca ejercer alguno de los derechos antes mencionados. ',

        '4.Cualquier otro elemento o documento que facilite la localización de los datos personales. ',

        'Para ejercer los derechos de acceso, rectificación, cancelación y oposición, así como para limitar el uso y divulgación de sus datos personales, usted tendrá las siguientes opciones: ',

        '(1) Acudir directamente al domicilio de Cardif ya señalado. ',

        '(2)Enviar un correo electrónico a la dirección datospersonales@cardif.com.mx donde Cardif le enviará el formato requerido para hacernos llegar su solicitud vía correo electrónico. ',

        '(3) Enviar un escrito/solicitud libre dirigido al Oficial de Privacidad, al siguiente correo datospersonales@cardif.com.mx ',

        '(4) Para dudas contactarnos vía telefónica al número 55 5001 4419 en la Ciudad de México y Área Metropolitana o al 800 801 0137 desde el interior de la República con un horario de atención de lunes a viernes de 08:30 a 21:00 horas ',

        'Para acreditar la identidad del titular o representante legal del titular, será necesario que se acredite por medio de alguno de los siguientes documentos: (i) credencial para votar; (ii) pasaporte; (iii) cartilla militar; (iv) cédula profesional; (v) cédula de identidad del país de nacimiento. En caso de ser representante legal del titular adicionalmente, es necesario el poder notarial o en su caso carta poder firmada ante dos testigos. ',

        'En caso de que la información proporcionada sea insuficiente o errónea, o que no se acompañen los documentos solicitados anteriormente, el Oficial de Privacidad podrá solicitarle al titular y/o representante de éste, en un periodo no mayor a 5 días hábiles, la información o documentación necesaria para continuar con el trámite, por lo que usted contará con 10 días hábiles posteriores a su recepción, para atender el requerimiento. ',

        'En caso de que la información proporcionada sea suficiente, Cardif responderá a su solicitud en un plazo de 20 días hábiles contados a partir de la fecha de recepción de la documentación completa. ',

        ' Para revocar el consentimiento que haya otorgado a Cardif para el tratamiento de sus datos personales, siempre y cuando, no sean necesarios para cumplir con las finalidades necesarias antes descritas, dentro de los límites previstos en la Regulación de Datos Personales y acorde con las obligaciones establecidas en la misma, usted tendrá las siguientes opciones: (1) acudir directamente al domicilio de Cardif ya señalado para obtener el formato correspondiente; y/o (2) contactarnos vía telefónica al número 55 5001 4419 en la Ciudad de México y Área Metropolitana o al 800 801 0137 desde el interior de la República con un horario de atención de lunes a viernes de 08:30 a 21:00 horas. Es importante que tenga en cuenta que no se podrá concluir el uso de forma inmediata, ya que es posible que por alguna obligación legal requiramos seguir tratando sus datos personales, hasta que el proceso de bloqueo se ejecute. ',

        'Una vez realizado lo anterior, y haber acreditado su personalidad o la de su representante legal, se excluirán sus datos de nuestras campañas con fines mercadotécnicos y/o prospección comercial y/o cualquier otra finalidad secundaria manifestada por el titular, en un plazo de 20 días contados a partir de la recepción de dicho formato. Esta revocación procederá sin necesidad de cumplir con algún requisito adicional y, por lo tanto, no tendrá que acudir posteriormente por respuesta alguna. ',

        'Adicionalmente, hacemos de su conocimiento que podrá inscribirse en el Registro Público de Usuarios (REUS) de la Comisión Nacional para la Protección y Defensa de los Usuarios de Servicios Financieros (CONDUSEF), padrón que contiene información de usuarios del sistema financiero que no desean ser contactados para fines de mercadotecnia por parte de las instituciones financieras. ',

        'Cardif se reserva el derecho de cambiar en cualquier momento este Aviso de Privacidad, por lo que recomendamos que usted lo revise periódicamente. En caso de que existiere algún cambio, lo haremos extensivo para usted por medio de: (1) una publicación visible en nuestro portal de internet; y/o (2) un aviso en un periódico de circulación nacional; y/o (3) por cualquier otro medio que a discreción de Cardif se considere como idóneo. '
    ]
}