import React from "react";

const useLogos = () => {
  const dosLogos = (
    <div className="footer__logos media">
      <div className="separador__derecho">
        <img
          src="/assets/img/homeout/img_sponsor.svg"
          alt="logo sponsor final"
        />
      </div>
      <div className="separador__izquierdo">
        <img
          src="/assets/img/homeout/logo_vivetuseguro.webp"
          alt="logo sponsor"
        />
      </div>
    </div>
  );

  const dosLogosHorizontal = (
    <div className="footer__logos">
      {/* <div >
                <img src="/assets/img/homeout/img_sponsor.svg" alt="logo Cardif" />
            </div> */}
      <div className="separador__izquierdo">
        <img
          src="/assets/img/homeout/logo_vivetuseguro.webp"
          alt="logo sponsor"
        />
      </div>
    </div>
  );

  const tresLogos = (
    <div className="footer__logos media">
      <div className="separador__derecho media">
        <img
          src="/assets/img/logo-scotiabank.png"
          alt="logo scotibank y Cardif"
        />
      </div>
      {/* <div className='separador__centro media'>
                <img src="/assets/img/logo-crediscotia.png" alt="logo scotibank y Cardif" />
            </div> */}
      <div className="separador__izquierdo media">
        <img src="/assets/img/logo-cardif.png" alt="logo scotibank y Cardif" />
      </div>
    </div>
  );

  const treLogosHorizontal = (
    <div className="footer__logos">
      <div className="separador__derecho">
        <img
          src="/assets/img/logo-scotiabank.png"
          alt="logo scotibank y Cardif"
        />
      </div>
      {/* <div className='separador__centro'>
                <img src="/assets/img/logo-crediscotia.png" alt="logo scotibank y Cardif" />
            </div> */}
      <div className="separador__izquierdo">
        <img src="/assets/img/logo-cardif.png" alt="logo scotibank y Cardif" />
      </div>
    </div>
  );

  return { dosLogos, tresLogos, treLogosHorizontal, dosLogosHorizontal };
};

export default useLogos;
