import React, { Suspense } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import PageNotFound from '../../modules/404/PageNotFound'
import FallbackCards from '../../modules/helpers/FallbackCards'
import PrincipalContactoResumen from '../../modules/homeout/principal/components/PrincipalContactoResumen'
import HomeOutProteccionFinancieraElemental from '../../modules/homeout/proteccion-financiera-elemental/screens/HomeOutProteccionFinancieraElemental'

const ProteccionFinancieraElementalOutSalud = React.lazy(()=> import ('../../modules/homeout/proteccion-financiera-elemental/components/ProteccionFinancieraElementalOutSalud'))
const ProteccionFinancieraElementalOutCredito = React.lazy(()=> import ('../../modules/homeout/proteccion-financiera-elemental/components/ProteccionFinancieraElementalOutCredito'))
const ProteccionFinancieraElementalOutTaxi = React.lazy(()=> import ('../../modules/homeout/proteccion-financiera-elemental/components/ProteccionFinancieraElementalOutTaxi'))
const ProteccionFinancieraElementalOutLegal = React.lazy(()=> import ('../../modules/homeout/proteccion-financiera-elemental/components/ProteccionFinancieraElementalOutLegal'))
const ProteccionFinancieraElementalOutInformatica = React.lazy(()=> import ('../../modules/homeout/proteccion-financiera-elemental/components/ProteccionFinancieraElementalOutInformatica'))

const RouterOutProteccionFinancieraElemental = () => {

    return (
        <>
            <Suspense fallback={<FallbackCards />}>
                <Routes>
                    <Route path='/salud' element={<ProteccionFinancieraElementalOutSalud />} />
                    <Route path='/score' element={<ProteccionFinancieraElementalOutCredito />} />
                    <Route path='/taxi' element={<ProteccionFinancieraElementalOutTaxi />} />
                    <Route path='/legal' element={<ProteccionFinancieraElementalOutLegal />} />
                    <Route path='/seguridad' element={<ProteccionFinancieraElementalOutInformatica />} />
                    <Route path='/' element={<HomeOutProteccionFinancieraElemental />} />
                    <Route path='/404' element={<PageNotFound />} />
                    <Route path='*' element={<Navigate to="/404" />} />
                </Routes>

                {window.location.pathname !== "/mi-salud-financiera" && < PrincipalContactoResumen />}
            </Suspense>
        </>
    )

}

export default RouterOutProteccionFinancieraElemental