import AuthState from "./context/auth/authState";
import RouterMain from "./routers/RouterMain";
import { BrowserRouter } from "react-router-dom";
import React from "react";

const App = () => {

  return (
    <div className="App">
      <BrowserRouter>
        <AuthState>
          <RouterMain />
        </AuthState>
      </BrowserRouter>
    </div>
  );
};

export default App;
