import React, { useContext, useState } from 'react'
import AuthContext from '../../../../context/auth/authContext';
import { getDataUser, getIdUser, getToken } from '../../../helpers/validations';
import { changePassProfile, changeUserProfile, getSession } from '../../../../services/auth';
import Swal from 'sweetalert2'
import { CLI_PUB } from "../../../../enviroments/enviroment"
import * as CryptoJS from "crypto-js";

import "../style/configuracionPerfil.css";

const HomeConfiguracionPerfil = () => {

    const { globalCerrarSesion, globalActualizarDatosUsuario } = useContext(AuthContext)
    const [show, setShow] = useState(true)
    const [datosUSer, setDatosUSer] = useState({
        nombres: getDataUser().nombres,
        apePat: getDataUser().apellidoPaterno,
        apeMat: getDataUser().apellidoMaterno,
        correo: getDataUser().correoElectronico,
        celular: getDataUser().numeroCelular,
        identificacion: getDataUser().identificacion
    })
    const [pass, setPass] = useState({
        anterior: "",
        nueva: "",
        confirmar: ""
    })
    const [seccion, setSeccion] = useState({
        sec1: "select",
        sec2: "",
        sec3: "",
        pagina: 1
    })

    const onclickseccion = (s1, s2, s3, pag) => {
        setSeccion({ ...seccion, sec1: s1, sec2: s2, sec3: s3, pagina: pag })
    }

    const onChangeUSer = (e) => {
        setDatosUSer({ ...datosUSer, [e.target.name]: e.target.value });
    }

    const onChangePass = (e) => {
        setPass({ ...pass, [e.target.name]: e.target.value });
    }

    const loguearNuevamente = (i) => {
        getSession(getToken()).then(resp => {
            let data = resp;
            data.apellidoMaterno = datosUSer.apeMat
            data.apellidoPaterno = datosUSer.apePat
            data.correoElectronico = datosUSer.correo
            data.nombres = datosUSer.nombres
            data.numeroCelular = datosUSer.celular

            sessionStorage.setItem('user', CryptoJS.AES.encrypt(JSON.stringify(data), CLI_PUB));
            if (i === 1) {
                Swal.fire({
                    title: 'Éxito',
                    text: 'Tu información personal ha sido actualizada',
                    icon: 'success',
                    confirmButtonText: 'Aceptar'
                })
            } else {
                Swal.fire({
                    title: 'Éxito',
                    text: 'Tu contraseña ha sido actualizada',
                    icon: 'success',
                    confirmButtonText: 'Aceptar'
                })
            }
        })
    }

    const updatePass = async () => {
        if (pass.confirmar === pass.nueva) {
            let jsonPassword = {
                "idUsuario": getIdUser().toString(),
                "clave": pass.nueva,
                "claveAnterior": pass.anterior,
            }

            changePassProfile(jsonPassword, getToken()).then(res => {
                if (res.codigo === 0) {
                    Swal.fire({
                        title: 'Éxito',
                        text: 'Tu contraseña ha sido actualizada',
                        icon: 'success',
                        confirmButtonText: 'Aceptar'
                    });
                    globalCerrarSesion()
                } else {
                    Swal.fire({
                        title: 'Advertencia',
                        text: res.mensaje,
                        icon: 'warning',
                        confirmButtonText: 'Aceptar'
                    });
                }
            })
        } else {
            Swal.fire({
                title: 'Advertencia',
                text: 'La confirmación de la contraseña no coincide.',
                icon: 'warning',
                confirmButtonText: 'Aceptar'
            });
        }

    }

    const updateUser = async () => {

        if (!datosUSer.correo.includes("@")) {
            Swal.fire({
                title: 'Advertencia',
                text: `Verificar formato de correo`,
                icon: 'warning',
                confirmButtonText: 'Aceptar'
            });
            return;

        }

        let form = {
            "idUsuario": getIdUser(),
            "nombres": datosUSer.nombres,
            "apellidoPaterno": datosUSer.apePat,
            "apellidoMaterno": datosUSer.apeMat,
            "correoElectronico": datosUSer.correo,
            "numeroCelular": datosUSer.celular,
        }

        changeUserProfile(form, getToken()).then(res => {
            globalActualizarDatosUsuario({
                globalUsuName: datosUSer.nombres,
                globalUsuSurname: datosUSer.apePat,
                globalUsuSurnameMat: datosUSer.apeMat,
                globalUsuEmail: datosUSer.correo,
                globalUsuCellphone: datosUSer.celular
            })
            loguearNuevamente(1)
        }
        )
    }

    return (
        <>
            <div className={'proteccion__titulo_perfil naranja'}>
                <div></div>
            </div>
            <div className='container-perfil'>


                <div className="conteiner_perfil-content">

                    <div className="conteiner_perfil-titulo gridPanepadd">
                        <h2>Configura tu perfil</h2>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <p>Hola, bienvenido, aquí tienes las opciones de configuración de tu perfil</p>
                    </div>
                    <div className='gridPane gridPanepadd'>
                        <div className={`tabpanel_Perfil ${seccion.sec1}`} onClick={() => { onclickseccion("select", "", "", 1); }} >
                            Información personal
                        </div>
                        <div className={`tabpanel_Perfil ${seccion.sec3}`} onClick={() => { onclickseccion("", "", "select", 3); }} >
                            Contraseña
                        </div>
                    </div>


                    {
                        seccion.pagina === 1 ? <>
                            <div className="container_perfil-form">
                                <div className="titulo-proteccionRes" style={{ fontSize: "18px" }} >


                                    <h2>Tus datos personales</h2>

                                    <div className="container_perfil-form-all">
                                        <div className="container_perfil-form-item">
                                            <p>Nombres</p>
                                            <input
                                                id="Nombres"
                                                name="nombres"
                                                type="text"
                                                value={datosUSer.nombres}
                                                onChange={onChangeUSer}
                                                style={{ padding: 10, width: '80%', borderRadius: 10, outline: 'none', marginLeft: 10 }}
                                                disabled
                                            />
                                        </div>
                                        <div className="container_perfil-form-item">
                                            <p>Apellidos</p>
                                            <input
                                                id="Apellidos"
                                                name="apePat"
                                                type="text"
                                                value={datosUSer.apePat}
                                                onChange={onChangeUSer}
                                                style={{ padding: 10, width: '80%', borderRadius: 10, outline: 'none', marginLeft: 10 }}
                                                disabled
                                            />
                                        </div>
                                        <div className="container_perfil-form-item">
                                            <p>Tipo de Documento</p>
                                            <input
                                                id="Documento"
                                                name="Documento"
                                                type="text"
                                                value={"RFC"}
                                                disabled={true}
                                                style={{ padding: 10, width: '80%', borderRadius: 10, outline: 'none', marginLeft: 10 }}
                                            />
                                        </div>
                                        <div className="container_perfil-form-item">
                                            <p>Número de Documento</p>
                                            <input
                                                type="number"
                                                placeholder="999"
                                                name='idetificacion'
                                                value={datosUSer.identificacion}
                                                onChange={onChangeUSer}
                                                disabled={true}
                                                style={{ padding: 10, width: '80%', borderRadius: 10, outline: 'none', marginLeft: 10 }}
                                            />
                                        </div>
                                        <div className="container_perfil-form-item">
                                            <p>Correo electrónico</p>
                                            <input
                                                id="Correo"
                                                name="correo"
                                                type="text"
                                                value={datosUSer.correo}
                                                onChange={onChangeUSer}
                                                pattern="[a-zA-Z ]+.*@[^ÑñáéíóúÁÉÍÓÚ]+.[com,org,co,net,es]"
                                                style={{ padding: 10, width: '80%', borderRadius: 10, outline: 'none', marginLeft: 10 }}
                                                disabled
                                            />
                                        </div>
                                        <div className="container_perfil-form-item">
                                            <p>Teléfono celular</p>
                                            <input
                                                type="number"
                                                placeholder="999"
                                                name="celular"
                                                value={datosUSer.celular}
                                                onChange={onChangeUSer}
                                                style={{ padding: 10, width: '80%', borderRadius: 10, outline: 'none', marginLeft: 10 }}
                                                disabled
                                            />
                                        </div>

                                    </div>

                                    <div className="">
                                        <button
                                            className="container_perfil-button"
                                            hidden="hidden"
                                            onClick={updateUser}
                                        >
                                            Guardar cambios
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </> : null
                    }
                    {
                        seccion.pagina === 3 ? <>
                            <div>

                                <div className="conteiner_perfil-password" style={{ fontSize: "18px" }} >

                                    <h2>Contraseña</h2>

                                    <div className="salario__filtros">
                                        <div className="conteiner_perfil-password-item">
                                            <p>Contraseña actual</p>
                                            <input
                                                id="Nombres"
                                                name="anterior"
                                                type={`${show ? 'password' : 'text'}`}
                                                value={pass.anterior}
                                                onChange={onChangePass}
                                            />
                                        </div>
                                        <div className="conteiner_perfil-password-item">
                                            <p>Nueva contraseña</p>
                                            <input
                                                id="Apellidos"
                                                name="nueva"
                                                type={`${show ? 'password' : 'text'}`}
                                                value={pass.nueva}
                                                onChange={onChangePass}
                                            />
                                        </div>
                                        <div className="conteiner_perfil-password-item">
                                            <p>Confirmación nueva contraseña</p>
                                            <input
                                                id="Documento"
                                                name="confirmar"
                                                type={`${show ? 'password' : 'text'}`}
                                                value={pass.confirmar}
                                                onChange={onChangePass}
                                            />
                                        </div>


                                    </div>

                                    <div className='conteiner_perfil-password-buttons'>
                                        <button
                                            className='perfil_button-next'
                                            onClick={updatePass}
                                        >
                                            Guardar cambios
                                        </button>
                                        <button
                                            className='perfil_button-show'
                                            onClick={() => setShow(!show)}
                                        >
                                            {show ? 'Mostrar' : 'Ocultar'} Contraseña
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </> : null
                    }
                </div>
            </div>
        </>
    )
}

export default HomeConfiguracionPerfil