import React from 'react'
import ReactDOM from 'react-dom'
import PortalInformacion from './PortalInformacion'
import './portal.css'

const Portal = (props) => {
  return (
    <div>
        {
            ReactDOM.createPortal(
                <PortalInformacion {...props} />
                , document.querySelector('#portal')
            )
        }
    </div>
  )
}

export default Portal