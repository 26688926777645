export const ProteccionDeDatos = {
    titulo: 'Política de cookies',
    descrip: [
        'En esta política utilizamos el término «cookies» para referirnos a las cookies, web beacons y/u a otras tecnologías similares, a través de las cuales es posible medir y recopilar datos estadísticos contempladas por la Directiva de la Unión Europea sobre la privacidad y las comunicaciones electrónicas.',
        '¿Qué es una cookie?',
        'Una cookie es un pequeño fichero de datos que un sitio web solicita al navegador que guarde en el ordenador o en el dispositivo móvil del usuario. Gracias a esa cookie, el sitio web puede "recordar" las acciones o preferencias del usuario con el paso del tiempo.',

        'La mayoría de los navegadores de internet admiten cookies; pero los usuarios pueden configurarlos para que no admitan determinados tipos de cookies o cookies específicas. Los usuarios también pueden eliminar las cookies en cualquier momento.',

        'Las cookies no pueden ejecutar ningún programa o virus.',

        '¿Por qué usamos cookies?',
        'Utilizamos cookies para saber cómo interactúan los usuarios con el contenido y mejorar su experiencia cuando visitan nuestros sitios web y conocer el sistema operativo que utiliza, el navegador, etc. Por ejemplo, algunas cookies sirven para recordar el idioma o las preferencias del usuario, de modo que este no tiene que seleccionarlos cada vez que visita nuestros sitios web. También utilizamos cookies para realizar un seguimiento de la ubicación geográfica, de manera que podemos mostrar al usuario las ubicaciones de las oficinas y gasolineras Shell más próximas. Por otra parte, las cookies nos permiten presentar al usuario contenido específico, como los vídeos incluidos en nuestros sitios web. Podemos utilizar lo que aprendemos sobre el comportamiento de los usuarios en los sitios web para presentarles una publicidad personalizada en sitios web de terceros, con la idea de volver a ofrecerle nuestros productos y servicios. Sin embargo, toda la información es usada sobre una base anónima de tal forma que los usuarios NO pueden ser identificados personalmente. De igual forma las cookies de este sitio NO pueden leer datos de su disco duro o dispositivo móvil desde el que esté accesando, ni tampoco será almacenada ni guardada en ningún caso la dirección IP de su dispositivo (el número que lo identifica).',

        '¿Qué tipos de cookies utilizamos?',

        'Cookies propias y de terceros',
        'En nuestro sitio web utilizamos tanto cookies propias como de terceros.',

        'Las cookies propias son cookies enviadas desde el dominio shellxxxx.com, utilizadas generalmente para identificar preferencias en cuanto a idioma y ubicación o para ofrecer las funciones básicas del sitio.',

        'Las cookies de terceros pertenecen a otras empresas y son gestionadas por estas (por ejemplo, proveedores de servicios o socios empresariales de ShellCardif). Estas cookies podrían ser necesarias para facilitar determinados formularios (por ejemplo, la presentación de una solicitud de empleo) o para permitir algún tipo de publicidad fuera del sitio web de ShellCardif.',

        'Cookies de sesión',
        'Se trata de cookies temporales utilizadas para recordar al usuario durante el transcurso de su visita al sitio web. Cuando el usuario cierra el navegador, desaparecen.',

        'Cookies permanentes',
        'Se emplean para recordar las preferencias del usuario en el sitio web y permanecen en el ordenador o dispositivo móvil incluso después de que se haya cerrado el navegador o reiniciado el ordenador. Utilizamos estas cookies para analizar el comportamiento del usuario, con el fin de obtener patrones de visita y poder mejorar las funciones del sitio web. Estas cookies también nos permiten presentar al usuario publicidad personalizada y medir la eficacia de las funciones y la publicidad de nuestro sitio.',

        '¿Cómo se utilizan las cookies con fines publicitarios?',
        'Las cookies y otras tecnologías utilizadas para colocar anuncios, como las balizas web, los píxeles y las etiquetas anónimas y de red, nos ayudan a presentar al usuario anuncios pertinentes de manera más eficaz. También nos ayudan a recompilar datos de auditoría agregados, estudios e informes de resultados para los anunciantes. Gracias a los píxeles, podemos conocer y mejorar la presentación de anuncios al usuario y saber qué anuncios concretos ha visto. Dado que el navegador web del usuario puede solicitar anuncios y balizas web directamente a servidores de red publicitarios, dichas redes podrán ver, modificar o configurar sus propias cookies, igual que si el usuario hubiera solicitado acceder a una página web desde su sitio.',

        'Aunque no utilizamos las cookies para crear perfiles web del comportamiento de los usuarios cuando navegan por sitios de terceros, sí empleamos datos agregados de terceros para mostrarle una publicidad personalizada de acuerdo con sus intereses. NOo facilitamos ningún dato personal recopilado a los anunciantes. El usuario puede desactivar la publicidad externa al sitio y la publicidad con información de terceros ajustando la configuración de las cookies. Si desactiva la publicidad, esta no desaparecerá de las páginas que visite, pero los anuncios que vea no estarán personalizados de acuerdo con sus intereses, es decir, esas cookies específicas no asociarán los anuncios que vea con sus intereses.',

        '¿Cómo se utilizan las cookies de terceros?',
        'Para algunas de las funciones incluidas en nuestros sitios web, recurrimos a terceros proveedores. Por ejemplo, cuando el usuario visita una página en la que se han insertado vídeos de YouTube o enlaces a estos. Dichos vídeos o enlaces (y cualquier otro contenido de otros proveedores) podrían incluir cookies de terceros. Por lo tanto, animamos a los usuarios a que consulten las políticas de privacidad de estos en sus sitios web, para saber cómo utilizan las cookies.',

        '¿Cómo se rechazan y eliminan las cookies?',
        'El usuario puede optar por rechazar o bloquear todas las cookies o grupos específicos de estas al visitar un sitio web de ShellCardif, haciendo clic en las preferencias sobre cookies que figuran en nuestros sitios web. Podrán modificar sus preferencias para los sitios web de Shell Cardif y/o los sitios web de cualquier otro proveedor cambiando la configuración del navegador. Tenga en cuenta que la mayoría de los navegadores aceptan las cookies de forma automática. Por lo tanto, si no desea que se utilicen cookies, es posible que deba tomar alguna medida para eliminarlas o bloquearlas. Si el usuario declina el uso de cookies, podrá visitar igualmente nuestros sitios web pero algunas de sus prestaciones podrían no funcionar correctamente. También se puede visitar www.allaboutcookies.org para consultar información pormenorizada sobre cómo eliminar o rechazar cookies y para obtener más información general sobre estas. Si se utiliza el sitio web sin eliminar ni rechazar las cookies, el usuario acepta que podamos emplear las cookies que no se han eliminado ni rechazado en el dispositivo del usuario.',
    ]
}