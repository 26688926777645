import React, {  useContext, useEffect, useRef, useState } from 'react'
import AuthContext from '../../../context/auth/authContext'
import SpinnerCuadrado from '../../helpers/SpinnerCuadrado'
import HeaderUserPerfil from './HeaderUserPerfil'

const HeaderUser = () => {

    const {globalDataUser} = useContext(AuthContext)
    const [subMenuUser, setSubMenuUser] = useState(false)
    const menuUserRef = useRef()
    const activarMenuBeneficios = () => {
        setSubMenuUser(!subMenuUser)
    }
    const [cerrando, setCerrando] = useState(false)

    useEffect(() => {

        const inactivarMenu = (e) => {
            if (!menuUserRef.current.contains(e.target)) {
                setSubMenuUser(false)
            }
        }
        document.addEventListener('mousedown', inactivarMenu)

        return () => {
            document.removeEventListener('mousedown', inactivarMenu)
        }
    }, [])

    return (
        <>
            {
                cerrando && <SpinnerCuadrado />
            }
            <div ref={menuUserRef} className='header__user'>

                <div className={`header__user ${subMenuUser ? 'remarcado' : ''}`} onClick={activarMenuBeneficios} >

                    <div className={`header__user-text`}>
                        <p className='desktop' style={{color:"#fff",opacity:"0",cursor:"none"}} >Mis Seguros</p>
                    </div>

                    <div className={`header__user-text`}>
                        <i className="fa-regular fa-circle-user"></i>
                        <p className='desktop' style={{ marginBottom: '0rem'}}>Hola, {globalDataUser.globalUsuName} </p>
                        <i className="fa-solid fa-angle-down header__selector desktop"></i>
                    </div>

                    <HeaderUserPerfil 
                        subMenuUser={subMenuUser}
                        setCerrando={setCerrando}
                    />
                </div>
            </div>
        </>
    )
}

export default HeaderUser