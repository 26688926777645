import React, { useState, useRef } from "react";
import AuthRegisterFormDocumento from "./AuthRegisterFormDocumento";
import AuthRegisterFormCodigo from "./AuthRegisterFormCodigo";
import AuthRegisterFormPass from "./AuthRegisterFormPass";
import SpinnerCuadrado from "../../helpers/SpinnerCuadrado";
import AuthRegisterSuccess from "./AuthRegisterSuccess";

const AuthRegisterFormIdentification = () => {
  const [errorData, setErrorData] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [spinner, setSpinner] = useState(false);
  const [formCode, setFormCode] = useState({
    id: "",
    code: "",
    fono: "",
    tokenCaptcha: "",
  });

  const [count, setCount] = useState(1);

  const [form, setForm] = useState({
    identificacion: "",
    tokenCaptcha: "",
  });

  // RECAPTCHA
  const recaptchaRef = useRef();

  return (
    <>
      {spinner && <SpinnerCuadrado />}

      {count === 1 && (
        <AuthRegisterFormDocumento
          errorData={errorData}
          mensaje={mensaje}
          recaptchaRef={recaptchaRef}
          setMensaje={setMensaje}
          setSpinner={setSpinner}
          setErrorData={setErrorData}
          setFormCode={setFormCode}
          formCode={formCode}
          setCount={setCount}
          form={form}
          setForm={setForm}
        />
      )}
      {count === 2 && (
        <AuthRegisterFormCodigo
          errorData={errorData}
          mensaje={mensaje}
          formCode={formCode}
          recaptchaRef={recaptchaRef}
          setMensaje={setMensaje}
          setSpinner={setSpinner}
          form={form}
          setErrorData={setErrorData}
          setCount={setCount}
          setFormCode={setFormCode}
        />
      )}
      {count === 3 && (
        <AuthRegisterFormPass
          errorData={errorData}
          mensaje={mensaje}
          recaptchaRef={recaptchaRef}
          setMensaje={setMensaje}
          setSpinner={setSpinner}
          setErrorData={setErrorData}
          formCode={formCode}
          setCount={setCount}
          form={form}
        />
      )}
      {count === 4 && <AuthRegisterSuccess />}
    </>
  );
};

export default AuthRegisterFormIdentification;
