import * as CryptoJS from "crypto-js";
import { CLI_PUB } from "../../enviroments/enviroment"
import { Buffer } from 'buffer';

export function encryptDecrypt(password, keyPass) {
     var key = keyPass.split("");
    var output = [];
  
    for (var i = 0; i < password.length; i++) {
      var charCode = password.charCodeAt(i) ^ key[i % key.length].charCodeAt(0);
      output.push(String.fromCharCode(charCode));
    }
    return output.join("");
}


export function encodeBase64(data){
  return Buffer.from(data).toString('base64');
}

export function decodeBase64(data) {
  return Buffer.from(data, 'base64').toString('ascii');
}

export function isactive(){
   return sessionStorage.getItem('token')&&sessionStorage.getItem('user')?true:false; 
}

export function getDataUser(){
  return JSON.parse(CryptoJS.AES.decrypt(sessionStorage.getItem('user'),CLI_PUB).toString(CryptoJS.enc.Utf8));
}

export function getToken(){
  return CryptoJS.AES.decrypt(sessionStorage.getItem('token'),CLI_PUB).toString(CryptoJS.enc.Utf8);
}

export function getToken2(){
  return JSON.parse(Buffer.from(getToken().split('.')[1],'base64').toString('ascii'))
}

export function getIdUser(){
  return Number(Buffer.from(getDataUser().idUsuario, 'base64').toString('ascii'))
}

export function getCoberturas(){

  let coberturas=[];
  if(isactive()){
    coberturas = getDataUser()['perfiles'].$values
  }else{
    coberturas = ['Desempleo','Itt','Fraude','Salud','Cancer', 'Accidentes','Vida'];
  }
  return coberturas;
  
}

export function getCoberturasGlobal(coberturas){

   let coberturaGlobal = {
    modValorFactura: false,
    modCuidados: false,
    modPFinanciera: false,
    modEmpleabilidad: false,
    modEmprendimiento: false,
    modSalud: false,
    modVida: false,
    modCancer: false,
    modAccidentes: false,
    modProteccionFinanciera: false,
    modVidaPlus: false,
    modPFinancieraElemental: false,
    modCoursera: false,
   }
   
    coberturaGlobal.modCuidados = (coberturas.includes("Cuidados"))
    coberturaGlobal.modEmprendimiento = (coberturas.includes("Emprendimiento") || coberturas.includes("Proteccion+Emprendimiento") )
    coberturaGlobal.modEmpleabilidad = (coberturas.includes("Empleabilidad") || coberturas.includes("Proteccion+Empleabilidad"))
    coberturaGlobal.modProteccionFinanciera = (coberturas.includes("Protección Financiera") || coberturas.includes("Proteccion+Financiera"))
    coberturaGlobal.modVidaPlus = (coberturas.includes("Vital Plus") || coberturas.includes("Proteccion+VitalPlus"))
    coberturaGlobal.modPFinancieraElemental = (coberturas.includes("Mi Salud Financiera") || coberturas.includes("Proteccion+Financiera+Elemental"))
    coberturaGlobal.modCoursera = (coberturas.includes("Eco Coursera"))
    coberturaGlobal.modSalud = (coberturas.includes("Salud"))
    coberturaGlobal.modVida = (coberturas.includes("Vida"))
    coberturaGlobal.modCuidados = (coberturas.includes("Cuidados"))
    coberturaGlobal.modValorFactura = (coberturas.includes("Valor Factura"))
  
  return coberturaGlobal;
  
}


export function getMed(){

  let coberturas = [];
    if (isactive()) {
      coberturas = getDataUser()['perfilesUs'];
    }
    else{
      coberturas = ['Desempleo','Itt','Fraude'];
    }
    return coberturas;

}

export function getCurrentDate(separator=''){

  let newDate = new Date()
  let date = newDate.getDate();
  let month = newDate.getMonth() + 1;
  let year = newDate.getFullYear();
  
  return `${year}${separator}${month<10?`0${month}`:`${month}`}${separator}${date}`
}
