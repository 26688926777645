import ReCAPTCHA from 'react-google-recaptcha';
import { NavLink } from 'react-router-dom';
import { COD_RECAPTCHA_V3 } from '../../../enviroments/enviroment';
import { recovery } from '../../../services/auth';
import { addLogs } from '../../../services/logs_proteccion';

const AuthPasswordFormDocumento = ({ errorData, setErrorData, mensaje, setMensaje, recaptchaRef, setSpinner, form, setForm, setFormCode, formCode, setCount, textVolver }) => {

    const handleChange = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value,
        })
    }

    const handleSubmitDocumento = async (e) => {
        e.preventDefault();

        if (form.identificacion.trim() !== "") {

            const token = await recaptchaRef.current.executeAsync();
            recaptchaRef.current.reset()
            if (!token) {
                setMensaje('Captcha inválido, actualizar web')
                return
            }

            setSpinner(true)

            let objUsuario = {
                identificacion: form.identificacion,
                tokenCaptcha: token
            }

            addLogs('login', 'recuperar contraseña usuario', `${objUsuario}`);

            recovery(objUsuario).then(res => {
                // console.log({ res })
                setSpinner(false)
                if (res.codigo === 0) {

                    setMensaje("")
                    setErrorData(false)
                    setFormCode({ ...formCode, id: res.data, tokenCaptcha: token })

                    setCount(2)
                    // TagManager.dataLayer({
                    //     dataLayer: {
                    //         event: 'recuperarContraseña',
                    //         category: 'login',
                    //         action: 'Recuperar contraseña',
                    //         label: '(not available)',
                    //     }
                    // })
                    return
                } else if (res.codigo === 1) {
                    if (res.mensaje === 'No se validó el captcha') {
                        setMensaje(res.mensaje)
                    } else {
                        setMensaje('Correo no válido.')
                    }
                } else {
                    setMensaje(res.mensaje)
                }
                setErrorData(true)
            }).catch((err) => {
                setMensaje(err)
                setErrorData(true)
                setSpinner(false)
            })
        } else {
            setMensaje("Para continuar debes completar todos los campos.")
        }
    }

    return (
        <div className="auth__login">
            <h3 className="auth__title">¿Olvidaste tu contraseña?</h3>
            <p className="auth__subtitle" > No hay problema. Ingresa tu Correo, para recuperar tu contraseña, misma que será enviada a tu celular y correo electrónico registrado.  </p>
            {
                errorData &&
                <div className="cajaregistra__error" >
                    <i className="fa-solid fa-triangle-exclamation"></i>
                    <div className="error__informativo">
                        <p>{mensaje}</p>
                    </div>
                </div>
            }

            <form onSubmit={handleSubmitDocumento}>

                <div className="auth__login-label" >Ingresar Usuario</div>
                <div className="auth__login-group" >
                    <input
                        type="text"
                        className="auth__login-docompleto"
                        placeholder="Ingresa tu usuario"
                        name="identificacion"
                        value={form.identificacion}
                        onChange={handleChange}
                    />
                </div>

                <div>
                    <input
                        type="submit"
                        className="btn__login completo"
                        value="Enviar"
                    />
                    <ReCAPTCHA
                        ref={recaptchaRef}
                        size="invisible"
                        sitekey={COD_RECAPTCHA_V3}
                    />
                </div>
            </form>

            <NavLink to="/iniciasesion" className={'linkRegistra'} >
                <div className="top20">
                    {textVolver}
                </div>
            </NavLink>
        </div>
    )
}

export default AuthPasswordFormDocumento