import { useContext, useEffect, useState } from "react";
import * as CryptoJS from "crypto-js";
import { CLI_PUB } from "../../../enviroments/enviroment";
import { getSession } from "../../../services/auth";
import {
  getCoberturas,
  getCoberturasGlobal,
  getIdUser,
  isactive,
} from "../../helpers/validations";
import AuthContext from "../../../context/auth/authContext";
import { refreshTokenSession } from "../../helpers/sessions";
import { useNavigate } from "react-router-dom";
import SpinnerCuadrado from "../../helpers/SpinnerCuadrado";

const AuthAutoLogin = () => {
  const { globalIniciarSesion, globalEcosistemas } = useContext(AuthContext);

  const [showSpinner, setShowSpinner] = useState(false);

  let navigate = useNavigate();

  const validaruta = (perfilesActivos) => {
    if (isactive()) {
      let cobertura = globalEcosistemas.filter((e) => e.active === true);
      for (let i = 0; i < cobertura.length; i++) {
        for (let x = 0; x < perfilesActivos.length; x++) {
          if (cobertura[i].name === perfilesActivos[x]) {
            navigate("/ecosistema" + cobertura[i].rutaexterna, {
              state: {
                loggedIn: true,
              },
            });
            return;
          }
        }
      }
    }
  };

  useEffect(() => {
    setShowSpinner(true);
    let queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const token = urlParams.get("token");

    sessionStorage.setItem("token", CryptoJS.AES.encrypt(token, CLI_PUB));
    getSession(token).then((data) => {
      let res = { data }.data;
      let perfilesActivos = res.perfiles.$values;

      sessionStorage.setItem(
        "user",
        CryptoJS.AES.encrypt(JSON.stringify(res), CLI_PUB)
      );

      let idUser = getIdUser();
      globalIniciarSesion({
        globalDataUser: {
          globalUsuId: idUser,
          globalUsuName: res.nombres,
          globalUsuSurnameMat: res.apellidoMaterno,
          globalUsuSurname: res.apellidoPaterno,
          globalUsuCellphone: res.numeroCelular,
          globalUsuEmail: res.correoElectronico,
          globalUsuTipoDoc: res.dicTipoDocumento,
          globalDocIdentidad: res.identificacion,
          globalUsuRole: "",
          globalInvitado: res.nroinvitado > 0,
          globalNroInvitados: res.nroinvitado,
          globalPlanCardifId: res.idPlanCardif,
        },
        globalModulos: getCoberturasGlobal(getCoberturas()),
        globalToken: CryptoJS.AES.encrypt(token, CLI_PUB),
        globalTokenUser: CryptoJS.AES.encrypt(JSON.stringify(res), CLI_PUB),
        globalAutenticado: true,
        globalCargando: false,
        globalStatusEncuesta: res.encuestaNeo === 1,
      });

      refreshTokenSession();
      validaruta(perfilesActivos);
    });
  }, []);

  return <>{showSpinner && <SpinnerCuadrado />}</>;
};

export default AuthAutoLogin;
