import React from "react";
import useCards from "../../../hooks/useCards";

const SaludCards = () => {
  const { homeinCards } = useCards();

  return <>{homeinCards(12)}</>;
};

export default SaludCards;
