import React, { useContext } from 'react'
import AuthContext from '../../../context/auth/authContext'
import '../styles/titulo.css'

const EcosistemaTitulo = () => {
    const {globalEcosistemas, globalEcosistemaActivo} = useContext(AuthContext)
 
    return (
      <div 
        className={`desktop proteccion__titulo ${globalEcosistemas.find(a => a.id === globalEcosistemaActivo).color}`}>
      </div>
    )
  }

export default EcosistemaTitulo