import React from 'react'
import ReactDOM from 'react-dom'
import PortalCierreSesionDetalle from './PortalCierreSesionDetalle'

const PortalCierreSesion = () => {
  return (
        <div>
            {
                ReactDOM.createPortal(
                    <PortalCierreSesionDetalle />,
                    document.getElementById('cierre')
                )
            }
        </div>
    )
}

export default PortalCierreSesion