import React, { Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import AuthLogin from "../modules/auth/screens/AuthLogin";
import AuthRegister from "../modules/auth/screens/AuthRegister";
import "../styles/general.css";
import "../styles/steps.css";

import HomeOutNosotros from '../modules/homeout/principal/components/HomeOutNosotros'
import HomeOutScreen from '../modules/homeout/principal/screens/HomeOutScreen'
import AuthPassword from '../modules/auth/screens/AuthPassword'
import RouterOutEmprendimiento from './emprendimiento/RouterOutEmprendimiento'
import RouterOutEmpleabilidad from './empleabilidad/RouterOutEmpleabilidad'
import RouterOutProteccionFinanciera from './proteccion-financiera/RouterOutProteccionFinanciera'
import RouterOutProteccionFinancieraElemental from './proteccion-financiera-elemental/RouterOutProteccionFinancieraElemental'
import RouterOutCoursera from './coursera/RouterOutCoursera'
import RouterOutSalud from './salud/RouterOutSalud'
import RouterOutCuidados from "./cuidados/RouterOutCuidados";
import FallbackCards from '../modules/helpers/FallbackCards'
import ScrolltoTop from './ScrolltoTop'
import RouterOutVidaPlus from './vidaplus/RouterOutVidaPlus'
import AuthRegisterSuccess from '../modules/auth/components/AuthRegisterSuccess'
import AuthAutoLogin from '../modules/auth/components/AuthAutoLogin'
import RouterOutVida from './vida/RouterOutVida'
import RouterOutValorFactura from "./valor-factura/RouterOutValorFactura";

const RouterHomeOut = () => {
  return (
    <>
      <Suspense fallback={<FallbackCards />}>
        <ScrolltoTop />
        <Routes>
          <Route path='/emprendimiento/*' element={<RouterOutEmprendimiento />} />
          <Route path='/empleabilidad/*' element={<RouterOutEmpleabilidad />} />
          <Route path='/proteccion-financiera/*' element={<RouterOutProteccionFinanciera />} />
          <Route path='/vidaplus/*' element={<RouterOutVidaPlus />} />
          <Route path='/mi-salud-financiera/*' element={<RouterOutProteccionFinancieraElemental />} />
          <Route path='/coursera/*' element={<RouterOutCoursera />} />
          <Route path='/salud/*' element={<RouterOutSalud />} />
          <Route path='/vida/*' element={<RouterOutVida />} />
          <Route path="/cuidados/*" element={<RouterOutCuidados />} />
          <Route path='/valor-factura/*' element={<RouterOutValorFactura />} />

          <Route path="/iniciasesion" element={<AuthLogin />} />
          <Route path="/registro" element={<AuthRegister />} />
          <Route path="/registroexitoso" element={<AuthRegisterSuccess />} />
          <Route path="/nosotros" element={<HomeOutNosotros />} />

          <Route path="/password" element={<AuthPassword />} />
          <Route path="/auth/auto-login" element={<AuthAutoLogin />} />
          <Route path="/" element={<HomeOutScreen />} />

          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Suspense>
    </>
  );
};

export default RouterHomeOut;
