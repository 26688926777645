import "../../styles/404.css";
import { useLocation, useNavigate } from "react-router-dom";

const PageNotFound = () => {
  const { pathname } = useLocation();
  let navigate = useNavigate();

  const gotoInicio = () => {
    if (pathname.includes("ecosistema")) {
      navigate("/ecosistema/");
    } else {
      navigate("/");
    }
  };

  return (
    <div className="home p-0 d-lg-block">
      <div className="container container-cookie bannerInicial">
        <div className="row bannerI">
          <div className="col-12 col-md-6 col-back">
            <img
              src="assets/img/homeout/logo_vivetuseguro.webp"
              alt="logo_proteccion_celular"
              className="banner__logo"
            />
            <div className="home__text-container">
              <div className="home__title">
                <h2>404</h2>
              </div>
              <div className="subtitulo__404">
                <b>This page not found</b>
              </div>

              <div className="home__text">
                <p className="mb-0">
                  Parece que algo salió mal, discúlpanos por las molestias.
                </p>
              </div>
              <button
                className="btn__inicio"
                style={{ width: "35%" }}
                onClick={gotoInicio}
              >
                VOLVER AL INICIO
              </button>
            </div>
          </div>

          <div className="col-12 col-md-6 col-image p-0 d-lg-block d-none">
            <div className="home__image-container">
              <img
                src="/assets/img/404/img-404.png"
                className="home__image_desks"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageNotFound;
