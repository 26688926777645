import React, { Suspense } from 'react'
import FallbackCards from '../../../helpers/FallbackCards'
import useNearScreen from '../../../hooks/useNearScreen'

const PrincipalCarousel = React.lazy(()=>import('./PrincipalCarousel'))

const LazyPrincipalCarousel = () => {

    const { elementRef, isNear } = useNearScreen('20px')

    return <div ref={elementRef}>
        <Suspense fallback={<FallbackCards />}>
            {isNear ? <PrincipalCarousel /> : <FallbackCards />}
        </Suspense>
    </div >
}

export default LazyPrincipalCarousel