import React from 'react'
import ReCAPTCHA from 'react-google-recaptcha';
import { COD_RECAPTCHA_V3 } from '../../../enviroments/enviroment';
import { sendCode } from '../../../services/auth';
import { addLogs } from '../../../services/logs_proteccion';

const AuthPasswordFormCodigo = ({ errorData, mensaje, formCode, recaptchaRef, setMensaje, setSpinner, form, setErrorData, setCount, setFormCode }) => {

    const handleSubmitCodigo = async (e) => {
        e.preventDefault();

        if (formCode.code.trim() !== "") {

            const token = await recaptchaRef.current.executeAsync();
            recaptchaRef.current.reset()
            if (!token) {
                setMensaje('Captcha inválido, actualizar web')
                return
            }

            setSpinner(true)

            let objUsuario = {
                id: formCode.id,
                code: formCode.code,
                tokenCaptcha: token
            }

            addLogs('login', 'Intento Recuperar contraseña código', form.identificacion);

            sendCode(objUsuario).then(res => {

                // console.log({ res })
                setSpinner(false)
                if (res.codigo === 0) {

                    addLogs('login', 'Recuperar contraseña código Exitoso', form.identificacion);
                    setMensaje("")
                    setErrorData(false)
                    setCount(3)
                } else {
                    addLogs('login', 'Error Recuperar contraseña código', form.identificacion);
                    setMensaje('Código inválido')
                    setErrorData(true)
                }
            }).catch((err) => {
                setMensaje(err)
                setErrorData(true)
                setSpinner(false)
            })
        } else {
            setMensaje("Debes proporcionar un código.")
        }
    }

    const handleChangeCode = (e) => {
        setFormCode({
            ...formCode,
            [e.target.name]: e.target.value,
        })
    }

    return (
        <div className="auth__login">
            <h3 className="auth__title">Código de verificación</h3>
            <p className="auth__subtitle" >Ingresa tu código de verificación que se ha enviado a tu teléfono celular o correo electrónico para recuperar la contraseña. </p>

            {
                errorData &&
                <div className="cajaregistra__error" >
                    <i className="fa-solid fa-triangle-exclamation"></i>
                    <div className="error__informativo">
                        <p>{mensaje}</p>
                    </div>
                </div>
            }
            <form onSubmit={handleSubmitCodigo}>

                <div className="auth__login-label" >Código</div>
                <div className="auth__login-group" >
                    <input
                        type="text"
                        className="auth__login-docompleto"
                        placeholder="Ingresa el código"
                        name="code"
                        value={formCode.code}
                        onChange={handleChangeCode}
                    />
                </div>

                <div>
                    <input
                        type="submit"
                        className="btn__login completo"
                        value="Validar"
                    />

                    <ReCAPTCHA
                        ref={recaptchaRef}
                        size="invisible"
                        sitekey={COD_RECAPTCHA_V3}
                    />
                </div>
            </form>
        </div>
    )
}

export default AuthPasswordFormCodigo