import React, { Suspense } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import FallbackCards from '../../modules/helpers/FallbackCards'

const SaludAgendamiento = React.lazy(() => import('../../modules/homein/salud/components/agendamiento/SaludAgendamiento'))
const SaludScreen = React.lazy(() => import('../../modules/homein/salud/screens/SaludScreen'))
const PageNotFound = React.lazy(() => import('../../modules/404/PageNotFound'))

const RouterSalud = () => {
    return (
        <>
            <Suspense fallback={<FallbackCards />}>
                <Routes>
                    <Route path='/checkup-anual' element={<SaludAgendamiento />} />
                    <Route path='/' element={<SaludScreen />} />
                    <Route path='/404' element={<PageNotFound />} />
                    <Route path='*' element={<Navigate to="/404" />} />
                </Routes>
            </Suspense>
        </>
    )
}

export default RouterSalud