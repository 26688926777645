import React, { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import AuthContext from "../../../../context/auth/authContext";
import Modulos from "../../../general/screen/Modulos";
import EcosistemaTitulo from "../../ecosistemas/EcosistemaTitulo";
import HomeInUser from "../../principal/components/HomeInUser";
import CourseraCards from "../components/CourseraCards";
import useTitle from "../../../hooks/useTitle";

const CourseraScreen = () => {
  useTitle({ title: "Detalle Ecosistema Eco Coursera" });
  const { pathname } = useLocation();
  const { globalActualizarEcosistemaActivo } = useContext(AuthContext);

  useEffect(() => {
    if (pathname === "/ecosistema/coursera") {
      globalActualizarEcosistemaActivo(11);
    }
  }, []);

  return (
    <div className="homein">
      <EcosistemaTitulo />

      <div className="homein__resumen">
        <HomeInUser id={11} />
      </div>

      <div className="homein__detalle">
        <Modulos />
        <CourseraCards />
      </div>
    </div>
  );
};

export default CourseraScreen;
