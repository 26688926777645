import { URL_BACKEND_AUTHS } from "../enviroments/enviroment"

export const getPlantillas = async (token) =>{
    try {
        const response = await fetch(`${URL_BACKEND_AUTHS}/Hoja/plantillas`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }
        )
        const json = await response.json()
        return json


    } catch (err) {
        throw err
    }
}

export const getCurriculum = async (token,idUsuario) =>{
    try {
        const response = await fetch(`${URL_BACKEND_AUTHS}/hoja/TraeDatosPersonalesIdUser/${idUsuario}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }
        )

        const json = await response.json()
        return json


    } catch (err) {
        throw err
    }
}

export const getDiccionario = async (_id) =>{
    try {
        const response = await fetch(`${URL_BACKEND_AUTHS}/Login/tipos/${_id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                //'Authorization': 'Bearer ' + getToken()
            }
        }
        )
        const json = await response.json()
        return json


    } catch (err) {
        throw err
    }
}

export const saveCurriculum = async (token,obj) => {
    try {
        const response = await fetch(`${URL_BACKEND_AUTHS}/hoja/UpdateDatosPersonales`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(obj),
        }
        )
        const json = await response.json()
        return json


    } catch (err) {
        throw err
    }
}

export const setPersonalization = async (token,idPlantillaAplicada,_user,_tipoTexto,_color,_ecosistema) =>{
    try {
        const response = await fetch(`${URL_BACKEND_AUTHS}/Hoja/actualizarPlantilla/${idPlantillaAplicada}?user=`+_user+`&tipoTexto=`+_tipoTexto+`&colorHv=`+_color.replace("#","")+`&ecosistema=${_ecosistema}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }
        )
        const json = await response.json()
        return json


    } catch (err) {
        throw err
    }
}

export const getPdfCurriculum = async (token,idUsuario) =>{
    try {
        const response = await fetch(`${URL_BACKEND_AUTHS}/Hoja/PdfJson/${idUsuario}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }
        )
        const json = await response.json()
        return json


    } catch (err) {
        throw err
    }
}

export const saveInfoEducativa = async (token,obj) => {
    try {

        const response = await fetch(`${URL_BACKEND_AUTHS}/Hoja/InsertaEstudioPrincipal`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(obj),
        }
        )
        const json = await response.json()
        return json


    } catch (err) {
        throw err
    }
}

export const editInfoEducativa = async (token,obj) => {
    try {
        const response = await fetch(`${URL_BACKEND_AUTHS}/Hoja/EditarEstudioPrincipal`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(obj),
        }
        )
        const json = await response.json()
        return json


    } catch (err) {
        throw err
    }
}

export const saveInfoLaboral = async (token,obj) => {
    try {
        const response = await fetch(`${URL_BACKEND_AUTHS}/Hoja/InsertaExperienciaLaboral`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(obj),
        }
        )
        const json = await response.json()
        return json


    } catch (err) {
        throw err
    }
}

export const editInfoLaboral = async (token,obj) => {
    try {
        const response = await fetch(`${URL_BACKEND_AUTHS}/Hoja/EditaExperienciaLaboral`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(obj),
        }
        )
        const json = await response.json()
        return json


    } catch (err) {
        throw err
    }
}

export const saveInfoComplementaria = async (token,obj) => {
    try {
        const response = await fetch(`${URL_BACKEND_AUTHS}/Hoja/InsertaEstudioComplementario`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(obj),
        }
        )
        const json = await response.json()
        return json


    } catch (err) {
        throw err
    }
}


export const editInfoComplementaria = async (token,obj) => {
    try {
        const response = await fetch(`${URL_BACKEND_AUTHS}/Hoja/EditarEstudioComplementario`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(obj),
        }
        )
        const json = await response.json()
        return json


    } catch (err) {
        throw err
    }
}

export const savePhotoCurriculum = async (token,obj) => {
    try {
        const response = await fetch(`${URL_BACKEND_AUTHS}/Hoja/subirArchivo`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(obj),
        }
        )
        const json = await response

        return json


    } catch (err) {
        throw err
    }
}

export const getPhotoCurriculum = async (token,id) => {
    try {
        const response = await fetch(`${URL_BACKEND_AUTHS}/Hoja/mostrarImagen/${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }
        )
        const json = await response.json()
        return json


    } catch (err) {
        throw err
    }
}

export const deleteInfoEducacion = async (token,id) => {
    try {
        const response = await fetch(`${URL_BACKEND_AUTHS}/Hoja/NoVigenteEstudio/${id}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
        })
        const json = await response.json()
        return json

    } catch (err) {
        throw err
    }
}

export const deleteInfoLaboral = async (token,id) => {
    try {
        const response = await fetch(`${URL_BACKEND_AUTHS}/Hoja/NoVigenteLaboral/${id}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
        })
        const json = await response.json()
        return json

    } catch (err) {
        throw err
    }
}
