import { HashLink } from 'react-router-hash-link';
import '../styles/homeOutPrincipal.css'
import PrincipalPresentacionForm from './PrincipalPresentacionForm';

const PrincipalPresentacion = () => {

    

    return (
        <section className='out__principalpresentacion'>

            <div className='out__principalpresentacion-texto'>
                <div>
                    <h2>¿Ya estás <span>disfrutando</span></h2>
                    <h2><span>tus beneficios?</span></h2>
                    <h5>Activa tu cuenta en 1 minuto.</h5>
                    <PrincipalPresentacionForm />
                </div>
            </div>

            <div className="out__principalpresentacion-abajo">
                <div className='desktop'>
                    {/* <HashLink style={{fontFamily:'Scotia-Headline', letterSpacing:'1px'}}
                        to="/#carouselPrincipal"
                        scroll={el => el.scrollIntoView({ behavior: 'smooth', block: 'end' })}
                    >DESPLÁZATE HACIA ABAJO</HashLink> */}
                </div>
            </div>



        </section>
    )
}

export default PrincipalPresentacion