const FormBaseBeneficiario = ({
  i,
  onChangeForm,
  infoCuestionario,
  cerrar,
}) => {
  return (
    <>
      <div className="row">
        {i > 0 && (
          <div className="text-end">
            <button type="button" className="btnClose" onClick={cerrar}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="35"
                height="35"
                className="bi bi-x-circle"
                viewBox="0 0 16 16"
              >
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"></path>
              </svg>
            </button>
          </div>
        )}
        <div className="row">
          <div className="col-12 col-md-6 mt-3">
            <label className="titulo_form" style={{ paddingRight: "3rem" }}>
              *Primer nombre
            </label>{" "}
            <br />
            <input
              className="form-control-salud-beneficiario fondo_campo"
              type="text"
              name="primerNombreUsuario"
              value={infoCuestionario[i].primerNombreUsuario}
              placeholder="Camilo"
              style={{ borderRadius: "0px 8px 8px 0px", flexGrow: 1 }}
              onChange={(e) => {
                onChangeForm(e, i);
              }}
              required
            />
          </div>
          <div className="col-12 col-md-6 mt-3">
            <label className="titulo_form" style={{ paddingRight: "3rem" }}>
              Segundo nombre (opcional)
            </label>{" "}
            <br />
            <input
              className="form-control-salud-beneficiario fondo_campo"
              type="text"
              name="segundoNombreUsuario"
              value={infoCuestionario[i].segundoNombreUsuario}
              placeholder="Andres "
              style={{ borderRadius: "0px 8px 8px 0px", flexGrow: 1 }}
              onChange={(e) => {
                onChangeForm(e, i);
              }}
            />
          </div>
          <div className="col-12 col-md-6 mt-3">
            <label className="titulo_form" style={{ paddingRight: "3rem" }}>
              *Apellido Paterno
            </label>{" "}
            <br />
            <input
              className="form-control-salud-beneficiario fondo_campo"
              type="text"
              name="apellidoPaternoUsuario"
              value={infoCuestionario[i].apellidoPaternoUsuario}
              placeholder="Leon"
              style={{ borderRadius: "0px 8px 8px 0px", flexGrow: 1 }}
              onChange={(e) => {
                onChangeForm(e, i);
              }}
              required
            />
          </div>
          <div className="col-12 col-md-6 mt-3">
            <label className="titulo_form" style={{ paddingRight: "3rem" }}>
              *Apellido Materno
            </label>{" "}
            <br />
            <input
              className="form-control-salud-beneficiario fondo_campo"
              type="text"
              name="apellidoMaternoUsuario"
              value={infoCuestionario[i].apellidoMaternoUsuario}
              placeholder="Sanchez"
              style={{ borderRadius: "0px 8px 8px 0px", flexGrow: 1 }}
              onChange={(e) => {
                onChangeForm(e, i);
              }}
              required
            />
          </div>

          <div className="col-12 col-md-6 mt-3">
            <label className="titulo_form" style={{ paddingRight: "3rem" }}>
              *Parentesco
            </label>{" "}
            <br />
            <select
              className="form-control-salud-beneficiario fondo_campo"
              name="parentesco"
              onChange={(e) => {
                onChangeForm(e, i);
              }}
              required
            >
              <option value="">Selecccionar</option>
              <option value="Padre">Padre</option>
              <option value="Madre">Madre</option>
              <option value="Conyuge">Cónyuge</option>
              <option value="Hijo/a">Hijo/a</option>
            </select>
          </div>

          <div className="col-12 col-md-6 mt-3">
            <div className="custom-radio" style={{ fontSize: "15px" }}>
              <label className="titulo_form">*Género</label> <br />
              <input
                className="cuestionario__radio-button_salud"
                type="radio"
                name={`genero${i}`}
                value="Masculino"
                style={{ verticalAlign: "middle" }}
                onChange={(e) => {
                  onChangeForm(e, i);
                }}
                required
              />
              <label style={{ paddingRight: "3rem", paddingLeft: "0.5rem" }}>
                {" "}
                Másculino
              </label>
              <div className="breakLine-mobile"></div>
              <input
                className="cuestionario__radio-button_salud"
                type="radio"
                name={`genero${i}`}
                value="Femenino"
                style={{ verticalAlign: "middle" }}
                onChange={(e) => {
                  onChangeForm(e, i);
                }}
                required
              />
              <label style={{ paddingRight: "3rem", paddingLeft: "0.5rem" }}>
                {" "}
                Femenino
              </label>
            </div>
          </div>

          <div className="col-12 col-md-6 mt-3">
            <label className="titulo_form" style={{ paddingRight: "3rem" }}>
              *Celular
            </label>{" "}
            <br />
            <input
              className="form-control-salud-beneficiario"
              type="number"
              name="numeroCelular"
              value={infoCuestionario[i].numeroCelular}
              placeholder="Ej: 3143760932"
              style={{ borderRadius: "0px 8px 8px 0px", flexGrow: 1 }}
              onChange={(e) => {
                onChangeForm(e, i);
              }}
              required
            />
          </div>

          <div className="col-12 col-md-6 mt-3">
            <label className="titulo_form" style={{ paddingRight: "3rem" }}>
              *Correo electrónico
            </label>{" "}
            <br />
            <input
              className="form-control-salud-beneficiario"
              type="email"
              name="correoElectronico"
              value={infoCuestionario[i].correoElectronico}
              placeholder="Ej: ejemplo@ejemplo.com"
              style={{ borderRadius: "0px 8px 8px 0px", flexGrow: 1 }}
              onChange={(e) => {
                onChangeForm(e, i);
              }}
              required
            />
          </div>
          <div className="col-12 col-md-6 mt-3">
            <label className="titulo_form" style={{ paddingRight: "3rem" }}>
              *Fecha de nacimiento
            </label>{" "}
            <br />
            <input
              className="form-control-salud-beneficiario"
              type="date"
              name="fechaNacimiento"
              value={infoCuestionario[i].fechaNacimiento}
              style={{ borderRadius: "0px 8px 8px 0px", flexGrow: 1 }}
              onChange={(e) => {
                onChangeForm(e, i);
              }}
              max={new Date().toJSON().slice(0, 10)}
              required
            />
          </div>
          <div className="col-12 col-md-6 mt-3">
            <label className="titulo_form" style={{ paddingRight: "3rem" }}>
              *RFC
            </label>{" "}
            <br />
            <input
              className="form-control-salud-beneficiario"
              type="text"
              name="RFC"
              value={infoCuestionario[i].RFC}
              placeholder="Ej: MOCD0000000W0"
              style={{ borderRadius: "0px 8px 8px 0px", flexGrow: 1 }}
              onChange={(e) => {
                onChangeForm(e, i);
              }}
              required
            />
          </div>
          <div className="col-12 col-md-6 mt-3">
            <label className="titulo_form" style={{ paddingRight: "3rem" }}>
              *CURP
            </label>{" "}
            <br />
            <input
              className="form-control-salud-beneficiario"
              type="text"
              name="CURP"
              value={infoCuestionario[i].CURP}
              placeholder="Ej: MOCD000000MDFNS00"
              style={{ borderRadius: "0px 8px 8px 0px", flexGrow: 1 }}
              onChange={(e) => {
                onChangeForm(e, i);
              }}
              required
              minLength="18"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default FormBaseBeneficiario;
