import React from 'react';
// import ReactDOM from 'react-dom'
import {createRoot} from 'react-dom/client'
import App from './App';


// REACT 18
createRoot(document.getElementById('root')).render(<App />)

// REACT 17
// ReactDOM.render(
//     <App />,
//   document.getElementById('root')
// );
