import React, { Suspense } from 'react'
import FallbackCards from '../../../helpers/FallbackCards'
import useNearScreen from '../../../hooks/useNearScreen'

const PrincipalContacto = React.lazy(()=> import('./PrincipalContacto'))

const LazyPrincipalContacto = () => {

    const { elementRef, isNear } = useNearScreen('20px')

    return <div ref={elementRef}>
        <Suspense fallback={<FallbackCards />}>
            {
                isNear ? <PrincipalContacto /> : <FallbackCards />
            }
        </Suspense>
    </div>
}

export default LazyPrincipalContacto