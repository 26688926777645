import React from 'react'
import useCards from '../../../hooks/useCards'
import useOutEcosistema from '../../../hooks/useOutEcosistema'
import useTitle from '../../../hooks/useTitle'
import HomeOutActivaTuCuenta from '../../ecosistemas/HomeOutActivaTuCuenta'
import PrincipalContacto from '../../principal/components/PrincipalContacto'
import PrincipalLogos from '../../principal/components/PrincipalLogos'

const HomeOutProteccionFinanciera = () => {

  useTitle({ title: 'Protección Financiera | BNP Paribas Cardif' })
  const { ecosistemaPresentacionOut } = useOutEcosistema()
  const { ecosistemaCards } = useCards()

  return (
    <>
      {ecosistemaPresentacionOut(8)}

      {ecosistemaCards(8)}

      <HomeOutActivaTuCuenta />
      <div style={{ backgroundColor:'red !important' }}>
        <PrincipalLogos direccion='centro' />
        <PrincipalContacto /> {/* footer - homeOut main */}
      </div>

    </>
  )
}

export default HomeOutProteccionFinanciera