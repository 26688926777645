import Swal from "sweetalert2";

export const getAgendaCalendar = ({
  asunto,
  dateStart,
  dateEnd,
  description,
  location,
}) => {
  try {
    let urlGoogle = `https://calendar.google.com/calendar/r/eventedit?text=${asunto}&dates=${dateStart}/${dateEnd}&details=${description}&location=${location}&sf=true&output=xml`;
    window.open(urlGoogle, "_blank");
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const downAgendaOutlookV2 = ({
  asunto,
  dateStart,
  dateEnd,
  description,
  location,
  asuntoAlarma,
}) => {
  let urlOutlook = [
    "BEGIN:VCALENDAR",
    "VERSION:2.0",
    "BEGIN:VEVENT",
    "DTSTART:" + dateStart,
    "DTEND:" + dateEnd,
    "SUMMARY:" + asunto,
    "DESCRIPTION:" + description,
    "LOCATION:" + location,
    "BEGIN:VALARM",
    "TRIGGER:-PT15M",
    "REPEAT:1",
    "DURATION:PT15M",
    "ACTION:DISPLAY",
    "DESCRIPTION:" + asuntoAlarma,
    "END:VALARM",
    "END:VEVENT",
    "END:VCALENDAR",
  ].join("\n");
  let fileName = "Encuentros.ics";
  fileName = fileName || "Calendario.ics";
  const data =
    "data:text/calendar;charset=utf8," + encodeURIComponent(urlOutlook);
  const downloadLink = document.createElement("a");
  try {
    if ("download" in downloadLink) {
      // Descarga compatible con navegadores que admiten el atributo 'download'
      downloadLink.href = data;
      downloadLink.download = fileName;
      downloadLink.style.display = "none";
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    } else {
      // Descarga para Safari y otros navegadores que no admiten el atributo 'download'
      const blob = new Blob([data], { type: "text/calendar" });
      const blobURL = URL.createObjectURL(blob);
      downloadLink.href = blobURL;
      downloadLink.download = fileName;
      downloadLink.style.display = "none";
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      URL.revokeObjectURL(blobURL);
    }
  } catch (error) {
    console.log(error);
    Swal.fire({
      icon: "error",
      title: "Ocurrió un error. Intente nuevamente.",
      buttonConfirm: "Aceptar",
    });
  }
};

export const getAgendaYahoo = ({
  asunto,
  dateStart,
  dateEnd,
  description,
  location,
  duration,
}) => {
  try {
    let urlYahoo = `http://calendar.yahoo.com/?v=60&view=d&type=20&title=${asunto}&st=${dateStart}&et=${dateEnd}&dur=${duration}&desc=${description}&in_loc=${location}`;
    window.open(urlYahoo, "_blank");
  } catch (error) {
    console.log(error);
    return false;
  }
};

export function ObtenerHoraAgenda(horaBase = 7,horaMaxima = 20){
    const intervalo = 30 * 60 * 1000; 
    let ArregloHoras = []
    let id = 1
    
    for(let horaIterada = horaBase; horaIterada <= horaMaxima; ++horaIterada) {
        let horaBase = new Date ();
        horaBase.setHours (horaIterada, 0, 0);
        let [hora, minuto] = horaBase.toLocaleTimeString('es-Mx').split(":")
        const objetoHorario = {
            id: id,
            hora: horaBase.getHours() < 12 ? (hora + ":" + minuto + " am") : (hora >= 13 ? ((hora - 12) + ":" + minuto) : (hora + ":" + minuto) ) + " pm",
            value: horaBase.getHours() > 12 ? parseInt((parseInt(hora) + 12) + minuto) : parseInt(hora + minuto),
            activo: false,
            hora2: horaBase.getHours() > 12 ? (parseInt(hora) + 12) + ":" + minuto : hora + ":" + minuto,
        }
        ArregloHoras.push(objetoHorario)
        id++;
        if(horaIterada < horaMaxima) {
            let NuevaHora =  new Date (horaBase.getTime () + intervalo);
            let [horaNueva, minutoNuevo] = NuevaHora.toLocaleTimeString('es-Mx').split(":")
            const objetoHorarioNuevo = {
                id: id,
                hora: horaBase.getHours() < 12 ? (horaNueva + ":" + minutoNuevo + " am") : (horaNueva >= 13 ? ((horaNueva - 12) + ":" + minutoNuevo) : (horaNueva + ":" + minutoNuevo) ) + " pm",
                value: horaBase.getHours() > 12 ? parseInt((parseInt(horaNueva) + 12) + minutoNuevo) : parseInt(horaNueva + minutoNuevo),
                activo: false,
                hora2:  horaBase.getHours() > 12 ? (parseInt(horaNueva) + 12) + ":" + minutoNuevo : horaNueva + ":" + minutoNuevo,
            }
            ArregloHoras.push(objetoHorarioNuevo)
            id++;
        }
    }
    return ArregloHoras
}
